/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';

/**
 * 
 * @export
 * @interface UpdateAccountInput
 */
export interface UpdateAccountInput {
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    surname: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountInput
     */
    age?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    engFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    engLastName?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateAccountInput
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    prefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    tel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    zipcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInput
     */
    captchaResponse?: string | null;
}

export function UpdateAccountInputFromJSON(json: any): UpdateAccountInput {
    return UpdateAccountInputFromJSONTyped(json, false);
}

export function UpdateAccountInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAccountInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': json['userName'],
        'name': json['name'],
        'surname': json['surname'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'engFirstName': !exists(json, 'engFirstName') ? undefined : json['engFirstName'],
        'engLastName': !exists(json, 'engLastName') ? undefined : json['engLastName'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'prefix': !exists(json, 'prefix') ? undefined : json['prefix'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'emailAddress': json['emailAddress'],
        'captchaResponse': !exists(json, 'captchaResponse') ? undefined : json['captchaResponse'],
    };
}

export function UpdateAccountInputToJSON(value?: UpdateAccountInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'age': value.age,
        'engFirstName': value.engFirstName,
        'engLastName': value.engLastName,
        'gender': GenderToJSON(value.gender),
        'prefix': value.prefix,
        'phone': value.phone,
        'tel': value.tel,
        'region': value.region,
        'country': value.country,
        'zipcode': value.zipcode,
        'address': value.address,
        'emailAddress': value.emailAddress,
        'captchaResponse': value.captchaResponse,
    };
}

