/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserEventDto,
    UserEventDtoFromJSON,
    UserEventDtoToJSON,
} from '../models';

export interface ApiServicesAppUserEventInsertPostRequest {
    userEventDto?: UserEventDto;
}

/**
 * 
 */
export class UserEventApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppUserEventInsertPostRaw(requestParameters: ApiServicesAppUserEventInsertPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/UserEvent/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserEventDtoToJSON(requestParameters.userEventDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserEventInsertPost(requestParameters: ApiServicesAppUserEventInsertPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserEventInsertPostRaw(requestParameters, initOverrides);
    }

}
