/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminOrderListDtoPagedResultDto,
    AdminOrderListDtoPagedResultDtoFromJSON,
    AdminOrderListDtoPagedResultDtoToJSON,
    OrderIntent,
    OrderIntentFromJSON,
    OrderIntentToJSON,
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusToJSON,
} from '../models';

export interface ApiServicesAppAdminOrderGetAllGetRequest {
    userId?: number;
    startDate?: Date;
    endDate?: Date;
    status?: OrderStatus;
    intent?: OrderIntent;
    keyword?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

/**
 * 
 */
export class AdminOrderApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppAdminOrderGetAllGetRaw(requestParameters: ApiServicesAppAdminOrderGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminOrderListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.status !== undefined) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.intent !== undefined) {
            queryParameters['Intent'] = requestParameters.intent;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/AdminOrder/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminOrderListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdminOrderGetAllGet(requestParameters: ApiServicesAppAdminOrderGetAllGetRequest, initOverrides?: RequestInit): Promise<AdminOrderListDtoPagedResultDto> {
        const response = await this.apiServicesAppAdminOrderGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
