/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientType,
    ClientTypeFromJSON,
    ClientTypeFromJSONTyped,
    ClientTypeToJSON,
} from './ClientType';

/**
 * 
 * @export
 * @interface AdvertisementTranslatedDto
 */
export interface AdvertisementTranslatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementTranslatedDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementTranslatedDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementTranslatedDto
     */
    slug?: string | null;
    /**
     * 
     * @type {ClientType}
     * @memberof AdvertisementTranslatedDto
     */
    client?: ClientType;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementTranslatedDto
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementTranslatedDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementTranslatedDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementTranslatedDto
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementTranslatedDto
     */
    content?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AdvertisementTranslatedDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdvertisementTranslatedDto
     */
    lastModificationTime?: Date | null;
}

export function AdvertisementTranslatedDtoFromJSON(json: any): AdvertisementTranslatedDto {
    return AdvertisementTranslatedDtoFromJSONTyped(json, false);
}

export function AdvertisementTranslatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvertisementTranslatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'client': !exists(json, 'client') ? undefined : ClientTypeFromJSON(json['client']),
        'link': !exists(json, 'link') ? undefined : json['link'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function AdvertisementTranslatedDtoToJSON(value?: AdvertisementTranslatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'slug': value.slug,
        'client': ClientTypeToJSON(value.client),
        'link': value.link,
        'title': value.title,
        'description': value.description,
        'image': value.image,
        'content': value.content,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

