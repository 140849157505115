import { Dispatch, FC, Fragment, PropsWithChildren} from 'react';
import { memo} from 'react';
import { ReactNode, createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import type { NextPage } from 'next';
import { PropsOf } from 'src/types/react-helper';
import { AuthTemplate } from './AuthTemplate';
import { CommonTemplate } from './CommonTemplate';
import { isEqual } from 'lodash';
import { PracticePopup } from '@/components/practicePopup/PracticePopup';
export type AvailableLayout = {
  "default": typeof CommonTemplate,
  "auth":typeof AuthTemplate,
  "empty":typeof Fragment
}
export type LayoutType = keyof AvailableLayout;
export type LayoutTypeToLayoutPropsType =  {
  [key in LayoutType]: Omit<PropsOf<AvailableLayout[key]>, "children">
}
export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
   layout?: LayoutType
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayoutContextInterface {
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseLayout extends LayoutContextInterface {
  updateBridge?:boolean,
  setUpdateBridge?:React.Dispatch<React.SetStateAction<boolean>>

}

const LayoutCtx = createContext<LayoutContextInterface | null>({
} as {
  updateBridge?:boolean,
  setUpdateBridge?:React.Dispatch<React.SetStateAction<boolean>>
});

export const LayoutProvider: FC<PropsWithChildren<{
  layoutProps: LayoutTypeToLayoutPropsType,
  layoutType?: LayoutType,
  perPageLayoutProps?: any,
  
}>> = memo(({ layoutProps, layoutType, children, perPageLayoutProps}) => {
  const Layout = useMemo(()=>{
    switch (layoutType) {
    case "auth":
      return AuthTemplate
    case "empty":
      return Fragment
    default:
      return CommonTemplate
    }
  }, [layoutType]);

  const [updateBridge,setUpdateBridge]=useState<boolean>(false)

  
  return <LayoutCtx.Provider value={{updateBridge,setUpdateBridge}}>
    <Layout {...(layoutType?layoutProps[layoutType]:{})} {...(perPageLayoutProps??{})} >{children}</Layout>
  </LayoutCtx.Provider>
}, (prev, next) => {
  return prev.layoutType == next.layoutType && isEqual(prev.layoutProps, next.layoutProps) && isEqual(prev.perPageLayoutProps, next.perPageLayoutProps)&& isEqual(prev.children, next.children);
});
LayoutProvider.displayName = "LayoutProvider";

/**
 * 
 * @returns currently serve no purpose
 */
export function useLayout(): UseLayout {
  const ctx = useContext(LayoutCtx);
  return ctx!;
}

export default useLayout;
