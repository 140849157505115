/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    School,
    SchoolFromJSON,
    SchoolFromJSONTyped,
    SchoolToJSON,
} from './School';
import {
    StudentGroup,
    StudentGroupFromJSON,
    StudentGroupFromJSONTyped,
    StudentGroupToJSON,
} from './StudentGroup';
import {
    StudentGroupTagTranslation,
    StudentGroupTagTranslationFromJSON,
    StudentGroupTagTranslationFromJSONTyped,
    StudentGroupTagTranslationToJSON,
} from './StudentGroupTagTranslation';

/**
 * 
 * @export
 * @interface StudentGroupTag
 */
export interface StudentGroupTag {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTag
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTag
     */
    schoolId?: number;
    /**
     * 
     * @type {School}
     * @memberof StudentGroupTag
     */
    school?: School;
    /**
     * 
     * @type {Array<StudentGroup>}
     * @memberof StudentGroupTag
     */
    studentGroups?: Array<StudentGroup> | null;
    /**
     * 
     * @type {Array<StudentGroupTagTranslation>}
     * @memberof StudentGroupTag
     */
    translations?: Array<StudentGroupTagTranslation> | null;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTag
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroupTag
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTag
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroupTag
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTag
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroupTag
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof StudentGroupTag
     */
    isDeleted?: boolean;
}

export function StudentGroupTagFromJSON(json: any): StudentGroupTag {
    return StudentGroupTagFromJSONTyped(json, false);
}

export function StudentGroupTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'school': !exists(json, 'school') ? undefined : SchoolFromJSON(json['school']),
        'studentGroups': !exists(json, 'studentGroups') ? undefined : (json['studentGroups'] === null ? null : (json['studentGroups'] as Array<any>).map(StudentGroupFromJSON)),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StudentGroupTagTranslationFromJSON)),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function StudentGroupTagToJSON(value?: StudentGroupTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'school': SchoolToJSON(value.school),
        'studentGroups': value.studentGroups === undefined ? undefined : (value.studentGroups === null ? null : (value.studentGroups as Array<any>).map(StudentGroupToJSON)),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StudentGroupTagTranslationToJSON)),
        'creatorUserId': value.creatorUserId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
    };
}

