/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductItemListDto,
    ProductItemListDtoFromJSON,
    ProductItemListDtoFromJSONTyped,
    ProductItemListDtoToJSON,
} from './ProductItemListDto';
import {
    ProductPurchaseMethod,
    ProductPurchaseMethodFromJSON,
    ProductPurchaseMethodFromJSONTyped,
    ProductPurchaseMethodToJSON,
} from './ProductPurchaseMethod';

/**
 * 
 * @export
 * @interface ProductListDto
 */
export interface ProductListDto {
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    id?: number;
    /**
     * 
     * @type {ProductPurchaseMethod}
     * @memberof ProductListDto
     */
    productPurchaseMethod?: ProductPurchaseMethod;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListDto
     */
    autoRenew?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    originPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductListDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductListDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductListDto
     */
    cover?: string | null;
    /**
     * 
     * @type {Array<ProductItemListDto>}
     * @memberof ProductListDto
     */
    items?: Array<ProductItemListDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductListDto
     */
    lastModificationTime?: Date | null;
}

export function ProductListDtoFromJSON(json: any): ProductListDto {
    return ProductListDtoFromJSONTyped(json, false);
}

export function ProductListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productPurchaseMethod': !exists(json, 'productPurchaseMethod') ? undefined : ProductPurchaseMethodFromJSON(json['productPurchaseMethod']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'autoRenew': !exists(json, 'autoRenew') ? undefined : json['autoRenew'],
        'originPrice': !exists(json, 'originPrice') ? undefined : json['originPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cover': !exists(json, 'cover') ? undefined : json['cover'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ProductItemListDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function ProductListDtoToJSON(value?: ProductListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productPurchaseMethod': ProductPurchaseMethodToJSON(value.productPurchaseMethod),
        'isActive': value.isActive,
        'autoRenew': value.autoRenew,
        'originPrice': value.originPrice,
        'price': value.price,
        'currency': value.currency,
        'title': value.title,
        'description': value.description,
        'cover': value.cover,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ProductItemListDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

