/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FAQItemAdminListDto,
    FAQItemAdminListDtoFromJSON,
    FAQItemAdminListDtoFromJSONTyped,
    FAQItemAdminListDtoToJSON,
} from './FAQItemAdminListDto';

/**
 * 
 * @export
 * @interface FAQItemAdminListDtoListResultDto
 */
export interface FAQItemAdminListDtoListResultDto {
    /**
     * 
     * @type {Array<FAQItemAdminListDto>}
     * @memberof FAQItemAdminListDtoListResultDto
     */
    items?: Array<FAQItemAdminListDto> | null;
}

export function FAQItemAdminListDtoListResultDtoFromJSON(json: any): FAQItemAdminListDtoListResultDto {
    return FAQItemAdminListDtoListResultDtoFromJSONTyped(json, false);
}

export function FAQItemAdminListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQItemAdminListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(FAQItemAdminListDtoFromJSON)),
    };
}

export function FAQItemAdminListDtoListResultDtoToJSON(value?: FAQItemAdminListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(FAQItemAdminListDtoToJSON)),
    };
}

