/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchoolAdminListDto
 */
export interface SchoolAdminListDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolAdminListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminListDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminListDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminListDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SchoolAdminListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SchoolAdminListDto
     */
    schoolId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SchoolAdminListDto
     */
    creationTime?: Date;
}

export function SchoolAdminListDtoFromJSON(json: any): SchoolAdminListDto {
    return SchoolAdminListDtoFromJSONTyped(json, false);
}

export function SchoolAdminListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolAdminListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
    };
}

export function SchoolAdminListDtoToJSON(value?: SchoolAdminListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'isActive': value.isActive,
        'schoolId': value.schoolId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
    };
}

