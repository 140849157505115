/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Assembly,
    AssemblyFromJSON,
    AssemblyFromJSONTyped,
    AssemblyToJSON,
} from './Assembly';
import {
    ConstructorInfo,
    ConstructorInfoFromJSON,
    ConstructorInfoFromJSONTyped,
    ConstructorInfoToJSON,
} from './ConstructorInfo';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    EventInfo,
    EventInfoFromJSON,
    EventInfoFromJSONTyped,
    EventInfoToJSON,
} from './EventInfo';
import {
    FieldInfo,
    FieldInfoFromJSON,
    FieldInfoFromJSONTyped,
    FieldInfoToJSON,
} from './FieldInfo';
import {
    GenericParameterAttributes,
    GenericParameterAttributesFromJSON,
    GenericParameterAttributesFromJSONTyped,
    GenericParameterAttributesToJSON,
} from './GenericParameterAttributes';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoFromJSONTyped,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    MemberTypes,
    MemberTypesFromJSON,
    MemberTypesFromJSONTyped,
    MemberTypesToJSON,
} from './MemberTypes';
import {
    MethodBase,
    MethodBaseFromJSON,
    MethodBaseFromJSONTyped,
    MethodBaseToJSON,
} from './MethodBase';
import {
    MethodInfo,
    MethodInfoFromJSON,
    MethodInfoFromJSONTyped,
    MethodInfoToJSON,
} from './MethodInfo';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    PropertyInfo,
    PropertyInfoFromJSON,
    PropertyInfoFromJSONTyped,
    PropertyInfoToJSON,
} from './PropertyInfo';
import {
    RuntimeTypeHandle,
    RuntimeTypeHandleFromJSON,
    RuntimeTypeHandleFromJSONTyped,
    RuntimeTypeHandleToJSON,
} from './RuntimeTypeHandle';
import {
    StructLayoutAttribute,
    StructLayoutAttributeFromJSON,
    StructLayoutAttributeFromJSONTyped,
    StructLayoutAttributeToJSON,
} from './StructLayoutAttribute';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';
import {
    TypeAttributes,
    TypeAttributesFromJSON,
    TypeAttributesFromJSONTyped,
    TypeAttributesToJSON,
} from './TypeAttributes';

/**
 * 
 * @export
 * @interface TypeInfo
 */
export interface TypeInfo {
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof TypeInfo
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    readonly metadataToken?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isInterface?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof TypeInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    readonly namespace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    readonly assemblyQualifiedName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {Assembly}
     * @memberof TypeInfo
     */
    assembly?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof TypeInfo
     */
    module?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNested?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof TypeInfo
     */
    declaringMethod?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    underlyingSystemType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isConstructedGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isGenericParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isGenericTypeParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isGenericMethodParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isGenericTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSZArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isVariableBoundArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isByRefLike?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly hasElementType?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    readonly genericTypeArguments?: Array<Type> | null;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    readonly genericParameterPosition?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof TypeInfo
     */
    genericParameterAttributes?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof TypeInfo
     */
    attributes?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isImport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSealed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNestedAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNestedFamANDAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNestedFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNestedFamORAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNestedPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNestedPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isNotPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isAutoLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isExplicitLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isLayoutSequential?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isAnsiClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isAutoClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isUnicodeClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isCOMObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isContextful?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isEnum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isMarshalByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isPrimitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isValueType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSignatureType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSecurityTransparent?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof TypeInfo
     */
    structLayoutAttribute?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof TypeInfo
     */
    typeInitializer?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof TypeInfo
     */
    typeHandle?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    readonly guid?: string;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    baseType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isSerializable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly containsGenericParameters?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    readonly isVisible?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    readonly genericTypeParameters?: Array<Type> | null;
    /**
     * 
     * @type {Array<ConstructorInfo>}
     * @memberof TypeInfo
     */
    readonly declaredConstructors?: Array<ConstructorInfo> | null;
    /**
     * 
     * @type {Array<EventInfo>}
     * @memberof TypeInfo
     */
    readonly declaredEvents?: Array<EventInfo> | null;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof TypeInfo
     */
    readonly declaredFields?: Array<FieldInfo> | null;
    /**
     * 
     * @type {Array<MemberInfo>}
     * @memberof TypeInfo
     */
    readonly declaredMembers?: Array<MemberInfo> | null;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof TypeInfo
     */
    readonly declaredMethods?: Array<MethodInfo> | null;
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof TypeInfo
     */
    readonly declaredNestedTypes?: Array<TypeInfo> | null;
    /**
     * 
     * @type {Array<PropertyInfo>}
     * @memberof TypeInfo
     */
    readonly declaredProperties?: Array<PropertyInfo> | null;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    readonly implementedInterfaces?: Array<Type> | null;
}

export function TypeInfoFromJSON(json: any): TypeInfo {
    return TypeInfoFromJSONTyped(json, false);
}

export function TypeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'isCollectible': !exists(json, 'isCollectible') ? undefined : json['isCollectible'],
        'metadataToken': !exists(json, 'metadataToken') ? undefined : json['metadataToken'],
        'isInterface': !exists(json, 'isInterface') ? undefined : json['isInterface'],
        'memberType': !exists(json, 'memberType') ? undefined : MemberTypesFromJSON(json['memberType']),
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'assemblyQualifiedName': !exists(json, 'assemblyQualifiedName') ? undefined : json['assemblyQualifiedName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'assembly': !exists(json, 'assembly') ? undefined : AssemblyFromJSON(json['assembly']),
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'isNested': !exists(json, 'isNested') ? undefined : json['isNested'],
        'declaringType': !exists(json, 'declaringType') ? undefined : TypeFromJSON(json['declaringType']),
        'declaringMethod': !exists(json, 'declaringMethod') ? undefined : MethodBaseFromJSON(json['declaringMethod']),
        'reflectedType': !exists(json, 'reflectedType') ? undefined : TypeFromJSON(json['reflectedType']),
        'underlyingSystemType': !exists(json, 'underlyingSystemType') ? undefined : TypeFromJSON(json['underlyingSystemType']),
        'isTypeDefinition': !exists(json, 'isTypeDefinition') ? undefined : json['isTypeDefinition'],
        'isArray': !exists(json, 'isArray') ? undefined : json['isArray'],
        'isByRef': !exists(json, 'isByRef') ? undefined : json['isByRef'],
        'isPointer': !exists(json, 'isPointer') ? undefined : json['isPointer'],
        'isConstructedGenericType': !exists(json, 'isConstructedGenericType') ? undefined : json['isConstructedGenericType'],
        'isGenericParameter': !exists(json, 'isGenericParameter') ? undefined : json['isGenericParameter'],
        'isGenericTypeParameter': !exists(json, 'isGenericTypeParameter') ? undefined : json['isGenericTypeParameter'],
        'isGenericMethodParameter': !exists(json, 'isGenericMethodParameter') ? undefined : json['isGenericMethodParameter'],
        'isGenericType': !exists(json, 'isGenericType') ? undefined : json['isGenericType'],
        'isGenericTypeDefinition': !exists(json, 'isGenericTypeDefinition') ? undefined : json['isGenericTypeDefinition'],
        'isSZArray': !exists(json, 'isSZArray') ? undefined : json['isSZArray'],
        'isVariableBoundArray': !exists(json, 'isVariableBoundArray') ? undefined : json['isVariableBoundArray'],
        'isByRefLike': !exists(json, 'isByRefLike') ? undefined : json['isByRefLike'],
        'hasElementType': !exists(json, 'hasElementType') ? undefined : json['hasElementType'],
        'genericTypeArguments': !exists(json, 'genericTypeArguments') ? undefined : (json['genericTypeArguments'] === null ? null : (json['genericTypeArguments'] as Array<any>).map(TypeFromJSON)),
        'genericParameterPosition': !exists(json, 'genericParameterPosition') ? undefined : json['genericParameterPosition'],
        'genericParameterAttributes': !exists(json, 'genericParameterAttributes') ? undefined : GenericParameterAttributesFromJSON(json['genericParameterAttributes']),
        'attributes': !exists(json, 'attributes') ? undefined : TypeAttributesFromJSON(json['attributes']),
        'isAbstract': !exists(json, 'isAbstract') ? undefined : json['isAbstract'],
        'isImport': !exists(json, 'isImport') ? undefined : json['isImport'],
        'isSealed': !exists(json, 'isSealed') ? undefined : json['isSealed'],
        'isSpecialName': !exists(json, 'isSpecialName') ? undefined : json['isSpecialName'],
        'isClass': !exists(json, 'isClass') ? undefined : json['isClass'],
        'isNestedAssembly': !exists(json, 'isNestedAssembly') ? undefined : json['isNestedAssembly'],
        'isNestedFamANDAssem': !exists(json, 'isNestedFamANDAssem') ? undefined : json['isNestedFamANDAssem'],
        'isNestedFamily': !exists(json, 'isNestedFamily') ? undefined : json['isNestedFamily'],
        'isNestedFamORAssem': !exists(json, 'isNestedFamORAssem') ? undefined : json['isNestedFamORAssem'],
        'isNestedPrivate': !exists(json, 'isNestedPrivate') ? undefined : json['isNestedPrivate'],
        'isNestedPublic': !exists(json, 'isNestedPublic') ? undefined : json['isNestedPublic'],
        'isNotPublic': !exists(json, 'isNotPublic') ? undefined : json['isNotPublic'],
        'isPublic': !exists(json, 'isPublic') ? undefined : json['isPublic'],
        'isAutoLayout': !exists(json, 'isAutoLayout') ? undefined : json['isAutoLayout'],
        'isExplicitLayout': !exists(json, 'isExplicitLayout') ? undefined : json['isExplicitLayout'],
        'isLayoutSequential': !exists(json, 'isLayoutSequential') ? undefined : json['isLayoutSequential'],
        'isAnsiClass': !exists(json, 'isAnsiClass') ? undefined : json['isAnsiClass'],
        'isAutoClass': !exists(json, 'isAutoClass') ? undefined : json['isAutoClass'],
        'isUnicodeClass': !exists(json, 'isUnicodeClass') ? undefined : json['isUnicodeClass'],
        'isCOMObject': !exists(json, 'isCOMObject') ? undefined : json['isCOMObject'],
        'isContextful': !exists(json, 'isContextful') ? undefined : json['isContextful'],
        'isEnum': !exists(json, 'isEnum') ? undefined : json['isEnum'],
        'isMarshalByRef': !exists(json, 'isMarshalByRef') ? undefined : json['isMarshalByRef'],
        'isPrimitive': !exists(json, 'isPrimitive') ? undefined : json['isPrimitive'],
        'isValueType': !exists(json, 'isValueType') ? undefined : json['isValueType'],
        'isSignatureType': !exists(json, 'isSignatureType') ? undefined : json['isSignatureType'],
        'isSecurityCritical': !exists(json, 'isSecurityCritical') ? undefined : json['isSecurityCritical'],
        'isSecuritySafeCritical': !exists(json, 'isSecuritySafeCritical') ? undefined : json['isSecuritySafeCritical'],
        'isSecurityTransparent': !exists(json, 'isSecurityTransparent') ? undefined : json['isSecurityTransparent'],
        'structLayoutAttribute': !exists(json, 'structLayoutAttribute') ? undefined : StructLayoutAttributeFromJSON(json['structLayoutAttribute']),
        'typeInitializer': !exists(json, 'typeInitializer') ? undefined : ConstructorInfoFromJSON(json['typeInitializer']),
        'typeHandle': !exists(json, 'typeHandle') ? undefined : RuntimeTypeHandleFromJSON(json['typeHandle']),
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'baseType': !exists(json, 'baseType') ? undefined : TypeFromJSON(json['baseType']),
        'isSerializable': !exists(json, 'isSerializable') ? undefined : json['isSerializable'],
        'containsGenericParameters': !exists(json, 'containsGenericParameters') ? undefined : json['containsGenericParameters'],
        'isVisible': !exists(json, 'isVisible') ? undefined : json['isVisible'],
        'genericTypeParameters': !exists(json, 'genericTypeParameters') ? undefined : (json['genericTypeParameters'] === null ? null : (json['genericTypeParameters'] as Array<any>).map(TypeFromJSON)),
        'declaredConstructors': !exists(json, 'declaredConstructors') ? undefined : (json['declaredConstructors'] === null ? null : (json['declaredConstructors'] as Array<any>).map(ConstructorInfoFromJSON)),
        'declaredEvents': !exists(json, 'declaredEvents') ? undefined : (json['declaredEvents'] === null ? null : (json['declaredEvents'] as Array<any>).map(EventInfoFromJSON)),
        'declaredFields': !exists(json, 'declaredFields') ? undefined : (json['declaredFields'] === null ? null : (json['declaredFields'] as Array<any>).map(FieldInfoFromJSON)),
        'declaredMembers': !exists(json, 'declaredMembers') ? undefined : (json['declaredMembers'] === null ? null : (json['declaredMembers'] as Array<any>).map(MemberInfoFromJSON)),
        'declaredMethods': !exists(json, 'declaredMethods') ? undefined : (json['declaredMethods'] === null ? null : (json['declaredMethods'] as Array<any>).map(MethodInfoFromJSON)),
        'declaredNestedTypes': !exists(json, 'declaredNestedTypes') ? undefined : (json['declaredNestedTypes'] === null ? null : (json['declaredNestedTypes'] as Array<any>).map(TypeInfoFromJSON)),
        'declaredProperties': !exists(json, 'declaredProperties') ? undefined : (json['declaredProperties'] === null ? null : (json['declaredProperties'] as Array<any>).map(PropertyInfoFromJSON)),
        'implementedInterfaces': !exists(json, 'implementedInterfaces') ? undefined : (json['implementedInterfaces'] === null ? null : (json['implementedInterfaces'] as Array<any>).map(TypeFromJSON)),
    };
}

export function TypeInfoToJSON(value?: TypeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberType': MemberTypesToJSON(value.memberType),
        'assembly': AssemblyToJSON(value.assembly),
        'module': ModuleToJSON(value.module),
        'declaringType': TypeToJSON(value.declaringType),
        'declaringMethod': MethodBaseToJSON(value.declaringMethod),
        'reflectedType': TypeToJSON(value.reflectedType),
        'underlyingSystemType': TypeToJSON(value.underlyingSystemType),
        'genericParameterAttributes': GenericParameterAttributesToJSON(value.genericParameterAttributes),
        'attributes': TypeAttributesToJSON(value.attributes),
        'structLayoutAttribute': StructLayoutAttributeToJSON(value.structLayoutAttribute),
        'typeInitializer': ConstructorInfoToJSON(value.typeInitializer),
        'typeHandle': RuntimeTypeHandleToJSON(value.typeHandle),
        'baseType': TypeToJSON(value.baseType),
    };
}

