/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TestQuestionDto,
    TestQuestionDtoFromJSON,
    TestQuestionDtoFromJSONTyped,
    TestQuestionDtoToJSON,
} from './TestQuestionDto';
import {
    TestType,
    TestTypeFromJSON,
    TestTypeFromJSONTyped,
    TestTypeToJSON,
} from './TestType';

/**
 * 
 * @export
 * @interface PracticeDto
 */
export interface PracticeDto {
    /**
     * 
     * @type {number}
     * @memberof PracticeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    testName?: string | null;
    /**
     * 
     * @type {TestType}
     * @memberof PracticeDto
     */
    testType?: TestType;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    testTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    questionTypes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeDto
     */
    questionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeDto
     */
    testTimeLimit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeDto
     */
    totalPoints?: number | null;
    /**
     * 
     * @type {Array<TestQuestionDto>}
     * @memberof PracticeDto
     */
    questions?: Array<TestQuestionDto> | null;
}

export function PracticeDtoFromJSON(json: any): PracticeDto {
    return PracticeDtoFromJSONTyped(json, false);
}

export function PracticeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'testName': !exists(json, 'testName') ? undefined : json['testName'],
        'testType': !exists(json, 'testType') ? undefined : TestTypeFromJSON(json['testType']),
        'testTypeName': !exists(json, 'testTypeName') ? undefined : json['testTypeName'],
        'questionTypes': !exists(json, 'questionTypes') ? undefined : json['questionTypes'],
        'questionCount': !exists(json, 'questionCount') ? undefined : json['questionCount'],
        'testTimeLimit': !exists(json, 'testTimeLimit') ? undefined : json['testTimeLimit'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(TestQuestionDtoFromJSON)),
    };
}

export function PracticeDtoToJSON(value?: PracticeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'testName': value.testName,
        'testType': TestTypeToJSON(value.testType),
        'testTypeName': value.testTypeName,
        'questionTypes': value.questionTypes,
        'questionCount': value.questionCount,
        'testTimeLimit': value.testTimeLimit,
        'totalPoints': value.totalPoints,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(TestQuestionDtoToJSON)),
    };
}

