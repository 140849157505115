import clsx from 'clsx';
import { Field, FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { CommonInputProps } from '../common-input-props';
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { EyeIcon, EyeSlashIcon as EyeOffIcon } from '@heroicons/react/24/outline'
import PwOnIcon from "public/icons/auth/on.svg"
import PwOffIcon from "public/icons/auth/off.svg"

export type InputBoxVariant = "outsideStartAdornment";

export interface InputBoxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, CommonInputProps {
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    type: string;
    variant?: InputBoxVariant;
    hideError?: boolean;
    startAdornments?: string | React.ReactElement<SVGSVGElement>;
    endAdornments?: string | React.ReactElement<SVGSVGElement>;
    onValueChanged?: (value: string | number | Date) => void;
}

export const commonClasses = clsx`font-medium rounded-xl text-sm px-5 py-2.5 text-center mr-2 mb-2 focus:ring-4 transition`;

const InputBox = (props: InputBoxProps) => {
  const { children, type, variant, hideError, label, errorMessage, inputProps, startAdornments, endAdornments, onValueChanged, disableErrorMessage, ...rest } = props;
  const isPassword = type == "password";
  const [iconSwitch, setIconSwitch] = useState(0);
  const [innerType, setInnerType] = useState(type);

  useEffect(() => {
    let newType = "";

    if (iconSwitch == 0) {
      newType = "password"
    } else {
      newType = "text"
    }

    setInnerType(newType);
  }), [iconSwitch];

  return <div className={clsx(rest.className, "")}>
    {label &&
            <label htmlFor={inputProps?.id} className={clsx("block text-sm font-medium text-gray-700")}>
              {label}
            </label>
    }
    <div className={clsx("relative flex", variant != "outsideStartAdornment" && " shadow-sm")}>
      {startAdornments &&
                <div className={clsx(variant != "outsideStartAdornment" && "absolute pl-3", variant == "outsideStartAdornment" && "pl-2 pr-2", "inset-y-0 left-0 flex items-center pointer-events-none")}>
                  <span className="text-gray-500 sm:text-sm">{startAdornments}</span>
                </div>
      }
      <input
        {...inputProps}
        onChange={(ev) => { inputProps && inputProps?.onChange?.(ev); onValueChanged?.(ev.target.value); }}
        type={isPassword ? innerType : type}
        className={clsx("transition w-full focus:ring-primary-500 focus:border-primary-500 group-focus:ring-primary-500 group-focus:border-primary-500 block sm:text-sm border-gray-300 p-3 flex-grow",
          //startAdornments ? "pl-12" : "pr-3",
          startAdornments ? variant == "outsideStartAdornment" ? "pl-3" : "pl-12" : "pl-3",
          (endAdornments|| isPassword) ? "pr-12" : "pr-3",
          inputProps?.className,
          errorMessage && "border-danger-500")}
      />
      {endAdornments &&
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <span className="text-gray-500 sm:text-sm">{endAdornments}</span>
                </div>
      }
      {!endAdornments && isPassword &&
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" 
                  onClick={() => { iconSwitch == 0 ? setIconSwitch(1) : setIconSwitch(0) }}>
                  <span className="text-gray-500 sm:text-sm">{iconSwitch == 0 ? 
                    <PwOnIcon  className=" w-11 h-11 text-primary-500  " /> : 
                    <PwOffIcon className="text-primary-500  w-11 h-11 " />}</span>
                </div>
      }
    </div>
    {(errorMessage && !disableErrorMessage && !hideError) &&
            <span className='mt-1 block text-sm font-thin pl-2 text-danger-500 align-middle'><InformationCircleIcon className="w-5 h-5 mr-1 inline-block" aria-hidden={true} />{errorMessage}</span>
    }
  </div>

}

export const FormikInputBox = (type: string, disableErrorMessage?: boolean) => (props: InputBoxProps & FieldProps<any, any>) =>
  InputBox({
    ...props,
    type,
    inputProps: { placeholder: props.placeholder, ...props.inputProps, ...props.field },
    errorMessage: props.errorMessage ?? props.form?.errors?.[props.field?.name],
    label: props.label ?? props.field.name,
    disableErrorMessage: disableErrorMessage
  });

InputBox.displayName = 'InputBox';

export default InputBox;