/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';
import {
    TimeSpan,
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';

/**
 * 
 * @export
 * @interface SubjectStatementDaily
 */
export interface SubjectStatementDaily {
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementDaily
     */
    subjectId?: number;
    /**
     * 
     * @type {SubjectType}
     * @memberof SubjectStatementDaily
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementDaily
     */
    subjectName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementDaily
     */
    term?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementDaily
     */
    gradeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementDaily
     */
    gradeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementDaily
     */
    stageCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementDaily
     */
    stageName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementDaily
     */
    day?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof SubjectStatementDaily
     */
    timeSpent?: TimeSpan;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementDaily
     */
    score?: number;
}

export function SubjectStatementDailyFromJSON(json: any): SubjectStatementDaily {
    return SubjectStatementDailyFromJSONTyped(json, false);
}

export function SubjectStatementDailyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectStatementDaily {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'subjectName': !exists(json, 'subjectName') ? undefined : json['subjectName'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'gradeName': !exists(json, 'gradeName') ? undefined : json['gradeName'],
        'stageCode': !exists(json, 'stageCode') ? undefined : json['stageCode'],
        'stageName': !exists(json, 'stageName') ? undefined : json['stageName'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'timeSpent': !exists(json, 'timeSpent') ? undefined : TimeSpanFromJSON(json['timeSpent']),
        'score': !exists(json, 'score') ? undefined : json['score'],
    };
}

export function SubjectStatementDailyToJSON(value?: SubjectStatementDaily | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectId': value.subjectId,
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'subjectName': value.subjectName,
        'term': value.term,
        'gradeCode': value.gradeCode,
        'gradeName': value.gradeName,
        'stageCode': value.stageCode,
        'stageName': value.stageName,
        'day': value.day,
        'timeSpent': TimeSpanToJSON(value.timeSpent),
        'score': value.score,
    };
}

