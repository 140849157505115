import React, { useState } from 'react';
import clsx from 'clsx';

const DropDownMenu = (props: { button: JSX.Element, items?: JSX.Element[] }) => {
  const { button, items } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (<>
    <div className={clsx("relative h-full")} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      {button}
      <div className={clsx("absolute left-1/2 -translate-x-1/2 w-full shadow-md", !isOpen && "hidden")}>
        {
          items?.map((it,index) =>
            <div key={index}>{it}</div>)
        }
      </div>
    </div>
  </>)
}

export default DropDownMenu;