import loadingJson from 'public/lottie/loading.json';
import newLoadingJson from 'public/lottie/new_loading.json';
import Lottie, { LottieProps } from 'react-lottie-player';
const LoadingLottie:React.FC<LottieProps> = (props) => {
  return <Lottie
    loop
    animationData={newLoadingJson}
    play
    style={{ width: 300, height: 300 }}
    {...props}
  />
}
export default LoadingLottie;