/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailDto,
    QuestionDetailDtoFromJSON,
    QuestionDetailDtoFromJSONTyped,
    QuestionDetailDtoToJSON,
} from './QuestionDetailDto';

/**
 * 
 * @export
 * @interface TestResultTestSubQuestionDto
 */
export interface TestResultTestSubQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof TestResultTestSubQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestSubQuestionDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestSubQuestionDto
     */
    points?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestResultTestSubQuestionDto
     */
    testerAnswer?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestResultTestSubQuestionDto
     */
    isTesterAnswerCorrect?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestSubQuestionDto
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestSubQuestionDto
     */
    teacherUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof TestResultTestSubQuestionDto
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {QuestionDetailDto}
     * @memberof TestResultTestSubQuestionDto
     */
    question?: QuestionDetailDto;
}

export function TestResultTestSubQuestionDtoFromJSON(json: any): TestResultTestSubQuestionDto {
    return TestResultTestSubQuestionDtoFromJSONTyped(json, false);
}

export function TestResultTestSubQuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestResultTestSubQuestionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'testerAnswer': !exists(json, 'testerAnswer') ? undefined : json['testerAnswer'],
        'isTesterAnswerCorrect': !exists(json, 'isTesterAnswerCorrect') ? undefined : json['isTesterAnswerCorrect'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'teacherUserId': !exists(json, 'teacherUserId') ? undefined : json['teacherUserId'],
        'teacherComment': !exists(json, 'teacherComment') ? undefined : json['teacherComment'],
        'question': !exists(json, 'question') ? undefined : QuestionDetailDtoFromJSON(json['question']),
    };
}

export function TestResultTestSubQuestionDtoToJSON(value?: TestResultTestSubQuestionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'points': value.points,
        'testerAnswer': value.testerAnswer,
        'isTesterAnswerCorrect': value.isTesterAnswerCorrect,
        'score': value.score,
        'teacherUserId': value.teacherUserId,
        'teacherComment': value.teacherComment,
        'question': QuestionDetailDtoToJSON(value.question),
    };
}

