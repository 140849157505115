/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuntimeFieldHandle
 */
export interface RuntimeFieldHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeFieldHandle
     */
    value?: object;
}

export function RuntimeFieldHandleFromJSON(json: any): RuntimeFieldHandle {
    return RuntimeFieldHandleFromJSONTyped(json, false);
}

export function RuntimeFieldHandleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeFieldHandle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function RuntimeFieldHandleToJSON(value?: RuntimeFieldHandle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}

