/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SettingItemDto,
    SettingItemDtoFromJSON,
    SettingItemDtoToJSON,
} from '../models';

export interface ApiServicesAppSettingsGetAppSettingsGetRequest {
    settingNamespace?: string;
}

export interface ApiServicesAppSettingsGetTenantSettingsGetRequest {
    settingNamespace?: string;
}

export interface ApiServicesAppSettingsUpdateAppSettingsPutRequest {
    requestBody?: { [key: string]: string; };
}

export interface ApiServicesAppSettingsUpdateTenantSettingsPutRequest {
    requestBody?: { [key: string]: string; };
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSettingsGetAppSettingsGetRaw(requestParameters: ApiServicesAppSettingsGetAppSettingsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SettingItemDto>>> {
        const queryParameters: any = {};

        if (requestParameters.settingNamespace !== undefined) {
            queryParameters['settingNamespace'] = requestParameters.settingNamespace;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Settings/GetAppSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingItemDtoFromJSON));
    }

    /**
     */
    async apiServicesAppSettingsGetAppSettingsGet(requestParameters: ApiServicesAppSettingsGetAppSettingsGetRequest, initOverrides?: RequestInit): Promise<Array<SettingItemDto>> {
        const response = await this.apiServicesAppSettingsGetAppSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSettingsGetTenantSettingsGetRaw(requestParameters: ApiServicesAppSettingsGetTenantSettingsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SettingItemDto>>> {
        const queryParameters: any = {};

        if (requestParameters.settingNamespace !== undefined) {
            queryParameters['settingNamespace'] = requestParameters.settingNamespace;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Settings/GetTenantSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingItemDtoFromJSON));
    }

    /**
     */
    async apiServicesAppSettingsGetTenantSettingsGet(requestParameters: ApiServicesAppSettingsGetTenantSettingsGetRequest, initOverrides?: RequestInit): Promise<Array<SettingItemDto>> {
        const response = await this.apiServicesAppSettingsGetTenantSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSettingsUpdateAppSettingsPutRaw(requestParameters: ApiServicesAppSettingsUpdateAppSettingsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Settings/UpdateAppSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSettingsUpdateAppSettingsPut(requestParameters: ApiServicesAppSettingsUpdateAppSettingsPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSettingsUpdateAppSettingsPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSettingsUpdateTenantSettingsPutRaw(requestParameters: ApiServicesAppSettingsUpdateTenantSettingsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Settings/UpdateTenantSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSettingsUpdateTenantSettingsPut(requestParameters: ApiServicesAppSettingsUpdateTenantSettingsPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSettingsUpdateTenantSettingsPutRaw(requestParameters, initOverrides);
    }

}
