/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAvatorInput
 */
export interface UpdateAvatorInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateAvatorInput
     */
    avator?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAvatorInput
     */
    id?: number;
}

export function UpdateAvatorInputFromJSON(json: any): UpdateAvatorInput {
    return UpdateAvatorInputFromJSONTyped(json, false);
}

export function UpdateAvatorInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAvatorInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avator': !exists(json, 'avator') ? undefined : json['avator'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function UpdateAvatorInputToJSON(value?: UpdateAvatorInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avator': value.avator,
        'id': value.id,
    };
}

