import clsx from 'clsx';
import React, { createContext, useContext, useMemo } from 'react';
import { useSpring, animated, SpringValue, SpringConfig } from '@react-spring/web'
import MouseParallaxContainer from './MouseParallaxContainer';
import MouseParallaxLayer from './MouseParallaxLayer';
export const MouseParallaxContext = createContext<{
    xy?: SpringValue< number[] >
}>({});
type NotFunction<T> = T extends Function ? never : T;
export interface MouseParallaxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    children:  React.ReactNode,
    springConfig?: SpringConfig 
}
const calc = (x:number , y:number) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

const MouseParallax = (props:MouseParallaxProps)=>{
  const {children,springConfig, ...rest} = props;
  const [springProps, set] = useSpring(() => ({ xy: [0, 0], config: springConfig?? { mass: 10, tension: 550, friction: 140 } }))
  const value = useMemo(() => {
    return {
      xy: springProps.xy
    }
  }, [springProps])

  return <div {...rest}  onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
    <MouseParallaxContext.Provider value={value}>
      {children}
    </MouseParallaxContext.Provider>
  </div>
}
MouseParallax.displayName = 'MouseParallax';
MouseParallax.Layer = MouseParallaxLayer;
MouseParallax.Container = MouseParallaxContainer;
export default MouseParallax;