/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticateModel
 */
export interface AuthenticateModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateModel
     */
    userNameOrEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateModel
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticateModel
     */
    rememberClient?: boolean;
}

export function AuthenticateModelFromJSON(json: any): AuthenticateModel {
    return AuthenticateModelFromJSONTyped(json, false);
}

export function AuthenticateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userNameOrEmailAddress': json['userNameOrEmailAddress'],
        'password': json['password'],
        'rememberClient': !exists(json, 'rememberClient') ? undefined : json['rememberClient'],
    };
}

export function AuthenticateModelToJSON(value?: AuthenticateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userNameOrEmailAddress': value.userNameOrEmailAddress,
        'password': value.password,
        'rememberClient': value.rememberClient,
    };
}

