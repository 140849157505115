/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductPurchaseMethod,
    ProductPurchaseMethodFromJSON,
    ProductPurchaseMethodFromJSONTyped,
    ProductPurchaseMethodToJSON,
} from './ProductPurchaseMethod';

/**
 * 
 * @export
 * @interface OrderProductListDto
 */
export interface OrderProductListDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductListDto
     */
    id?: number;
    /**
     * 
     * @type {ProductPurchaseMethod}
     * @memberof OrderProductListDto
     */
    productPurchaseMethod?: ProductPurchaseMethod;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductListDto
     */
    autoRenew?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductListDto
     */
    originPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductListDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductListDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductListDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductListDto
     */
    description?: string | null;
}

export function OrderProductListDtoFromJSON(json: any): OrderProductListDto {
    return OrderProductListDtoFromJSONTyped(json, false);
}

export function OrderProductListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productPurchaseMethod': !exists(json, 'productPurchaseMethod') ? undefined : ProductPurchaseMethodFromJSON(json['productPurchaseMethod']),
        'autoRenew': !exists(json, 'autoRenew') ? undefined : json['autoRenew'],
        'originPrice': !exists(json, 'originPrice') ? undefined : json['originPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function OrderProductListDtoToJSON(value?: OrderProductListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productPurchaseMethod': ProductPurchaseMethodToJSON(value.productPurchaseMethod),
        'autoRenew': value.autoRenew,
        'originPrice': value.originPrice,
        'price': value.price,
        'currency': value.currency,
        'title': value.title,
        'description': value.description,
    };
}

