/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleListDto,
    RoleListDtoFromJSON,
    RoleListDtoFromJSONTyped,
    RoleListDtoToJSON,
} from './RoleListDto';

/**
 * 
 * @export
 * @interface RoleListDtoListResultDto
 */
export interface RoleListDtoListResultDto {
    /**
     * 
     * @type {Array<RoleListDto>}
     * @memberof RoleListDtoListResultDto
     */
    items?: Array<RoleListDto> | null;
}

export function RoleListDtoListResultDtoFromJSON(json: any): RoleListDtoListResultDto {
    return RoleListDtoListResultDtoFromJSONTyped(json, false);
}

export function RoleListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(RoleListDtoFromJSON)),
    };
}

export function RoleListDtoListResultDtoToJSON(value?: RoleListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(RoleListDtoToJSON)),
    };
}

