/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Question,
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';
import {
    School,
    SchoolFromJSON,
    SchoolFromJSONTyped,
    SchoolToJSON,
} from './School';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';
import {
    TestRange,
    TestRangeFromJSON,
    TestRangeFromJSONTyped,
    TestRangeToJSON,
} from './TestRange';

/**
 * 
 * @export
 * @interface Lesson
 */
export interface Lesson {
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Lesson
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lesson
     */
    visible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lesson
     */
    subjectiveOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Lesson
     */
    freeTrial?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    sort?: number;
    /**
     * 
     * @type {any}
     * @memberof Lesson
     */
    readonly extra?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    extraSerialize?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    subjectId?: number;
    /**
     * 
     * @type {Subject}
     * @memberof Lesson
     */
    subject?: Subject;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    schoolId?: number | null;
    /**
     * 
     * @type {School}
     * @memberof Lesson
     */
    school?: School;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    maxQuestionCountPerPractice?: number;
    /**
     * 
     * @type {Array<TestRange>}
     * @memberof Lesson
     */
    testRanges?: Array<TestRange> | null;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Lesson
     */
    questions?: Array<Question> | null;
    /**
     * 
     * @type {Date}
     * @memberof Lesson
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Lesson
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Lesson
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Lesson
     */
    isDeleted?: boolean;
}

export function LessonFromJSON(json: any): Lesson {
    return LessonFromJSONTyped(json, false);
}

export function LessonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Lesson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
        'subjectiveOnly': !exists(json, 'subjectiveOnly') ? undefined : json['subjectiveOnly'],
        'freeTrial': !exists(json, 'freeTrial') ? undefined : json['freeTrial'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'extraSerialize': !exists(json, 'extraSerialize') ? undefined : json['extraSerialize'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subject': !exists(json, 'subject') ? undefined : SubjectFromJSON(json['subject']),
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'school': !exists(json, 'school') ? undefined : SchoolFromJSON(json['school']),
        'maxQuestionCountPerPractice': !exists(json, 'maxQuestionCountPerPractice') ? undefined : json['maxQuestionCountPerPractice'],
        'testRanges': !exists(json, 'testRanges') ? undefined : (json['testRanges'] === null ? null : (json['testRanges'] as Array<any>).map(TestRangeFromJSON)),
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(QuestionFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function LessonToJSON(value?: Lesson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'visible': value.visible,
        'subjectiveOnly': value.subjectiveOnly,
        'freeTrial': value.freeTrial,
        'name': value.name,
        'description': value.description,
        'sort': value.sort,
        'extraSerialize': value.extraSerialize,
        'subjectId': value.subjectId,
        'subject': SubjectToJSON(value.subject),
        'schoolId': value.schoolId,
        'school': SchoolToJSON(value.school),
        'maxQuestionCountPerPractice': value.maxQuestionCountPerPractice,
        'testRanges': value.testRanges === undefined ? undefined : (value.testRanges === null ? null : (value.testRanges as Array<any>).map(TestRangeToJSON)),
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(QuestionToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
    };
}

