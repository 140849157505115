/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminCreateChildUserInput,
    AdminCreateChildUserInputFromJSON,
    AdminCreateChildUserInputToJSON,
    AdminCreateMemberInput,
    AdminCreateMemberInputFromJSON,
    AdminCreateMemberInputToJSON,
    AdminUpdateChildUserInput,
    AdminUpdateChildUserInputFromJSON,
    AdminUpdateChildUserInputToJSON,
    AdminUpdateMemberInput,
    AdminUpdateMemberInputFromJSON,
    AdminUpdateMemberInputToJSON,
    ChildrenAccountDto,
    ChildrenAccountDtoFromJSON,
    ChildrenAccountDtoToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    Int64EntityDto,
    Int64EntityDtoFromJSON,
    Int64EntityDtoToJSON,
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoToJSON,
    MemberListDtoPagedResultDto,
    MemberListDtoPagedResultDtoFromJSON,
    MemberListDtoPagedResultDtoToJSON,
    ToggleActivateResult,
    ToggleActivateResultFromJSON,
    ToggleActivateResultToJSON,
} from '../models';

export interface ApiServicesAppMemberActivatePostRequest {
    int64EntityDto?: Int64EntityDto;
}

export interface ApiServicesAppMemberCreateChildPostRequest {
    adminCreateChildUserInput?: AdminCreateChildUserInput;
}

export interface ApiServicesAppMemberCreatePostRequest {
    adminCreateMemberInput?: AdminCreateMemberInput;
}

export interface ApiServicesAppMemberDeActivatePostRequest {
    int64EntityDto?: Int64EntityDto;
}

export interface ApiServicesAppMemberGetAllGetRequest {
    isActived?: boolean;
    search?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppMemberGetChildrenGetRequest {
    parentId?: number;
}

export interface ApiServicesAppMemberGetGetRequest {
    id?: number;
}

export interface ApiServicesAppMemberToggleActivatePutRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppMemberUpdateChildPutRequest {
    adminUpdateChildUserInput?: AdminUpdateChildUserInput;
}

export interface ApiServicesAppMemberUpdatePutRequest {
    adminUpdateMemberInput?: AdminUpdateMemberInput;
}

/**
 * 
 */
export class MemberApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppMemberActivatePostRaw(requestParameters: ApiServicesAppMemberActivatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/Activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int64EntityDtoToJSON(requestParameters.int64EntityDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppMemberActivatePost(requestParameters: ApiServicesAppMemberActivatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppMemberActivatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppMemberCreateChildPostRaw(requestParameters: ApiServicesAppMemberCreateChildPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/CreateChild`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCreateChildUserInputToJSON(requestParameters.adminCreateChildUserInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppMemberCreateChildPost(requestParameters: ApiServicesAppMemberCreateChildPostRequest, initOverrides?: RequestInit): Promise<MemberDto> {
        const response = await this.apiServicesAppMemberCreateChildPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberCreatePostRaw(requestParameters: ApiServicesAppMemberCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminCreateMemberInputToJSON(requestParameters.adminCreateMemberInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppMemberCreatePost(requestParameters: ApiServicesAppMemberCreatePostRequest, initOverrides?: RequestInit): Promise<MemberDto> {
        const response = await this.apiServicesAppMemberCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberDeActivatePostRaw(requestParameters: ApiServicesAppMemberDeActivatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/DeActivate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int64EntityDtoToJSON(requestParameters.int64EntityDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppMemberDeActivatePost(requestParameters: ApiServicesAppMemberDeActivatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppMemberDeActivatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppMemberGetAllGetRaw(requestParameters: ApiServicesAppMemberGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MemberListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.isActived !== undefined) {
            queryParameters['IsActived'] = requestParameters.isActived;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['Search'] = requestParameters.search;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Member/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppMemberGetAllGet(requestParameters: ApiServicesAppMemberGetAllGetRequest, initOverrides?: RequestInit): Promise<MemberListDtoPagedResultDto> {
        const response = await this.apiServicesAppMemberGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberGetAllMemberRolesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Member/GetAllMemberRoles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiServicesAppMemberGetAllMemberRolesGet(initOverrides?: RequestInit): Promise<{ [key: string]: string; }> {
        const response = await this.apiServicesAppMemberGetAllMemberRolesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberGetChildrenGetRaw(requestParameters: ApiServicesAppMemberGetChildrenGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ChildrenAccountDto>>> {
        const queryParameters: any = {};

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Member/GetChildren`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChildrenAccountDtoFromJSON));
    }

    /**
     */
    async apiServicesAppMemberGetChildrenGet(requestParameters: ApiServicesAppMemberGetChildrenGetRequest, initOverrides?: RequestInit): Promise<Array<ChildrenAccountDto>> {
        const response = await this.apiServicesAppMemberGetChildrenGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberGetGetRaw(requestParameters: ApiServicesAppMemberGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MemberDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Member/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppMemberGetGet(requestParameters: ApiServicesAppMemberGetGetRequest, initOverrides?: RequestInit): Promise<MemberDto> {
        const response = await this.apiServicesAppMemberGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberToggleActivatePutRaw(requestParameters: ApiServicesAppMemberToggleActivatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToggleActivateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/ToggleActivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleActivateResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppMemberToggleActivatePut(requestParameters: ApiServicesAppMemberToggleActivatePutRequest, initOverrides?: RequestInit): Promise<ToggleActivateResult> {
        const response = await this.apiServicesAppMemberToggleActivatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberUpdateChildPutRaw(requestParameters: ApiServicesAppMemberUpdateChildPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/UpdateChild`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateChildUserInputToJSON(requestParameters.adminUpdateChildUserInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppMemberUpdateChildPut(requestParameters: ApiServicesAppMemberUpdateChildPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppMemberUpdateChildPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppMemberUpdatePutRaw(requestParameters: ApiServicesAppMemberUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Member/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateMemberInputToJSON(requestParameters.adminUpdateMemberInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppMemberUpdatePut(requestParameters: ApiServicesAppMemberUpdatePutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppMemberUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
