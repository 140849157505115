import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useUserData } from "./UserDataHook";

const SocketContext = createContext<{
  socket: Socket | null;
  socketQuery: (input: string | URL, init?: RequestInit)=>Promise<Response>
    } | null>(null);
export const SocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { accessToken } = useUserData();
  const [ioclient, setIoClient] = useState<Socket | null>(null);
  useEffect(():any => {
    if(!accessToken )
      return;
    // connect to socket server
    const ioclient = io(process.env["NEXT_PUBLIC_REALTIME_HUB_URL"]??"", {     
      reconnectionDelayMax: 10000,
      auth:{
        token:accessToken
      }
    });
    ioclient.on('connect', ()=>{
      console.debug("SOCKET CONNECTED!", ioclient.id);
      setIoClient(ioclient);
    });
    // socket disconnet onUnmount if exists
    if (ioclient) return () => {
      setIoClient(null);
      ioclient.disconnect();
    }
  }, [accessToken]);
  const socketQuery = useCallback(
    (input: string | URL, init?: RequestInit) => {
      const newInput = new URL(input);
      newInput.hostname = process.env["NEXT_PUBLIC_REALTIME_HUB_URL"]??""
      console.log(newInput);
      return fetch(newInput.toString(), {...init??{}, headers: {...init?.headers??{}, Authorization: `Bearer ${accessToken}`}});
    },
    [accessToken],
  )
  
  return <SocketContext.Provider value={{socket: ioclient, socketQuery: socketQuery}}>
    {children}
  </SocketContext.Provider>
}
export const useSocket = ()=>{
  const ctx = useContext(SocketContext);
  if(!ctx)
    throw 'useSocket must be used within SocketProvider';
  return ctx;
}