/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChildUserDto,
    ChildUserDtoFromJSON,
    ChildUserDtoToJSON,
    UpdateChildInput,
    UpdateChildInputFromJSON,
    UpdateChildInputToJSON,
} from '../models';

export interface ApiServicesAppChildAccountUpdatePutRequest {
    updateChildInput?: UpdateChildInput;
}

/**
 * 
 */
export class ChildAccountApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppChildAccountGetGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildUserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ChildAccount/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppChildAccountGetGet(initOverrides?: RequestInit): Promise<ChildUserDto> {
        const response = await this.apiServicesAppChildAccountGetGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppChildAccountUpdatePutRaw(requestParameters: ApiServicesAppChildAccountUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ChildAccount/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChildInputToJSON(requestParameters.updateChildInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppChildAccountUpdatePut(requestParameters: ApiServicesAppChildAccountUpdatePutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppChildAccountUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
