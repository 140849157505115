/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchoolNameWithParentDto
 */
export interface SchoolNameWithParentDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolNameWithParentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolNameWithParentDto
     */
    name?: string | null;
    /**
     * 
     * @type {SchoolNameWithParentDto}
     * @memberof SchoolNameWithParentDto
     */
    parent?: SchoolNameWithParentDto;
}

export function SchoolNameWithParentDtoFromJSON(json: any): SchoolNameWithParentDto {
    return SchoolNameWithParentDtoFromJSONTyped(json, false);
}

export function SchoolNameWithParentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolNameWithParentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parent': !exists(json, 'parent') ? undefined : SchoolNameWithParentDtoFromJSON(json['parent']),
    };
}

export function SchoolNameWithParentDtoToJSON(value?: SchoolNameWithParentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'parent': SchoolNameWithParentDtoToJSON(value.parent),
    };
}

