/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    MethodInfo,
    MethodInfoFromJSON,
    MethodInfoFromJSONTyped,
    MethodInfoToJSON,
} from './MethodInfo';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    SecurityRuleSet,
    SecurityRuleSetFromJSON,
    SecurityRuleSetFromJSONTyped,
    SecurityRuleSetToJSON,
} from './SecurityRuleSet';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';
import {
    TypeInfo,
    TypeInfoFromJSON,
    TypeInfoFromJSONTyped,
    TypeInfoToJSON,
} from './TypeInfo';

/**
 * 
 * @export
 * @interface Assembly
 */
export interface Assembly {
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof Assembly
     */
    readonly definedTypes?: Array<TypeInfo> | null;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Assembly
     */
    readonly exportedTypes?: Array<Type> | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    readonly codeBase?: string | null;
    /**
     * 
     * @type {MethodInfo}
     * @memberof Assembly
     */
    entryPoint?: MethodInfo;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    readonly imageRuntimeVersion?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    readonly isDynamic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    readonly location?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    readonly reflectionOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    readonly isCollectible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    readonly isFullyTrusted?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Assembly
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    readonly escapedCodeBase?: string | null;
    /**
     * 
     * @type {Module}
     * @memberof Assembly
     */
    manifestModule?: Module;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Assembly
     */
    readonly modules?: Array<Module> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    readonly globalAssemblyCache?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Assembly
     */
    readonly hostContext?: number;
    /**
     * 
     * @type {SecurityRuleSet}
     * @memberof Assembly
     */
    securityRuleSet?: SecurityRuleSet;
}

export function AssemblyFromJSON(json: any): Assembly {
    return AssemblyFromJSONTyped(json, false);
}

export function AssemblyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Assembly {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definedTypes': !exists(json, 'definedTypes') ? undefined : (json['definedTypes'] === null ? null : (json['definedTypes'] as Array<any>).map(TypeInfoFromJSON)),
        'exportedTypes': !exists(json, 'exportedTypes') ? undefined : (json['exportedTypes'] === null ? null : (json['exportedTypes'] as Array<any>).map(TypeFromJSON)),
        'codeBase': !exists(json, 'codeBase') ? undefined : json['codeBase'],
        'entryPoint': !exists(json, 'entryPoint') ? undefined : MethodInfoFromJSON(json['entryPoint']),
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'imageRuntimeVersion': !exists(json, 'imageRuntimeVersion') ? undefined : json['imageRuntimeVersion'],
        'isDynamic': !exists(json, 'isDynamic') ? undefined : json['isDynamic'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'reflectionOnly': !exists(json, 'reflectionOnly') ? undefined : json['reflectionOnly'],
        'isCollectible': !exists(json, 'isCollectible') ? undefined : json['isCollectible'],
        'isFullyTrusted': !exists(json, 'isFullyTrusted') ? undefined : json['isFullyTrusted'],
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'escapedCodeBase': !exists(json, 'escapedCodeBase') ? undefined : json['escapedCodeBase'],
        'manifestModule': !exists(json, 'manifestModule') ? undefined : ModuleFromJSON(json['manifestModule']),
        'modules': !exists(json, 'modules') ? undefined : (json['modules'] === null ? null : (json['modules'] as Array<any>).map(ModuleFromJSON)),
        'globalAssemblyCache': !exists(json, 'globalAssemblyCache') ? undefined : json['globalAssemblyCache'],
        'hostContext': !exists(json, 'hostContext') ? undefined : json['hostContext'],
        'securityRuleSet': !exists(json, 'securityRuleSet') ? undefined : SecurityRuleSetFromJSON(json['securityRuleSet']),
    };
}

export function AssemblyToJSON(value?: Assembly | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entryPoint': MethodInfoToJSON(value.entryPoint),
        'manifestModule': ModuleToJSON(value.manifestModule),
        'securityRuleSet': SecurityRuleSetToJSON(value.securityRuleSet),
    };
}

