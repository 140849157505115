/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BindExternalLoginByPasswordDto
 */
export interface BindExternalLoginByPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof BindExternalLoginByPasswordDto
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof BindExternalLoginByPasswordDto
     */
    providerKey: string;
    /**
     * 
     * @type {string}
     * @memberof BindExternalLoginByPasswordDto
     */
    userNameOrEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof BindExternalLoginByPasswordDto
     */
    password: string;
}

export function BindExternalLoginByPasswordDtoFromJSON(json: any): BindExternalLoginByPasswordDto {
    return BindExternalLoginByPasswordDtoFromJSONTyped(json, false);
}

export function BindExternalLoginByPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BindExternalLoginByPasswordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': json['provider'],
        'providerKey': json['providerKey'],
        'userNameOrEmailAddress': json['userNameOrEmailAddress'],
        'password': json['password'],
    };
}

export function BindExternalLoginByPasswordDtoToJSON(value?: BindExternalLoginByPasswordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'providerKey': value.providerKey,
        'userNameOrEmailAddress': value.userNameOrEmailAddress,
        'password': value.password,
    };
}

