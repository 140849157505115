/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdvertisementDto,
    AdvertisementDtoFromJSON,
    AdvertisementDtoToJSON,
    AdvertisementListDtoListResultDto,
    AdvertisementListDtoListResultDtoFromJSON,
    AdvertisementListDtoListResultDtoToJSON,
    AdvertisementListDtoPagedResultDto,
    AdvertisementListDtoPagedResultDtoFromJSON,
    AdvertisementListDtoPagedResultDtoToJSON,
    AdvertisementTranslatedDto,
    AdvertisementTranslatedDtoFromJSON,
    AdvertisementTranslatedDtoToJSON,
    ClientType,
    ClientTypeFromJSON,
    ClientTypeToJSON,
} from '../models';

export interface ApiServicesAppAdvertisementCreatePostRequest {
    advertisementDto?: AdvertisementDto;
}

export interface ApiServicesAppAdvertisementDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppAdvertisementGetAllGetRequest {
    search?: string;
    isActive?: boolean;
    clientType?: ClientType;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppAdvertisementGetDetailGetRequest {
    slug?: string;
}

export interface ApiServicesAppAdvertisementGetListGetRequest {
    client?: ClientType;
    skipCache?: boolean;
}

export interface ApiServicesAppAdvertisementUpdatePutRequest {
    advertisementDto?: AdvertisementDto;
}

/**
 * 
 */
export class AdvertisementApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppAdvertisementCreatePostRaw(requestParameters: ApiServicesAppAdvertisementCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdvertisementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Advertisement/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdvertisementDtoToJSON(requestParameters.advertisementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvertisementDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdvertisementCreatePost(requestParameters: ApiServicesAppAdvertisementCreatePostRequest, initOverrides?: RequestInit): Promise<AdvertisementDto> {
        const response = await this.apiServicesAppAdvertisementCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdvertisementDeleteDeleteRaw(requestParameters: ApiServicesAppAdvertisementDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Advertisement/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppAdvertisementDeleteDelete(requestParameters: ApiServicesAppAdvertisementDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppAdvertisementDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppAdvertisementGetAllGetRaw(requestParameters: ApiServicesAppAdvertisementGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdvertisementListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.clientType !== undefined) {
            queryParameters['clientType'] = requestParameters.clientType;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Advertisement/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvertisementListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdvertisementGetAllGet(requestParameters: ApiServicesAppAdvertisementGetAllGetRequest, initOverrides?: RequestInit): Promise<AdvertisementListDtoPagedResultDto> {
        const response = await this.apiServicesAppAdvertisementGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdvertisementGetDetailGetRaw(requestParameters: ApiServicesAppAdvertisementGetDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdvertisementTranslatedDto>> {
        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Advertisement/GetDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvertisementTranslatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdvertisementGetDetailGet(requestParameters: ApiServicesAppAdvertisementGetDetailGetRequest, initOverrides?: RequestInit): Promise<AdvertisementTranslatedDto> {
        const response = await this.apiServicesAppAdvertisementGetDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdvertisementGetListGetRaw(requestParameters: ApiServicesAppAdvertisementGetListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdvertisementListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.skipCache !== undefined) {
            queryParameters['skipCache'] = requestParameters.skipCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Advertisement/GetList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvertisementListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdvertisementGetListGet(requestParameters: ApiServicesAppAdvertisementGetListGetRequest, initOverrides?: RequestInit): Promise<AdvertisementListDtoListResultDto> {
        const response = await this.apiServicesAppAdvertisementGetListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdvertisementUpdatePutRaw(requestParameters: ApiServicesAppAdvertisementUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdvertisementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Advertisement/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdvertisementDtoToJSON(requestParameters.advertisementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvertisementDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdvertisementUpdatePut(requestParameters: ApiServicesAppAdvertisementUpdatePutRequest, initOverrides?: RequestInit): Promise<AdvertisementDto> {
        const response = await this.apiServicesAppAdvertisementUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
