/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomAttributeTypedArgument,
    CustomAttributeTypedArgumentFromJSON,
    CustomAttributeTypedArgumentFromJSONTyped,
    CustomAttributeTypedArgumentToJSON,
} from './CustomAttributeTypedArgument';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoFromJSONTyped,
    MemberInfoToJSON,
} from './MemberInfo';

/**
 * 
 * @export
 * @interface CustomAttributeNamedArgument
 */
export interface CustomAttributeNamedArgument {
    /**
     * 
     * @type {MemberInfo}
     * @memberof CustomAttributeNamedArgument
     */
    memberInfo?: MemberInfo;
    /**
     * 
     * @type {CustomAttributeTypedArgument}
     * @memberof CustomAttributeNamedArgument
     */
    typedValue?: CustomAttributeTypedArgument;
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeNamedArgument
     */
    readonly memberName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomAttributeNamedArgument
     */
    readonly isField?: boolean;
}

export function CustomAttributeNamedArgumentFromJSON(json: any): CustomAttributeNamedArgument {
    return CustomAttributeNamedArgumentFromJSONTyped(json, false);
}

export function CustomAttributeNamedArgumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAttributeNamedArgument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberInfo': !exists(json, 'memberInfo') ? undefined : MemberInfoFromJSON(json['memberInfo']),
        'typedValue': !exists(json, 'typedValue') ? undefined : CustomAttributeTypedArgumentFromJSON(json['typedValue']),
        'memberName': !exists(json, 'memberName') ? undefined : json['memberName'],
        'isField': !exists(json, 'isField') ? undefined : json['isField'],
    };
}

export function CustomAttributeNamedArgumentToJSON(value?: CustomAttributeNamedArgument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberInfo': MemberInfoToJSON(value.memberInfo),
        'typedValue': CustomAttributeTypedArgumentToJSON(value.typedValue),
    };
}

