/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TicketCommentDto,
    TicketCommentDtoFromJSON,
    TicketCommentDtoFromJSONTyped,
    TicketCommentDtoToJSON,
} from './TicketCommentDto';
import {
    TicketStatus,
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TicketDto
 */
export interface TicketDto {
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    message: string;
    /**
     * 
     * @type {TicketStatus}
     * @memberof TicketDto
     */
    status?: TicketStatus;
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    assignedToUserId?: number;
    /**
     * 
     * @type {Array<TicketCommentDto>}
     * @memberof TicketDto
     */
    comments?: Array<TicketCommentDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof TicketDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TicketDto
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {User}
     * @memberof TicketDto
     */
    creatorUser?: User;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    readonly creatorUserName?: string | null;
}

export function TicketDtoFromJSON(json: any): TicketDto {
    return TicketDtoFromJSONTyped(json, false);
}

export function TicketDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subject': json['subject'],
        'message': json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'assignedToUserId': !exists(json, 'assignedToUserId') ? undefined : json['assignedToUserId'],
        'comments': !exists(json, 'comments') ? undefined : (json['comments'] === null ? null : (json['comments'] as Array<any>).map(TicketCommentDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
        'creatorUserName': !exists(json, 'creatorUserName') ? undefined : json['creatorUserName'],
    };
}

export function TicketDtoToJSON(value?: TicketDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'assignedToUserId': value.assignedToUserId,
        'comments': value.comments === undefined ? undefined : (value.comments === null ? null : (value.comments as Array<any>).map(TicketCommentDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'creatorUser': UserToJSON(value.creatorUser),
    };
}

