/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';

/**
 * 
 * @export
 * @interface ChildAccountDto
 */
export interface ChildAccountDto {
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    gradeCode: string;
    /**
     * 
     * @type {number}
     * @memberof ChildAccountDto
     */
    readonly term?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    school?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    district?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof ChildAccountDto
     */
    gender?: Gender;
    /**
     * 
     * @type {Date}
     * @memberof ChildAccountDto
     */
    birthday?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ChildAccountDto
     */
    avator?: string | null;
}

export function ChildAccountDtoFromJSON(json: any): ChildAccountDto {
    return ChildAccountDtoFromJSONTyped(json, false);
}

export function ChildAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gradeCode': json['gradeCode'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'school': !exists(json, 'school') ? undefined : json['school'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'birthday': !exists(json, 'birthday') ? undefined : (json['birthday'] === null ? null : new Date(json['birthday'])),
        'userName': json['userName'],
        'name': json['name'],
        'surname': json['surname'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'password': json['password'],
        'avator': !exists(json, 'avator') ? undefined : json['avator'],
    };
}

export function ChildAccountDtoToJSON(value?: ChildAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gradeCode': value.gradeCode,
        'school': value.school,
        'district': value.district,
        'gender': GenderToJSON(value.gender),
        'birthday': value.birthday === undefined ? undefined : (value.birthday === null ? null : value.birthday.toISOString()),
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'fullName': value.fullName,
        'password': value.password,
        'avator': value.avator,
    };
}

