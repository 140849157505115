import React from 'react';

const LogoutIcon = (prop: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg id="logout" xmlns="http://www.w3.org/2000/svg" width="23.915" height="21.313" viewBox="0 0 23.915 21.313" fill="currentColor" {...prop}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1285" data-name="Rectangle 1285" width="23.915" height="21.313" fill="currentColor" />
        </clipPath>
      </defs>
      <g id="Group_3392" data-name="Group 3392" clipPath="url(#clip-path)">
        <path id="Path_1744" data-name="Path 1744" d="M0,10.61C0,7.973,0,5.337.005,2.7A2.625,2.625,0,0,1,2.694.007q4.962-.01,9.924,0a2.836,2.836,0,0,1,2.928,2.882c.019,1.294.01,2.589,0,3.883-.005.768-.2.991-.829,1-.656.014-.884-.207-.892-.939-.014-1.246,0-2.493-.006-3.739-.008-.958-.4-1.362-1.353-1.364-3.116-.008-6.233,0-9.349,0a3.262,3.262,0,0,0-.574.005.929.929,0,0,0-.812,1.018q0,7.694,0,15.388c0,.969.417,1.386,1.428,1.433.479.022.959,0,1.438,0,2.493,0,4.986.005,7.479,0,1.243,0,1.73-.479,1.742-1.7.012-1.2,0-2.4,0-3.6,0-.706.2-1.005.649-1.04.312-.024,1.054.453,1.062.784.035,1.341.025,2.684.007,4.026a2.719,2.719,0,0,1-.2,1.065,2.991,2.991,0,0,1-2.959,2.184c-.815.008-1.63,0-2.445,0-2.205,0-4.411-.01-6.616.005a4.589,4.589,0,0,1-2.069-.568A1.976,1.976,0,0,1,0,18.808c.009-2.732,0-5.465,0-8.2" transform="translate(0 0)" fill="currentColor" />
        <path id="Path_1745" data-name="Path 1745" d="M33.663,22.561c1.823,0,3.645,0,5.468,0a6.31,6.31,0,0,0,.687-.075,4.633,4.633,0,0,0-.381-.506c-.706-.719-1.421-1.427-2.133-2.139a5.641,5.641,0,0,1-.4-.409,1.035,1.035,0,0,1,.045-1.268,1,1,0,0,1,1.266,0q2.3,2.274,4.579,4.577a.666.666,0,0,1,.007,1.078c-.359.386-.743.749-1.116,1.122-1.187,1.187-2.37,2.378-3.563,3.559-.506.5-.842.541-1.184.181a1.011,1.011,0,0,1,.078-1.37c.4-.418.821-.807,1.216-1.225.535-.568,1.054-1.151,1.58-1.727-.758-.022-1.516-.063-2.274-.065-2.974-.007-5.947,0-8.921-.009a5.843,5.843,0,0,1-1.1-.132.671.671,0,0,1-.594-.878.724.724,0,0,1,.842-.707c1.966-.011,3.933,0,5.9,0" transform="translate(-19.152 -12.768)" fill="currentColor" />
      </g>
    </svg>
  );
}

export default LogoutIcon;