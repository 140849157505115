/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GradeTranslation,
    GradeTranslationFromJSON,
    GradeTranslationFromJSONTyped,
    GradeTranslationToJSON,
} from './GradeTranslation';
import {
    Stage,
    StageFromJSON,
    StageFromJSONTyped,
    StageToJSON,
} from './Stage';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface Grade
 */
export interface Grade {
    /**
     * 
     * @type {number}
     * @memberof Grade
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Grade
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof Grade
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<GradeTranslation>}
     * @memberof Grade
     */
    translations?: Array<GradeTranslation> | null;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof Grade
     */
    subjects?: Array<Subject> | null;
    /**
     * 
     * @type {string}
     * @memberof Grade
     */
    stageCode?: string | null;
    /**
     * 
     * @type {Stage}
     * @memberof Grade
     */
    stage?: Stage;
}

export function GradeFromJSON(json: any): Grade {
    return GradeFromJSONTyped(json, false);
}

export function GradeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Grade {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': json['code'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(GradeTranslationFromJSON)),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(SubjectFromJSON)),
        'stageCode': !exists(json, 'stageCode') ? undefined : json['stageCode'],
        'stage': !exists(json, 'stage') ? undefined : StageFromJSON(json['stage']),
    };
}

export function GradeToJSON(value?: Grade | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'isActive': value.isActive,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(GradeTranslationToJSON)),
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(SubjectToJSON)),
        'stageCode': value.stageCode,
        'stage': StageToJSON(value.stage),
    };
}

