/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Stage,
    StageFromJSON,
    StageFromJSONTyped,
    StageToJSON,
} from './Stage';

/**
 * 
 * @export
 * @interface StageTranslation
 */
export interface StageTranslation {
    /**
     * 
     * @type {number}
     * @memberof StageTranslation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StageTranslation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StageTranslation
     */
    description?: string | null;
    /**
     * 
     * @type {Stage}
     * @memberof StageTranslation
     */
    core?: Stage;
    /**
     * 
     * @type {number}
     * @memberof StageTranslation
     */
    coreId?: number;
    /**
     * 
     * @type {string}
     * @memberof StageTranslation
     */
    language?: string | null;
}

export function StageTranslationFromJSON(json: any): StageTranslation {
    return StageTranslationFromJSONTyped(json, false);
}

export function StageTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StageTranslation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'core': !exists(json, 'core') ? undefined : StageFromJSON(json['core']),
        'coreId': !exists(json, 'coreId') ? undefined : json['coreId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function StageTranslationToJSON(value?: StageTranslation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'core': StageToJSON(value.core),
        'coreId': value.coreId,
        'language': value.language,
    };
}

