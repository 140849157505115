export enum QuestionType {
  SingleChoice = 0,
  MultipleChoice = 1,
  LongFillIn = 2,
  Sorting = 3,
  Matching = 4,
  FillIn = 5,
  QuestionOnly = 6,
  Writing = 7,
}
export enum SubjectType {
  Chinese = 0,
  Maths = 1,
  English = 2,
  EnglishMaths = 3
}