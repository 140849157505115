/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {number}
     * @memberof PermissionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    description?: string | null;
}

export function PermissionDtoFromJSON(json: any): PermissionDto {
    return PermissionDtoFromJSONTyped(json, false);
}

export function PermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function PermissionDtoToJSON(value?: PermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'displayName': value.displayName,
        'description': value.description,
    };
}

