/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleTranslationDto
 */
export interface ArticleTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof ArticleTranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleTranslationDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleTranslationDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleTranslationDto
     */
    cover?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleTranslationDto
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleTranslationDto
     */
    language?: string | null;
}

export function ArticleTranslationDtoFromJSON(json: any): ArticleTranslationDto {
    return ArticleTranslationDtoFromJSONTyped(json, false);
}

export function ArticleTranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleTranslationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cover': !exists(json, 'cover') ? undefined : json['cover'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function ArticleTranslationDtoToJSON(value?: ArticleTranslationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'cover': value.cover,
        'content': value.content,
        'language': value.language,
    };
}

