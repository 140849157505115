/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionTypeListDto,
    QuestionTypeListDtoFromJSON,
    QuestionTypeListDtoFromJSONTyped,
    QuestionTypeListDtoToJSON,
} from './QuestionTypeListDto';

/**
 * 
 * @export
 * @interface QuestionTypeListDtoListResultDto
 */
export interface QuestionTypeListDtoListResultDto {
    /**
     * 
     * @type {Array<QuestionTypeListDto>}
     * @memberof QuestionTypeListDtoListResultDto
     */
    items?: Array<QuestionTypeListDto> | null;
}

export function QuestionTypeListDtoListResultDtoFromJSON(json: any): QuestionTypeListDtoListResultDto {
    return QuestionTypeListDtoListResultDtoFromJSONTyped(json, false);
}

export function QuestionTypeListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionTypeListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(QuestionTypeListDtoFromJSON)),
    };
}

export function QuestionTypeListDtoListResultDtoToJSON(value?: QuestionTypeListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(QuestionTypeListDtoToJSON)),
    };
}

