/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FlatPermissionDto,
    FlatPermissionDtoFromJSON,
    FlatPermissionDtoFromJSONTyped,
    FlatPermissionDtoToJSON,
} from './FlatPermissionDto';
import {
    RoleEditDto,
    RoleEditDtoFromJSON,
    RoleEditDtoFromJSONTyped,
    RoleEditDtoToJSON,
} from './RoleEditDto';

/**
 * 
 * @export
 * @interface GetRoleForEditOutput
 */
export interface GetRoleForEditOutput {
    /**
     * 
     * @type {RoleEditDto}
     * @memberof GetRoleForEditOutput
     */
    role?: RoleEditDto;
    /**
     * 
     * @type {Array<FlatPermissionDto>}
     * @memberof GetRoleForEditOutput
     */
    permissions?: Array<FlatPermissionDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRoleForEditOutput
     */
    grantedPermissionNames?: Array<string> | null;
}

export function GetRoleForEditOutputFromJSON(json: any): GetRoleForEditOutput {
    return GetRoleForEditOutputFromJSONTyped(json, false);
}

export function GetRoleForEditOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRoleForEditOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : RoleEditDtoFromJSON(json['role']),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(FlatPermissionDtoFromJSON)),
        'grantedPermissionNames': !exists(json, 'grantedPermissionNames') ? undefined : json['grantedPermissionNames'],
    };
}

export function GetRoleForEditOutputToJSON(value?: GetRoleForEditOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleEditDtoToJSON(value.role),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(FlatPermissionDtoToJSON)),
        'grantedPermissionNames': value.grantedPermissionNames,
    };
}

