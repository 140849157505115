/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentCategoryListDto,
    ContentCategoryListDtoFromJSON,
    ContentCategoryListDtoFromJSONTyped,
    ContentCategoryListDtoToJSON,
} from './ContentCategoryListDto';

/**
 * 
 * @export
 * @interface ContentCategoryListDtoListResultDto
 */
export interface ContentCategoryListDtoListResultDto {
    /**
     * 
     * @type {Array<ContentCategoryListDto>}
     * @memberof ContentCategoryListDtoListResultDto
     */
    items?: Array<ContentCategoryListDto> | null;
}

export function ContentCategoryListDtoListResultDtoFromJSON(json: any): ContentCategoryListDtoListResultDto {
    return ContentCategoryListDtoListResultDtoFromJSONTyped(json, false);
}

export function ContentCategoryListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentCategoryListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ContentCategoryListDtoFromJSON)),
    };
}

export function ContentCategoryListDtoListResultDtoToJSON(value?: ContentCategoryListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ContentCategoryListDtoToJSON)),
    };
}

