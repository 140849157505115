/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModifyBalanceDto
 */
export interface ModifyBalanceDto {
    /**
     * 
     * @type {number}
     * @memberof ModifyBalanceDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyBalanceDto
     */
    balance?: number;
}

export function ModifyBalanceDtoFromJSON(json: any): ModifyBalanceDto {
    return ModifyBalanceDtoFromJSONTyped(json, false);
}

export function ModifyBalanceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifyBalanceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
    };
}

export function ModifyBalanceDtoToJSON(value?: ModifyBalanceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'balance': value.balance,
    };
}

