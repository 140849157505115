/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExport,
    ExaminationExportFromJSON,
    ExaminationExportFromJSONTyped,
    ExaminationExportToJSON,
} from './ExaminationExport';
import {
    ExaminationType,
    ExaminationTypeFromJSON,
    ExaminationTypeFromJSONTyped,
    ExaminationTypeToJSON,
} from './ExaminationType';
import {
    Lesson,
    LessonFromJSON,
    LessonFromJSONTyped,
    LessonToJSON,
} from './Lesson';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';
import {
    TestQuestion,
    TestQuestionFromJSON,
    TestQuestionFromJSONTyped,
    TestQuestionToJSON,
} from './TestQuestion';
import {
    TestType,
    TestTypeFromJSON,
    TestTypeFromJSONTyped,
    TestTypeToJSON,
} from './TestType';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Test
 */
export interface Test {
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof Test
     */
    user?: User;
    /**
     * 
     * @type {TestType}
     * @memberof Test
     */
    testType?: TestType;
    /**
     * 
     * @type {string}
     * @memberof Test
     */
    testName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    lessonId?: number | null;
    /**
     * 
     * @type {Lesson}
     * @memberof Test
     */
    lesson?: Lesson;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    subjectId?: number | null;
    /**
     * 
     * @type {Subject}
     * @memberof Test
     */
    subject?: Subject;
    /**
     * 
     * @type {ExaminationType}
     * @memberof Test
     */
    examinationType?: ExaminationType;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    testTimeLimit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    questionCount?: number;
    /**
     * 
     * @type {Array<TestQuestion>}
     * @memberof Test
     */
    questions?: Array<TestQuestion> | null;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    score?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Test
     */
    totalPoints?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Test
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Test
     */
    beginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Test
     */
    finishTime?: Date | null;
    /**
     * 
     * @type {ExaminationExport}
     * @memberof Test
     */
    referenceExport?: ExaminationExport;
}

export function TestFromJSON(json: any): Test {
    return TestFromJSONTyped(json, false);
}

export function TestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Test {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'testType': !exists(json, 'testType') ? undefined : TestTypeFromJSON(json['testType']),
        'testName': !exists(json, 'testName') ? undefined : json['testName'],
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
        'lesson': !exists(json, 'lesson') ? undefined : LessonFromJSON(json['lesson']),
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subject': !exists(json, 'subject') ? undefined : SubjectFromJSON(json['subject']),
        'examinationType': !exists(json, 'examinationType') ? undefined : ExaminationTypeFromJSON(json['examinationType']),
        'testTimeLimit': !exists(json, 'testTimeLimit') ? undefined : json['testTimeLimit'],
        'questionCount': !exists(json, 'questionCount') ? undefined : json['questionCount'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(TestQuestionFromJSON)),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'beginTime': !exists(json, 'beginTime') ? undefined : (json['beginTime'] === null ? null : new Date(json['beginTime'])),
        'finishTime': !exists(json, 'finishTime') ? undefined : (json['finishTime'] === null ? null : new Date(json['finishTime'])),
        'referenceExport': !exists(json, 'referenceExport') ? undefined : ExaminationExportFromJSON(json['referenceExport']),
    };
}

export function TestToJSON(value?: Test | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'testType': TestTypeToJSON(value.testType),
        'testName': value.testName,
        'lessonId': value.lessonId,
        'lesson': LessonToJSON(value.lesson),
        'subjectId': value.subjectId,
        'subject': SubjectToJSON(value.subject),
        'examinationType': ExaminationTypeToJSON(value.examinationType),
        'testTimeLimit': value.testTimeLimit,
        'questionCount': value.questionCount,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(TestQuestionToJSON)),
        'score': value.score,
        'totalPoints': value.totalPoints,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'beginTime': value.beginTime === undefined ? undefined : (value.beginTime === null ? null : value.beginTime.toISOString()),
        'finishTime': value.finishTime === undefined ? undefined : (value.finishTime === null ? null : value.finishTime.toISOString()),
        'referenceExport': ExaminationExportToJSON(value.referenceExport),
    };
}

