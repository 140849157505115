/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    MemberTypes,
    MemberTypesFromJSON,
    MemberTypesFromJSONTyped,
    MemberTypesToJSON,
} from './MemberTypes';
import {
    MethodInfo,
    MethodInfoFromJSON,
    MethodInfoFromJSONTyped,
    MethodInfoToJSON,
} from './MethodInfo';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    PropertyAttributes,
    PropertyAttributesFromJSON,
    PropertyAttributesFromJSONTyped,
    PropertyAttributesToJSON,
} from './PropertyAttributes';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';

/**
 * 
 * @export
 * @interface PropertyInfo
 */
export interface PropertyInfo {
    /**
     * 
     * @type {string}
     * @memberof PropertyInfo
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof PropertyInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof PropertyInfo
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    readonly isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyInfo
     */
    readonly metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof PropertyInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    propertyType?: Type;
    /**
     * 
     * @type {PropertyAttributes}
     * @memberof PropertyInfo
     */
    attributes?: PropertyAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    readonly isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    readonly canRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    readonly canWrite?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    getMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    setMethod?: MethodInfo;
}

export function PropertyInfoFromJSON(json: any): PropertyInfo {
    return PropertyInfoFromJSONTyped(json, false);
}

export function PropertyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'declaringType': !exists(json, 'declaringType') ? undefined : TypeFromJSON(json['declaringType']),
        'reflectedType': !exists(json, 'reflectedType') ? undefined : TypeFromJSON(json['reflectedType']),
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'isCollectible': !exists(json, 'isCollectible') ? undefined : json['isCollectible'],
        'metadataToken': !exists(json, 'metadataToken') ? undefined : json['metadataToken'],
        'memberType': !exists(json, 'memberType') ? undefined : MemberTypesFromJSON(json['memberType']),
        'propertyType': !exists(json, 'propertyType') ? undefined : TypeFromJSON(json['propertyType']),
        'attributes': !exists(json, 'attributes') ? undefined : PropertyAttributesFromJSON(json['attributes']),
        'isSpecialName': !exists(json, 'isSpecialName') ? undefined : json['isSpecialName'],
        'canRead': !exists(json, 'canRead') ? undefined : json['canRead'],
        'canWrite': !exists(json, 'canWrite') ? undefined : json['canWrite'],
        'getMethod': !exists(json, 'getMethod') ? undefined : MethodInfoFromJSON(json['getMethod']),
        'setMethod': !exists(json, 'setMethod') ? undefined : MethodInfoFromJSON(json['setMethod']),
    };
}

export function PropertyInfoToJSON(value?: PropertyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declaringType': TypeToJSON(value.declaringType),
        'reflectedType': TypeToJSON(value.reflectedType),
        'module': ModuleToJSON(value.module),
        'memberType': MemberTypesToJSON(value.memberType),
        'propertyType': TypeToJSON(value.propertyType),
        'attributes': PropertyAttributesToJSON(value.attributes),
        'getMethod': MethodInfoToJSON(value.getMethod),
        'setMethod': MethodInfoToJSON(value.setMethod),
    };
}

