/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportQuestionsInput
 */
export interface ImportQuestionsInput {
    /**
     * 
     * @type {string}
     * @memberof ImportQuestionsInput
     */
    filePath: string;
    /**
     * 
     * @type {number}
     * @memberof ImportQuestionsInput
     */
    lessonId?: number;
}

export function ImportQuestionsInputFromJSON(json: any): ImportQuestionsInput {
    return ImportQuestionsInputFromJSONTyped(json, false);
}

export function ImportQuestionsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportQuestionsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filePath': json['filePath'],
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
    };
}

export function ImportQuestionsInputToJSON(value?: ImportQuestionsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filePath': value.filePath,
        'lessonId': value.lessonId,
    };
}

