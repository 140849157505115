/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FAQItemListDto,
    FAQItemListDtoFromJSON,
    FAQItemListDtoFromJSONTyped,
    FAQItemListDtoToJSON,
} from './FAQItemListDto';

/**
 * 
 * @export
 * @interface FAQItemListDtoPagedResultDto
 */
export interface FAQItemListDtoPagedResultDto {
    /**
     * 
     * @type {Array<FAQItemListDto>}
     * @memberof FAQItemListDtoPagedResultDto
     */
    items?: Array<FAQItemListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FAQItemListDtoPagedResultDto
     */
    totalCount?: number;
}

export function FAQItemListDtoPagedResultDtoFromJSON(json: any): FAQItemListDtoPagedResultDto {
    return FAQItemListDtoPagedResultDtoFromJSONTyped(json, false);
}

export function FAQItemListDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQItemListDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(FAQItemListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function FAQItemListDtoPagedResultDtoToJSON(value?: FAQItemListDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(FAQItemListDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

