/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportQuestionOptionsDto,
    ExaminationExportQuestionOptionsDtoFromJSON,
    ExaminationExportQuestionOptionsDtoFromJSONTyped,
    ExaminationExportQuestionOptionsDtoToJSON,
} from './ExaminationExportQuestionOptionsDto';
import {
    ExaminationExportSubQuestionListDto,
    ExaminationExportSubQuestionListDtoFromJSON,
    ExaminationExportSubQuestionListDtoFromJSONTyped,
    ExaminationExportSubQuestionListDtoToJSON,
} from './ExaminationExportSubQuestionListDto';

/**
 * 
 * @export
 * @interface ExaminationExportQuestionListDto
 */
export interface ExaminationExportQuestionListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionListDto
     */
    examinationExportSectionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionListDto
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionListDto
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionListDto
     */
    sort?: number;
    /**
     * 
     * @type {ExaminationExportQuestionOptionsDto}
     * @memberof ExaminationExportQuestionListDto
     */
    question?: ExaminationExportQuestionOptionsDto;
    /**
     * 
     * @type {Array<ExaminationExportSubQuestionListDto>}
     * @memberof ExaminationExportQuestionListDto
     */
    subExaminationExportQuestions?: Array<ExaminationExportSubQuestionListDto> | null;
}

export function ExaminationExportQuestionListDtoFromJSON(json: any): ExaminationExportQuestionListDto {
    return ExaminationExportQuestionListDtoFromJSONTyped(json, false);
}

export function ExaminationExportQuestionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportQuestionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportSectionId': !exists(json, 'examinationExportSectionId') ? undefined : json['examinationExportSectionId'],
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'question': !exists(json, 'question') ? undefined : ExaminationExportQuestionOptionsDtoFromJSON(json['question']),
        'subExaminationExportQuestions': !exists(json, 'subExaminationExportQuestions') ? undefined : (json['subExaminationExportQuestions'] === null ? null : (json['subExaminationExportQuestions'] as Array<any>).map(ExaminationExportSubQuestionListDtoFromJSON)),
    };
}

export function ExaminationExportQuestionListDtoToJSON(value?: ExaminationExportQuestionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportSectionId': value.examinationExportSectionId,
        'questionId': value.questionId,
        'points': value.points,
        'sort': value.sort,
        'question': ExaminationExportQuestionOptionsDtoToJSON(value.question),
        'subExaminationExportQuestions': value.subExaminationExportQuestions === undefined ? undefined : (value.subExaminationExportQuestions === null ? null : (value.subExaminationExportQuestions as Array<any>).map(ExaminationExportSubQuestionListDtoToJSON)),
    };
}

