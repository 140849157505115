/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminRegisterSchoolInput,
    AdminRegisterSchoolInputFromJSON,
    AdminRegisterSchoolInputToJSON,
    AdminSchoolListDtoListResultDto,
    AdminSchoolListDtoListResultDtoFromJSON,
    AdminSchoolListDtoListResultDtoToJSON,
    AdminSchoolListDtoPagedResultDto,
    AdminSchoolListDtoPagedResultDtoFromJSON,
    AdminSchoolListDtoPagedResultDtoToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    RegisterSchoolInput,
    RegisterSchoolInputFromJSON,
    RegisterSchoolInputToJSON,
    RegisterSchoolOutput,
    RegisterSchoolOutputFromJSON,
    RegisterSchoolOutputToJSON,
    SchoolDetailDto,
    SchoolDetailDtoFromJSON,
    SchoolDetailDtoToJSON,
    SchoolDetailDtoWithId,
    SchoolDetailDtoWithIdFromJSON,
    SchoolDetailDtoWithIdToJSON,
    SchoolDetailDtoWithParent,
    SchoolDetailDtoWithParentFromJSON,
    SchoolDetailDtoWithParentToJSON,
    SchoolListDtoListResultDto,
    SchoolListDtoListResultDtoFromJSON,
    SchoolListDtoListResultDtoToJSON,
    ToggleActivateResult,
    ToggleActivateResultFromJSON,
    ToggleActivateResultToJSON,
} from '../models';

export interface ApiServicesAppSchoolAdminRegisterPostRequest {
    adminRegisterSchoolInput?: AdminRegisterSchoolInput;
}

export interface ApiServicesAppSchoolCreateBranchPostRequest {
    schoolDetailDto?: SchoolDetailDto;
}

export interface ApiServicesAppSchoolDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppSchoolGetAllGetRequest {
    isActive?: boolean;
    keyWords?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSchoolGetBranchesGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolGetGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolGetWithParentGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolRegisterPostRequest {
    registerSchoolInput?: RegisterSchoolInput;
}

export interface ApiServicesAppSchoolToggleActivatePutRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppSchoolUpdatePutRequest {
    schoolDetailDtoWithId?: SchoolDetailDtoWithId;
}

/**
 * 
 */
export class SchoolApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSchoolAdminRegisterPostRaw(requestParameters: ApiServicesAppSchoolAdminRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/School/AdminRegister`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminRegisterSchoolInputToJSON(requestParameters.adminRegisterSchoolInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolAdminRegisterPost(requestParameters: ApiServicesAppSchoolAdminRegisterPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolAdminRegisterPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolCreateBranchPostRaw(requestParameters: ApiServicesAppSchoolCreateBranchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolDetailDtoWithId>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/School/CreateBranch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchoolDetailDtoToJSON(requestParameters.schoolDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolDetailDtoWithIdFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolCreateBranchPost(requestParameters: ApiServicesAppSchoolCreateBranchPostRequest, initOverrides?: RequestInit): Promise<SchoolDetailDtoWithId> {
        const response = await this.apiServicesAppSchoolCreateBranchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolDeleteDeleteRaw(requestParameters: ApiServicesAppSchoolDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/School/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolDeleteDelete(requestParameters: ApiServicesAppSchoolDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolGetAllGetRaw(requestParameters: ApiServicesAppSchoolGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminSchoolListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/School/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSchoolListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolGetAllGet(requestParameters: ApiServicesAppSchoolGetAllGetRequest, initOverrides?: RequestInit): Promise<AdminSchoolListDtoPagedResultDto> {
        const response = await this.apiServicesAppSchoolGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolGetBranchesGetRaw(requestParameters: ApiServicesAppSchoolGetBranchesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminSchoolListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/School/GetBranches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSchoolListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolGetBranchesGet(requestParameters: ApiServicesAppSchoolGetBranchesGetRequest, initOverrides?: RequestInit): Promise<AdminSchoolListDtoListResultDto> {
        const response = await this.apiServicesAppSchoolGetBranchesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolGetGetRaw(requestParameters: ApiServicesAppSchoolGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolDetailDtoWithId>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/School/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolDetailDtoWithIdFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolGetGet(requestParameters: ApiServicesAppSchoolGetGetRequest, initOverrides?: RequestInit): Promise<SchoolDetailDtoWithId> {
        const response = await this.apiServicesAppSchoolGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolGetMineGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolListDtoListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/School/GetMine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolGetMineGet(initOverrides?: RequestInit): Promise<SchoolListDtoListResultDto> {
        const response = await this.apiServicesAppSchoolGetMineGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolGetWithParentGetRaw(requestParameters: ApiServicesAppSchoolGetWithParentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolDetailDtoWithParent>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/School/GetWithParent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolDetailDtoWithParentFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolGetWithParentGet(requestParameters: ApiServicesAppSchoolGetWithParentGetRequest, initOverrides?: RequestInit): Promise<SchoolDetailDtoWithParent> {
        const response = await this.apiServicesAppSchoolGetWithParentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolRegisterPostRaw(requestParameters: ApiServicesAppSchoolRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegisterSchoolOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/School/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSchoolInputToJSON(requestParameters.registerSchoolInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterSchoolOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolRegisterPost(requestParameters: ApiServicesAppSchoolRegisterPostRequest, initOverrides?: RequestInit): Promise<RegisterSchoolOutput> {
        const response = await this.apiServicesAppSchoolRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolToggleActivatePutRaw(requestParameters: ApiServicesAppSchoolToggleActivatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToggleActivateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/School/ToggleActivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleActivateResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolToggleActivatePut(requestParameters: ApiServicesAppSchoolToggleActivatePutRequest, initOverrides?: RequestInit): Promise<ToggleActivateResult> {
        const response = await this.apiServicesAppSchoolToggleActivatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolUpdatePutRaw(requestParameters: ApiServicesAppSchoolUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolDetailDtoWithId>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/School/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchoolDetailDtoWithIdToJSON(requestParameters.schoolDetailDtoWithId),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolDetailDtoWithIdFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolUpdatePut(requestParameters: ApiServicesAppSchoolUpdatePutRequest, initOverrides?: RequestInit): Promise<SchoolDetailDtoWithId> {
        const response = await this.apiServicesAppSchoolUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
