/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StudentGroupTagDetailDto,
    StudentGroupTagDetailDtoFromJSON,
    StudentGroupTagDetailDtoToJSON,
    StudentGroupTagListDtoListResultDto,
    StudentGroupTagListDtoListResultDtoFromJSON,
    StudentGroupTagListDtoListResultDtoToJSON,
} from '../models';

export interface ApiServicesAppStudentGroupTagCreatePostRequest {
    studentGroupTagDetailDto?: StudentGroupTagDetailDto;
}

export interface ApiServicesAppStudentGroupTagDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppStudentGroupTagGetNameListGetRequest {
    schoolId?: number;
}

export interface ApiServicesAppStudentGroupTagUpdatePutRequest {
    studentGroupTagDetailDto?: StudentGroupTagDetailDto;
}

/**
 * 
 */
export class StudentGroupTagApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppStudentGroupTagCreatePostRaw(requestParameters: ApiServicesAppStudentGroupTagCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupTagDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/StudentGroupTag/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StudentGroupTagDetailDtoToJSON(requestParameters.studentGroupTagDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupTagDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupTagCreatePost(requestParameters: ApiServicesAppStudentGroupTagCreatePostRequest, initOverrides?: RequestInit): Promise<StudentGroupTagDetailDto> {
        const response = await this.apiServicesAppStudentGroupTagCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupTagDeleteDeleteRaw(requestParameters: ApiServicesAppStudentGroupTagDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroupTag/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppStudentGroupTagDeleteDelete(requestParameters: ApiServicesAppStudentGroupTagDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppStudentGroupTagDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppStudentGroupTagGetListGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupTagListDtoListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroupTag/GetList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupTagListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupTagGetListGet(initOverrides?: RequestInit): Promise<StudentGroupTagListDtoListResultDto> {
        const response = await this.apiServicesAppStudentGroupTagGetListGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupTagGetNameListGetRaw(requestParameters: ApiServicesAppStudentGroupTagGetNameListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupTagListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['schoolId'] = requestParameters.schoolId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroupTag/GetNameList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupTagListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupTagGetNameListGet(requestParameters: ApiServicesAppStudentGroupTagGetNameListGetRequest, initOverrides?: RequestInit): Promise<StudentGroupTagListDtoListResultDto> {
        const response = await this.apiServicesAppStudentGroupTagGetNameListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupTagUpdatePutRaw(requestParameters: ApiServicesAppStudentGroupTagUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupTagDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/StudentGroupTag/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StudentGroupTagDetailDtoToJSON(requestParameters.studentGroupTagDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupTagDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupTagUpdatePut(requestParameters: ApiServicesAppStudentGroupTagUpdatePutRequest, initOverrides?: RequestInit): Promise<StudentGroupTagDetailDto> {
        const response = await this.apiServicesAppStudentGroupTagUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
