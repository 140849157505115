/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FAQCategoryTranslationDto
 */
export interface FAQCategoryTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof FAQCategoryTranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryTranslationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryTranslationDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryTranslationDto
     */
    language: string;
}

export function FAQCategoryTranslationDtoFromJSON(json: any): FAQCategoryTranslationDto {
    return FAQCategoryTranslationDtoFromJSONTyped(json, false);
}

export function FAQCategoryTranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQCategoryTranslationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'language': json['language'],
    };
}

export function FAQCategoryTranslationDtoToJSON(value?: FAQCategoryTranslationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'language': value.language,
    };
}

