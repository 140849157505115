/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LayoutKind,
    LayoutKindFromJSON,
    LayoutKindFromJSONTyped,
    LayoutKindToJSON,
} from './LayoutKind';

/**
 * 
 * @export
 * @interface StructLayoutAttribute
 */
export interface StructLayoutAttribute {
    /**
     * 
     * @type {any}
     * @memberof StructLayoutAttribute
     */
    readonly typeId?: any | null;
    /**
     * 
     * @type {LayoutKind}
     * @memberof StructLayoutAttribute
     */
    value?: LayoutKind;
}

export function StructLayoutAttributeFromJSON(json: any): StructLayoutAttribute {
    return StructLayoutAttributeFromJSONTyped(json, false);
}

export function StructLayoutAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StructLayoutAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'value': !exists(json, 'value') ? undefined : LayoutKindFromJSON(json['value']),
    };
}

export function StructLayoutAttributeToJSON(value?: StructLayoutAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': LayoutKindToJSON(value.value),
    };
}

