/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionNavigator,
    QuestionNavigatorFromJSON,
    QuestionNavigatorFromJSONTyped,
    QuestionNavigatorToJSON,
} from './QuestionNavigator';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface QuestionWithNavigatorDto
 */
export interface QuestionWithNavigatorDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionWithNavigatorDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionWithNavigatorDto
     */
    questionType: QuestionType;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithNavigatorDto
     */
    questionBody: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionWithNavigatorDto
     */
    isSubjective?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    stars?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    lessonId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    schoolId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithNavigatorDto
     */
    optionsSerialize?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithNavigatorDto
     */
    answerSerialize?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithNavigatorDto
     */
    explain?: string | null;
    /**
     * 
     * @type {any}
     * @memberof QuestionWithNavigatorDto
     */
    extra?: any | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithNavigatorDto
     */
    extraSerialize?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionWithNavigatorDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithNavigatorDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionWithNavigatorDto
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {QuestionNavigator}
     * @memberof QuestionWithNavigatorDto
     */
    previous?: QuestionNavigator;
    /**
     * 
     * @type {QuestionNavigator}
     * @memberof QuestionWithNavigatorDto
     */
    next?: QuestionNavigator;
}

export function QuestionWithNavigatorDtoFromJSON(json: any): QuestionWithNavigatorDto {
    return QuestionWithNavigatorDtoFromJSONTyped(json, false);
}

export function QuestionWithNavigatorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionWithNavigatorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'questionType': QuestionTypeFromJSON(json['questionType']),
        'questionBody': json['questionBody'],
        'isSubjective': !exists(json, 'isSubjective') ? undefined : json['isSubjective'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'optionsSerialize': !exists(json, 'optionsSerialize') ? undefined : json['optionsSerialize'],
        'answerSerialize': !exists(json, 'answerSerialize') ? undefined : json['answerSerialize'],
        'explain': !exists(json, 'explain') ? undefined : json['explain'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'extraSerialize': !exists(json, 'extraSerialize') ? undefined : json['extraSerialize'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'previous': !exists(json, 'previous') ? undefined : QuestionNavigatorFromJSON(json['previous']),
        'next': !exists(json, 'next') ? undefined : QuestionNavigatorFromJSON(json['next']),
    };
}

export function QuestionWithNavigatorDtoToJSON(value?: QuestionWithNavigatorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'questionType': QuestionTypeToJSON(value.questionType),
        'questionBody': value.questionBody,
        'isSubjective': value.isSubjective,
        'stars': value.stars,
        'parentId': value.parentId,
        'lessonId': value.lessonId,
        'schoolId': value.schoolId,
        'optionsSerialize': value.optionsSerialize,
        'answerSerialize': value.answerSerialize,
        'explain': value.explain,
        'extra': value.extra,
        'extraSerialize': value.extraSerialize,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'previous': QuestionNavigatorToJSON(value.previous),
        'next': QuestionNavigatorToJSON(value.next),
    };
}

