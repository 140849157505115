/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroup,
    StudentGroupFromJSON,
    StudentGroupFromJSONTyped,
    StudentGroupToJSON,
} from './StudentGroup';

/**
 * 
 * @export
 * @interface StudentGroupTranslation
 */
export interface StudentGroupTranslation {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTranslation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupTranslation
     */
    name: string;
    /**
     * 
     * @type {StudentGroup}
     * @memberof StudentGroupTranslation
     */
    core?: StudentGroup;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTranslation
     */
    coreId?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupTranslation
     */
    language?: string | null;
}

export function StudentGroupTranslationFromJSON(json: any): StudentGroupTranslation {
    return StudentGroupTranslationFromJSONTyped(json, false);
}

export function StudentGroupTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupTranslation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'core': !exists(json, 'core') ? undefined : StudentGroupFromJSON(json['core']),
        'coreId': !exists(json, 'coreId') ? undefined : json['coreId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function StudentGroupTranslationToJSON(value?: StudentGroupTranslation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'core': StudentGroupToJSON(value.core),
        'coreId': value.coreId,
        'language': value.language,
    };
}

