/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPermissionSetting,
    UserPermissionSettingFromJSON,
    UserPermissionSettingFromJSONTyped,
    UserPermissionSettingToJSON,
} from './UserPermissionSetting';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface MemberListDto
 */
export interface MemberListDto {
    /**
     * 
     * @type {number}
     * @memberof MemberListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberListDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberListDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberListDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<MemberListDto>}
     * @memberof MemberListDto
     */
    children?: Array<MemberListDto> | null;
    /**
     * 
     * @type {Array<UserPermissionSetting>}
     * @memberof MemberListDto
     */
    permissions?: Array<UserPermissionSetting> | null;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof MemberListDto
     */
    roles?: Array<UserRole> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MemberListDto
     */
    readonly roleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberListDto
     */
    readonly permissionNames?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof MemberListDto
     */
    lastLoginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MemberListDto
     */
    creationTime?: Date;
}

export function MemberListDtoFromJSON(json: any): MemberListDto {
    return MemberListDtoFromJSONTyped(json, false);
}

export function MemberListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(MemberListDtoFromJSON)),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(UserPermissionSettingFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(UserRoleFromJSON)),
        'roleIds': !exists(json, 'roleIds') ? undefined : json['roleIds'],
        'permissionNames': !exists(json, 'permissionNames') ? undefined : json['permissionNames'],
        'lastLoginTime': !exists(json, 'lastLoginTime') ? undefined : (json['lastLoginTime'] === null ? null : new Date(json['lastLoginTime'])),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
    };
}

export function MemberListDtoToJSON(value?: MemberListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'isActive': value.isActive,
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(MemberListDtoToJSON)),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(UserPermissionSettingToJSON)),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(UserRoleToJSON)),
        'lastLoginTime': value.lastLoginTime === undefined ? undefined : (value.lastLoginTime === null ? null : value.lastLoginTime.toISOString()),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
    };
}

