/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessonWithSubjectListDto,
    LessonWithSubjectListDtoFromJSON,
    LessonWithSubjectListDtoFromJSONTyped,
    LessonWithSubjectListDtoToJSON,
} from './LessonWithSubjectListDto';

/**
 * 
 * @export
 * @interface LessonWithSubjectListDtoPagedResultDto
 */
export interface LessonWithSubjectListDtoPagedResultDto {
    /**
     * 
     * @type {Array<LessonWithSubjectListDto>}
     * @memberof LessonWithSubjectListDtoPagedResultDto
     */
    items?: Array<LessonWithSubjectListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof LessonWithSubjectListDtoPagedResultDto
     */
    totalCount?: number;
}

export function LessonWithSubjectListDtoPagedResultDtoFromJSON(json: any): LessonWithSubjectListDtoPagedResultDto {
    return LessonWithSubjectListDtoPagedResultDtoFromJSONTyped(json, false);
}

export function LessonWithSubjectListDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonWithSubjectListDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(LessonWithSubjectListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function LessonWithSubjectListDtoPagedResultDtoToJSON(value?: LessonWithSubjectListDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(LessonWithSubjectListDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

