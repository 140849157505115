/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ConfirmUserIdCodeGetRequest {
    userId: string;
    code: string;
}

/**
 * 
 */
export class AccountConfirmApi extends runtime.BaseAPI {

    /**
     */
    async confirmUserIdCodeGetRaw(requestParameters: ConfirmUserIdCodeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling confirmUserIdCodeGet.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling confirmUserIdCodeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/confirm/{userId}/{code}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmUserIdCodeGet(requestParameters: ConfirmUserIdCodeGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.confirmUserIdCodeGetRaw(requestParameters, initOverrides);
    }

}
