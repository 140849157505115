/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FAQItemAdminListDtoListResultDto,
    FAQItemAdminListDtoListResultDtoFromJSON,
    FAQItemAdminListDtoListResultDtoToJSON,
    FAQItemDto,
    FAQItemDtoFromJSON,
    FAQItemDtoToJSON,
    FAQItemDtoPagedResultDto,
    FAQItemDtoPagedResultDtoFromJSON,
    FAQItemDtoPagedResultDtoToJSON,
    FAQItemListDtoPagedResultDto,
    FAQItemListDtoPagedResultDtoFromJSON,
    FAQItemListDtoPagedResultDtoToJSON,
    ReorderInput,
    ReorderInputFromJSON,
    ReorderInputToJSON,
} from '../models';

export interface ApiServicesAppFAQItemCreatePostRequest {
    fAQItemDto?: FAQItemDto;
}

export interface ApiServicesAppFAQItemDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppFAQItemGetActivatedItemsGetRequest {
    fAQCategoryId?: number;
    search?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppFAQItemGetAllGetRequest {
    skipCount?: number;
    maxResultCount?: number;
    sorting?: string;
}

export interface ApiServicesAppFAQItemGetAllTranslatedGetRequest {
    isActive?: boolean;
    fAQCategoryId?: number;
    keyword?: string;
}

export interface ApiServicesAppFAQItemGetGetRequest {
    id?: number;
}

export interface ApiServicesAppFAQItemReorderPostRequest {
    reorderInput?: ReorderInput;
}

export interface ApiServicesAppFAQItemUpdatePutRequest {
    fAQItemDto?: FAQItemDto;
}

/**
 * 
 */
export class FAQItemApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppFAQItemCreatePostRaw(requestParameters: ApiServicesAppFAQItemCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQItemDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/FAQItem/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FAQItemDtoToJSON(requestParameters.fAQItemDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQItemCreatePost(requestParameters: ApiServicesAppFAQItemCreatePostRequest, initOverrides?: RequestInit): Promise<FAQItemDto> {
        const response = await this.apiServicesAppFAQItemCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQItemDeleteDeleteRaw(requestParameters: ApiServicesAppFAQItemDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQItem/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppFAQItemDeleteDelete(requestParameters: ApiServicesAppFAQItemDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppFAQItemDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppFAQItemGetActivatedItemsGetRaw(requestParameters: ApiServicesAppFAQItemGetActivatedItemsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQItemListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.fAQCategoryId !== undefined) {
            queryParameters['FAQCategoryId'] = requestParameters.fAQCategoryId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['Search'] = requestParameters.search;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQItem/GetActivatedItems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQItemListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQItemGetActivatedItemsGet(requestParameters: ApiServicesAppFAQItemGetActivatedItemsGetRequest, initOverrides?: RequestInit): Promise<FAQItemListDtoPagedResultDto> {
        const response = await this.apiServicesAppFAQItemGetActivatedItemsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQItemGetAllGetRaw(requestParameters: ApiServicesAppFAQItemGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQItemDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQItem/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQItemDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQItemGetAllGet(requestParameters: ApiServicesAppFAQItemGetAllGetRequest, initOverrides?: RequestInit): Promise<FAQItemDtoPagedResultDto> {
        const response = await this.apiServicesAppFAQItemGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQItemGetAllTranslatedGetRaw(requestParameters: ApiServicesAppFAQItemGetAllTranslatedGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQItemAdminListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.fAQCategoryId !== undefined) {
            queryParameters['FAQCategoryId'] = requestParameters.fAQCategoryId;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQItem/GetAllTranslated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQItemAdminListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQItemGetAllTranslatedGet(requestParameters: ApiServicesAppFAQItemGetAllTranslatedGetRequest, initOverrides?: RequestInit): Promise<FAQItemAdminListDtoListResultDto> {
        const response = await this.apiServicesAppFAQItemGetAllTranslatedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQItemGetGetRaw(requestParameters: ApiServicesAppFAQItemGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQItemDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQItem/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQItemGetGet(requestParameters: ApiServicesAppFAQItemGetGetRequest, initOverrides?: RequestInit): Promise<FAQItemDto> {
        const response = await this.apiServicesAppFAQItemGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQItemReorderPostRaw(requestParameters: ApiServicesAppFAQItemReorderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/FAQItem/Reorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderInputToJSON(requestParameters.reorderInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppFAQItemReorderPost(requestParameters: ApiServicesAppFAQItemReorderPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppFAQItemReorderPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppFAQItemUpdatePutRaw(requestParameters: ApiServicesAppFAQItemUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQItemDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/FAQItem/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FAQItemDtoToJSON(requestParameters.fAQItemDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQItemUpdatePut(requestParameters: ApiServicesAppFAQItemUpdatePutRequest, initOverrides?: RequestInit): Promise<FAQItemDto> {
        const response = await this.apiServicesAppFAQItemUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
