/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiteInfoEditDto
 */
export interface SiteInfoEditDto {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    readonly appName?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    readonly companyName?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    readonly logo?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    footerLogo?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    emailAddress?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    addressOfHeadQuarters?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    readonly addressOfMOSCenter?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SiteInfoEditDto
     */
    readonly copyright?: { [key: string]: any; } | null;
}

export function SiteInfoEditDtoFromJSON(json: any): SiteInfoEditDto {
    return SiteInfoEditDtoFromJSONTyped(json, false);
}

export function SiteInfoEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteInfoEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appName': !exists(json, 'appName') ? undefined : json['appName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'footerLogo': !exists(json, 'footerLogo') ? undefined : json['footerLogo'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'addressOfHeadQuarters': !exists(json, 'addressOfHeadQuarters') ? undefined : json['addressOfHeadQuarters'],
        'addressOfMOSCenter': !exists(json, 'addressOfMOSCenter') ? undefined : json['addressOfMOSCenter'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
    };
}

export function SiteInfoEditDtoToJSON(value?: SiteInfoEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footerLogo': value.footerLogo,
        'emailAddress': value.emailAddress,
        'addressOfHeadQuarters': value.addressOfHeadQuarters,
    };
}

