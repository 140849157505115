/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';

/**
 * 
 * @export
 * @interface RoleDtoListResultDto
 */
export interface RoleDtoListResultDto {
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleDtoListResultDto
     */
    items?: Array<RoleDto> | null;
}

export function RoleDtoListResultDtoFromJSON(json: any): RoleDtoListResultDto {
    return RoleDtoListResultDtoFromJSONTyped(json, false);
}

export function RoleDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(RoleDtoFromJSON)),
    };
}

export function RoleDtoListResultDtoToJSON(value?: RoleDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(RoleDtoToJSON)),
    };
}

