/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModuleHandle
 */
export interface ModuleHandle {
    /**
     * 
     * @type {number}
     * @memberof ModuleHandle
     */
    readonly mdStreamVersion?: number;
}

export function ModuleHandleFromJSON(json: any): ModuleHandle {
    return ModuleHandleFromJSONTyped(json, false);
}

export function ModuleHandleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleHandle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdStreamVersion': !exists(json, 'mdStreamVersion') ? undefined : json['mdStreamVersion'],
    };
}

export function ModuleHandleToJSON(value?: ModuleHandle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

