/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SiteInfoDto,
    SiteInfoDtoFromJSON,
    SiteInfoDtoToJSON,
    SiteInfoEditDto,
    SiteInfoEditDtoFromJSON,
    SiteInfoEditDtoToJSON,
} from '../models';

export interface ApiServicesAppSiteInfoUpdatePutRequest {
    siteInfoEditDto?: SiteInfoEditDto;
}

/**
 * 
 */
export class SiteInfoApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSiteInfoGetGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SiteInfoEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SiteInfo/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteInfoEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSiteInfoGetGet(initOverrides?: RequestInit): Promise<SiteInfoEditDto> {
        const response = await this.apiServicesAppSiteInfoGetGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSiteInfoGetSiteInfoGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SiteInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SiteInfo/GetSiteInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSiteInfoGetSiteInfoGet(initOverrides?: RequestInit): Promise<SiteInfoDto> {
        const response = await this.apiServicesAppSiteInfoGetSiteInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSiteInfoUpdatePutRaw(requestParameters: ApiServicesAppSiteInfoUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SiteInfo/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SiteInfoEditDtoToJSON(requestParameters.siteInfoEditDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSiteInfoUpdatePut(requestParameters: ApiServicesAppSiteInfoUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSiteInfoUpdatePutRaw(requestParameters, initOverrides);
    }

}
