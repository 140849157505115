/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductListDto,
    ProductListDtoFromJSON,
    ProductListDtoFromJSONTyped,
    ProductListDtoToJSON,
} from './ProductListDto';

/**
 * 
 * @export
 * @interface OrderItemListDto
 */
export interface OrderItemListDto {
    /**
     * 
     * @type {number}
     * @memberof OrderItemListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemListDto
     */
    productId?: number;
    /**
     * 
     * @type {ProductListDto}
     * @memberof OrderItemListDto
     */
    product?: ProductListDto;
    /**
     * 
     * @type {number}
     * @memberof OrderItemListDto
     */
    quantity?: number;
}

export function OrderItemListDtoFromJSON(json: any): OrderItemListDto {
    return OrderItemListDtoFromJSONTyped(json, false);
}

export function OrderItemListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'product': !exists(json, 'product') ? undefined : ProductListDtoFromJSON(json['product']),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function OrderItemListDtoToJSON(value?: OrderItemListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'product': ProductListDtoToJSON(value.product),
        'quantity': value.quantity,
    };
}

