/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldAttributes {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_16 = 16,
    NUMBER_32 = 32,
    NUMBER_64 = 64,
    NUMBER_128 = 128,
    NUMBER_256 = 256,
    NUMBER_512 = 512,
    NUMBER_1024 = 1024,
    NUMBER_4096 = 4096,
    NUMBER_8192 = 8192,
    NUMBER_32768 = 32768,
    NUMBER_38144 = 38144
}

export function FieldAttributesFromJSON(json: any): FieldAttributes {
    return FieldAttributesFromJSONTyped(json, false);
}

export function FieldAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldAttributes {
    return json as FieldAttributes;
}

export function FieldAttributesToJSON(value?: FieldAttributes | null): any {
    return value as any;
}

