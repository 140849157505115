/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserCoinReasonType,
    UserCoinReasonTypeFromJSON,
    UserCoinReasonTypeFromJSONTyped,
    UserCoinReasonTypeToJSON,
} from './UserCoinReasonType';

/**
 * 
 * @export
 * @interface UserCoinHistoryDto
 */
export interface UserCoinHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistoryDto
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistoryDto
     */
    amount?: number;
    /**
     * 
     * @type {UserCoinReasonType}
     * @memberof UserCoinHistoryDto
     */
    userCoinReasonType?: UserCoinReasonType;
    /**
     * 
     * @type {string}
     * @memberof UserCoinHistoryDto
     */
    reasonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCoinHistoryDto
     */
    remark?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserCoinHistoryDto
     */
    creationTime?: Date;
}

export function UserCoinHistoryDtoFromJSON(json: any): UserCoinHistoryDto {
    return UserCoinHistoryDtoFromJSONTyped(json, false);
}

export function UserCoinHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCoinHistoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'userCoinReasonType': !exists(json, 'userCoinReasonType') ? undefined : UserCoinReasonTypeFromJSON(json['userCoinReasonType']),
        'reasonId': !exists(json, 'reasonId') ? undefined : json['reasonId'],
        'remark': !exists(json, 'remark') ? undefined : json['remark'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
    };
}

export function UserCoinHistoryDtoToJSON(value?: UserCoinHistoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'amount': value.amount,
        'userCoinReasonType': UserCoinReasonTypeToJSON(value.userCoinReasonType),
        'reasonId': value.reasonId,
        'remark': value.remark,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
    };
}

