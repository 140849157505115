/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvertisementListDto,
    AdvertisementListDtoFromJSON,
    AdvertisementListDtoFromJSONTyped,
    AdvertisementListDtoToJSON,
} from './AdvertisementListDto';

/**
 * 
 * @export
 * @interface AdvertisementListDtoListResultDto
 */
export interface AdvertisementListDtoListResultDto {
    /**
     * 
     * @type {Array<AdvertisementListDto>}
     * @memberof AdvertisementListDtoListResultDto
     */
    items?: Array<AdvertisementListDto> | null;
}

export function AdvertisementListDtoListResultDtoFromJSON(json: any): AdvertisementListDtoListResultDto {
    return AdvertisementListDtoListResultDtoFromJSONTyped(json, false);
}

export function AdvertisementListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvertisementListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(AdvertisementListDtoFromJSON)),
    };
}

export function AdvertisementListDtoListResultDtoToJSON(value?: AdvertisementListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(AdvertisementListDtoToJSON)),
    };
}

