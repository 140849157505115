/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticateResultModel
 */
export interface AuthenticateResultModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResultModel
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResultModel
     */
    encryptedAccessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResultModel
     */
    refreshToken?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateResultModel
     */
    expireInSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateResultModel
     */
    userId?: number;
}

export function AuthenticateResultModelFromJSON(json: any): AuthenticateResultModel {
    return AuthenticateResultModelFromJSONTyped(json, false);
}

export function AuthenticateResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticateResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'encryptedAccessToken': !exists(json, 'encryptedAccessToken') ? undefined : json['encryptedAccessToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'expireInSeconds': !exists(json, 'expireInSeconds') ? undefined : json['expireInSeconds'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function AuthenticateResultModelToJSON(value?: AuthenticateResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'encryptedAccessToken': value.encryptedAccessToken,
        'refreshToken': value.refreshToken,
        'expireInSeconds': value.expireInSeconds,
        'userId': value.userId,
    };
}

