/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessonListDto,
    LessonListDtoFromJSON,
    LessonListDtoFromJSONTyped,
    LessonListDtoToJSON,
} from './LessonListDto';
import {
    QuestionTypeListDto,
    QuestionTypeListDtoFromJSON,
    QuestionTypeListDtoFromJSONTyped,
    QuestionTypeListDtoToJSON,
} from './QuestionTypeListDto';
import {
    SubjectListDto,
    SubjectListDtoFromJSON,
    SubjectListDtoFromJSONTyped,
    SubjectListDtoToJSON,
} from './SubjectListDto';

/**
 * 
 * @export
 * @interface LessonListDtoLessonListResultDto
 */
export interface LessonListDtoLessonListResultDto {
    /**
     * 
     * @type {Array<LessonListDto>}
     * @memberof LessonListDtoLessonListResultDto
     */
    items?: Array<LessonListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof LessonListDtoLessonListResultDto
     */
    availableSubjectiveCommentTimes?: number;
    /**
     * 
     * @type {Array<QuestionTypeListDto>}
     * @memberof LessonListDtoLessonListResultDto
     */
    questionTypes?: Array<QuestionTypeListDto> | null;
    /**
     * 
     * @type {SubjectListDto}
     * @memberof LessonListDtoLessonListResultDto
     */
    subject?: SubjectListDto;
}

export function LessonListDtoLessonListResultDtoFromJSON(json: any): LessonListDtoLessonListResultDto {
    return LessonListDtoLessonListResultDtoFromJSONTyped(json, false);
}

export function LessonListDtoLessonListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonListDtoLessonListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(LessonListDtoFromJSON)),
        'availableSubjectiveCommentTimes': !exists(json, 'availableSubjectiveCommentTimes') ? undefined : json['availableSubjectiveCommentTimes'],
        'questionTypes': !exists(json, 'questionTypes') ? undefined : (json['questionTypes'] === null ? null : (json['questionTypes'] as Array<any>).map(QuestionTypeListDtoFromJSON)),
        'subject': !exists(json, 'subject') ? undefined : SubjectListDtoFromJSON(json['subject']),
    };
}

export function LessonListDtoLessonListResultDtoToJSON(value?: LessonListDtoLessonListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(LessonListDtoToJSON)),
        'availableSubjectiveCommentTimes': value.availableSubjectiveCommentTimes,
        'questionTypes': value.questionTypes === undefined ? undefined : (value.questionTypes === null ? null : (value.questionTypes as Array<any>).map(QuestionTypeListDtoToJSON)),
        'subject': SubjectListDtoToJSON(value.subject),
    };
}

