/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddSchoolAdminInput
 */
export interface AddSchoolAdminInput {
    /**
     * 
     * @type {string}
     * @memberof AddSchoolAdminInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof AddSchoolAdminInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AddSchoolAdminInput
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof AddSchoolAdminInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddSchoolAdminInput
     */
    surname: string;
    /**
     * 
     * @type {number}
     * @memberof AddSchoolAdminInput
     */
    schoolId?: number;
}

export function AddSchoolAdminInputFromJSON(json: any): AddSchoolAdminInput {
    return AddSchoolAdminInputFromJSONTyped(json, false);
}

export function AddSchoolAdminInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSchoolAdminInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': json['userName'],
        'emailAddress': json['emailAddress'],
        'password': json['password'],
        'name': json['name'],
        'surname': json['surname'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
    };
}

export function AddSchoolAdminInputToJSON(value?: AddSchoolAdminInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'name': value.name,
        'surname': value.surname,
        'schoolId': value.schoolId,
    };
}

