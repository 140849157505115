/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallingConventions,
    CallingConventionsFromJSON,
    CallingConventionsFromJSONTyped,
    CallingConventionsToJSON,
} from './CallingConventions';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    MemberTypes,
    MemberTypesFromJSON,
    MemberTypesFromJSONTyped,
    MemberTypesToJSON,
} from './MemberTypes';
import {
    MethodAttributes,
    MethodAttributesFromJSON,
    MethodAttributesFromJSONTyped,
    MethodAttributesToJSON,
} from './MethodAttributes';
import {
    MethodImplAttributes,
    MethodImplAttributesFromJSON,
    MethodImplAttributesFromJSONTyped,
    MethodImplAttributesToJSON,
} from './MethodImplAttributes';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    ParameterInfo,
    ParameterInfoFromJSON,
    ParameterInfoFromJSONTyped,
    ParameterInfoToJSON,
} from './ParameterInfo';
import {
    RuntimeMethodHandle,
    RuntimeMethodHandleFromJSON,
    RuntimeMethodHandleFromJSONTyped,
    RuntimeMethodHandleToJSON,
} from './RuntimeMethodHandle';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';

/**
 * 
 * @export
 * @interface MethodInfo
 */
export interface MethodInfo {
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodInfo
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodInfo
     */
    readonly metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodInfo
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodInfo
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodInfo
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodInfo
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    readonly isSecurityTransparent?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {ParameterInfo}
     * @memberof MethodInfo
     */
    returnParameter?: ParameterInfo;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    returnType?: Type;
    /**
     * 
     * @type {object}
     * @memberof MethodInfo
     */
    returnTypeCustomAttributes?: object;
}

export function MethodInfoFromJSON(json: any): MethodInfo {
    return MethodInfoFromJSONTyped(json, false);
}

export function MethodInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'declaringType': !exists(json, 'declaringType') ? undefined : TypeFromJSON(json['declaringType']),
        'reflectedType': !exists(json, 'reflectedType') ? undefined : TypeFromJSON(json['reflectedType']),
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'isCollectible': !exists(json, 'isCollectible') ? undefined : json['isCollectible'],
        'metadataToken': !exists(json, 'metadataToken') ? undefined : json['metadataToken'],
        'attributes': !exists(json, 'attributes') ? undefined : MethodAttributesFromJSON(json['attributes']),
        'methodImplementationFlags': !exists(json, 'methodImplementationFlags') ? undefined : MethodImplAttributesFromJSON(json['methodImplementationFlags']),
        'callingConvention': !exists(json, 'callingConvention') ? undefined : CallingConventionsFromJSON(json['callingConvention']),
        'isAbstract': !exists(json, 'isAbstract') ? undefined : json['isAbstract'],
        'isConstructor': !exists(json, 'isConstructor') ? undefined : json['isConstructor'],
        'isFinal': !exists(json, 'isFinal') ? undefined : json['isFinal'],
        'isHideBySig': !exists(json, 'isHideBySig') ? undefined : json['isHideBySig'],
        'isSpecialName': !exists(json, 'isSpecialName') ? undefined : json['isSpecialName'],
        'isStatic': !exists(json, 'isStatic') ? undefined : json['isStatic'],
        'isVirtual': !exists(json, 'isVirtual') ? undefined : json['isVirtual'],
        'isAssembly': !exists(json, 'isAssembly') ? undefined : json['isAssembly'],
        'isFamily': !exists(json, 'isFamily') ? undefined : json['isFamily'],
        'isFamilyAndAssembly': !exists(json, 'isFamilyAndAssembly') ? undefined : json['isFamilyAndAssembly'],
        'isFamilyOrAssembly': !exists(json, 'isFamilyOrAssembly') ? undefined : json['isFamilyOrAssembly'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'isPublic': !exists(json, 'isPublic') ? undefined : json['isPublic'],
        'isConstructedGenericMethod': !exists(json, 'isConstructedGenericMethod') ? undefined : json['isConstructedGenericMethod'],
        'isGenericMethod': !exists(json, 'isGenericMethod') ? undefined : json['isGenericMethod'],
        'isGenericMethodDefinition': !exists(json, 'isGenericMethodDefinition') ? undefined : json['isGenericMethodDefinition'],
        'containsGenericParameters': !exists(json, 'containsGenericParameters') ? undefined : json['containsGenericParameters'],
        'methodHandle': !exists(json, 'methodHandle') ? undefined : RuntimeMethodHandleFromJSON(json['methodHandle']),
        'isSecurityCritical': !exists(json, 'isSecurityCritical') ? undefined : json['isSecurityCritical'],
        'isSecuritySafeCritical': !exists(json, 'isSecuritySafeCritical') ? undefined : json['isSecuritySafeCritical'],
        'isSecurityTransparent': !exists(json, 'isSecurityTransparent') ? undefined : json['isSecurityTransparent'],
        'memberType': !exists(json, 'memberType') ? undefined : MemberTypesFromJSON(json['memberType']),
        'returnParameter': !exists(json, 'returnParameter') ? undefined : ParameterInfoFromJSON(json['returnParameter']),
        'returnType': !exists(json, 'returnType') ? undefined : TypeFromJSON(json['returnType']),
        'returnTypeCustomAttributes': !exists(json, 'returnTypeCustomAttributes') ? undefined : json['returnTypeCustomAttributes'],
    };
}

export function MethodInfoToJSON(value?: MethodInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declaringType': TypeToJSON(value.declaringType),
        'reflectedType': TypeToJSON(value.reflectedType),
        'module': ModuleToJSON(value.module),
        'attributes': MethodAttributesToJSON(value.attributes),
        'methodImplementationFlags': MethodImplAttributesToJSON(value.methodImplementationFlags),
        'callingConvention': CallingConventionsToJSON(value.callingConvention),
        'methodHandle': RuntimeMethodHandleToJSON(value.methodHandle),
        'memberType': MemberTypesToJSON(value.memberType),
        'returnParameter': ParameterInfoToJSON(value.returnParameter),
        'returnType': TypeToJSON(value.returnType),
        'returnTypeCustomAttributes': value.returnTypeCustomAttributes,
    };
}

