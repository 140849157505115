/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Question,
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';
import {
    Test,
    TestFromJSON,
    TestFromJSONTyped,
    TestToJSON,
} from './Test';

/**
 * 
 * @export
 * @interface TestQuestion
 */
export interface TestQuestion {
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    testId?: number | null;
    /**
     * 
     * @type {Test}
     * @memberof TestQuestion
     */
    test?: Test;
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    parentId?: number | null;
    /**
     * 
     * @type {TestQuestion}
     * @memberof TestQuestion
     */
    parentTestQuestion?: TestQuestion;
    /**
     * 
     * @type {Array<TestQuestion>}
     * @memberof TestQuestion
     */
    subTestQuestions?: Array<TestQuestion> | null;
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    questionId?: number;
    /**
     * 
     * @type {Question}
     * @memberof TestQuestion
     */
    question?: Question;
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    points?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestQuestion
     */
    testerAnswer?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestQuestion
     */
    isTesterAnswerCorrect?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof TestQuestion
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestQuestion
     */
    teacherUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof TestQuestion
     */
    teacherAuditedTime?: Date | null;
}

export function TestQuestionFromJSON(json: any): TestQuestion {
    return TestQuestionFromJSONTyped(json, false);
}

export function TestQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
        'test': !exists(json, 'test') ? undefined : TestFromJSON(json['test']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'parentTestQuestion': !exists(json, 'parentTestQuestion') ? undefined : TestQuestionFromJSON(json['parentTestQuestion']),
        'subTestQuestions': !exists(json, 'subTestQuestions') ? undefined : (json['subTestQuestions'] === null ? null : (json['subTestQuestions'] as Array<any>).map(TestQuestionFromJSON)),
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'question': !exists(json, 'question') ? undefined : QuestionFromJSON(json['question']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'testerAnswer': !exists(json, 'testerAnswer') ? undefined : json['testerAnswer'],
        'isTesterAnswerCorrect': !exists(json, 'isTesterAnswerCorrect') ? undefined : json['isTesterAnswerCorrect'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'teacherComment': !exists(json, 'teacherComment') ? undefined : json['teacherComment'],
        'teacherUserId': !exists(json, 'teacherUserId') ? undefined : json['teacherUserId'],
        'teacherAuditedTime': !exists(json, 'teacherAuditedTime') ? undefined : (json['teacherAuditedTime'] === null ? null : new Date(json['teacherAuditedTime'])),
    };
}

export function TestQuestionToJSON(value?: TestQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'testId': value.testId,
        'test': TestToJSON(value.test),
        'parentId': value.parentId,
        'parentTestQuestion': TestQuestionToJSON(value.parentTestQuestion),
        'subTestQuestions': value.subTestQuestions === undefined ? undefined : (value.subTestQuestions === null ? null : (value.subTestQuestions as Array<any>).map(TestQuestionToJSON)),
        'questionId': value.questionId,
        'question': QuestionToJSON(value.question),
        'points': value.points,
        'testerAnswer': value.testerAnswer,
        'isTesterAnswerCorrect': value.isTesterAnswerCorrect,
        'score': value.score,
        'teacherComment': value.teacherComment,
        'teacherUserId': value.teacherUserId,
        'teacherAuditedTime': value.teacherAuditedTime === undefined ? undefined : (value.teacherAuditedTime === null ? null : value.teacherAuditedTime.toISOString()),
    };
}

