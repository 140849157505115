/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    School,
    SchoolFromJSON,
    SchoolFromJSONTyped,
    SchoolToJSON,
} from './School';
import {
    Setting,
    SettingFromJSON,
    SettingFromJSONTyped,
    SettingToJSON,
} from './Setting';
import {
    UserClaim,
    UserClaimFromJSON,
    UserClaimFromJSONTyped,
    UserClaimToJSON,
} from './UserClaim';
import {
    UserCoinHistory,
    UserCoinHistoryFromJSON,
    UserCoinHistoryFromJSONTyped,
    UserCoinHistoryToJSON,
} from './UserCoinHistory';
import {
    UserLogin,
    UserLoginFromJSON,
    UserLoginFromJSONTyped,
    UserLoginToJSON,
} from './UserLogin';
import {
    UserPermissionSetting,
    UserPermissionSettingFromJSON,
    UserPermissionSettingFromJSONTyped,
    UserPermissionSettingToJSON,
} from './UserPermissionSetting';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';
import {
    UserToken,
    UserTokenFromJSON,
    UserTokenFromJSONTyped,
    UserTokenToJSON,
} from './UserToken';
import {
    UserWallet,
    UserWalletFromJSON,
    UserWalletFromJSONTyped,
    UserWalletToJSON,
} from './UserWallet';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    authenticationSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userName: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    tenantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailConfirmationCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordResetCode?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lockoutEndDateUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    accessFailedCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isLockoutEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isPhoneNumberConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    securityStamp?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isTwoFactorEnabled?: boolean;
    /**
     * 
     * @type {Array<UserLogin>}
     * @memberof User
     */
    logins?: Array<UserLogin> | null;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof User
     */
    roles?: Array<UserRole> | null;
    /**
     * 
     * @type {Array<UserClaim>}
     * @memberof User
     */
    claims?: Array<UserClaim> | null;
    /**
     * 
     * @type {Array<UserPermissionSetting>}
     * @memberof User
     */
    permissions?: Array<UserPermissionSetting> | null;
    /**
     * 
     * @type {Array<Setting>}
     * @memberof User
     */
    settings?: Array<Setting> | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isEmailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedUserName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    concurrencyStamp?: string | null;
    /**
     * 
     * @type {Array<UserToken>}
     * @memberof User
     */
    tokens?: Array<UserToken> | null;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    deleterUser?: User;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    creatorUser?: User;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    lastModifierUser?: User;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    parentId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    parent?: User;
    /**
     * 
     * @type {Array<User>}
     * @memberof User
     */
    children?: Array<User> | null;
    /**
     * 
     * @type {Array<UserWallet>}
     * @memberof User
     */
    wallets?: Array<UserWallet> | null;
    /**
     * 
     * @type {Array<UserCoinHistory>}
     * @memberof User
     */
    userCoinHistories?: Array<UserCoinHistory> | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    schoolId?: number | null;
    /**
     * 
     * @type {School}
     * @memberof User
     */
    school?: School;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'authenticationSource': !exists(json, 'authenticationSource') ? undefined : json['authenticationSource'],
        'userName': json['userName'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'emailAddress': json['emailAddress'],
        'name': json['name'],
        'surname': json['surname'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'password': json['password'],
        'emailConfirmationCode': !exists(json, 'emailConfirmationCode') ? undefined : json['emailConfirmationCode'],
        'passwordResetCode': !exists(json, 'passwordResetCode') ? undefined : json['passwordResetCode'],
        'lockoutEndDateUtc': !exists(json, 'lockoutEndDateUtc') ? undefined : (json['lockoutEndDateUtc'] === null ? null : new Date(json['lockoutEndDateUtc'])),
        'accessFailedCount': !exists(json, 'accessFailedCount') ? undefined : json['accessFailedCount'],
        'isLockoutEnabled': !exists(json, 'isLockoutEnabled') ? undefined : json['isLockoutEnabled'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'isPhoneNumberConfirmed': !exists(json, 'isPhoneNumberConfirmed') ? undefined : json['isPhoneNumberConfirmed'],
        'securityStamp': !exists(json, 'securityStamp') ? undefined : json['securityStamp'],
        'isTwoFactorEnabled': !exists(json, 'isTwoFactorEnabled') ? undefined : json['isTwoFactorEnabled'],
        'logins': !exists(json, 'logins') ? undefined : (json['logins'] === null ? null : (json['logins'] as Array<any>).map(UserLoginFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(UserRoleFromJSON)),
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(UserClaimFromJSON)),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(UserPermissionSettingFromJSON)),
        'settings': !exists(json, 'settings') ? undefined : (json['settings'] === null ? null : (json['settings'] as Array<any>).map(SettingFromJSON)),
        'isEmailConfirmed': !exists(json, 'isEmailConfirmed') ? undefined : json['isEmailConfirmed'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'normalizedUserName': json['normalizedUserName'],
        'normalizedEmailAddress': json['normalizedEmailAddress'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'tokens': !exists(json, 'tokens') ? undefined : (json['tokens'] === null ? null : (json['tokens'] as Array<any>).map(UserTokenFromJSON)),
        'deleterUser': !exists(json, 'deleterUser') ? undefined : UserFromJSON(json['deleterUser']),
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
        'lastModifierUser': !exists(json, 'lastModifierUser') ? undefined : UserFromJSON(json['lastModifierUser']),
        'userType': !exists(json, 'userType') ? undefined : json['userType'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'parent': !exists(json, 'parent') ? undefined : UserFromJSON(json['parent']),
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(UserFromJSON)),
        'wallets': !exists(json, 'wallets') ? undefined : (json['wallets'] === null ? null : (json['wallets'] as Array<any>).map(UserWalletFromJSON)),
        'userCoinHistories': !exists(json, 'userCoinHistories') ? undefined : (json['userCoinHistories'] === null ? null : (json['userCoinHistories'] as Array<any>).map(UserCoinHistoryFromJSON)),
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'school': !exists(json, 'school') ? undefined : SchoolFromJSON(json['school']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'isDeleted': value.isDeleted,
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'authenticationSource': value.authenticationSource,
        'userName': value.userName,
        'tenantId': value.tenantId,
        'emailAddress': value.emailAddress,
        'name': value.name,
        'surname': value.surname,
        'password': value.password,
        'emailConfirmationCode': value.emailConfirmationCode,
        'passwordResetCode': value.passwordResetCode,
        'lockoutEndDateUtc': value.lockoutEndDateUtc === undefined ? undefined : (value.lockoutEndDateUtc === null ? null : value.lockoutEndDateUtc.toISOString()),
        'accessFailedCount': value.accessFailedCount,
        'isLockoutEnabled': value.isLockoutEnabled,
        'phoneNumber': value.phoneNumber,
        'isPhoneNumberConfirmed': value.isPhoneNumberConfirmed,
        'securityStamp': value.securityStamp,
        'isTwoFactorEnabled': value.isTwoFactorEnabled,
        'logins': value.logins === undefined ? undefined : (value.logins === null ? null : (value.logins as Array<any>).map(UserLoginToJSON)),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(UserRoleToJSON)),
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(UserClaimToJSON)),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(UserPermissionSettingToJSON)),
        'settings': value.settings === undefined ? undefined : (value.settings === null ? null : (value.settings as Array<any>).map(SettingToJSON)),
        'isEmailConfirmed': value.isEmailConfirmed,
        'isActive': value.isActive,
        'normalizedUserName': value.normalizedUserName,
        'normalizedEmailAddress': value.normalizedEmailAddress,
        'concurrencyStamp': value.concurrencyStamp,
        'tokens': value.tokens === undefined ? undefined : (value.tokens === null ? null : (value.tokens as Array<any>).map(UserTokenToJSON)),
        'deleterUser': UserToJSON(value.deleterUser),
        'creatorUser': UserToJSON(value.creatorUser),
        'lastModifierUser': UserToJSON(value.lastModifierUser),
        'userType': value.userType,
        'parentId': value.parentId,
        'parent': UserToJSON(value.parent),
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(UserToJSON)),
        'wallets': value.wallets === undefined ? undefined : (value.wallets === null ? null : (value.wallets as Array<any>).map(UserWalletToJSON)),
        'userCoinHistories': value.userCoinHistories === undefined ? undefined : (value.userCoinHistories === null ? null : (value.userCoinHistories as Array<any>).map(UserCoinHistoryToJSON)),
        'schoolId': value.schoolId,
        'school': SchoolToJSON(value.school),
    };
}

