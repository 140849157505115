/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportQuestionOptionsDto,
    ExaminationExportQuestionOptionsDtoFromJSON,
    ExaminationExportQuestionOptionsDtoFromJSONTyped,
    ExaminationExportQuestionOptionsDtoToJSON,
} from './ExaminationExportQuestionOptionsDto';

/**
 * 
 * @export
 * @interface ExaminationExportQuestionOptionsDtoListResultDto
 */
export interface ExaminationExportQuestionOptionsDtoListResultDto {
    /**
     * 
     * @type {Array<ExaminationExportQuestionOptionsDto>}
     * @memberof ExaminationExportQuestionOptionsDtoListResultDto
     */
    items?: Array<ExaminationExportQuestionOptionsDto> | null;
}

export function ExaminationExportQuestionOptionsDtoListResultDtoFromJSON(json: any): ExaminationExportQuestionOptionsDtoListResultDto {
    return ExaminationExportQuestionOptionsDtoListResultDtoFromJSONTyped(json, false);
}

export function ExaminationExportQuestionOptionsDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportQuestionOptionsDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ExaminationExportQuestionOptionsDtoFromJSON)),
    };
}

export function ExaminationExportQuestionOptionsDtoListResultDtoToJSON(value?: ExaminationExportQuestionOptionsDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ExaminationExportQuestionOptionsDtoToJSON)),
    };
}

