/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StudentStatments,
    StudentStatmentsFromJSON,
    StudentStatmentsToJSON,
} from '../models';

export interface ApiServicesAppAggregatesGetStudentStatementsGetRequest {
    userId?: number;
}

/**
 * 
 */
export class AggregatesApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppAggregatesGetStudentStatementsGetRaw(requestParameters: ApiServicesAppAggregatesGetStudentStatementsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentStatments>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Aggregates/GetStudentStatements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentStatmentsFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAggregatesGetStudentStatementsGet(requestParameters: ApiServicesAppAggregatesGetStudentStatementsGetRequest, initOverrides?: RequestInit): Promise<StudentStatments> {
        const response = await this.apiServicesAppAggregatesGetStudentStatementsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
