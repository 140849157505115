/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    StageTranslation,
    StageTranslationFromJSON,
    StageTranslationFromJSONTyped,
    StageTranslationToJSON,
} from './StageTranslation';

/**
 * 
 * @export
 * @interface Stage
 */
export interface Stage {
    /**
     * 
     * @type {number}
     * @memberof Stage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Stage
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof Stage
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<StageTranslation>}
     * @memberof Stage
     */
    translations?: Array<StageTranslation> | null;
    /**
     * 
     * @type {Array<Grade>}
     * @memberof Stage
     */
    grades?: Array<Grade> | null;
}

export function StageFromJSON(json: any): Stage {
    return StageFromJSONTyped(json, false);
}

export function StageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': json['code'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StageTranslationFromJSON)),
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] === null ? null : (json['grades'] as Array<any>).map(GradeFromJSON)),
    };
}

export function StageToJSON(value?: Stage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'isActive': value.isActive,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StageTranslationToJSON)),
        'grades': value.grades === undefined ? undefined : (value.grades === null ? null : (value.grades as Array<any>).map(GradeToJSON)),
    };
}

