/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateOrderInput,
    CreateOrderInputFromJSON,
    CreateOrderInputToJSON,
    CreateOrderResponse,
    CreateOrderResponseFromJSON,
    CreateOrderResponseToJSON,
    GetOrderResponse,
    GetOrderResponseFromJSON,
    GetOrderResponseToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    OrderListDtoPagedResultDto,
    OrderListDtoPagedResultDtoFromJSON,
    OrderListDtoPagedResultDtoToJSON,
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusToJSON,
    PreOrderInput,
    PreOrderInputFromJSON,
    PreOrderInputToJSON,
    PreOrderResponse,
    PreOrderResponseFromJSON,
    PreOrderResponseToJSON,
    ProductPaymentListDto,
    ProductPaymentListDtoFromJSON,
    ProductPaymentListDtoToJSON,
    ProductPurchaseMethod,
    ProductPurchaseMethodFromJSON,
    ProductPurchaseMethodToJSON,
    QueryOrderInput,
    QueryOrderInputFromJSON,
    QueryOrderInputToJSON,
    UserOrderDetailDto,
    UserOrderDetailDtoFromJSON,
    UserOrderDetailDtoToJSON,
    UserOrderListDtoPagedResultDto,
    UserOrderListDtoPagedResultDtoFromJSON,
    UserOrderListDtoPagedResultDtoToJSON,
} from '../models';

export interface ApiServicesAppOrderCancelPostRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppOrderCancelSubscriptionPostRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppOrderCreateOrderPostRequest {
    createOrderInput?: CreateOrderInput;
}

export interface ApiServicesAppOrderDeleteDeleteRequest {
    orderId?: number;
}

export interface ApiServicesAppOrderGetAllGetRequest {
    status?: OrderStatus;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppOrderGetProductPaymentListGetRequest {
    productPurchaseMethod?: ProductPurchaseMethod;
}

export interface ApiServicesAppOrderGetUserOrderDetailGetRequest {
    orderId?: number;
}

export interface ApiServicesAppOrderGetUserOrderListGetRequest {
    status?: OrderStatus;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppOrderPreOrderPostRequest {
    preOrderInput?: PreOrderInput;
}

export interface ApiServicesAppOrderQueryOrderPostRequest {
    queryOrderInput?: QueryOrderInput;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppOrderCancelPostRaw(requestParameters: ApiServicesAppOrderCancelPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Order/Cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppOrderCancelPost(requestParameters: ApiServicesAppOrderCancelPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppOrderCancelPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppOrderCancelSubscriptionPostRaw(requestParameters: ApiServicesAppOrderCancelSubscriptionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Order/CancelSubscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppOrderCancelSubscriptionPost(requestParameters: ApiServicesAppOrderCancelSubscriptionPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppOrderCancelSubscriptionPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppOrderCreateOrderPostRaw(requestParameters: ApiServicesAppOrderCreateOrderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Order/CreateOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderInputToJSON(requestParameters.createOrderInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderCreateOrderPost(requestParameters: ApiServicesAppOrderCreateOrderPostRequest, initOverrides?: RequestInit): Promise<CreateOrderResponse> {
        const response = await this.apiServicesAppOrderCreateOrderPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppOrderDeleteDeleteRaw(requestParameters: ApiServicesAppOrderDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Order/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppOrderDeleteDelete(requestParameters: ApiServicesAppOrderDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppOrderDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppOrderGetAllGetRaw(requestParameters: ApiServicesAppOrderGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Order/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderGetAllGet(requestParameters: ApiServicesAppOrderGetAllGetRequest, initOverrides?: RequestInit): Promise<OrderListDtoPagedResultDto> {
        const response = await this.apiServicesAppOrderGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppOrderGetProductPaymentListGetRaw(requestParameters: ApiServicesAppOrderGetProductPaymentListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductPaymentListDto>> {
        const queryParameters: any = {};

        if (requestParameters.productPurchaseMethod !== undefined) {
            queryParameters['ProductPurchaseMethod'] = requestParameters.productPurchaseMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Order/GetProductPaymentList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPaymentListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderGetProductPaymentListGet(requestParameters: ApiServicesAppOrderGetProductPaymentListGetRequest, initOverrides?: RequestInit): Promise<ProductPaymentListDto> {
        const response = await this.apiServicesAppOrderGetProductPaymentListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppOrderGetUserOrderDetailGetRaw(requestParameters: ApiServicesAppOrderGetUserOrderDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserOrderDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Order/GetUserOrderDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOrderDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderGetUserOrderDetailGet(requestParameters: ApiServicesAppOrderGetUserOrderDetailGetRequest, initOverrides?: RequestInit): Promise<UserOrderDetailDto> {
        const response = await this.apiServicesAppOrderGetUserOrderDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppOrderGetUserOrderListGetRaw(requestParameters: ApiServicesAppOrderGetUserOrderListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserOrderListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Order/GetUserOrderList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOrderListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderGetUserOrderListGet(requestParameters: ApiServicesAppOrderGetUserOrderListGetRequest, initOverrides?: RequestInit): Promise<UserOrderListDtoPagedResultDto> {
        const response = await this.apiServicesAppOrderGetUserOrderListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppOrderPreOrderPostRaw(requestParameters: ApiServicesAppOrderPreOrderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PreOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Order/PreOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOrderInputToJSON(requestParameters.preOrderInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderPreOrderPost(requestParameters: ApiServicesAppOrderPreOrderPostRequest, initOverrides?: RequestInit): Promise<PreOrderResponse> {
        const response = await this.apiServicesAppOrderPreOrderPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppOrderQueryOrderPostRaw(requestParameters: ApiServicesAppOrderQueryOrderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Order/QueryOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryOrderInputToJSON(requestParameters.queryOrderInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppOrderQueryOrderPost(requestParameters: ApiServicesAppOrderQueryOrderPostRequest, initOverrides?: RequestInit): Promise<GetOrderResponse> {
        const response = await this.apiServicesAppOrderQueryOrderPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
