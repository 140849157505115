/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeAssigneesInput
 */
export interface ChangeAssigneesInput {
    /**
     * 
     * @type {number}
     * @memberof ChangeAssigneesInput
     */
    examinationExportId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeAssigneesInput
     */
    assigneesUserId?: Array<number> | null;
}

export function ChangeAssigneesInputFromJSON(json: any): ChangeAssigneesInput {
    return ChangeAssigneesInputFromJSONTyped(json, false);
}

export function ChangeAssigneesInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeAssigneesInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'assigneesUserId': !exists(json, 'assigneesUserId') ? undefined : json['assigneesUserId'],
    };
}

export function ChangeAssigneesInputToJSON(value?: ChangeAssigneesInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examinationExportId': value.examinationExportId,
        'assigneesUserId': value.assigneesUserId,
    };
}

