/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateDailyPracticeInput,
    CreateDailyPracticeInputFromJSON,
    CreateDailyPracticeInputToJSON,
    CreateExaminationInput,
    CreateExaminationInputFromJSON,
    CreateExaminationInputToJSON,
    CreateFromQuestionListInput,
    CreateFromQuestionListInputFromJSON,
    CreateFromQuestionListInputToJSON,
    CreatePracticeInput,
    CreatePracticeInputFromJSON,
    CreatePracticeInputToJSON,
    DailyPracticeDetailDto,
    DailyPracticeDetailDtoFromJSON,
    DailyPracticeDetailDtoToJSON,
    DailyPracticeListDtoPagedResultDto,
    DailyPracticeListDtoPagedResultDtoFromJSON,
    DailyPracticeListDtoPagedResultDtoToJSON,
    EntityDto,
    EntityDtoFromJSON,
    EntityDtoToJSON,
    ExaminationDto,
    ExaminationDtoFromJSON,
    ExaminationDtoToJSON,
    PracticeDto,
    PracticeDtoFromJSON,
    PracticeDtoToJSON,
    SubmitTestResult,
    SubmitTestResultFromJSON,
    SubmitTestResultToJSON,
    TestIdInput,
    TestIdInputFromJSON,
    TestIdInputToJSON,
    TestListDtoPagedResultDto,
    TestListDtoPagedResultDtoFromJSON,
    TestListDtoPagedResultDtoToJSON,
    TestResultDetailDto,
    TestResultDetailDtoFromJSON,
    TestResultDetailDtoToJSON,
    TestType,
    TestTypeFromJSON,
    TestTypeToJSON,
} from '../models';

export interface ApiServicesAppTestBeginTestPostRequest {
    testIdInput?: TestIdInput;
}

export interface ApiServicesAppTestCreateDailyPracticePostRequest {
    createDailyPracticeInput?: CreateDailyPracticeInput;
}

export interface ApiServicesAppTestCreateExaminationPostRequest {
    createExaminationInput?: CreateExaminationInput;
}

export interface ApiServicesAppTestCreateFromQuestionListPostRequest {
    createFromQuestionListInput?: CreateFromQuestionListInput;
}

export interface ApiServicesAppTestCreatePracticePostRequest {
    createPracticeInput?: CreatePracticeInput;
}

export interface ApiServicesAppTestCreateWithExportPaperIdPostRequest {
    entityDto?: EntityDto;
}

export interface ApiServicesAppTestGetDailyPracticeDetailGetRequest {
    id?: number;
}

export interface ApiServicesAppTestGetDailyPracticeListGetRequest {
    subjectId?: number;
    userId?: number;
    isFinished?: boolean;
    testType?: Array<TestType>;
    dateStart?: Date;
    dateEnd?: Date;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppTestGetDailyPracticesWithDetailGetRequest {
    day?: Date;
    userId?: number;
}

export interface ApiServicesAppTestGetTestResultDetailGetRequest {
    testId?: number;
}

export interface ApiServicesAppTestGetTestSummariesGetRequest {
    userId?: number;
}

export interface ApiServicesAppTestGetTestsGetRequest {
    subjectId?: number;
    userId?: number;
    isFinished?: boolean;
    testType?: Array<TestType>;
    dateStart?: Date;
    dateEnd?: Date;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppTestSubmitExaminationPostRequest {
    examinationDto?: ExaminationDto;
}

export interface ApiServicesAppTestSubmitPracticePostRequest {
    practiceDto?: PracticeDto;
}

/**
 * 
 */
export class TestApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppTestBeginTestPostRaw(requestParameters: ApiServicesAppTestBeginTestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/BeginTest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestIdInputToJSON(requestParameters.testIdInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppTestBeginTestPost(requestParameters: ApiServicesAppTestBeginTestPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppTestBeginTestPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppTestCreateDailyPracticePostRaw(requestParameters: ApiServicesAppTestCreateDailyPracticePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/CreateDailyPractice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDailyPracticeInputToJSON(requestParameters.createDailyPracticeInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestCreateDailyPracticePost(requestParameters: ApiServicesAppTestCreateDailyPracticePostRequest, initOverrides?: RequestInit): Promise<PracticeDto> {
        const response = await this.apiServicesAppTestCreateDailyPracticePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestCreateExaminationPostRaw(requestParameters: ApiServicesAppTestCreateExaminationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/CreateExamination`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExaminationInputToJSON(requestParameters.createExaminationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestCreateExaminationPost(requestParameters: ApiServicesAppTestCreateExaminationPostRequest, initOverrides?: RequestInit): Promise<ExaminationDto> {
        const response = await this.apiServicesAppTestCreateExaminationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestCreateFromQuestionListPostRaw(requestParameters: ApiServicesAppTestCreateFromQuestionListPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/CreateFromQuestionList`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFromQuestionListInputToJSON(requestParameters.createFromQuestionListInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestCreateFromQuestionListPost(requestParameters: ApiServicesAppTestCreateFromQuestionListPostRequest, initOverrides?: RequestInit): Promise<PracticeDto> {
        const response = await this.apiServicesAppTestCreateFromQuestionListPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestCreatePracticePostRaw(requestParameters: ApiServicesAppTestCreatePracticePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/CreatePractice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePracticeInputToJSON(requestParameters.createPracticeInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestCreatePracticePost(requestParameters: ApiServicesAppTestCreatePracticePostRequest, initOverrides?: RequestInit): Promise<PracticeDto> {
        const response = await this.apiServicesAppTestCreatePracticePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestCreateWithExportPaperIdPostRaw(requestParameters: ApiServicesAppTestCreateWithExportPaperIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PracticeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/CreateWithExportPaperId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityDtoToJSON(requestParameters.entityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PracticeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestCreateWithExportPaperIdPost(requestParameters: ApiServicesAppTestCreateWithExportPaperIdPostRequest, initOverrides?: RequestInit): Promise<PracticeDto> {
        const response = await this.apiServicesAppTestCreateWithExportPaperIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestGetDailyPracticeDetailGetRaw(requestParameters: ApiServicesAppTestGetDailyPracticeDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DailyPracticeDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Test/GetDailyPracticeDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyPracticeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestGetDailyPracticeDetailGet(requestParameters: ApiServicesAppTestGetDailyPracticeDetailGetRequest, initOverrides?: RequestInit): Promise<DailyPracticeDetailDto> {
        const response = await this.apiServicesAppTestGetDailyPracticeDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestGetDailyPracticeListGetRaw(requestParameters: ApiServicesAppTestGetDailyPracticeListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DailyPracticeListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectId !== undefined) {
            queryParameters['SubjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.isFinished !== undefined) {
            queryParameters['IsFinished'] = requestParameters.isFinished;
        }

        if (requestParameters.testType) {
            queryParameters['TestType'] = requestParameters.testType;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['DateStart'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['DateEnd'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Test/GetDailyPracticeList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyPracticeListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestGetDailyPracticeListGet(requestParameters: ApiServicesAppTestGetDailyPracticeListGetRequest, initOverrides?: RequestInit): Promise<DailyPracticeListDtoPagedResultDto> {
        const response = await this.apiServicesAppTestGetDailyPracticeListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestGetDailyPracticesWithDetailGetRaw(requestParameters: ApiServicesAppTestGetDailyPracticesWithDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DailyPracticeDetailDto>>> {
        const queryParameters: any = {};

        if (requestParameters.day !== undefined) {
            queryParameters['Day'] = (requestParameters.day as any).toISOString();
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Test/GetDailyPracticesWithDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DailyPracticeDetailDtoFromJSON));
    }

    /**
     */
    async apiServicesAppTestGetDailyPracticesWithDetailGet(requestParameters: ApiServicesAppTestGetDailyPracticesWithDetailGetRequest, initOverrides?: RequestInit): Promise<Array<DailyPracticeDetailDto>> {
        const response = await this.apiServicesAppTestGetDailyPracticesWithDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestGetTestResultDetailGetRaw(requestParameters: ApiServicesAppTestGetTestResultDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TestResultDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.testId !== undefined) {
            queryParameters['testId'] = requestParameters.testId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Test/GetTestResultDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestResultDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestGetTestResultDetailGet(requestParameters: ApiServicesAppTestGetTestResultDetailGetRequest, initOverrides?: RequestInit): Promise<TestResultDetailDto> {
        const response = await this.apiServicesAppTestGetTestResultDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestGetTestSummariesGetRaw(requestParameters: ApiServicesAppTestGetTestSummariesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Test/GetTestSummaries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppTestGetTestSummariesGet(requestParameters: ApiServicesAppTestGetTestSummariesGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppTestGetTestSummariesGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppTestGetTestsGetRaw(requestParameters: ApiServicesAppTestGetTestsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TestListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectId !== undefined) {
            queryParameters['SubjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.isFinished !== undefined) {
            queryParameters['IsFinished'] = requestParameters.isFinished;
        }

        if (requestParameters.testType) {
            queryParameters['TestType'] = requestParameters.testType;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['DateStart'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['DateEnd'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Test/GetTests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestGetTestsGet(requestParameters: ApiServicesAppTestGetTestsGetRequest, initOverrides?: RequestInit): Promise<TestListDtoPagedResultDto> {
        const response = await this.apiServicesAppTestGetTestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestSubmitExaminationPostRaw(requestParameters: ApiServicesAppTestSubmitExaminationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubmitTestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/SubmitExamination`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExaminationDtoToJSON(requestParameters.examinationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmitTestResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestSubmitExaminationPost(requestParameters: ApiServicesAppTestSubmitExaminationPostRequest, initOverrides?: RequestInit): Promise<SubmitTestResult> {
        const response = await this.apiServicesAppTestSubmitExaminationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTestSubmitPracticePostRaw(requestParameters: ApiServicesAppTestSubmitPracticePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubmitTestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Test/SubmitPractice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PracticeDtoToJSON(requestParameters.practiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmitTestResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTestSubmitPracticePost(requestParameters: ApiServicesAppTestSubmitPracticePostRequest, initOverrides?: RequestInit): Promise<SubmitTestResult> {
        const response = await this.apiServicesAppTestSubmitPracticePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
