import { atom } from "recoil";
import { isClient } from "src/utils/next-helper";
import { localforageEffect } from "../atomEffect";
import { SubjectType } from "src/fetch";

export type PracticeProgress = number | "not_started" | "reviewing" | "submitting" | "submitted";
export type DailyPracticeMeta = {
  [key in SubjectType]?: {
    id: number,
    progress: PracticeProgress
  }
}
export const DailyPracticeMetaAtom = atom<DailyPracticeMeta>({
  key: 'DailyPracticeMeta',
  default: {

  },
  effects: [
    localforageEffect('DailyPracticeMeta'),
  ]
});