/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleTranslationDto,
    ArticleTranslationDtoFromJSON,
    ArticleTranslationDtoFromJSONTyped,
    ArticleTranslationDtoToJSON,
} from './ArticleTranslationDto';
import {
    ContentCustomFieldDto,
    ContentCustomFieldDtoFromJSON,
    ContentCustomFieldDtoFromJSONTyped,
    ContentCustomFieldDtoToJSON,
} from './ContentCustomFieldDto';

/**
 * 
 * @export
 * @interface ContentArticleDto
 */
export interface ContentArticleDto {
    /**
     * 
     * @type {number}
     * @memberof ContentArticleDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContentArticleDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleDto
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {Array<ContentArticleDto>}
     * @memberof ContentArticleDto
     */
    subArticles?: Array<ContentArticleDto> | null;
    /**
     * 
     * @type {Array<ContentCustomFieldDto>}
     * @memberof ContentArticleDto
     */
    customFields?: Array<ContentCustomFieldDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleDto
     */
    sort?: number;
    /**
     * 
     * @type {Date}
     * @memberof ContentArticleDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContentArticleDto
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {Array<ArticleTranslationDto>}
     * @memberof ContentArticleDto
     */
    translations?: Array<ArticleTranslationDto> | null;
}

export function ContentArticleDtoFromJSON(json: any): ContentArticleDto {
    return ContentArticleDtoFromJSONTyped(json, false);
}

export function ContentArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'slug': json['slug'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'subArticles': !exists(json, 'subArticles') ? undefined : (json['subArticles'] === null ? null : (json['subArticles'] as Array<any>).map(ContentArticleDtoFromJSON)),
        'customFields': !exists(json, 'customFields') ? undefined : (json['customFields'] === null ? null : (json['customFields'] as Array<any>).map(ContentCustomFieldDtoFromJSON)),
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(ArticleTranslationDtoFromJSON)),
    };
}

export function ContentArticleDtoToJSON(value?: ContentArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'categoryId': value.categoryId,
        'slug': value.slug,
        'type': value.type,
        'parentId': value.parentId,
        'subArticles': value.subArticles === undefined ? undefined : (value.subArticles === null ? null : (value.subArticles as Array<any>).map(ContentArticleDtoToJSON)),
        'customFields': value.customFields === undefined ? undefined : (value.customFields === null ? null : (value.customFields as Array<any>).map(ContentCustomFieldDtoToJSON)),
        'sort': value.sort,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(ArticleTranslationDtoToJSON)),
    };
}

