/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailDto,
    QuestionDetailDtoFromJSON,
    QuestionDetailDtoFromJSONTyped,
    QuestionDetailDtoToJSON,
} from './QuestionDetailDto';

/**
 * 
 * @export
 * @interface QuestionAnswerDto
 */
export interface QuestionAnswerDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerDto
     */
    subjectiveAnswerId?: number;
    /**
     * 
     * @type {QuestionDetailDto}
     * @memberof QuestionAnswerDto
     */
    question?: QuestionDetailDto;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerDto
     */
    points?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerDto
     */
    testerAnswer?: string | null;
    /**
     * 
     * @type {any}
     * @memberof QuestionAnswerDto
     */
    readonly oTesterAnswer?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerDto
     */
    isTesterAnswerCorrect?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerDto
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerDto
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerDto
     */
    insufficientBalance?: boolean;
}

export function QuestionAnswerDtoFromJSON(json: any): QuestionAnswerDto {
    return QuestionAnswerDtoFromJSONTyped(json, false);
}

export function QuestionAnswerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionAnswerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subjectiveAnswerId': !exists(json, 'subjectiveAnswerId') ? undefined : json['subjectiveAnswerId'],
        'question': !exists(json, 'question') ? undefined : QuestionDetailDtoFromJSON(json['question']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'testerAnswer': !exists(json, 'testerAnswer') ? undefined : json['testerAnswer'],
        'oTesterAnswer': !exists(json, 'oTesterAnswer') ? undefined : json['oTesterAnswer'],
        'isTesterAnswerCorrect': !exists(json, 'isTesterAnswerCorrect') ? undefined : json['isTesterAnswerCorrect'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'teacherComment': !exists(json, 'teacherComment') ? undefined : json['teacherComment'],
        'insufficientBalance': !exists(json, 'insufficientBalance') ? undefined : json['insufficientBalance'],
    };
}

export function QuestionAnswerDtoToJSON(value?: QuestionAnswerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subjectiveAnswerId': value.subjectiveAnswerId,
        'question': QuestionDetailDtoToJSON(value.question),
        'points': value.points,
        'testerAnswer': value.testerAnswer,
        'isTesterAnswerCorrect': value.isTesterAnswerCorrect,
        'score': value.score,
        'teacherComment': value.teacherComment,
        'insufficientBalance': value.insufficientBalance,
    };
}

