/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleEditDto
 */
export interface RoleEditDto {
    /**
     * 
     * @type {number}
     * @memberof RoleEditDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleEditDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleEditDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof RoleEditDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleEditDto
     */
    isStatic?: boolean;
}

export function RoleEditDtoFromJSON(json: any): RoleEditDto {
    return RoleEditDtoFromJSONTyped(json, false);
}

export function RoleEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'displayName': json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isStatic': !exists(json, 'isStatic') ? undefined : json['isStatic'],
    };
}

export function RoleEditDtoToJSON(value?: RoleEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'displayName': value.displayName,
        'description': value.description,
        'isStatic': value.isStatic,
    };
}

