/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiteInfoDto
 */
export interface SiteInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    appName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    footerLogo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    addressOfHeadQuarters?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    addressOfMOSCenter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteInfoDto
     */
    copyright?: string | null;
}

export function SiteInfoDtoFromJSON(json: any): SiteInfoDto {
    return SiteInfoDtoFromJSONTyped(json, false);
}

export function SiteInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appName': !exists(json, 'appName') ? undefined : json['appName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'footerLogo': !exists(json, 'footerLogo') ? undefined : json['footerLogo'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'addressOfHeadQuarters': !exists(json, 'addressOfHeadQuarters') ? undefined : json['addressOfHeadQuarters'],
        'addressOfMOSCenter': !exists(json, 'addressOfMOSCenter') ? undefined : json['addressOfMOSCenter'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
    };
}

export function SiteInfoDtoToJSON(value?: SiteInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appName': value.appName,
        'companyName': value.companyName,
        'logo': value.logo,
        'footerLogo': value.footerLogo,
        'emailAddress': value.emailAddress,
        'addressOfHeadQuarters': value.addressOfHeadQuarters,
        'addressOfMOSCenter': value.addressOfMOSCenter,
        'copyright': value.copyright,
    };
}

