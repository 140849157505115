/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationListDto,
    LocationListDtoFromJSON,
    LocationListDtoFromJSONTyped,
    LocationListDtoToJSON,
} from './LocationListDto';

/**
 * 
 * @export
 * @interface LocationListDtoListResultDto
 */
export interface LocationListDtoListResultDto {
    /**
     * 
     * @type {Array<LocationListDto>}
     * @memberof LocationListDtoListResultDto
     */
    items?: Array<LocationListDto> | null;
}

export function LocationListDtoListResultDtoFromJSON(json: any): LocationListDtoListResultDto {
    return LocationListDtoListResultDtoFromJSONTyped(json, false);
}

export function LocationListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(LocationListDtoFromJSON)),
    };
}

export function LocationListDtoListResultDtoToJSON(value?: LocationListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(LocationListDtoToJSON)),
    };
}

