/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentLessonProgress,
    StudentLessonProgressFromJSON,
    StudentLessonProgressFromJSONTyped,
    StudentLessonProgressToJSON,
} from './StudentLessonProgress';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';

/**
 * 
 * @export
 * @interface StudentProgress
 */
export interface StudentProgress {
    /**
     * 
     * @type {number}
     * @memberof StudentProgress
     */
    subjectId?: number;
    /**
     * 
     * @type {SubjectType}
     * @memberof StudentProgress
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof StudentProgress
     */
    subjectName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StudentProgress
     */
    term?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentProgress
     */
    gradeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentProgress
     */
    gradeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentProgress
     */
    stageCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentProgress
     */
    stageName?: string | null;
    /**
     * 
     * @type {Array<StudentLessonProgress>}
     * @memberof StudentProgress
     */
    lessons?: Array<StudentLessonProgress> | null;
}

export function StudentProgressFromJSON(json: any): StudentProgress {
    return StudentProgressFromJSONTyped(json, false);
}

export function StudentProgressFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentProgress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'subjectName': !exists(json, 'subjectName') ? undefined : json['subjectName'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'gradeName': !exists(json, 'gradeName') ? undefined : json['gradeName'],
        'stageCode': !exists(json, 'stageCode') ? undefined : json['stageCode'],
        'stageName': !exists(json, 'stageName') ? undefined : json['stageName'],
        'lessons': !exists(json, 'lessons') ? undefined : (json['lessons'] === null ? null : (json['lessons'] as Array<any>).map(StudentLessonProgressFromJSON)),
    };
}

export function StudentProgressToJSON(value?: StudentProgress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectId': value.subjectId,
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'subjectName': value.subjectName,
        'term': value.term,
        'gradeCode': value.gradeCode,
        'gradeName': value.gradeName,
        'stageCode': value.stageCode,
        'stageName': value.stageName,
        'lessons': value.lessons === undefined ? undefined : (value.lessons === null ? null : (value.lessons as Array<any>).map(StudentLessonProgressToJSON)),
    };
}

