/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchoolListDto,
    SchoolListDtoFromJSON,
    SchoolListDtoFromJSONTyped,
    SchoolListDtoToJSON,
} from './SchoolListDto';

/**
 * 
 * @export
 * @interface SchoolListDtoListResultDto
 */
export interface SchoolListDtoListResultDto {
    /**
     * 
     * @type {Array<SchoolListDto>}
     * @memberof SchoolListDtoListResultDto
     */
    items?: Array<SchoolListDto> | null;
}

export function SchoolListDtoListResultDtoFromJSON(json: any): SchoolListDtoListResultDto {
    return SchoolListDtoListResultDtoFromJSONTyped(json, false);
}

export function SchoolListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(SchoolListDtoFromJSON)),
    };
}

export function SchoolListDtoListResultDtoToJSON(value?: SchoolListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(SchoolListDtoToJSON)),
    };
}

