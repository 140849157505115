/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    School,
    SchoolFromJSON,
    SchoolFromJSONTyped,
    SchoolToJSON,
} from './School';
import {
    SchoolStudent,
    SchoolStudentFromJSON,
    SchoolStudentFromJSONTyped,
    SchoolStudentToJSON,
} from './SchoolStudent';
import {
    SchoolTeacher,
    SchoolTeacherFromJSON,
    SchoolTeacherFromJSONTyped,
    SchoolTeacherToJSON,
} from './SchoolTeacher';
import {
    StudentGroupTag,
    StudentGroupTagFromJSON,
    StudentGroupTagFromJSONTyped,
    StudentGroupTagToJSON,
} from './StudentGroupTag';
import {
    StudentGroupTranslation,
    StudentGroupTranslationFromJSON,
    StudentGroupTranslationFromJSONTyped,
    StudentGroupTranslationToJSON,
} from './StudentGroupTranslation';

/**
 * 
 * @export
 * @interface StudentGroup
 */
export interface StudentGroup {
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    schoolId?: number;
    /**
     * 
     * @type {School}
     * @memberof StudentGroup
     */
    school?: School;
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    studentGroupTagId?: number | null;
    /**
     * 
     * @type {StudentGroupTag}
     * @memberof StudentGroup
     */
    studentGroupTag?: StudentGroupTag;
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    gradeId?: number | null;
    /**
     * 
     * @type {Grade}
     * @memberof StudentGroup
     */
    grade?: Grade;
    /**
     * 
     * @type {Array<SchoolStudent>}
     * @memberof StudentGroup
     */
    students?: Array<SchoolStudent> | null;
    /**
     * 
     * @type {Array<SchoolTeacher>}
     * @memberof StudentGroup
     */
    teachers?: Array<SchoolTeacher> | null;
    /**
     * 
     * @type {Array<StudentGroupTranslation>}
     * @memberof StudentGroup
     */
    translations?: Array<StudentGroupTranslation> | null;
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroup
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroup
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StudentGroup
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroup
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof StudentGroup
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentGroup
     */
    isActive?: boolean;
}

export function StudentGroupFromJSON(json: any): StudentGroup {
    return StudentGroupFromJSONTyped(json, false);
}

export function StudentGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'school': !exists(json, 'school') ? undefined : SchoolFromJSON(json['school']),
        'studentGroupTagId': !exists(json, 'studentGroupTagId') ? undefined : json['studentGroupTagId'],
        'studentGroupTag': !exists(json, 'studentGroupTag') ? undefined : StudentGroupTagFromJSON(json['studentGroupTag']),
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'students': !exists(json, 'students') ? undefined : (json['students'] === null ? null : (json['students'] as Array<any>).map(SchoolStudentFromJSON)),
        'teachers': !exists(json, 'teachers') ? undefined : (json['teachers'] === null ? null : (json['teachers'] as Array<any>).map(SchoolTeacherFromJSON)),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StudentGroupTranslationFromJSON)),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function StudentGroupToJSON(value?: StudentGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'school': SchoolToJSON(value.school),
        'studentGroupTagId': value.studentGroupTagId,
        'studentGroupTag': StudentGroupTagToJSON(value.studentGroupTag),
        'gradeId': value.gradeId,
        'grade': GradeToJSON(value.grade),
        'students': value.students === undefined ? undefined : (value.students === null ? null : (value.students as Array<any>).map(SchoolStudentToJSON)),
        'teachers': value.teachers === undefined ? undefined : (value.teachers === null ? null : (value.teachers as Array<any>).map(SchoolTeacherToJSON)),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StudentGroupTranslationToJSON)),
        'creatorUserId': value.creatorUserId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
        'isActive': value.isActive,
    };
}

