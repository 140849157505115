/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Assembly,
    AssemblyFromJSON,
    AssemblyFromJSONTyped,
    AssemblyToJSON,
} from './Assembly';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    ModuleHandle,
    ModuleHandleFromJSON,
    ModuleHandleFromJSONTyped,
    ModuleHandleToJSON,
} from './ModuleHandle';

/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Assembly}
     * @memberof Module
     */
    assembly?: Assembly;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    readonly fullyQualifiedName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    readonly name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    readonly mdStreamVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    readonly moduleVersionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    readonly scopeName?: string | null;
    /**
     * 
     * @type {ModuleHandle}
     * @memberof Module
     */
    moduleHandle?: ModuleHandle;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Module
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    readonly metadataToken?: number;
}

export function ModuleFromJSON(json: any): Module {
    return ModuleFromJSONTyped(json, false);
}

export function ModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Module {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assembly': !exists(json, 'assembly') ? undefined : AssemblyFromJSON(json['assembly']),
        'fullyQualifiedName': !exists(json, 'fullyQualifiedName') ? undefined : json['fullyQualifiedName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mdStreamVersion': !exists(json, 'mdStreamVersion') ? undefined : json['mdStreamVersion'],
        'moduleVersionId': !exists(json, 'moduleVersionId') ? undefined : json['moduleVersionId'],
        'scopeName': !exists(json, 'scopeName') ? undefined : json['scopeName'],
        'moduleHandle': !exists(json, 'moduleHandle') ? undefined : ModuleHandleFromJSON(json['moduleHandle']),
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'metadataToken': !exists(json, 'metadataToken') ? undefined : json['metadataToken'],
    };
}

export function ModuleToJSON(value?: Module | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assembly': AssemblyToJSON(value.assembly),
        'moduleHandle': ModuleHandleToJSON(value.moduleHandle),
    };
}

