/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudentDto
 */
export interface StudentDto {
    /**
     * 
     * @type {number}
     * @memberof StudentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    avator?: string | null;
}

export function StudentDtoFromJSON(json: any): StudentDto {
    return StudentDtoFromJSONTyped(json, false);
}

export function StudentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': json['userName'],
        'name': json['name'],
        'avator': !exists(json, 'avator') ? undefined : json['avator'],
    };
}

export function StudentDtoToJSON(value?: StudentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'avator': value.avator,
    };
}

