/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoToJSON,
    ProductListDto,
    ProductListDtoFromJSON,
    ProductListDtoToJSON,
    ProductListDtoListResultDto,
    ProductListDtoListResultDtoFromJSON,
    ProductListDtoListResultDtoToJSON,
    ProductListDtoPagedResultDto,
    ProductListDtoPagedResultDtoFromJSON,
    ProductListDtoPagedResultDtoToJSON,
    ProductPurchaseMethod,
    ProductPurchaseMethodFromJSON,
    ProductPurchaseMethodToJSON,
} from '../models';

export interface ApiServicesAppProductCreatePostRequest {
    productDto?: ProductDto;
}

export interface ApiServicesAppProductDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppProductGetAllGetRequest {
    productPurchaseMethod?: ProductPurchaseMethod;
    keyword?: string;
    isActive?: boolean;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppProductGetDetailGetRequest {
    productId?: number;
}

export interface ApiServicesAppProductGetGetRequest {
    id?: number;
}

export interface ApiServicesAppProductGetPromotionsGetRequest {
    skipCache?: boolean;
}

export interface ApiServicesAppProductUpdatePutRequest {
    productDto?: ProductDto;
}

/**
 * 
 */
export class ProductApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppProductCreatePostRaw(requestParameters: ApiServicesAppProductCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Product/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDtoToJSON(requestParameters.productDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppProductCreatePost(requestParameters: ApiServicesAppProductCreatePostRequest, initOverrides?: RequestInit): Promise<ProductDto> {
        const response = await this.apiServicesAppProductCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppProductDeleteDeleteRaw(requestParameters: ApiServicesAppProductDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Product/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppProductDeleteDelete(requestParameters: ApiServicesAppProductDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppProductDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppProductGetAllGetRaw(requestParameters: ApiServicesAppProductGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.productPurchaseMethod !== undefined) {
            queryParameters['ProductPurchaseMethod'] = requestParameters.productPurchaseMethod;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Product/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppProductGetAllGet(requestParameters: ApiServicesAppProductGetAllGetRequest, initOverrides?: RequestInit): Promise<ProductListDtoPagedResultDto> {
        const response = await this.apiServicesAppProductGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppProductGetDetailGetRaw(requestParameters: ApiServicesAppProductGetDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductListDto>> {
        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Product/GetDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppProductGetDetailGet(requestParameters: ApiServicesAppProductGetDetailGetRequest, initOverrides?: RequestInit): Promise<ProductListDto> {
        const response = await this.apiServicesAppProductGetDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppProductGetGetRaw(requestParameters: ApiServicesAppProductGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Product/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppProductGetGet(requestParameters: ApiServicesAppProductGetGetRequest, initOverrides?: RequestInit): Promise<ProductDto> {
        const response = await this.apiServicesAppProductGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppProductGetPromotionsGetRaw(requestParameters: ApiServicesAppProductGetPromotionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.skipCache !== undefined) {
            queryParameters['skipCache'] = requestParameters.skipCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Product/GetPromotions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppProductGetPromotionsGet(requestParameters: ApiServicesAppProductGetPromotionsGetRequest, initOverrides?: RequestInit): Promise<ProductListDtoListResultDto> {
        const response = await this.apiServicesAppProductGetPromotionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppProductUpdatePutRaw(requestParameters: ApiServicesAppProductUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Product/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDtoToJSON(requestParameters.productDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppProductUpdatePut(requestParameters: ApiServicesAppProductUpdatePutRequest, initOverrides?: RequestInit): Promise<ProductDto> {
        const response = await this.apiServicesAppProductUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
