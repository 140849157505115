/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdArrayInput
 */
export interface IdArrayInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof IdArrayInput
     */
    ids?: Array<number> | null;
}

export function IdArrayInputFromJSON(json: any): IdArrayInput {
    return IdArrayInputFromJSONTyped(json, false);
}

export function IdArrayInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdArrayInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
    };
}

export function IdArrayInputToJSON(value?: IdArrayInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
    };
}

