/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsTenantAvailableInput
 */
export interface IsTenantAvailableInput {
    /**
     * 
     * @type {string}
     * @memberof IsTenantAvailableInput
     */
    tenancyName: string;
}

export function IsTenantAvailableInputFromJSON(json: any): IsTenantAvailableInput {
    return IsTenantAvailableInputFromJSONTyped(json, false);
}

export function IsTenantAvailableInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsTenantAvailableInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenancyName': json['tenancyName'],
    };
}

export function IsTenantAvailableInputToJSON(value?: IsTenantAvailableInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenancyName': value.tenancyName,
    };
}

