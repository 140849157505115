/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddSchoolAdminInput,
    AddSchoolAdminInputFromJSON,
    AddSchoolAdminInputToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    SchoolAdminDetailDto,
    SchoolAdminDetailDtoFromJSON,
    SchoolAdminDetailDtoToJSON,
    SchoolAdminListDtoPagedResultDto,
    SchoolAdminListDtoPagedResultDtoFromJSON,
    SchoolAdminListDtoPagedResultDtoToJSON,
    ToggleActivateResult,
    ToggleActivateResultFromJSON,
    ToggleActivateResultToJSON,
    UpdateSchoolAdminInput,
    UpdateSchoolAdminInputFromJSON,
    UpdateSchoolAdminInputToJSON,
} from '../models';

export interface ApiServicesAppSchoolAdminAddPostRequest {
    addSchoolAdminInput?: AddSchoolAdminInput;
}

export interface ApiServicesAppSchoolAdminDeleteDeleteRequest {
    userId?: number;
}

export interface ApiServicesAppSchoolAdminGetGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolAdminGetListGetRequest {
    schoolId?: number;
    keyWords?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSchoolAdminToggleActivatePutRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppSchoolAdminUpdatePutRequest {
    updateSchoolAdminInput?: UpdateSchoolAdminInput;
}

/**
 * 
 */
export class SchoolAdminApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSchoolAdminAddPostRaw(requestParameters: ApiServicesAppSchoolAdminAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolAdmin/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSchoolAdminInputToJSON(requestParameters.addSchoolAdminInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolAdminAddPost(requestParameters: ApiServicesAppSchoolAdminAddPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolAdminAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolAdminDeleteDeleteRaw(requestParameters: ApiServicesAppSchoolAdminDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolAdmin/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolAdminDeleteDelete(requestParameters: ApiServicesAppSchoolAdminDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolAdminDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolAdminGetGetRaw(requestParameters: ApiServicesAppSchoolAdminGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolAdminDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolAdmin/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolAdminDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolAdminGetGet(requestParameters: ApiServicesAppSchoolAdminGetGetRequest, initOverrides?: RequestInit): Promise<SchoolAdminDetailDto> {
        const response = await this.apiServicesAppSchoolAdminGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolAdminGetListGetRaw(requestParameters: ApiServicesAppSchoolAdminGetListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolAdminListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['SchoolId'] = requestParameters.schoolId;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolAdmin/GetList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolAdminListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolAdminGetListGet(requestParameters: ApiServicesAppSchoolAdminGetListGetRequest, initOverrides?: RequestInit): Promise<SchoolAdminListDtoPagedResultDto> {
        const response = await this.apiServicesAppSchoolAdminGetListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolAdminToggleActivatePutRaw(requestParameters: ApiServicesAppSchoolAdminToggleActivatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToggleActivateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolAdmin/ToggleActivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleActivateResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolAdminToggleActivatePut(requestParameters: ApiServicesAppSchoolAdminToggleActivatePutRequest, initOverrides?: RequestInit): Promise<ToggleActivateResult> {
        const response = await this.apiServicesAppSchoolAdminToggleActivatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolAdminUpdatePutRaw(requestParameters: ApiServicesAppSchoolAdminUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolAdmin/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchoolAdminInputToJSON(requestParameters.updateSchoolAdminInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolAdminUpdatePut(requestParameters: ApiServicesAppSchoolAdminUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolAdminUpdatePutRaw(requestParameters, initOverrides);
    }

}
