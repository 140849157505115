/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionListWithCategoryDto,
    QuestionListWithCategoryDtoFromJSON,
    QuestionListWithCategoryDtoFromJSONTyped,
    QuestionListWithCategoryDtoToJSON,
} from './QuestionListWithCategoryDto';

/**
 * 
 * @export
 * @interface QuestionListWithCategoryDtoPagedResultDto
 */
export interface QuestionListWithCategoryDtoPagedResultDto {
    /**
     * 
     * @type {Array<QuestionListWithCategoryDto>}
     * @memberof QuestionListWithCategoryDtoPagedResultDto
     */
    items?: Array<QuestionListWithCategoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionListWithCategoryDtoPagedResultDto
     */
    totalCount?: number;
}

export function QuestionListWithCategoryDtoPagedResultDtoFromJSON(json: any): QuestionListWithCategoryDtoPagedResultDto {
    return QuestionListWithCategoryDtoPagedResultDtoFromJSONTyped(json, false);
}

export function QuestionListWithCategoryDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionListWithCategoryDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(QuestionListWithCategoryDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function QuestionListWithCategoryDtoPagedResultDtoToJSON(value?: QuestionListWithCategoryDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(QuestionListWithCategoryDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

