/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalAuthenticateModel
 */
export interface ExternalAuthenticateModel {
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateModel
     */
    authProvider: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateModel
     */
    providerKey: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateModel
     */
    providerAccessCode: string;
}

export function ExternalAuthenticateModelFromJSON(json: any): ExternalAuthenticateModel {
    return ExternalAuthenticateModelFromJSONTyped(json, false);
}

export function ExternalAuthenticateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAuthenticateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authProvider': json['authProvider'],
        'providerKey': json['providerKey'],
        'providerAccessCode': json['providerAccessCode'],
    };
}

export function ExternalAuthenticateModelToJSON(value?: ExternalAuthenticateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authProvider': value.authProvider,
        'providerKey': value.providerKey,
        'providerAccessCode': value.providerAccessCode,
    };
}

