/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    EventAttributes,
    EventAttributesFromJSON,
    EventAttributesFromJSONTyped,
    EventAttributesToJSON,
} from './EventAttributes';
import {
    MemberTypes,
    MemberTypesFromJSON,
    MemberTypesFromJSONTyped,
    MemberTypesToJSON,
} from './MemberTypes';
import {
    MethodInfo,
    MethodInfoFromJSON,
    MethodInfoFromJSONTyped,
    MethodInfoToJSON,
} from './MethodInfo';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';

/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof EventInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof EventInfo
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    readonly isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    readonly metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof EventInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {EventAttributes}
     * @memberof EventInfo
     */
    attributes?: EventAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    readonly isSpecialName?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    addMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    removeMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    raiseMethod?: MethodInfo;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    readonly isMulticast?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    eventHandlerType?: Type;
}

export function EventInfoFromJSON(json: any): EventInfo {
    return EventInfoFromJSONTyped(json, false);
}

export function EventInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'declaringType': !exists(json, 'declaringType') ? undefined : TypeFromJSON(json['declaringType']),
        'reflectedType': !exists(json, 'reflectedType') ? undefined : TypeFromJSON(json['reflectedType']),
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'isCollectible': !exists(json, 'isCollectible') ? undefined : json['isCollectible'],
        'metadataToken': !exists(json, 'metadataToken') ? undefined : json['metadataToken'],
        'memberType': !exists(json, 'memberType') ? undefined : MemberTypesFromJSON(json['memberType']),
        'attributes': !exists(json, 'attributes') ? undefined : EventAttributesFromJSON(json['attributes']),
        'isSpecialName': !exists(json, 'isSpecialName') ? undefined : json['isSpecialName'],
        'addMethod': !exists(json, 'addMethod') ? undefined : MethodInfoFromJSON(json['addMethod']),
        'removeMethod': !exists(json, 'removeMethod') ? undefined : MethodInfoFromJSON(json['removeMethod']),
        'raiseMethod': !exists(json, 'raiseMethod') ? undefined : MethodInfoFromJSON(json['raiseMethod']),
        'isMulticast': !exists(json, 'isMulticast') ? undefined : json['isMulticast'],
        'eventHandlerType': !exists(json, 'eventHandlerType') ? undefined : TypeFromJSON(json['eventHandlerType']),
    };
}

export function EventInfoToJSON(value?: EventInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declaringType': TypeToJSON(value.declaringType),
        'reflectedType': TypeToJSON(value.reflectedType),
        'module': ModuleToJSON(value.module),
        'memberType': MemberTypesToJSON(value.memberType),
        'attributes': EventAttributesToJSON(value.attributes),
        'addMethod': MethodInfoToJSON(value.addMethod),
        'removeMethod': MethodInfoToJSON(value.removeMethod),
        'raiseMethod': MethodInfoToJSON(value.raiseMethod),
        'eventHandlerType': TypeToJSON(value.eventHandlerType),
    };
}

