/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';

/**
 * 
 * @export
 * @interface SubjectNameDto
 */
export interface SubjectNameDto {
    /**
     * 
     * @type {SubjectType}
     * @memberof SubjectNameDto
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof SubjectNameDto
     */
    readonly subjectTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectNameDto
     */
    name?: string | null;
}

export function SubjectNameDtoFromJSON(json: any): SubjectNameDto {
    return SubjectNameDtoFromJSONTyped(json, false);
}

export function SubjectNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectNameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'subjectTypeName': !exists(json, 'subjectTypeName') ? undefined : json['subjectTypeName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function SubjectNameDtoToJSON(value?: SubjectNameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'name': value.name,
    };
}

