/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSchoolTeacherInput
 */
export interface UpdateSchoolTeacherInput {
    /**
     * 
     * @type {number}
     * @memberof UpdateSchoolTeacherInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolTeacherInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolTeacherInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolTeacherInput
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolTeacherInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolTeacherInput
     */
    surname: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSchoolTeacherInput
     */
    studentGroupsId?: Array<number> | null;
}

export function UpdateSchoolTeacherInputFromJSON(json: any): UpdateSchoolTeacherInput {
    return UpdateSchoolTeacherInputFromJSONTyped(json, false);
}

export function UpdateSchoolTeacherInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSchoolTeacherInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': json['userName'],
        'emailAddress': json['emailAddress'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'name': json['name'],
        'surname': json['surname'],
        'studentGroupsId': !exists(json, 'studentGroupsId') ? undefined : json['studentGroupsId'],
    };
}

export function UpdateSchoolTeacherInputToJSON(value?: UpdateSchoolTeacherInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'name': value.name,
        'surname': value.surname,
        'studentGroupsId': value.studentGroupsId,
    };
}

