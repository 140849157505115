/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudentGroupListDto
 */
export interface StudentGroupListDto {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupListDto
     */
    schoolId?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupListDto
     */
    gradeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupListDto
     */
    gradeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupListDto
     */
    tag?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StudentGroupListDto
     */
    creationDateTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof StudentGroupListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupListDto
     */
    studentCount?: number;
}

export function StudentGroupListDtoFromJSON(json: any): StudentGroupListDto {
    return StudentGroupListDtoFromJSONTyped(json, false);
}

export function StudentGroupListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'gradeName': !exists(json, 'gradeName') ? undefined : json['gradeName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'creationDateTime': !exists(json, 'creationDateTime') ? undefined : (new Date(json['creationDateTime'])),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'studentCount': !exists(json, 'studentCount') ? undefined : json['studentCount'],
    };
}

export function StudentGroupListDtoToJSON(value?: StudentGroupListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'gradeId': value.gradeId,
        'gradeName': value.gradeName,
        'name': value.name,
        'tag': value.tag,
        'creationDateTime': value.creationDateTime === undefined ? undefined : (value.creationDateTime.toISOString()),
        'isActive': value.isActive,
        'studentCount': value.studentCount,
    };
}

