/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    normalizedName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    grantedPermissions?: Array<string> | null;
}

export function RoleDtoFromJSON(json: any): RoleDto {
    return RoleDtoFromJSONTyped(json, false);
}

export function RoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'displayName': json['displayName'],
        'normalizedName': !exists(json, 'normalizedName') ? undefined : json['normalizedName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'grantedPermissions': !exists(json, 'grantedPermissions') ? undefined : json['grantedPermissions'],
    };
}

export function RoleDtoToJSON(value?: RoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'displayName': value.displayName,
        'normalizedName': value.normalizedName,
        'description': value.description,
        'grantedPermissions': value.grantedPermissions,
    };
}

