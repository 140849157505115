/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FAQCategoryListDto
 */
export interface FAQCategoryListDto {
    /**
     * 
     * @type {number}
     * @memberof FAQCategoryListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryListDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryListDto
     */
    icon?: string | null;
}

export function FAQCategoryListDtoFromJSON(json: any): FAQCategoryListDto {
    return FAQCategoryListDtoFromJSONTyped(json, false);
}

export function FAQCategoryListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQCategoryListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function FAQCategoryListDtoToJSON(value?: FAQCategoryListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'icon': value.icon,
    };
}

