import AccountIcon from "@/assets/images/AccountIcon";
import AdvantageIcon from "@/assets/images/AdvantageIcon";
import CalculatorIcon from "@/assets/images/CalculatorIcon";
import DeviceIcon from "@/assets/images/DeviceIcon";
import FaqIcon from "@/assets/images/FaqIcon";
import HeaderForumIcon from "@/assets/images/HeaderForumIcon";
import LanguageIcon from "@/assets/images/LanguageIcon";
import LevelIcon from "@/assets/images/LevelIcon";
import Logo from "@/assets/images/logo.png";
import LogoutIcon from "@/assets/images/LogoutIcon";
import ParentSonIcon from "@/assets/images/ParentSonIcon";
import PrinterIcon from "@/assets/images/PrinterIcon";
import StudentRecordIcon from "@/assets/images/StudentRecordIcon";
import SubscriptionIcon from "@/assets/images/SubscriptionIcon";
import ExercisesIcon from "public/icons/nav/exercise.svg";
import ProgressIcon from "public/icons/nav/progress.svg";
import DailyIcon from "public/icons/nav/daily.svg";
import WideLogoIcon from "public/icons/studylu-logo-text.svg";
import UserIcon from "@/assets/images/UserIcon";
import SelectBox from "@/components/input/SelectBox/SelectBox";
import { GlobeAltIcon, Bars3Icon as MenuIcon, XMarkIcon as XIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useSession } from "next-auth/react";
import { Resources, useTranslation } from "next-i18next";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useDialog } from "src/hooks/dialog/DialogHook";
import { useUserData } from "src/hooks/UserDataHook";

import Button from "../Button/Button";
import CombinedLink from "../CombinedLink/CombinedLink";
import DropDownMenu from "./DropdownMenu";
import DropDownMenuMobile from "./DropdownMenuMobile";
import css from "./Header.module.css";
import { useDailyPractice } from "src/hooks/daily/DailyPracticeHook";
import { useQuery } from "react-query";
import { ExportApi } from "src/fetch";
import { SubjectTypeList } from "src/hooks/practice/PracticeProvider";
import {useLayout} from "src/views/Layout/LayoutHook";
import StudentIcon from "public/icons/icon-student.svg"
import ParentIcon from "public/icons/icon-parent.svg"


//import { createUseStyles } from 'react-jss';
//const useStyles = createUseStyles({});

export const i18nHeaderNamespace: Array<keyof Resources> = ["header"];

export const SelectLanguageDialog: FC<{ onResult: (result: string) => void }> =
  (props) => {
    const { t } = useTranslation(["common", "header"]);
    const [selectedLanguage, setSelectedLanguage] = useState("zh-Hant");

    return (
      <>
        <div className={clsx("flex justify-center")}>
          <GlobeAltIcon width={24} className={clsx("text-primary-500 m-2")} />
          <SelectBox
            options={[
              { label: t("header:tc"), key: "zh-Hant" },
              { label: t("header:en"), key: "en" },
            ]}
            labelSelector={(it) => it.label}
            label={<></>}
            defaultSelectedIdx={0}
            inputProps={{ className: clsx`bg-red-500/0` }}
            onChange={(v) => setSelectedLanguage(v.key)}
          />
        </div>
        <div className={clsx("m-5 flex justify-center")}>
          <Button
            className={clsx("static text-xl")}
            variant="default"
            type="submit"
            onClick={() => props.onResult(selectedLanguage)}
          >
            {t("common:confirm")}
          </Button>
        </div>
      </>
    );
  };

const Header = () => {
  //const classes = useStyles();
  

  const router = useRouter();
  const { t } = useTranslation(i18nHeaderNamespace);
  const { userInfo: userData, getApiConfig } = useUserData();
  const { data: session, status } = useSession();
  const [width, setWidth] = React.useState(0);
  const [isScrolled, setScrolled] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { showCustomDialog } = useDialog();
  const {dailyPracticeMeta}= useDailyPractice();

  const {updateBridge,setUpdateBridge }=useLayout()

  function switchDrawerStatus() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  function resetOpenStatus() {
    setIsUserMenuOpen(false);
  }

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  const isMemberRoles=useMemo(()=>{
    return  userData?.roles?.includes("Member")??false
  },[userData])

  const isStudentRoles=useMemo(()=>{
    return  userData?.roles?.includes("Student")??false
  },[userData])
   
  const isGuestRoles=useMemo(()=>{
    return  (!isMemberRoles&&!isStudentRoles )
  },[isMemberRoles,isStudentRoles])


  const assignedTestData = useQuery(
    ["assignedTest", userData?.user?.id, JSON.stringify({isFinished: true}),updateBridge],
    async () => {
      if (userData) {
        const apiPayload = {
          maxResultCount: 8,
          assigneeUserId: userData?.user?.id,
          isFinished: false
        };
        return (
          (await new ExportApi(getApiConfig())
            .apiServicesAppExportGetExaminationPapersGetRaw(apiPayload)
            ?.Convert()
            ?.then((it) =>{
              setUpdateBridge&&setUpdateBridge(false)
              return it.items})) ?? []
        );
      } else return null;
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!( userData?.roles?.includes("Student") && userData?.user?.id && getApiConfig?.loadedLocale && getApiConfig?.loadedUserInfo ),
    }
  );

  useEffect(() => {
    if ( isDrawerOpen != false) {
      setIsDrawerOpen(false);
      resetOpenStatus();
    }
  }, [width,router.pathname]);





  const navDict: {
    [key in "guest" | "parent" | "student"]: Array<{
      title: string;
      Icon: (prop: React.SVGProps<SVGSVGElement>) => JSX.Element;
      url: string;
      badge?: number; 
      subItem?: Array<{
        title: string;
        url: string;
      }>;
    }>;
  } = useMemo(()=>({
    guest: [
      // { title: t("header:supervision"), Icon: ParentSonIcon, url: "/#parent" },
      // { title: t("header:systemSync"), Icon: DeviceIcon, url: "/#system" },
      // { title: t("header:benefit"), Icon: AdvantageIcon, url: "/#pros" },
      // { title: t("header:price"), Icon: CalculatorIcon, url: "/price" },
      // { title: t("header:faq"), Icon: FaqIcon, url: "/faq" },
    ],
    parent: [
      { title: t("header:progress"), Icon: ProgressIcon, url: "/parent" },
      {
        title: t("header:paperRecord"),
        Icon: ExercisesIcon,
        url: "/export/records",
      },
      {
        title: t("header:dailyRecord"),
        Icon: DailyIcon,
        url: "/parent/studentRecord",
      }
    ],
    student: [
      {
        title: t("header:progress"),
        Icon: ProgressIcon,
        url: "/student",
      },
      {
        title:t("header:exercise"),
        Icon: DailyIcon,
        url: "/student/daily",
        badge: SubjectTypeList.length - Object.values(dailyPracticeMeta).filter((it) => it?.progress === "submitted").length,
      },
      {
        title: t("header:exercises"),
        Icon: ExercisesIcon,
        url: "/student/practiceRecord",
        badge: assignedTestData?.data?.length? (assignedTestData.data.length>0?assignedTestData?.data?.length:undefined):undefined,
      },
      // { title: t("header:progress"), Icon: ProgressIcon, url: "/student/subject" },
      // { title: t("header:faq"), Icon: FaqIcon, url: "/faq" },
    ],
  }),[assignedTestData, dailyPracticeMeta]);



  const navDictList=useMemo(()=>{
    if(isMemberRoles)
      return navDict.parent;
    if(isStudentRoles)
      return navDict.student;
    return navDict.guest;
  },[isMemberRoles, isStudentRoles, navDict])


  return (
    <div
      className={clsx(
        "fixed w-full top-0 right-0 z-30 bg-white shadow-lg  h-12 sm:h-20"
      )}
    >
      <div className={clsx("w-full mx-auto container max-w-6xl flex h-full flex-row items-center  justify-between")}>
        <div
          className={clsx(
            " basis-[30%] relative" 
             
          )}
        >
          <div className="cursor-pointer  relative w-12 h-12 sm:w-16 sm:h-16">
            <Link href={isStudentRoles?"/student":isMemberRoles?"/parent":"/"} passHref >
              <div className="py-2 h-full">
                <WideLogoIcon className="h-full"/>
              </div>
            </Link>
          </div>
        </div>
        <div className={clsx("basis-[40%] hidden md:flex justify-center h-full")}>
 
          {navDictList.map((it, idx) =>
            it.subItem == undefined ? (
              <div key={idx} className="">
                <CombinedLink href={it.url} className="no-underline">
                  <button
                    aria-pressed={router.pathname == it.url}
                    aria-label="Button"
                    className={clsx(
                      "h-full flex flex-col items-center justify-center px-2 lg:px-4 aria-pressed:bg-gray-100 hover:bg-gray-100",
                    )}
                  >
                    <div className="relative">
                      <it.Icon                        />
                      {it.badge?<span 
                        className={clsx("p-1 absolute min-w-4 min-h-4 rounded-full -top-1 -right-1 bg-danger-500 text-xs text-center text-white font-bold",
                          it.badge>8?"h-4":"h-4 w-4 py-0"
                        )}>
                        {it.badge}{it.badge>8?"+":""}</span>
                        :<></>}

                    </div>
                    <span className="text-sm text-gray-500">
                      {it.title}
                    </span>
                  </button>
                </CombinedLink>
              </div>
            ) : (
              <Fragment key={idx}>
                <DropDownMenu
                  button={
                    <CombinedLink href={it.url} className="no-underline">
                      <button
                        aria-pressed={router.pathname == it.url}
                        className={clsx(
                          "h-full flex flex-col items-center justify-center px-2 lg:px-4 mx-2 aria-pressed:bg-gray-100 hover:bg-gray-100",
                        )}
                      >
                        <div className="relative">
                          <it.Icon   />
                          {it.badge?
                            <span className={clsx(`absolute min-w-4 min-h-4 rounded-full -top-1 -right-1 bg-danger-500
                             text-xs text-center text-white font-bold`,
                            it.badge>9?"":"h-4"
                            )}>
                              {it.badge}{it.badge>9?"+":""}</span>:<></>}
                        </div>
                        {it.title}
                      </button>
                    </CombinedLink>
                  }
                  items={it.subItem.map((item, itemIdx) => (
                    <Fragment key={itemIdx}>
                      <CombinedLink href={item.url}>
                        <Button
                          variant="white"
                          className={clsx(
                            "w-full h-[70px] hover:bg-primary-500 text-primary-500 hover:text-white border-2 border-primary-500"
                          )}
                        >
                          {item.title}
                        </Button>
                      </CombinedLink>
                    </Fragment>
                  ))}
                />
              </Fragment>
            )
          )}


        </div>
        <div className={clsx("basis-[30%] hidden md:flex justify-end")}>
          <div>
            
            {status === "authenticated" ? (
              <>
                <DropDownMenu
                  button={
                    <Button
                      aria-label="Account Button"
                      variant="white"
                      className={clsx(
                        "hover:bg-primary-500 text-primary-500 hover:text-white h-full hidden md:block"
                      )}
                    >
                      <div className={clsx("flex items-center")}>
                        {isStudentRoles? 
                          <StudentIcon className="w-12 h-12"/>
                          :
                          <ParentIcon className="w-12 h-12"/>}
                        <span className={clsx("pl-1")}>
                          {session?.user.name ?? "Undefined"}
                        </span>
                      </div>
                    </Button>
                  }
                  items={[
                    <>
                      {/* <CombinedLink href={"/user"}>
                        <Button
                          variant="white"
                          className={clsx(
                            "w-full h-[70px] hover:bg-primary-500 text-primary-500 hover:text-white border-2 border-primary-500"
                          )}
                        >
                          <UserIcon className={clsx("inline-block w-7 h-7")} />{" "}
                          {t("header:profile")}
                        </Button>
                      </CombinedLink> */}
                    </>,
                    <>
                      <Button
                        variant="white"
                        className={clsx(
                          "w-full h-[70px] hover:bg-primary-500 text-primary-500 hover:text-white border-l-2 border-b-2 border-r-2 border-primary-500"
                        )}
                        onClick={async () => {
                          const result = await showCustomDialog({
                            title: t("header:selectLanguage"),
                            Content: SelectLanguageDialog,
                            contentProps: {},
                            ableToCancel: true,
                          });
                          if (result.status == "success") {
                            router.replace(router.pathname, router.pathname, {
                              locale: result.data,
                            });
                          }
                        }}
                      >
                        <LanguageIcon className={clsx("inline-block w-7 h-7")} />{" "}
                        {t("header:language")}
                      </Button>
                    </>,
                    <>
                      <Button
                        variant="white"
                        className={clsx(
                          "w-full h-[70px] hover:bg-primary-500 text-primary-500 hover:text-white border-l-2 border-b-2 border-r-2 border-primary-500"
                        )}
                        onClick={() => router.push("/auth/signout")}
                      >
                        <LogoutIcon className={clsx("inline-block w-7 h-7")} />{" "}
                        {t("header:logout")}
                      </Button>
                    </>,
                  ]}
                />
              </>
            ) : (
              <>
                <CombinedLink href={"/auth/signin"}>
                  <Button
                    aria-label="Account Button"
                    variant="white"
                    className={clsx(
                      "hover:bg-primary-500 text-primary-500 hover:text-white h-full hidden md:block w-max"
                    )}
                  >
                    <AccountIcon className={clsx("w-10 h-10 inline-block")} />{" "}
                    {t("header:loginOrRegister")}
                  </Button>
                </CombinedLink>
              </>
            )}
        
          </div>
        </div>
        <div className={clsx("flex-none h-full relative block md:hidden")}>
          <input
            id="menuDrawer"
            type="checkbox"
            className={clsx("hidden")}
            onClick={switchDrawerStatus}
          />
          <div className={clsx("relative top-1/2 -translate-y-1/2")}>
            <label
              htmlFor="menuDrawer"
              className={clsx(
                "bg-primary-500 text-white flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20 md:hidden hover:cursor-pointer"
              )}
            >
              {isDrawerOpen ? (
                <XIcon width={32} height={32} />
              ) : (
                <MenuIcon width={32} height={32} />
              )}
            </label>
          </div>
          <div className={clsx("relative", isDrawerOpen ? "block" : "hidden")}>
            <label
              htmlFor="menuDrawer"
              className={clsx(
                "fixed top-0 left-0 z-30 bg-black/50 w-full h-full "
              )}
              onClick={resetOpenStatus}
            />
            <div
              className={clsx(
                "fixed top-0 right-0 h-screen overflow-y-auto w-1/2 bg-white z-50 "
              )}
            >
              <div>
                <CombinedLink href={isStudentRoles?"/student":isMemberRoles?"/parent":"/"}  >
                  <Button
                    aria-label="Parent Supervise Button"
                    variant="white"
                    className={clsx(
                      "relative block border-l border-r border-b w-full h-[170px] hover:bg-primary-500  text-primary-500 hover:text-white", 
                    )}
                  >
                    <div className="mx-auto flex flex-row justify-center text-white">
                      {isStudentRoles? 
                        <StudentIcon  className="w-12 h-12"/>
                        :
                        <ParentIcon className="w-12 h-12"/>}
                    </div>
                    <span className={clsx("block ")}>
                      {session?.user?.name ?? "Undefined"}
                    </span>
                  </Button>
                </CombinedLink>
              </div>
              
             
              {navDictList.map((it, idx) =>
                it.subItem == undefined ? (
                  <Fragment key={idx}>
                    <CombinedLink href={it.url}>
                      <Button
                        aria-label="Button"
                        variant="white"
                        className={clsx(
                          "block border-l border-r border-b w-full h-[120px] hover:bg-primary-500 text-primary-500 hover:text-white"
                        )}
                      >

                        <div className="relative">
                          <it.Icon  className="m-auto inline-block"                       />
                          {it.badge?<span className="absolute w-4 h-4 rounded-full -top-1 -right-1 bg-danger-500 text-xs text-center text-white font-bold">{it.badge}</span>:<></>}
                        </div>
                        {it.title}
                      </Button>
                    </CombinedLink>
                  </Fragment>
                ) : (
                  <Fragment key={idx}>
                    <DropDownMenuMobile
                      url={it.url}
                      Icon={it.Icon}
                      title={it.title}
                      handleOnClick={() => {
                        setIsUserMenuOpen(false);
                      }}
                      Items={it.subItem.map((item, itemIdx) => (
                        <Fragment key={itemIdx}>
                          <div className={clsx("pt-5")}>
                            <CombinedLink href={item.url}>
                              {item.title}
                            </CombinedLink>
                          </div>
                          {/* <CombinedLink href={item.url}><Button variant="white" className={clsx("w-full h-[70px] hover:bg-primary-500 text-primary-500 hover:text-white border-2 border-primary-500")}>{item.title}</Button></CombinedLink> */}
                        </Fragment>
                      ))}
                    />
                  </Fragment>
                )
              )}

              <CombinedLink href={"/auth/selectLanguage"}>
                <Button
                  aria-label="Language Button"
                  variant="white"
                  className={clsx(
                    "block border w-full h-[120px] hover:bg-primary-500 text-primary-500 hover:text-white"
                  )}
                >
                  <LanguageIcon
                    className={clsx("w-10 h-10 mb-2 m-auto inline-block")}
                  />{" "}
                  {t("header:language")}
                </Button>
              </CombinedLink>
              <CombinedLink href={"/auth/signout"}>
                <Button
                  aria-label="Language Button"
                  variant="white"
                  className={clsx(
                    "block border w-full h-[120px] hover:bg-primary-500 text-primary-500 hover:text-white"
                  )}>
                  <LogoutIcon
                    className={clsx("w-10 h-10 mb-2 m-auto inline-block")}
                  />{" "}
                  {t("header:logout")}
                </Button>
              </CombinedLink>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  );
};

export default Header;
