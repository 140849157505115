/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ParameterAttributes {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_4 = 4,
    NUMBER_8 = 8,
    NUMBER_16 = 16,
    NUMBER_4096 = 4096,
    NUMBER_8192 = 8192,
    NUMBER_16384 = 16384,
    NUMBER_32768 = 32768,
    NUMBER_61440 = 61440
}

export function ParameterAttributesFromJSON(json: any): ParameterAttributes {
    return ParameterAttributesFromJSONTyped(json, false);
}

export function ParameterAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterAttributes {
    return json as ParameterAttributes;
}

export function ParameterAttributesToJSON(value?: ParameterAttributes | null): any {
    return value as any;
}

