/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChildUserListDto
 */
export interface ChildUserListDto {
    /**
     * 
     * @type {number}
     * @memberof ChildUserListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildUserListDto
     */
    avator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildUserListDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildUserListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildUserListDto
     */
    gradeCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChildUserListDto
     */
    isVIP?: boolean;
}

export function ChildUserListDtoFromJSON(json: any): ChildUserListDto {
    return ChildUserListDtoFromJSONTyped(json, false);
}

export function ChildUserListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildUserListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'avator': !exists(json, 'avator') ? undefined : json['avator'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'isVIP': !exists(json, 'isVIP') ? undefined : json['isVIP'],
    };
}

export function ChildUserListDtoToJSON(value?: ChildUserListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'avator': value.avator,
        'userName': value.userName,
        'name': value.name,
        'gradeCode': value.gradeCode,
        'isVIP': value.isVIP,
    };
}

