/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterSchoolInput
 */
export interface RegisterSchoolInput {
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    captchaResponse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    schoolPhone: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    schoolCode: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    schoolMaster: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSchoolInput
     */
    contactEmail: string;
}

export function RegisterSchoolInputFromJSON(json: any): RegisterSchoolInput {
    return RegisterSchoolInputFromJSONTyped(json, false);
}

export function RegisterSchoolInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterSchoolInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'password': json['password'],
        'captchaResponse': !exists(json, 'captchaResponse') ? undefined : json['captchaResponse'],
        'name': json['name'],
        'logo': json['logo'],
        'address': json['address'],
        'schoolPhone': json['schoolPhone'],
        'schoolCode': json['schoolCode'],
        'schoolMaster': json['schoolMaster'],
        'contactName': json['contactName'],
        'contactPhone': json['contactPhone'],
        'contactEmail': json['contactEmail'],
    };
}

export function RegisterSchoolInputToJSON(value?: RegisterSchoolInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'password': value.password,
        'captchaResponse': value.captchaResponse,
        'name': value.name,
        'logo': value.logo,
        'address': value.address,
        'schoolPhone': value.schoolPhone,
        'schoolCode': value.schoolCode,
        'schoolMaster': value.schoolMaster,
        'contactName': value.contactName,
        'contactPhone': value.contactPhone,
        'contactEmail': value.contactEmail,
    };
}

