/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroupTag,
    StudentGroupTagFromJSON,
    StudentGroupTagFromJSONTyped,
    StudentGroupTagToJSON,
} from './StudentGroupTag';

/**
 * 
 * @export
 * @interface StudentGroupTagTranslation
 */
export interface StudentGroupTagTranslation {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTagTranslation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupTagTranslation
     */
    name: string;
    /**
     * 
     * @type {StudentGroupTag}
     * @memberof StudentGroupTagTranslation
     */
    core?: StudentGroupTag;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTagTranslation
     */
    coreId?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentGroupTagTranslation
     */
    language?: string | null;
}

export function StudentGroupTagTranslationFromJSON(json: any): StudentGroupTagTranslation {
    return StudentGroupTagTranslationFromJSONTyped(json, false);
}

export function StudentGroupTagTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupTagTranslation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'core': !exists(json, 'core') ? undefined : StudentGroupTagFromJSON(json['core']),
        'coreId': !exists(json, 'coreId') ? undefined : json['coreId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function StudentGroupTagTranslationToJSON(value?: StudentGroupTagTranslation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'core': StudentGroupTagToJSON(value.core),
        'coreId': value.coreId,
        'language': value.language,
    };
}

