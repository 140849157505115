/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomAttributeData,
    CustomAttributeDataFromJSON,
    CustomAttributeDataFromJSONTyped,
    CustomAttributeDataToJSON,
} from './CustomAttributeData';
import {
    FieldAttributes,
    FieldAttributesFromJSON,
    FieldAttributesFromJSONTyped,
    FieldAttributesToJSON,
} from './FieldAttributes';
import {
    MemberTypes,
    MemberTypesFromJSON,
    MemberTypesFromJSONTyped,
    MemberTypesToJSON,
} from './MemberTypes';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    RuntimeFieldHandle,
    RuntimeFieldHandleFromJSON,
    RuntimeFieldHandleFromJSONTyped,
    RuntimeFieldHandleToJSON,
} from './RuntimeFieldHandle';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';

/**
 * 
 * @export
 * @interface FieldInfo
 */
export interface FieldInfo {
    /**
     * 
     * @type {string}
     * @memberof FieldInfo
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof FieldInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof FieldInfo
     */
    readonly customAttributes?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldInfo
     */
    readonly metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof FieldInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {FieldAttributes}
     * @memberof FieldInfo
     */
    attributes?: FieldAttributes;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    fieldType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isInitOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isLiteral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isNotSerialized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isPinvokeImpl?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    readonly isSecurityTransparent?: boolean;
    /**
     * 
     * @type {RuntimeFieldHandle}
     * @memberof FieldInfo
     */
    fieldHandle?: RuntimeFieldHandle;
}

export function FieldInfoFromJSON(json: any): FieldInfo {
    return FieldInfoFromJSONTyped(json, false);
}

export function FieldInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'declaringType': !exists(json, 'declaringType') ? undefined : TypeFromJSON(json['declaringType']),
        'reflectedType': !exists(json, 'reflectedType') ? undefined : TypeFromJSON(json['reflectedType']),
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'customAttributes': !exists(json, 'customAttributes') ? undefined : (json['customAttributes'] === null ? null : (json['customAttributes'] as Array<any>).map(CustomAttributeDataFromJSON)),
        'isCollectible': !exists(json, 'isCollectible') ? undefined : json['isCollectible'],
        'metadataToken': !exists(json, 'metadataToken') ? undefined : json['metadataToken'],
        'memberType': !exists(json, 'memberType') ? undefined : MemberTypesFromJSON(json['memberType']),
        'attributes': !exists(json, 'attributes') ? undefined : FieldAttributesFromJSON(json['attributes']),
        'fieldType': !exists(json, 'fieldType') ? undefined : TypeFromJSON(json['fieldType']),
        'isInitOnly': !exists(json, 'isInitOnly') ? undefined : json['isInitOnly'],
        'isLiteral': !exists(json, 'isLiteral') ? undefined : json['isLiteral'],
        'isNotSerialized': !exists(json, 'isNotSerialized') ? undefined : json['isNotSerialized'],
        'isPinvokeImpl': !exists(json, 'isPinvokeImpl') ? undefined : json['isPinvokeImpl'],
        'isSpecialName': !exists(json, 'isSpecialName') ? undefined : json['isSpecialName'],
        'isStatic': !exists(json, 'isStatic') ? undefined : json['isStatic'],
        'isAssembly': !exists(json, 'isAssembly') ? undefined : json['isAssembly'],
        'isFamily': !exists(json, 'isFamily') ? undefined : json['isFamily'],
        'isFamilyAndAssembly': !exists(json, 'isFamilyAndAssembly') ? undefined : json['isFamilyAndAssembly'],
        'isFamilyOrAssembly': !exists(json, 'isFamilyOrAssembly') ? undefined : json['isFamilyOrAssembly'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'isPublic': !exists(json, 'isPublic') ? undefined : json['isPublic'],
        'isSecurityCritical': !exists(json, 'isSecurityCritical') ? undefined : json['isSecurityCritical'],
        'isSecuritySafeCritical': !exists(json, 'isSecuritySafeCritical') ? undefined : json['isSecuritySafeCritical'],
        'isSecurityTransparent': !exists(json, 'isSecurityTransparent') ? undefined : json['isSecurityTransparent'],
        'fieldHandle': !exists(json, 'fieldHandle') ? undefined : RuntimeFieldHandleFromJSON(json['fieldHandle']),
    };
}

export function FieldInfoToJSON(value?: FieldInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declaringType': TypeToJSON(value.declaringType),
        'reflectedType': TypeToJSON(value.reflectedType),
        'module': ModuleToJSON(value.module),
        'memberType': MemberTypesToJSON(value.memberType),
        'attributes': FieldAttributesToJSON(value.attributes),
        'fieldType': TypeToJSON(value.fieldType),
        'fieldHandle': RuntimeFieldHandleToJSON(value.fieldHandle),
    };
}

