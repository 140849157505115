/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportSubjectListDto,
    ExaminationExportSubjectListDtoFromJSON,
    ExaminationExportSubjectListDtoFromJSONTyped,
    ExaminationExportSubjectListDtoToJSON,
} from './ExaminationExportSubjectListDto';

/**
 * 
 * @export
 * @interface ExaminationExportSubjectMappingDto
 */
export interface ExaminationExportSubjectMappingDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubjectMappingDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubjectMappingDto
     */
    examinationExportId?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubjectMappingDto
     */
    subjectId?: number;
    /**
     * 
     * @type {ExaminationExportSubjectListDto}
     * @memberof ExaminationExportSubjectMappingDto
     */
    subject?: ExaminationExportSubjectListDto;
}

export function ExaminationExportSubjectMappingDtoFromJSON(json: any): ExaminationExportSubjectMappingDto {
    return ExaminationExportSubjectMappingDtoFromJSONTyped(json, false);
}

export function ExaminationExportSubjectMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSubjectMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subject': !exists(json, 'subject') ? undefined : ExaminationExportSubjectListDtoFromJSON(json['subject']),
    };
}

export function ExaminationExportSubjectMappingDtoToJSON(value?: ExaminationExportSubjectMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'subjectId': value.subjectId,
        'subject': ExaminationExportSubjectListDtoToJSON(value.subject),
    };
}

