/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchoolTransationDto
 */
export interface SchoolTransationDto {
    /**
     * 
     * @type {string}
     * @memberof SchoolTransationDto
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolTransationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolTransationDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolTransationDto
     */
    schoolMaster: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolTransationDto
     */
    contactName: string;
}

export function SchoolTransationDtoFromJSON(json: any): SchoolTransationDto {
    return SchoolTransationDtoFromJSONTyped(json, false);
}

export function SchoolTransationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolTransationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': json['language'],
        'name': json['name'],
        'address': json['address'],
        'schoolMaster': json['schoolMaster'],
        'contactName': json['contactName'],
    };
}

export function SchoolTransationDtoToJSON(value?: SchoolTransationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': value.language,
        'name': value.name,
        'address': value.address,
        'schoolMaster': value.schoolMaster,
        'contactName': value.contactName,
    };
}

