/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmitTestResult
 */
export interface SubmitTestResult {
    /**
     * 
     * @type {boolean}
     * @memberof SubmitTestResult
     */
    insufficientSubjectiveBalance?: boolean;
}

export function SubmitTestResultFromJSON(json: any): SubmitTestResult {
    return SubmitTestResultFromJSONTyped(json, false);
}

export function SubmitTestResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitTestResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insufficientSubjectiveBalance': !exists(json, 'insufficientSubjectiveBalance') ? undefined : json['insufficientSubjectiveBalance'],
    };
}

export function SubmitTestResultToJSON(value?: SubmitTestResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insufficientSubjectiveBalance': value.insufficientSubjectiveBalance,
    };
}

