/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionDto,
    PermissionDtoFromJSON,
    PermissionDtoFromJSONTyped,
    PermissionDtoToJSON,
} from './PermissionDto';

/**
 * 
 * @export
 * @interface PermissionDtoListResultDto
 */
export interface PermissionDtoListResultDto {
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionDtoListResultDto
     */
    items?: Array<PermissionDto> | null;
}

export function PermissionDtoListResultDtoFromJSON(json: any): PermissionDtoListResultDto {
    return PermissionDtoListResultDtoFromJSONTyped(json, false);
}

export function PermissionDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(PermissionDtoFromJSON)),
    };
}

export function PermissionDtoListResultDtoToJSON(value?: PermissionDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(PermissionDtoToJSON)),
    };
}

