/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TenantAvailabilityState,
    TenantAvailabilityStateFromJSON,
    TenantAvailabilityStateFromJSONTyped,
    TenantAvailabilityStateToJSON,
} from './TenantAvailabilityState';

/**
 * 
 * @export
 * @interface IsTenantAvailableOutput
 */
export interface IsTenantAvailableOutput {
    /**
     * 
     * @type {TenantAvailabilityState}
     * @memberof IsTenantAvailableOutput
     */
    state?: TenantAvailabilityState;
    /**
     * 
     * @type {number}
     * @memberof IsTenantAvailableOutput
     */
    tenantId?: number | null;
}

export function IsTenantAvailableOutputFromJSON(json: any): IsTenantAvailableOutput {
    return IsTenantAvailableOutputFromJSONTyped(json, false);
}

export function IsTenantAvailableOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsTenantAvailableOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': !exists(json, 'state') ? undefined : TenantAvailabilityStateFromJSON(json['state']),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function IsTenantAvailableOutputToJSON(value?: IsTenantAvailableOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': TenantAvailabilityStateToJSON(value.state),
        'tenantId': value.tenantId,
    };
}

