import clsx from 'clsx';
import React, { createContext, useContext, useMemo } from 'react';
import { useSpring, animated, SpringValue, SpringConfig } from '@react-spring/web'
import { MouseParallaxContext } from './MouseParallax';
import MouseParallaxLayer from './MouseParallaxLayer';
export interface MouseParallaxContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    children:  React.ReactElement<typeof MouseParallaxLayer> | React.ReactElement<typeof MouseParallaxLayer>[]
}

const MouseParallaxContainer = (props:MouseParallaxContainerProps )=>{
  const {children, ...rest} = props;

  return <div {...rest} className={clsx(rest.className, "items-center content-center select-none pointer-events-none")} >
    {children}
  </div>
}
MouseParallaxContainer.displayName = 'MouseParallaxContainer';
export default MouseParallaxContainer;