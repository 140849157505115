/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDto,
    AccountDtoFromJSON,
    AccountDtoToJSON,
    BindExternalLoginByPasswordDto,
    BindExternalLoginByPasswordDtoFromJSON,
    BindExternalLoginByPasswordDtoToJSON,
    ChildUserDto,
    ChildUserDtoFromJSON,
    ChildUserDtoToJSON,
    ChildUserListDtoChildUserListResultDto,
    ChildUserListDtoChildUserListResultDtoFromJSON,
    ChildUserListDtoChildUserListResultDtoToJSON,
    CreateChildUserDto,
    CreateChildUserDtoFromJSON,
    CreateChildUserDtoToJSON,
    EmailAddressInput,
    EmailAddressInputFromJSON,
    EmailAddressInputToJSON,
    EmailInput,
    EmailInputFromJSON,
    EmailInputToJSON,
    IsTenantAvailableInput,
    IsTenantAvailableInputFromJSON,
    IsTenantAvailableInputToJSON,
    IsTenantAvailableOutput,
    IsTenantAvailableOutputFromJSON,
    IsTenantAvailableOutputToJSON,
    Register3Input,
    Register3InputFromJSON,
    Register3InputToJSON,
    RegisterInput,
    RegisterInputFromJSON,
    RegisterInputToJSON,
    RegisterOutput,
    RegisterOutputFromJSON,
    RegisterOutputToJSON,
    ResetPasswordInput,
    ResetPasswordInputFromJSON,
    ResetPasswordInputToJSON,
    UpdateAccountInput,
    UpdateAccountInputFromJSON,
    UpdateAccountInputToJSON,
    UpdateAvatorInput,
    UpdateAvatorInputFromJSON,
    UpdateAvatorInputToJSON,
    UpdateChildWithIDInput,
    UpdateChildWithIDInputFromJSON,
    UpdateChildWithIDInputToJSON,
    UpdateEmailAddressInput,
    UpdateEmailAddressInputFromJSON,
    UpdateEmailAddressInputToJSON,
    UpdatePasswordDto,
    UpdatePasswordDtoFromJSON,
    UpdatePasswordDtoToJSON,
} from '../models';

export interface ApiServicesAppAccountBindExternalLoginByPasswordPostRequest {
    bindExternalLoginByPasswordDto?: BindExternalLoginByPasswordDto;
}

export interface ApiServicesAppAccountCreateChildPostRequest {
    createChildUserDto?: CreateChildUserDto;
}

export interface ApiServicesAppAccountDeleteChildDeleteRequest {
    userId?: number;
}

export interface ApiServicesAppAccountGetChildGetRequest {
    userId?: number;
}

export interface ApiServicesAppAccountIsEmailAddressValidPostRequest {
    emailAddressInput?: EmailAddressInput;
}

export interface ApiServicesAppAccountIsTenantAvailablePostRequest {
    isTenantAvailableInput?: IsTenantAvailableInput;
}

export interface ApiServicesAppAccountRegister2PostRequest {
    register3Input?: Register3Input;
}

export interface ApiServicesAppAccountRegisterPostRequest {
    registerInput?: RegisterInput;
}

export interface ApiServicesAppAccountResetPasswordPostRequest {
    resetPasswordInput?: ResetPasswordInput;
}

export interface ApiServicesAppAccountSendConfirmEmailPostRequest {
    emailInput?: EmailInput;
}

export interface ApiServicesAppAccountUpdateAvatorPutRequest {
    updateAvatorInput?: UpdateAvatorInput;
}

export interface ApiServicesAppAccountUpdateBasicPutRequest {
    updateAccountInput?: UpdateAccountInput;
}

export interface ApiServicesAppAccountUpdateChildPutRequest {
    updateChildWithIDInput?: UpdateChildWithIDInput;
}

export interface ApiServicesAppAccountUpdateEmailAddressPutRequest {
    updateEmailAddressInput?: UpdateEmailAddressInput;
}

export interface ApiServicesAppAccountUpdatePasswordPutRequest {
    updatePasswordDto?: UpdatePasswordDto;
}

export interface ApiServicesAppAccountUpdatePutRequest {
    updateAccountInput?: UpdateAccountInput;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppAccountBindExternalLoginByPasswordPostRaw(requestParameters: ApiServicesAppAccountBindExternalLoginByPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/BindExternalLoginByPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BindExternalLoginByPasswordDtoToJSON(requestParameters.bindExternalLoginByPasswordDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountBindExternalLoginByPasswordPost(requestParameters: ApiServicesAppAccountBindExternalLoginByPasswordPostRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountBindExternalLoginByPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountCreateChildPostRaw(requestParameters: ApiServicesAppAccountCreateChildPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegisterOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/CreateChild`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChildUserDtoToJSON(requestParameters.createChildUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountCreateChildPost(requestParameters: ApiServicesAppAccountCreateChildPostRequest, initOverrides?: RequestInit): Promise<RegisterOutput> {
        const response = await this.apiServicesAppAccountCreateChildPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountDeleteChildDeleteRaw(requestParameters: ApiServicesAppAccountDeleteChildDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Account/DeleteChild`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountDeleteChildDelete(requestParameters: ApiServicesAppAccountDeleteChildDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountDeleteChildDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountGetChildGetRaw(requestParameters: ApiServicesAppAccountGetChildGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildUserDto>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Account/GetChild`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountGetChildGet(requestParameters: ApiServicesAppAccountGetChildGetRequest, initOverrides?: RequestInit): Promise<ChildUserDto> {
        const response = await this.apiServicesAppAccountGetChildGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountGetChildrenGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildUserListDtoChildUserListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Account/GetChildren`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildUserListDtoChildUserListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountGetChildrenGet(initOverrides?: RequestInit): Promise<ChildUserListDtoChildUserListResultDto> {
        const response = await this.apiServicesAppAccountGetChildrenGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountGetGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Account/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountGetGet(initOverrides?: RequestInit): Promise<AccountDto> {
        const response = await this.apiServicesAppAccountGetGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountGetUserProfilesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Account/GetUserProfiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiServicesAppAccountGetUserProfilesGet(initOverrides?: RequestInit): Promise<{ [key: string]: any; }> {
        const response = await this.apiServicesAppAccountGetUserProfilesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountIsEmailAddressValidPostRaw(requestParameters: ApiServicesAppAccountIsEmailAddressValidPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/IsEmailAddressValid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailAddressInputToJSON(requestParameters.emailAddressInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountIsEmailAddressValidPost(requestParameters: ApiServicesAppAccountIsEmailAddressValidPostRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountIsEmailAddressValidPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountIsTenantAvailablePostRaw(requestParameters: ApiServicesAppAccountIsTenantAvailablePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IsTenantAvailableOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/IsTenantAvailable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsTenantAvailableInputToJSON(requestParameters.isTenantAvailableInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsTenantAvailableOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountIsTenantAvailablePost(requestParameters: ApiServicesAppAccountIsTenantAvailablePostRequest, initOverrides?: RequestInit): Promise<IsTenantAvailableOutput> {
        const response = await this.apiServicesAppAccountIsTenantAvailablePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountRegister2PostRaw(requestParameters: ApiServicesAppAccountRegister2PostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegisterOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/Register2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Register3InputToJSON(requestParameters.register3Input),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountRegister2Post(requestParameters: ApiServicesAppAccountRegister2PostRequest, initOverrides?: RequestInit): Promise<RegisterOutput> {
        const response = await this.apiServicesAppAccountRegister2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountRegisterPostRaw(requestParameters: ApiServicesAppAccountRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegisterOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterInputToJSON(requestParameters.registerInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAccountRegisterPost(requestParameters: ApiServicesAppAccountRegisterPostRequest, initOverrides?: RequestInit): Promise<RegisterOutput> {
        const response = await this.apiServicesAppAccountRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountResetPasswordPostRaw(requestParameters: ApiServicesAppAccountResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/ResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordInputToJSON(requestParameters.resetPasswordInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountResetPasswordPost(requestParameters: ApiServicesAppAccountResetPasswordPostRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountSendConfirmEmailPostRaw(requestParameters: ApiServicesAppAccountSendConfirmEmailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/SendConfirmEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailInputToJSON(requestParameters.emailInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppAccountSendConfirmEmailPost(requestParameters: ApiServicesAppAccountSendConfirmEmailPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppAccountSendConfirmEmailPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppAccountUpdateAvatorPutRaw(requestParameters: ApiServicesAppAccountUpdateAvatorPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/UpdateAvator`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAvatorInputToJSON(requestParameters.updateAvatorInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountUpdateAvatorPut(requestParameters: ApiServicesAppAccountUpdateAvatorPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountUpdateAvatorPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountUpdateBasicPutRaw(requestParameters: ApiServicesAppAccountUpdateBasicPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/UpdateBasic`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAccountInputToJSON(requestParameters.updateAccountInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountUpdateBasicPut(requestParameters: ApiServicesAppAccountUpdateBasicPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountUpdateBasicPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountUpdateChildPutRaw(requestParameters: ApiServicesAppAccountUpdateChildPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/UpdateChild`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChildWithIDInputToJSON(requestParameters.updateChildWithIDInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountUpdateChildPut(requestParameters: ApiServicesAppAccountUpdateChildPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountUpdateChildPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountUpdateEmailAddressPutRaw(requestParameters: ApiServicesAppAccountUpdateEmailAddressPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/UpdateEmailAddress`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmailAddressInputToJSON(requestParameters.updateEmailAddressInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountUpdateEmailAddressPut(requestParameters: ApiServicesAppAccountUpdateEmailAddressPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountUpdateEmailAddressPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountUpdatePasswordPutRaw(requestParameters: ApiServicesAppAccountUpdatePasswordPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/UpdatePassword`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordDtoToJSON(requestParameters.updatePasswordDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountUpdatePasswordPut(requestParameters: ApiServicesAppAccountUpdatePasswordPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountUpdatePasswordPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAccountUpdatePutRaw(requestParameters: ApiServicesAppAccountUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Account/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAccountInputToJSON(requestParameters.updateAccountInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppAccountUpdatePut(requestParameters: ApiServicesAppAccountUpdatePutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppAccountUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
