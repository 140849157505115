/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductListDto,
    ProductListDtoFromJSON,
    ProductListDtoFromJSONTyped,
    ProductListDtoToJSON,
} from './ProductListDto';

/**
 * 
 * @export
 * @interface ProductListDtoPagedResultDto
 */
export interface ProductListDtoPagedResultDto {
    /**
     * 
     * @type {Array<ProductListDto>}
     * @memberof ProductListDtoPagedResultDto
     */
    items?: Array<ProductListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductListDtoPagedResultDto
     */
    totalCount?: number;
}

export function ProductListDtoPagedResultDtoFromJSON(json: any): ProductListDtoPagedResultDto {
    return ProductListDtoPagedResultDtoFromJSONTyped(json, false);
}

export function ProductListDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ProductListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function ProductListDtoPagedResultDtoToJSON(value?: ProductListDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ProductListDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

