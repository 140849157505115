/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmailTemplateDto,
    EmailTemplateDtoFromJSON,
    EmailTemplateDtoToJSON,
    EmailTemplateListDtoListResultDto,
    EmailTemplateListDtoListResultDtoFromJSON,
    EmailTemplateListDtoListResultDtoToJSON,
} from '../models';

export interface ApiServicesAppEmailTemplateGetGetRequest {
    id?: number;
}

export interface ApiServicesAppEmailTemplateUpdatePutRequest {
    emailTemplateDto?: EmailTemplateDto;
}

/**
 * 
 */
export class EmailTemplateApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppEmailTemplateGetAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailTemplateListDtoListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/EmailTemplate/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTemplateListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppEmailTemplateGetAllGet(initOverrides?: RequestInit): Promise<EmailTemplateListDtoListResultDto> {
        const response = await this.apiServicesAppEmailTemplateGetAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppEmailTemplateGetGetRaw(requestParameters: ApiServicesAppEmailTemplateGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailTemplateDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/EmailTemplate/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppEmailTemplateGetGet(requestParameters: ApiServicesAppEmailTemplateGetGetRequest, initOverrides?: RequestInit): Promise<EmailTemplateDto> {
        const response = await this.apiServicesAppEmailTemplateGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppEmailTemplateUpdatePutRaw(requestParameters: ApiServicesAppEmailTemplateUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailTemplateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/EmailTemplate/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmailTemplateDtoToJSON(requestParameters.emailTemplateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppEmailTemplateUpdatePut(requestParameters: ApiServicesAppEmailTemplateUpdatePutRequest, initOverrides?: RequestInit): Promise<EmailTemplateDto> {
        const response = await this.apiServicesAppEmailTemplateUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
