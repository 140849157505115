/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeAssigneesInput,
    ChangeAssigneesInputFromJSON,
    ChangeAssigneesInputToJSON,
    CreateExaminationExportResult,
    CreateExaminationExportResultFromJSON,
    CreateExaminationExportResultToJSON,
    CreateExportExaminationInput,
    CreateExportExaminationInputFromJSON,
    CreateExportExaminationInputToJSON,
    ExaminationExportDto,
    ExaminationExportDtoFromJSON,
    ExaminationExportDtoToJSON,
    ExaminationExportListDtoPagedResultDto,
    ExaminationExportListDtoPagedResultDtoFromJSON,
    ExaminationExportListDtoPagedResultDtoToJSON,
    ExaminationExportQuestionOptionsDtoListResultDto,
    ExaminationExportQuestionOptionsDtoListResultDtoFromJSON,
    ExaminationExportQuestionOptionsDtoListResultDtoToJSON,
    ExaminationExportQuestionOptionsDtoPagedResultDto,
    ExaminationExportQuestionOptionsDtoPagedResultDtoFromJSON,
    ExaminationExportQuestionOptionsDtoPagedResultDtoToJSON,
    ExaminationExportSubjectListDtoListResultDto,
    ExaminationExportSubjectListDtoListResultDtoFromJSON,
    ExaminationExportSubjectListDtoListResultDtoToJSON,
    ExaminationExportType,
    ExaminationExportTypeFromJSON,
    ExaminationExportTypeToJSON,
    ExportExaminationPaperInput,
    ExportExaminationPaperInputFromJSON,
    ExportExaminationPaperInputToJSON,
    ExportExaminationSettingDto,
    ExportExaminationSettingDtoFromJSON,
    ExportExaminationSettingDtoToJSON,
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeToJSON,
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeToJSON,
} from '../models';

export interface ApiServicesAppExportChangeAssigneesPostRequest {
    changeAssigneesInput?: ChangeAssigneesInput;
}

export interface ApiServicesAppExportCreateDailyPracticePaperPostRequest {
    createExportExaminationInput?: CreateExportExaminationInput;
}

export interface ApiServicesAppExportCreateExaminationPaperPostRequest {
    createExportExaminationInput?: CreateExportExaminationInput;
}

export interface ApiServicesAppExportDeleteExaminationPaperDeleteRequest {
    id?: number;
}

export interface ApiServicesAppExportExportExaminationPaperPostRequest {
    exportExaminationPaperInput?: ExportExaminationPaperInput;
}

export interface ApiServicesAppExportGetDailyPracticeQuestionsListGetRequest {
    subjectId?: number;
    lessonId?: Array<number>;
    questionType?: Array<QuestionType>;
    keyword?: string;
    stars?: number;
    ex?: Array<number>;
    userId?: number;
    date?: Date;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppExportGetExaminationPaperDetailByOpenIdGetRequest {
    openId?: string;
}

export interface ApiServicesAppExportGetExaminationPaperDetailGetRequest {
    id?: number;
}

export interface ApiServicesAppExportGetExaminationPapersGetRequest {
    type?: ExaminationExportType;
    dateStart?: Date;
    dateEnd?: Date;
    assigneeUserId?: number;
    subjectType?: Array<SubjectType>;
    isFinished?: boolean;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppExportGetQuestionsOptionsGetRequest {
    gradeId?: number;
    subjectId?: number;
    lessonId?: number;
    questionType?: QuestionType;
    stars?: number;
    keyword?: string;
    ex?: Array<number>;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppExportGetRandomQuestionsOptionsGetRequest {
    gradeId?: number;
    subjectId?: number;
    lessonId?: Array<number>;
    userId?: number;
    questionType?: Array<QuestionType>;
    stars?: number;
    count?: number;
    ex?: Array<number>;
}

export interface ApiServicesAppExportGetSubjectOptionsGetRequest {
    gradeCodes?: Array<string>;
}

export interface ApiServicesAppExportUpdateExaminationPaperPutRequest {
    createExportExaminationInput?: CreateExportExaminationInput;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppExportChangeAssigneesPostRaw(requestParameters: ApiServicesAppExportChangeAssigneesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Export/ChangeAssignees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeAssigneesInputToJSON(requestParameters.changeAssigneesInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppExportChangeAssigneesPost(requestParameters: ApiServicesAppExportChangeAssigneesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppExportChangeAssigneesPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppExportCreateDailyPracticePaperPostRaw(requestParameters: ApiServicesAppExportCreateDailyPracticePaperPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateExaminationExportResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Export/CreateDailyPracticePaper`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportExaminationInputToJSON(requestParameters.createExportExaminationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateExaminationExportResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportCreateDailyPracticePaperPost(requestParameters: ApiServicesAppExportCreateDailyPracticePaperPostRequest, initOverrides?: RequestInit): Promise<CreateExaminationExportResult> {
        const response = await this.apiServicesAppExportCreateDailyPracticePaperPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportCreateExaminationPaperPostRaw(requestParameters: ApiServicesAppExportCreateExaminationPaperPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateExaminationExportResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Export/CreateExaminationPaper`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportExaminationInputToJSON(requestParameters.createExportExaminationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateExaminationExportResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportCreateExaminationPaperPost(requestParameters: ApiServicesAppExportCreateExaminationPaperPostRequest, initOverrides?: RequestInit): Promise<CreateExaminationExportResult> {
        const response = await this.apiServicesAppExportCreateExaminationPaperPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportDeleteExaminationPaperDeleteRaw(requestParameters: ApiServicesAppExportDeleteExaminationPaperDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/DeleteExaminationPaper`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppExportDeleteExaminationPaperDelete(requestParameters: ApiServicesAppExportDeleteExaminationPaperDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppExportDeleteExaminationPaperDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppExportExportExaminationPaperPostRaw(requestParameters: ApiServicesAppExportExportExaminationPaperPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Export/ExportExaminationPaper`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportExaminationPaperInputToJSON(requestParameters.exportExaminationPaperInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppExportExportExaminationPaperPost(requestParameters: ApiServicesAppExportExportExaminationPaperPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppExportExportExaminationPaperPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppExportGetDailyPracticeQuestionsListGetRaw(requestParameters: ApiServicesAppExportGetDailyPracticeQuestionsListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportQuestionOptionsDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectId !== undefined) {
            queryParameters['SubjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.lessonId) {
            queryParameters['LessonId'] = requestParameters.lessonId;
        }

        if (requestParameters.questionType) {
            queryParameters['QuestionType'] = requestParameters.questionType;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.stars !== undefined) {
            queryParameters['Stars'] = requestParameters.stars;
        }

        if (requestParameters.ex) {
            queryParameters['Ex'] = requestParameters.ex;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['Date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetDailyPracticeQuestionsList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportQuestionOptionsDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetDailyPracticeQuestionsListGet(requestParameters: ApiServicesAppExportGetDailyPracticeQuestionsListGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportQuestionOptionsDtoPagedResultDto> {
        const response = await this.apiServicesAppExportGetDailyPracticeQuestionsListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetDefaultExaminationExportSettingsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExportExaminationSettingDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetDefaultExaminationExportSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportExaminationSettingDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetDefaultExaminationExportSettingsGet(initOverrides?: RequestInit): Promise<ExportExaminationSettingDto> {
        const response = await this.apiServicesAppExportGetDefaultExaminationExportSettingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetExaminationPaperDetailByOpenIdGetRaw(requestParameters: ApiServicesAppExportGetExaminationPaperDetailByOpenIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportDto>> {
        const queryParameters: any = {};

        if (requestParameters.openId !== undefined) {
            queryParameters['openId'] = requestParameters.openId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetExaminationPaperDetailByOpenId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetExaminationPaperDetailByOpenIdGet(requestParameters: ApiServicesAppExportGetExaminationPaperDetailByOpenIdGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportDto> {
        const response = await this.apiServicesAppExportGetExaminationPaperDetailByOpenIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetExaminationPaperDetailGetRaw(requestParameters: ApiServicesAppExportGetExaminationPaperDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetExaminationPaperDetail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetExaminationPaperDetailGet(requestParameters: ApiServicesAppExportGetExaminationPaperDetailGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportDto> {
        const response = await this.apiServicesAppExportGetExaminationPaperDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetExaminationPapersGetRaw(requestParameters: ApiServicesAppExportGetExaminationPapersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['DateStart'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['DateEnd'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.assigneeUserId !== undefined) {
            queryParameters['AssigneeUserId'] = requestParameters.assigneeUserId;
        }

        if (requestParameters.subjectType) {
            queryParameters['SubjectType'] = requestParameters.subjectType;
        }

        if (requestParameters.isFinished !== undefined) {
            queryParameters['IsFinished'] = requestParameters.isFinished;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetExaminationPapers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetExaminationPapersGet(requestParameters: ApiServicesAppExportGetExaminationPapersGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportListDtoPagedResultDto> {
        const response = await this.apiServicesAppExportGetExaminationPapersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetQuestionsOptionsGetRaw(requestParameters: ApiServicesAppExportGetQuestionsOptionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportQuestionOptionsDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeId !== undefined) {
            queryParameters['GradeId'] = requestParameters.gradeId;
        }

        if (requestParameters.subjectId !== undefined) {
            queryParameters['SubjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.lessonId !== undefined) {
            queryParameters['LessonId'] = requestParameters.lessonId;
        }

        if (requestParameters.questionType !== undefined) {
            queryParameters['QuestionType'] = requestParameters.questionType;
        }

        if (requestParameters.stars !== undefined) {
            queryParameters['Stars'] = requestParameters.stars;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.ex) {
            queryParameters['Ex'] = requestParameters.ex;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetQuestionsOptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportQuestionOptionsDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetQuestionsOptionsGet(requestParameters: ApiServicesAppExportGetQuestionsOptionsGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportQuestionOptionsDtoPagedResultDto> {
        const response = await this.apiServicesAppExportGetQuestionsOptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetRandomQuestionsOptionsGetRaw(requestParameters: ApiServicesAppExportGetRandomQuestionsOptionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportQuestionOptionsDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeId !== undefined) {
            queryParameters['GradeId'] = requestParameters.gradeId;
        }

        if (requestParameters.subjectId !== undefined) {
            queryParameters['SubjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.lessonId) {
            queryParameters['LessonId'] = requestParameters.lessonId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.questionType) {
            queryParameters['QuestionType'] = requestParameters.questionType;
        }

        if (requestParameters.stars !== undefined) {
            queryParameters['Stars'] = requestParameters.stars;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['Count'] = requestParameters.count;
        }

        if (requestParameters.ex) {
            queryParameters['Ex'] = requestParameters.ex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetRandomQuestionsOptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportQuestionOptionsDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetRandomQuestionsOptionsGet(requestParameters: ApiServicesAppExportGetRandomQuestionsOptionsGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportQuestionOptionsDtoListResultDto> {
        const response = await this.apiServicesAppExportGetRandomQuestionsOptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportGetSubjectOptionsGetRaw(requestParameters: ApiServicesAppExportGetSubjectOptionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExaminationExportSubjectListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeCodes) {
            queryParameters['GradeCodes'] = requestParameters.gradeCodes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Export/GetSubjectOptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExaminationExportSubjectListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppExportGetSubjectOptionsGet(requestParameters: ApiServicesAppExportGetSubjectOptionsGetRequest, initOverrides?: RequestInit): Promise<ExaminationExportSubjectListDtoListResultDto> {
        const response = await this.apiServicesAppExportGetSubjectOptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppExportUpdateExaminationPaperPutRaw(requestParameters: ApiServicesAppExportUpdateExaminationPaperPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Export/UpdateExaminationPaper`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportExaminationInputToJSON(requestParameters.createExportExaminationInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppExportUpdateExaminationPaperPut(requestParameters: ApiServicesAppExportUpdateExaminationPaperPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppExportUpdateExaminationPaperPutRaw(requestParameters, initOverrides);
    }

}
