/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExportQuestionsInput,
    ExportQuestionsInputFromJSON,
    ExportQuestionsInputToJSON,
    IdArrayInput,
    IdArrayInputFromJSON,
    IdArrayInputToJSON,
    ImportQuestionsInput,
    ImportQuestionsInputFromJSON,
    ImportQuestionsInputToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    QuestionDto,
    QuestionDtoFromJSON,
    QuestionDtoToJSON,
    QuestionListWithCategoryDtoPagedResultDto,
    QuestionListWithCategoryDtoPagedResultDtoFromJSON,
    QuestionListWithCategoryDtoPagedResultDtoToJSON,
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeToJSON,
    QuestionTypeListDtoListResultDto,
    QuestionTypeListDtoListResultDtoFromJSON,
    QuestionTypeListDtoListResultDtoToJSON,
    QuestionWithNavigatorDto,
    QuestionWithNavigatorDtoFromJSON,
    QuestionWithNavigatorDtoToJSON,
    SubQuestionListDtoListResultDto,
    SubQuestionListDtoListResultDtoFromJSON,
    SubQuestionListDtoListResultDtoToJSON,
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeToJSON,
    SubjectiveQuestionListDtoPagedResultDto,
    SubjectiveQuestionListDtoPagedResultDtoFromJSON,
    SubjectiveQuestionListDtoPagedResultDtoToJSON,
} from '../models';

export interface ApiServicesAppQuestionCreatePostRequest {
    questionDto?: QuestionDto;
}

export interface ApiServicesAppQuestionDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppQuestionDeleteManyDeleteRequest {
    ids?: Array<number>;
}

export interface ApiServicesAppQuestionExportPostRequest {
    exportQuestionsInput?: ExportQuestionsInput;
}

export interface ApiServicesAppQuestionExportSelectedPostRequest {
    idArrayInput?: IdArrayInput;
}

export interface ApiServicesAppQuestionGetAllQuestionTypesGetRequest {
    subjectType?: Array<SubjectType>;
}

export interface ApiServicesAppQuestionGetDetailWithNavigatorsGetRequest {
    id?: number;
    isActive?: boolean;
    lessonsId?: number;
    schoolOnly?: boolean;
    questionType?: QuestionType;
    search?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppQuestionGetQuestionsGetRequest {
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
    isActive?: boolean;
    lessonsId?: number;
    schoolOnly?: boolean;
    questionType?: QuestionType;
    search?: string;
}

export interface ApiServicesAppQuestionGetSubQuestionsGetRequest {
    parentId?: number;
}

export interface ApiServicesAppQuestionGetSubjectiveQuestionsGetRequest {
    lessonId?: Array<number>;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppQuestionImportPostRequest {
    importQuestionsInput?: ImportQuestionsInput;
}

export interface ApiServicesAppQuestionToggleActivationPostRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppQuestionUpdatePutRequest {
    questionDto?: QuestionDto;
}

/**
 * 
 */
export class QuestionApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppQuestionCreatePostRaw(requestParameters: ApiServicesAppQuestionCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Question/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionDtoToJSON(requestParameters.questionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionCreatePost(requestParameters: ApiServicesAppQuestionCreatePostRequest, initOverrides?: RequestInit): Promise<QuestionDto> {
        const response = await this.apiServicesAppQuestionCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionDeleteDeleteRaw(requestParameters: ApiServicesAppQuestionDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppQuestionDeleteDelete(requestParameters: ApiServicesAppQuestionDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppQuestionDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppQuestionDeleteManyDeleteRaw(requestParameters: ApiServicesAppQuestionDeleteManyDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['Ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/DeleteMany`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppQuestionDeleteManyDelete(requestParameters: ApiServicesAppQuestionDeleteManyDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppQuestionDeleteManyDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppQuestionExportPostRaw(requestParameters: ApiServicesAppQuestionExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Question/Export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportQuestionsInputToJSON(requestParameters.exportQuestionsInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppQuestionExportPost(requestParameters: ApiServicesAppQuestionExportPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppQuestionExportPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppQuestionExportSelectedPostRaw(requestParameters: ApiServicesAppQuestionExportSelectedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Question/ExportSelected`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdArrayInputToJSON(requestParameters.idArrayInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppQuestionExportSelectedPost(requestParameters: ApiServicesAppQuestionExportSelectedPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppQuestionExportSelectedPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppQuestionGetAllQuestionTypesGetRaw(requestParameters: ApiServicesAppQuestionGetAllQuestionTypesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionTypeListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectType) {
            queryParameters['subjectType'] = requestParameters.subjectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/GetAllQuestionTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionTypeListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionGetAllQuestionTypesGet(requestParameters: ApiServicesAppQuestionGetAllQuestionTypesGetRequest, initOverrides?: RequestInit): Promise<QuestionTypeListDtoListResultDto> {
        const response = await this.apiServicesAppQuestionGetAllQuestionTypesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionGetDetailWithNavigatorsGetRaw(requestParameters: ApiServicesAppQuestionGetDetailWithNavigatorsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionWithNavigatorDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.lessonsId !== undefined) {
            queryParameters['LessonsId'] = requestParameters.lessonsId;
        }

        if (requestParameters.schoolOnly !== undefined) {
            queryParameters['SchoolOnly'] = requestParameters.schoolOnly;
        }

        if (requestParameters.questionType !== undefined) {
            queryParameters['QuestionType'] = requestParameters.questionType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['Search'] = requestParameters.search;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/GetDetailWithNavigators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionWithNavigatorDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionGetDetailWithNavigatorsGet(requestParameters: ApiServicesAppQuestionGetDetailWithNavigatorsGetRequest, initOverrides?: RequestInit): Promise<QuestionWithNavigatorDto> {
        const response = await this.apiServicesAppQuestionGetDetailWithNavigatorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionGetQuestionsGetRaw(requestParameters: ApiServicesAppQuestionGetQuestionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionListWithCategoryDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.lessonsId !== undefined) {
            queryParameters['LessonsId'] = requestParameters.lessonsId;
        }

        if (requestParameters.schoolOnly !== undefined) {
            queryParameters['SchoolOnly'] = requestParameters.schoolOnly;
        }

        if (requestParameters.questionType !== undefined) {
            queryParameters['QuestionType'] = requestParameters.questionType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['Search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/GetQuestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionListWithCategoryDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionGetQuestionsGet(requestParameters: ApiServicesAppQuestionGetQuestionsGetRequest, initOverrides?: RequestInit): Promise<QuestionListWithCategoryDtoPagedResultDto> {
        const response = await this.apiServicesAppQuestionGetQuestionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionGetSubQuestionsGetRaw(requestParameters: ApiServicesAppQuestionGetSubQuestionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubQuestionListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/GetSubQuestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubQuestionListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionGetSubQuestionsGet(requestParameters: ApiServicesAppQuestionGetSubQuestionsGetRequest, initOverrides?: RequestInit): Promise<SubQuestionListDtoListResultDto> {
        const response = await this.apiServicesAppQuestionGetSubQuestionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionGetSubjectiveQuestionsGetRaw(requestParameters: ApiServicesAppQuestionGetSubjectiveQuestionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectiveQuestionListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.lessonId) {
            queryParameters['LessonId'] = requestParameters.lessonId;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Question/GetSubjectiveQuestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectiveQuestionListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionGetSubjectiveQuestionsGet(requestParameters: ApiServicesAppQuestionGetSubjectiveQuestionsGetRequest, initOverrides?: RequestInit): Promise<SubjectiveQuestionListDtoPagedResultDto> {
        const response = await this.apiServicesAppQuestionGetSubjectiveQuestionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionImportPostRaw(requestParameters: ApiServicesAppQuestionImportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Question/Import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportQuestionsInputToJSON(requestParameters.importQuestionsInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppQuestionImportPost(requestParameters: ApiServicesAppQuestionImportPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppQuestionImportPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppQuestionToggleActivationPostRaw(requestParameters: ApiServicesAppQuestionToggleActivationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Question/ToggleActivation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionToggleActivationPost(requestParameters: ApiServicesAppQuestionToggleActivationPostRequest, initOverrides?: RequestInit): Promise<QuestionDto> {
        const response = await this.apiServicesAppQuestionToggleActivationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppQuestionUpdatePutRaw(requestParameters: ApiServicesAppQuestionUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Question/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionDtoToJSON(requestParameters.questionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppQuestionUpdatePut(requestParameters: ApiServicesAppQuestionUpdatePutRequest, initOverrides?: RequestInit): Promise<QuestionDto> {
        const response = await this.apiServicesAppQuestionUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
