/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroupListLightDto,
    StudentGroupListLightDtoFromJSON,
    StudentGroupListLightDtoFromJSONTyped,
    StudentGroupListLightDtoToJSON,
} from './StudentGroupListLightDto';

/**
 * 
 * @export
 * @interface StudentGroupListLightDtoListResultDto
 */
export interface StudentGroupListLightDtoListResultDto {
    /**
     * 
     * @type {Array<StudentGroupListLightDto>}
     * @memberof StudentGroupListLightDtoListResultDto
     */
    items?: Array<StudentGroupListLightDto> | null;
}

export function StudentGroupListLightDtoListResultDtoFromJSON(json: any): StudentGroupListLightDtoListResultDto {
    return StudentGroupListLightDtoListResultDtoFromJSONTyped(json, false);
}

export function StudentGroupListLightDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupListLightDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(StudentGroupListLightDtoFromJSON)),
    };
}

export function StudentGroupListLightDtoListResultDtoToJSON(value?: StudentGroupListLightDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(StudentGroupListLightDtoToJSON)),
    };
}

