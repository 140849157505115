/* tslint:disable */
/* eslint-disable */
export * from './AccountDto';
export * from './AddSchoolAdminInput';
export * from './AddTicketCommentInput';
export * from './AdminCreateChildUserInput';
export * from './AdminCreateMemberInput';
export * from './AdminLessonListDto';
export * from './AdminLessonListDtoListResultDto';
export * from './AdminLessonListDtoPagedResultDto';
export * from './AdminOrderListDto';
export * from './AdminOrderListDtoPagedResultDto';
export * from './AdminRegisterSchoolInput';
export * from './AdminSchoolListDto';
export * from './AdminSchoolListDtoListResultDto';
export * from './AdminSchoolListDtoPagedResultDto';
export * from './AdminUpdateChildUserInput';
export * from './AdminUpdateMemberInput';
export * from './AdvertisementDto';
export * from './AdvertisementListDto';
export * from './AdvertisementListDtoListResultDto';
export * from './AdvertisementListDtoPagedResultDto';
export * from './AdvertisementTranslatedDto';
export * from './AdvertisementTranslationDto';
export * from './AnswerStatus';
export * from './ApplicationInfoDto';
export * from './ArticleTranslationDto';
export * from './Assembly';
export * from './AssignTicketInput';
export * from './AssigneeScoreDto';
export * from './AuthenticateModel';
export * from './AuthenticateResultModel';
export * from './AuthenticationScheme';
export * from './BindExternalLoginByPasswordDto';
export * from './CallingConventions';
export * from './CategoryTranslationDto';
export * from './ChangeAssigneesInput';
export * from './ChangePasswordDto';
export * from './ChangeUiThemeInput';
export * from './ChangeUserLanguageDto';
export * from './ChildAccountDto';
export * from './ChildUserDto';
export * from './ChildUserListDto';
export * from './ChildUserListDtoChildUserListResultDto';
export * from './ChildrenAccountDto';
export * from './ClientType';
export * from './ConstructorInfo';
export * from './ContentArticleDto';
export * from './ContentArticleFullContentDto';
export * from './ContentArticleListDto';
export * from './ContentArticleListDtoPagedResultDto';
export * from './ContentCategoryDto';
export * from './ContentCategoryListDto';
export * from './ContentCategoryListDtoListResultDto';
export * from './ContentCustomFieldDto';
export * from './ContentCustomFieldListDto';
export * from './ContentCustomFieldTranslationDto';
export * from './CreateChildUserDto';
export * from './CreateDailyPracticeInput';
export * from './CreateExaminationExportResult';
export * from './CreateExaminationInput';
export * from './CreateExportExaminationInput';
export * from './CreateFromQuestionListInput';
export * from './CreateOrderInput';
export * from './CreateOrderResponse';
export * from './CreatePracticeInput';
export * from './CreateRoleDto';
export * from './CreateSchoolStudentInput';
export * from './CreateSchoolTeacherInput';
export * from './CreateStudentGroupInput';
export * from './CreateTenantDto';
export * from './CreateUserDto';
export * from './CurrentUserWalletDto';
export * from './CustomAttributeData';
export * from './CustomAttributeNamedArgument';
export * from './CustomAttributeTypedArgument';
export * from './DailyPracticeDetailDto';
export * from './DailyPracticeListDto';
export * from './DailyPracticeListDtoPagedResultDto';
export * from './EmailAddressInput';
export * from './EmailInput';
export * from './EmailTemplateDto';
export * from './EmailTemplateListDto';
export * from './EmailTemplateListDtoListResultDto';
export * from './EmailTemplateTranslationDto';
export * from './EntityDto';
export * from './EventAttributes';
export * from './EventInfo';
export * from './ExaminationDto';
export * from './ExaminationExport';
export * from './ExaminationExportAssignee';
export * from './ExaminationExportAssigneeListDto';
export * from './ExaminationExportAssigneeMappingDto';
export * from './ExaminationExportDto';
export * from './ExaminationExportGrade';
export * from './ExaminationExportGradeListDto';
export * from './ExaminationExportGradeMappingDto';
export * from './ExaminationExportLessonListDto';
export * from './ExaminationExportListDto';
export * from './ExaminationExportListDtoPagedResultDto';
export * from './ExaminationExportQuestion';
export * from './ExaminationExportQuestionListDto';
export * from './ExaminationExportQuestionOptionsDto';
export * from './ExaminationExportQuestionOptionsDtoListResultDto';
export * from './ExaminationExportQuestionOptionsDtoPagedResultDto';
export * from './ExaminationExportSection';
export * from './ExaminationExportSectionListDto';
export * from './ExaminationExportSubQuestionListDto';
export * from './ExaminationExportSubject';
export * from './ExaminationExportSubjectListDto';
export * from './ExaminationExportSubjectListDtoListResultDto';
export * from './ExaminationExportSubjectMappingDto';
export * from './ExaminationExportType';
export * from './ExaminationType';
export * from './ExportExaminationPaperInput';
export * from './ExportExaminationSettingDto';
export * from './ExportQuestionListDto';
export * from './ExportQuestionsInput';
export * from './ExportSectionListDto';
export * from './ExternalAuthenticateModel';
export * from './ExternalAuthenticateResultModel';
export * from './ExternalLoginProviderInfoModel';
export * from './FAQCategoryAdminListDto';
export * from './FAQCategoryAdminListDtoListResultDto';
export * from './FAQCategoryDto';
export * from './FAQCategoryDtoPagedResultDto';
export * from './FAQCategoryListDto';
export * from './FAQCategoryListDtoListResultDto';
export * from './FAQCategoryTranslationDto';
export * from './FAQItemAdminListDto';
export * from './FAQItemAdminListDtoListResultDto';
export * from './FAQItemDto';
export * from './FAQItemDtoPagedResultDto';
export * from './FAQItemListDto';
export * from './FAQItemListDtoPagedResultDto';
export * from './FAQItemTranslationDto';
export * from './FieldAttributes';
export * from './FieldInfo';
export * from './FlatPermissionDto';
export * from './Gender';
export * from './GenericParameterAttributes';
export * from './GetCurrentLoginInformationsOutput';
export * from './GetOrderResponse';
export * from './GetRoleForEditOutput';
export * from './Grade';
export * from './GradeDto';
export * from './GradeInludeStageDto';
export * from './GradeListDto';
export * from './GradeNameWithStageDto';
export * from './GradeTranslation';
export * from './GradeTranslationDto';
export * from './IdArrayInput';
export * from './ImportQuestionsInput';
export * from './Int32EntityDto';
export * from './Int64EntityDto';
export * from './IsTenantAvailableInput';
export * from './IsTenantAvailableOutput';
export * from './JToken';
export * from './LayoutKind';
export * from './Lesson';
export * from './LessonDto';
export * from './LessonListDto';
export * from './LessonListDtoLessonListResultDto';
export * from './LessonNameDto';
export * from './LessonNameWithSubjectDto';
export * from './LessonTestRangeDto';
export * from './LessonWithSubjectListDto';
export * from './LessonWithSubjectListDtoPagedResultDto';
export * from './LinkSchoolTeachersInput';
export * from './LocationListDto';
export * from './LocationListDtoListResultDto';
export * from './LocationType';
export * from './MakeVIPInput';
export * from './MemberDto';
export * from './MemberInfo';
export * from './MemberListDto';
export * from './MemberListDtoPagedResultDto';
export * from './MemberSettingDto';
export * from './MemberTypes';
export * from './MethodAttributes';
export * from './MethodBase';
export * from './MethodImplAttributes';
export * from './MethodInfo';
export * from './ModifyBalanceDto';
export * from './Module';
export * from './ModuleHandle';
export * from './OrderIntent';
export * from './OrderItemListDto';
export * from './OrderListDto';
export * from './OrderListDtoPagedResultDto';
export * from './OrderProductListDto';
export * from './OrderStatus';
export * from './ParameterAttributes';
export * from './ParameterInfo';
export * from './PaymentCallback';
export * from './PaymentListDto';
export * from './PaymentListDtoListResultDto';
export * from './PermissionDto';
export * from './PermissionDtoListResultDto';
export * from './PracticeDto';
export * from './PreOrderInput';
export * from './PreOrderItemInput';
export * from './PreOrderResponse';
export * from './ProductDto';
export * from './ProductItemDto';
export * from './ProductItemListDto';
export * from './ProductItemTranslationDto';
export * from './ProductItemType';
export * from './ProductListDto';
export * from './ProductListDtoListResultDto';
export * from './ProductListDtoPagedResultDto';
export * from './ProductPaymentListDto';
export * from './ProductPropertyDto';
export * from './ProductPurchaseMethod';
export * from './ProductTranslationDto';
export * from './PropertyAttributes';
export * from './PropertyInfo';
export * from './QueryOrderInput';
export * from './Question';
export * from './QuestionAnswerDto';
export * from './QuestionAnswerListDto';
export * from './QuestionAnswerListDtoPagedResultDto';
export * from './QuestionDetailDto';
export * from './QuestionDto';
export * from './QuestionListDto';
export * from './QuestionListWithCategoryDto';
export * from './QuestionListWithCategoryDtoPagedResultDto';
export * from './QuestionNavigator';
export * from './QuestionType';
export * from './QuestionTypeListDto';
export * from './QuestionTypeListDtoListResultDto';
export * from './QuestionWithNavigatorDto';
export * from './ReferenceTestListDto';
export * from './RefreshTokenModel';
export * from './Register3Input';
export * from './RegisterInput';
export * from './RegisterOutput';
export * from './RegisterSchoolInput';
export * from './RegisterSchoolOutput';
export * from './RemoveSchoolTeacherInput';
export * from './ReorderInput';
export * from './ResetPasswordDto';
export * from './ResetPasswordInput';
export * from './RoleDto';
export * from './RoleDtoListResultDto';
export * from './RoleDtoPagedResultDto';
export * from './RoleEditDto';
export * from './RoleListDto';
export * from './RoleListDtoListResultDto';
export * from './RuntimeFieldHandle';
export * from './RuntimeMethodHandle';
export * from './RuntimeTypeHandle';
export * from './School';
export * from './SchoolAdminDetailDto';
export * from './SchoolAdminListDto';
export * from './SchoolAdminListDtoPagedResultDto';
export * from './SchoolDetailDto';
export * from './SchoolDetailDtoWithId';
export * from './SchoolDetailDtoWithParent';
export * from './SchoolListDto';
export * from './SchoolListDtoListResultDto';
export * from './SchoolNameWithParentDto';
export * from './SchoolStudent';
export * from './SchoolStudentListDto';
export * from './SchoolStudentListDtoPagedResultDto';
export * from './SchoolTeacher';
export * from './SchoolTeacherDetailDto';
export * from './SchoolTeacherListDto';
export * from './SchoolTeacherListDtoPagedResultDto';
export * from './SchoolTeacherSelectListDto';
export * from './SchoolTeacherSelectListDtoPagedResultDto';
export * from './SchoolTransationDto';
export * from './SchoolTranslation';
export * from './SecurityRuleSet';
export * from './SendEmailCaptchaInput';
export * from './Setting';
export * from './SettingItemDto';
export * from './SiteInfoDto';
export * from './SiteInfoEditDto';
export * from './Stage';
export * from './StageListDto';
export * from './StageNameDto';
export * from './StageTranslation';
export * from './StageWithGradesListDto';
export * from './StageWithGradesListDtoListResultDto';
export * from './StructLayoutAttribute';
export * from './StudentDto';
export * from './StudentGroup';
export * from './StudentGroupDetailDto';
export * from './StudentGroupListDto';
export * from './StudentGroupListDtoPagedResultDto';
export * from './StudentGroupListLightDto';
export * from './StudentGroupListLightDtoListResultDto';
export * from './StudentGroupTag';
export * from './StudentGroupTagDetailDto';
export * from './StudentGroupTagListDto';
export * from './StudentGroupTagListDtoListResultDto';
export * from './StudentGroupTagTranslation';
export * from './StudentGroupTagTranslationDto';
export * from './StudentGroupTranslation';
export * from './StudentGroupTranslationDto';
export * from './StudentGroupUpdateInput';
export * from './StudentLessonProgress';
export * from './StudentProgress';
export * from './StudentStatments';
export * from './SubQuestionListDto';
export * from './SubQuestionListDtoListResultDto';
export * from './Subject';
export * from './SubjectDto';
export * from './SubjectIncludeGradeDto';
export * from './SubjectListDto';
export * from './SubjectListDtoListResultDto';
export * from './SubjectNameDto';
export * from './SubjectNameWithGradeDto';
export * from './SubjectQuestionTypeSetting';
export * from './SubjectQuestionTypeSettingDto';
export * from './SubjectStatementDaily';
export * from './SubjectStatementMonthly';
export * from './SubjectTranslation';
export * from './SubjectTranslationDto';
export * from './SubjectType';
export * from './SubjectiveAnswerDto';
export * from './SubjectiveAnswerDtoPagedResultDto';
export * from './SubjectiveQuestionListDto';
export * from './SubjectiveQuestionListDtoPagedResultDto';
export * from './SubmitTestResult';
export * from './SubscriptionStatus';
export * from './TenantAvailabilityState';
export * from './TenantDto';
export * from './TenantDtoPagedResultDto';
export * from './TenantLoginInfoDto';
export * from './Test';
export * from './TestIdInput';
export * from './TestListDto';
export * from './TestListDtoPagedResultDto';
export * from './TestQuestion';
export * from './TestQuestionDto';
export * from './TestRange';
export * from './TestResultDetailDto';
export * from './TestResultTestQuestionDto';
export * from './TestResultTestSubQuestionDto';
export * from './TestType';
export * from './Ticket';
export * from './TicketComment';
export * from './TicketCommentDto';
export * from './TicketCommentListDto';
export * from './TicketDto';
export * from './TicketDtoPagedResultDto';
export * from './TicketStatus';
export * from './TimeSpan';
export * from './TimeSpentDaily';
export * from './ToggleActivateResult';
export * from './Type';
export * from './TypeAttributes';
export * from './TypeInfo';
export * from './UpdateAccountInput';
export * from './UpdateAvatorInput';
export * from './UpdateChildInput';
export * from './UpdateChildWithIDInput';
export * from './UpdateEmailAddressInput';
export * from './UpdatePasswordDto';
export * from './UpdateSchoolAdminInput';
export * from './UpdateSchoolStudentInput';
export * from './UpdateSchoolTeacherInput';
export * from './UpdateTicketStatusInput';
export * from './User';
export * from './UserAddTicketInput';
export * from './UserClaim';
export * from './UserCoinHistory';
export * from './UserCoinHistoryDto';
export * from './UserCoinReasonType';
export * from './UserDto';
export * from './UserDtoPagedResultDto';
export * from './UserEventDto';
export * from './UserLogin';
export * from './UserLoginInfoDto';
export * from './UserOrderDetailDto';
export * from './UserOrderListDto';
export * from './UserOrderListDtoPagedResultDto';
export * from './UserPermissionSetting';
export * from './UserRole';
export * from './UserTicketDto';
export * from './UserTicketListDto';
export * from './UserTicketListDtoPagedResultDto';
export * from './UserToken';
export * from './UserWallet';
