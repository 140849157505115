/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchoolListDto
 */
export interface SchoolListDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolListDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SchoolListDto
     */
    isMaster?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SchoolListDto
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SchoolListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof SchoolListDto
     */
    studentGroupCount?: number;
}

export function SchoolListDtoFromJSON(json: any): SchoolListDto {
    return SchoolListDtoFromJSONTyped(json, false);
}

export function SchoolListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isMaster': !exists(json, 'isMaster') ? undefined : json['isMaster'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'studentGroupCount': !exists(json, 'studentGroupCount') ? undefined : json['studentGroupCount'],
    };
}

export function SchoolListDtoToJSON(value?: SchoolListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isMaster': value.isMaster,
        'name': value.name,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'studentGroupCount': value.studentGroupCount,
    };
}

