/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MakeVIPInput,
    MakeVIPInputFromJSON,
    MakeVIPInputToJSON,
} from '../models';

export interface ApiServicesAppPermissionCanAccessExportPaperGetRequest {
    id?: Array<number>;
}

export interface ApiServicesAppPermissionCanAccessTestGetRequest {
    id?: Array<number>;
}

export interface ApiServicesAppPermissionCanAccessUserGetRequest {
    id?: Array<number>;
}

export interface ApiServicesAppPermissionMakeVIPPostRequest {
    makeVIPInput?: MakeVIPInput;
}

export interface ApiServicesAppPermissionRemoveVIPDeleteRequest {
    id?: number;
}

/**
 * 
 */
export class PermissionApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppPermissionCanAccessExportPaperGetRaw(requestParameters: ApiServicesAppPermissionCanAccessExportPaperGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Permission/CanAccessExportPaper`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppPermissionCanAccessExportPaperGet(requestParameters: ApiServicesAppPermissionCanAccessExportPaperGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppPermissionCanAccessExportPaperGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppPermissionCanAccessTestGetRaw(requestParameters: ApiServicesAppPermissionCanAccessTestGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Permission/CanAccessTest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppPermissionCanAccessTestGet(requestParameters: ApiServicesAppPermissionCanAccessTestGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppPermissionCanAccessTestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppPermissionCanAccessUserGetRaw(requestParameters: ApiServicesAppPermissionCanAccessUserGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Permission/CanAccessUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppPermissionCanAccessUserGet(requestParameters: ApiServicesAppPermissionCanAccessUserGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppPermissionCanAccessUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppPermissionMakeVIPPostRaw(requestParameters: ApiServicesAppPermissionMakeVIPPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Permission/MakeVIP`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakeVIPInputToJSON(requestParameters.makeVIPInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppPermissionMakeVIPPost(requestParameters: ApiServicesAppPermissionMakeVIPPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppPermissionMakeVIPPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppPermissionRemoveVIPDeleteRaw(requestParameters: ApiServicesAppPermissionRemoveVIPDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Permission/RemoveVIP`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppPermissionRemoveVIPDelete(requestParameters: ApiServicesAppPermissionRemoveVIPDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppPermissionRemoveVIPDeleteRaw(requestParameters, initOverrides);
    }

}
