/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserToken
 */
export interface UserToken {
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    tenantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    loginProvider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    value?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserToken
     */
    expireDate?: Date | null;
}

export function UserTokenFromJSON(json: any): UserToken {
    return UserTokenFromJSONTyped(json, false);
}

export function UserTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'loginProvider': !exists(json, 'loginProvider') ? undefined : json['loginProvider'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'expireDate': !exists(json, 'expireDate') ? undefined : (json['expireDate'] === null ? null : new Date(json['expireDate'])),
    };
}

export function UserTokenToJSON(value?: UserToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'loginProvider': value.loginProvider,
        'name': value.name,
        'value': value.value,
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate === null ? null : value.expireDate.toISOString()),
    };
}

