/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTicketCommentInput,
    AddTicketCommentInputFromJSON,
    AddTicketCommentInputToJSON,
    AssignTicketInput,
    AssignTicketInputFromJSON,
    AssignTicketInputToJSON,
    TicketCommentDto,
    TicketCommentDtoFromJSON,
    TicketCommentDtoToJSON,
    TicketDto,
    TicketDtoFromJSON,
    TicketDtoToJSON,
    TicketDtoPagedResultDto,
    TicketDtoPagedResultDtoFromJSON,
    TicketDtoPagedResultDtoToJSON,
    TicketStatus,
    TicketStatusFromJSON,
    TicketStatusToJSON,
    UpdateTicketStatusInput,
    UpdateTicketStatusInputFromJSON,
    UpdateTicketStatusInputToJSON,
} from '../models';

export interface ApiServicesAppAdminTicketAssignPostRequest {
    assignTicketInput?: AssignTicketInput;
}

export interface ApiServicesAppAdminTicketCommentPostRequest {
    addTicketCommentInput?: AddTicketCommentInput;
}

export interface ApiServicesAppAdminTicketCreatePostRequest {
    ticketDto?: TicketDto;
}

export interface ApiServicesAppAdminTicketDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppAdminTicketGetAllGetRequest {
    status?: TicketStatus;
    keyword?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppAdminTicketGetGetRequest {
    id?: number;
}

export interface ApiServicesAppAdminTicketUpdatePutRequest {
    ticketDto?: TicketDto;
}

export interface ApiServicesAppAdminTicketUpdateStatusPutRequest {
    updateTicketStatusInput?: UpdateTicketStatusInput;
}

/**
 * 
 */
export class AdminTicketApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppAdminTicketAssignPostRaw(requestParameters: ApiServicesAppAdminTicketAssignPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/AdminTicket/Assign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignTicketInputToJSON(requestParameters.assignTicketInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppAdminTicketAssignPost(requestParameters: ApiServicesAppAdminTicketAssignPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppAdminTicketAssignPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppAdminTicketCommentPostRaw(requestParameters: ApiServicesAppAdminTicketCommentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TicketCommentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/AdminTicket/Comment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTicketCommentInputToJSON(requestParameters.addTicketCommentInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketCommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdminTicketCommentPost(requestParameters: ApiServicesAppAdminTicketCommentPostRequest, initOverrides?: RequestInit): Promise<TicketCommentDto> {
        const response = await this.apiServicesAppAdminTicketCommentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdminTicketCreatePostRaw(requestParameters: ApiServicesAppAdminTicketCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TicketDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/AdminTicket/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketDtoToJSON(requestParameters.ticketDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdminTicketCreatePost(requestParameters: ApiServicesAppAdminTicketCreatePostRequest, initOverrides?: RequestInit): Promise<TicketDto> {
        const response = await this.apiServicesAppAdminTicketCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdminTicketDeleteDeleteRaw(requestParameters: ApiServicesAppAdminTicketDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/AdminTicket/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppAdminTicketDeleteDelete(requestParameters: ApiServicesAppAdminTicketDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppAdminTicketDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppAdminTicketGetAllGetRaw(requestParameters: ApiServicesAppAdminTicketGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TicketDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/AdminTicket/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdminTicketGetAllGet(requestParameters: ApiServicesAppAdminTicketGetAllGetRequest, initOverrides?: RequestInit): Promise<TicketDtoPagedResultDto> {
        const response = await this.apiServicesAppAdminTicketGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdminTicketGetGetRaw(requestParameters: ApiServicesAppAdminTicketGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TicketDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/AdminTicket/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdminTicketGetGet(requestParameters: ApiServicesAppAdminTicketGetGetRequest, initOverrides?: RequestInit): Promise<TicketDto> {
        const response = await this.apiServicesAppAdminTicketGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdminTicketUpdatePutRaw(requestParameters: ApiServicesAppAdminTicketUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TicketDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/AdminTicket/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TicketDtoToJSON(requestParameters.ticketDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppAdminTicketUpdatePut(requestParameters: ApiServicesAppAdminTicketUpdatePutRequest, initOverrides?: RequestInit): Promise<TicketDto> {
        const response = await this.apiServicesAppAdminTicketUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppAdminTicketUpdateStatusPutRaw(requestParameters: ApiServicesAppAdminTicketUpdateStatusPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/AdminTicket/UpdateStatus`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTicketStatusInputToJSON(requestParameters.updateTicketStatusInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppAdminTicketUpdateStatusPut(requestParameters: ApiServicesAppAdminTicketUpdateStatusPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppAdminTicketUpdateStatusPutRaw(requestParameters, initOverrides);
    }

}
