/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StageNameDto,
    StageNameDtoFromJSON,
    StageNameDtoFromJSONTyped,
    StageNameDtoToJSON,
} from './StageNameDto';

/**
 * 
 * @export
 * @interface GradeNameWithStageDto
 */
export interface GradeNameWithStageDto {
    /**
     * 
     * @type {number}
     * @memberof GradeNameWithStageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GradeNameWithStageDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GradeNameWithStageDto
     */
    code?: string | null;
    /**
     * 
     * @type {StageNameDto}
     * @memberof GradeNameWithStageDto
     */
    stage?: StageNameDto;
}

export function GradeNameWithStageDtoFromJSON(json: any): GradeNameWithStageDto {
    return GradeNameWithStageDtoFromJSONTyped(json, false);
}

export function GradeNameWithStageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeNameWithStageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'stage': !exists(json, 'stage') ? undefined : StageNameDtoFromJSON(json['stage']),
    };
}

export function GradeNameWithStageDtoToJSON(value?: GradeNameWithStageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'stage': StageNameDtoToJSON(value.stage),
    };
}

