/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderIntent {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function OrderIntentFromJSON(json: any): OrderIntent {
    return OrderIntentFromJSONTyped(json, false);
}

export function OrderIntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderIntent {
    return json as OrderIntent;
}

export function OrderIntentToJSON(value?: OrderIntent | null): any {
    return value as any;
}

