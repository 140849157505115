import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UseQueryResult, useQuery } from 'react-query';
import {AccountApi, ChildUserDto, ChildUserListDto, Configuration, GetCurrentLoginInformationsOutput, SessionApi } from 'src/fetch';
import { genApiConfig } from 'src/fetch/fetcher-config';
import { ParentRoles } from 'src/utils/constants';

export type UserData = {
    accessToken: string | null,
    userId:number|undefined,
    userInfo: GetCurrentLoginInformationsOutput | null,
    getApiConfig : ((noCache?: boolean)=>Configuration)&{loadedUserInfo:boolean, loadedLocale:boolean},
    childrenQuery:UseQueryResult<ChildUserListDto[] | null | undefined>
};

const UserDataContext = React.createContext({} as UserData);


export const UserDataProvider = (props:PropsWithChildren<{childrenPlaceholder?:ChildUserListDto[]}> )=>{
  const {childrenPlaceholder} = props;
  const {locale} =useRouter();
  const {i18n} =useTranslation();
  const { data } = useSession();
  const [userInfo, setUserInfo] = useState<GetCurrentLoginInformationsOutput | null>(null);
  useEffect(() => {
    if (data && data?.user) {
      const loginInfoResult = new SessionApi(genApiConfig(locale??"zh-HK", null, data ?? undefined)).apiServicesAppSessionGetCurrentLoginInformationsGetRaw().Convert();
      loginInfoResult.then(it => {
        setUserInfo(it);
      }).catch(ex => {
        console.error(ex);
        setUserInfo(null)
      });
    } else {
      setUserInfo(null)
    }
  }, [data, locale])
  const childrenQuery = useQuery(["children",data?.user.id], async()=>{
    return await new AccountApi(genApiConfig(i18n.language, false, data ?? undefined))
      .apiServicesAppAccountGetChildrenGetRaw().Convert().then(it=>it.items)
  }, {
    refetchOnWindowFocus:false,
    enabled: data != null && !!ParentRoles.find(it=>data.user.role.includes(it)),
    placeholderData: childrenPlaceholder
  });

  const getApiConfig = useCallback((noCache?:boolean)=>{
    return genApiConfig(((locale??i18n.language)?.toLocaleLowerCase()=="zh-hant"?"zh-HK":locale)??"en",noCache, data ??undefined);
  }, [locale, data, i18n.language])

  const apiConfigWithState = useMemo<((noCache?: boolean)=>Configuration)&{loadedUserInfo:boolean, loadedLocale:boolean}>(()=>{
    const result:((noCache?: boolean)=>Configuration)&{loadedUserInfo:boolean, loadedLocale:boolean} = getApiConfig as any;
    result.loadedUserInfo = data?.user!=null;
    result.loadedLocale = (locale??i18n.language )!= null;
    return result;
  },[getApiConfig])
  return <UserDataContext.Provider value={
    {
      accessToken: data?.accessToken ?? null,
      userId: data?.user?.userId,
      userInfo: userInfo,
      getApiConfig: apiConfigWithState,
      childrenQuery
    }
  }>
    {props.children}
  </UserDataContext.Provider>
}

export const useUserData = () => {
  const userData = useContext(UserDataContext)
  return userData
}