/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailDto,
    QuestionDetailDtoFromJSON,
    QuestionDetailDtoFromJSONTyped,
    QuestionDetailDtoToJSON,
} from './QuestionDetailDto';

/**
 * 
 * @export
 * @interface TestQuestionDto
 */
export interface TestQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof TestQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {QuestionDetailDto}
     * @memberof TestQuestionDto
     */
    question?: QuestionDetailDto;
    /**
     * 
     * @type {Array<TestQuestionDto>}
     * @memberof TestQuestionDto
     */
    subTestQuestions?: Array<TestQuestionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TestQuestionDto
     */
    points?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestQuestionDto
     */
    testerAnswer?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestQuestionDto
     */
    isTesterAnswerCorrect?: boolean | null;
}

export function TestQuestionDtoFromJSON(json: any): TestQuestionDto {
    return TestQuestionDtoFromJSONTyped(json, false);
}

export function TestQuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestQuestionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'question': !exists(json, 'question') ? undefined : QuestionDetailDtoFromJSON(json['question']),
        'subTestQuestions': !exists(json, 'subTestQuestions') ? undefined : (json['subTestQuestions'] === null ? null : (json['subTestQuestions'] as Array<any>).map(TestQuestionDtoFromJSON)),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'testerAnswer': !exists(json, 'testerAnswer') ? undefined : json['testerAnswer'],
        'isTesterAnswerCorrect': !exists(json, 'isTesterAnswerCorrect') ? undefined : json['isTesterAnswerCorrect'],
    };
}

export function TestQuestionDtoToJSON(value?: TestQuestionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'question': QuestionDetailDtoToJSON(value.question),
        'subTestQuestions': value.subTestQuestions === undefined ? undefined : (value.subTestQuestions === null ? null : (value.subTestQuestions as Array<any>).map(TestQuestionDtoToJSON)),
        'points': value.points,
        'testerAnswer': value.testerAnswer,
        'isTesterAnswerCorrect': value.isTesterAnswerCorrect,
    };
}

