/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminLessonListDtoListResultDto,
    AdminLessonListDtoListResultDtoFromJSON,
    AdminLessonListDtoListResultDtoToJSON,
    GradeDto,
    GradeDtoFromJSON,
    GradeDtoToJSON,
    GradeListDto,
    GradeListDtoFromJSON,
    GradeListDtoToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    LessonListDtoLessonListResultDto,
    LessonListDtoLessonListResultDtoFromJSON,
    LessonListDtoLessonListResultDtoToJSON,
    LessonWithSubjectListDtoPagedResultDto,
    LessonWithSubjectListDtoPagedResultDtoFromJSON,
    LessonWithSubjectListDtoPagedResultDtoToJSON,
    StageWithGradesListDtoListResultDto,
    StageWithGradesListDtoListResultDtoFromJSON,
    StageWithGradesListDtoListResultDtoToJSON,
    SubjectDto,
    SubjectDtoFromJSON,
    SubjectDtoToJSON,
    SubjectListDto,
    SubjectListDtoFromJSON,
    SubjectListDtoToJSON,
    SubjectListDtoListResultDto,
    SubjectListDtoListResultDtoFromJSON,
    SubjectListDtoListResultDtoToJSON,
} from '../models';

export interface ApiServicesAppCategoryGetAllLessonsInSubjectGetRequest {
    subjectId?: number;
    isActive?: boolean;
    search?: string;
}

export interface ApiServicesAppCategoryGetAllStagesIncludingGradesGetRequest {
    skipCache?: boolean;
}

export interface ApiServicesAppCategoryGetAllSubjectsInGradeGetRequest {
    gradeCode?: string;
}

export interface ApiServicesAppCategoryGetGradeGetRequest {
    code?: string;
}

export interface ApiServicesAppCategoryGetGradeInfoGetRequest {
    gradeId?: number;
}

export interface ApiServicesAppCategoryGetLessonsInSubjectGetRequest {
    subjectId?: number;
}

export interface ApiServicesAppCategoryGetSubjectGetRequest {
    id?: number;
}

export interface ApiServicesAppCategoryGetSubjectInfoGetRequest {
    subjectId?: number;
}

export interface ApiServicesAppCategoryGetSubjectiveLessonsInGradeGetRequest {
    gradeId?: number;
    gradeCode?: string;
    term?: number;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppCategoryGetSubjectsInGradeGetRequest {
    gradeCode?: string;
    term?: number;
}

export interface ApiServicesAppCategoryToggleActivationPostRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppCategoryUpdateGradePutRequest {
    gradeDto?: GradeDto;
}

export interface ApiServicesAppCategoryUpdateSubjectPutRequest {
    subjectDto?: SubjectDto;
}

/**
 * 
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppCategoryGetAllLessonsInSubjectGetRaw(requestParameters: ApiServicesAppCategoryGetAllLessonsInSubjectGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminLessonListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectId !== undefined) {
            queryParameters['subjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetAllLessonsInSubject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminLessonListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetAllLessonsInSubjectGet(requestParameters: ApiServicesAppCategoryGetAllLessonsInSubjectGetRequest, initOverrides?: RequestInit): Promise<AdminLessonListDtoListResultDto> {
        const response = await this.apiServicesAppCategoryGetAllLessonsInSubjectGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetAllStagesIncludingGradesGetRaw(requestParameters: ApiServicesAppCategoryGetAllStagesIncludingGradesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StageWithGradesListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.skipCache !== undefined) {
            queryParameters['skipCache'] = requestParameters.skipCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetAllStagesIncludingGrades`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StageWithGradesListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetAllStagesIncludingGradesGet(requestParameters: ApiServicesAppCategoryGetAllStagesIncludingGradesGetRequest, initOverrides?: RequestInit): Promise<StageWithGradesListDtoListResultDto> {
        const response = await this.apiServicesAppCategoryGetAllStagesIncludingGradesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetAllSubjectsInGradeGetRaw(requestParameters: ApiServicesAppCategoryGetAllSubjectsInGradeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeCode !== undefined) {
            queryParameters['gradeCode'] = requestParameters.gradeCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetAllSubjectsInGrade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetAllSubjectsInGradeGet(requestParameters: ApiServicesAppCategoryGetAllSubjectsInGradeGetRequest, initOverrides?: RequestInit): Promise<SubjectListDtoListResultDto> {
        const response = await this.apiServicesAppCategoryGetAllSubjectsInGradeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetGradeGetRaw(requestParameters: ApiServicesAppCategoryGetGradeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GradeDto>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetGrade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetGradeGet(requestParameters: ApiServicesAppCategoryGetGradeGetRequest, initOverrides?: RequestInit): Promise<GradeDto> {
        const response = await this.apiServicesAppCategoryGetGradeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetGradeInfoGetRaw(requestParameters: ApiServicesAppCategoryGetGradeInfoGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GradeListDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeId !== undefined) {
            queryParameters['gradeId'] = requestParameters.gradeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetGradeInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetGradeInfoGet(requestParameters: ApiServicesAppCategoryGetGradeInfoGetRequest, initOverrides?: RequestInit): Promise<GradeListDto> {
        const response = await this.apiServicesAppCategoryGetGradeInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetLessonsInSubjectGetRaw(requestParameters: ApiServicesAppCategoryGetLessonsInSubjectGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessonListDtoLessonListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectId !== undefined) {
            queryParameters['subjectId'] = requestParameters.subjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetLessonsInSubject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessonListDtoLessonListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetLessonsInSubjectGet(requestParameters: ApiServicesAppCategoryGetLessonsInSubjectGetRequest, initOverrides?: RequestInit): Promise<LessonListDtoLessonListResultDto> {
        const response = await this.apiServicesAppCategoryGetLessonsInSubjectGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectGetRaw(requestParameters: ApiServicesAppCategoryGetSubjectGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetSubject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectGet(requestParameters: ApiServicesAppCategoryGetSubjectGetRequest, initOverrides?: RequestInit): Promise<SubjectDto> {
        const response = await this.apiServicesAppCategoryGetSubjectGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectInfoGetRaw(requestParameters: ApiServicesAppCategoryGetSubjectInfoGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectListDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectId !== undefined) {
            queryParameters['subjectId'] = requestParameters.subjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetSubjectInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectInfoGet(requestParameters: ApiServicesAppCategoryGetSubjectInfoGetRequest, initOverrides?: RequestInit): Promise<SubjectListDto> {
        const response = await this.apiServicesAppCategoryGetSubjectInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectiveLessonsInGradeGetRaw(requestParameters: ApiServicesAppCategoryGetSubjectiveLessonsInGradeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessonWithSubjectListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeId !== undefined) {
            queryParameters['GradeId'] = requestParameters.gradeId;
        }

        if (requestParameters.gradeCode !== undefined) {
            queryParameters['GradeCode'] = requestParameters.gradeCode;
        }

        if (requestParameters.term !== undefined) {
            queryParameters['Term'] = requestParameters.term;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetSubjectiveLessonsInGrade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessonWithSubjectListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectiveLessonsInGradeGet(requestParameters: ApiServicesAppCategoryGetSubjectiveLessonsInGradeGetRequest, initOverrides?: RequestInit): Promise<LessonWithSubjectListDtoPagedResultDto> {
        const response = await this.apiServicesAppCategoryGetSubjectiveLessonsInGradeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectsInGradeGetRaw(requestParameters: ApiServicesAppCategoryGetSubjectsInGradeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeCode !== undefined) {
            queryParameters['gradeCode'] = requestParameters.gradeCode;
        }

        if (requestParameters.term !== undefined) {
            queryParameters['term'] = requestParameters.term;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Category/GetSubjectsInGrade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryGetSubjectsInGradeGet(requestParameters: ApiServicesAppCategoryGetSubjectsInGradeGetRequest, initOverrides?: RequestInit): Promise<SubjectListDtoListResultDto> {
        const response = await this.apiServicesAppCategoryGetSubjectsInGradeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryToggleActivationPostRaw(requestParameters: ApiServicesAppCategoryToggleActivationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GradeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Category/ToggleActivation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryToggleActivationPost(requestParameters: ApiServicesAppCategoryToggleActivationPostRequest, initOverrides?: RequestInit): Promise<GradeDto> {
        const response = await this.apiServicesAppCategoryToggleActivationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryUpdateGradePutRaw(requestParameters: ApiServicesAppCategoryUpdateGradePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GradeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Category/UpdateGrade`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GradeDtoToJSON(requestParameters.gradeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryUpdateGradePut(requestParameters: ApiServicesAppCategoryUpdateGradePutRequest, initOverrides?: RequestInit): Promise<GradeDto> {
        const response = await this.apiServicesAppCategoryUpdateGradePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppCategoryUpdateSubjectPutRaw(requestParameters: ApiServicesAppCategoryUpdateSubjectPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Category/UpdateSubject`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubjectDtoToJSON(requestParameters.subjectDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppCategoryUpdateSubjectPut(requestParameters: ApiServicesAppCategoryUpdateSubjectPutRequest, initOverrides?: RequestInit): Promise<SubjectDto> {
        const response = await this.apiServicesAppCategoryUpdateSubjectPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
