/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserOrderListDto,
    UserOrderListDtoFromJSON,
    UserOrderListDtoFromJSONTyped,
    UserOrderListDtoToJSON,
} from './UserOrderListDto';

/**
 * 
 * @export
 * @interface UserOrderListDtoPagedResultDto
 */
export interface UserOrderListDtoPagedResultDto {
    /**
     * 
     * @type {Array<UserOrderListDto>}
     * @memberof UserOrderListDtoPagedResultDto
     */
    items?: Array<UserOrderListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof UserOrderListDtoPagedResultDto
     */
    totalCount?: number;
}

export function UserOrderListDtoPagedResultDtoFromJSON(json: any): UserOrderListDtoPagedResultDto {
    return UserOrderListDtoPagedResultDtoFromJSONTyped(json, false);
}

export function UserOrderListDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOrderListDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(UserOrderListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function UserOrderListDtoPagedResultDtoToJSON(value?: UserOrderListDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(UserOrderListDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

