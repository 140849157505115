/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangePasswordDto,
    ChangePasswordDtoFromJSON,
    ChangePasswordDtoToJSON,
    ChangeUserLanguageDto,
    ChangeUserLanguageDtoFromJSON,
    ChangeUserLanguageDtoToJSON,
    CreateUserDto,
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    Int64EntityDto,
    Int64EntityDtoFromJSON,
    Int64EntityDtoToJSON,
    ResetPasswordDto,
    ResetPasswordDtoFromJSON,
    ResetPasswordDtoToJSON,
    RoleDtoListResultDto,
    RoleDtoListResultDtoFromJSON,
    RoleDtoListResultDtoToJSON,
    UserDto,
    UserDtoFromJSON,
    UserDtoToJSON,
    UserDtoPagedResultDto,
    UserDtoPagedResultDtoFromJSON,
    UserDtoPagedResultDtoToJSON,
} from '../models';

export interface ApiServicesAppUserActivatePostRequest {
    int64EntityDto?: Int64EntityDto;
}

export interface ApiServicesAppUserChangeLanguagePostRequest {
    changeUserLanguageDto?: ChangeUserLanguageDto;
}

export interface ApiServicesAppUserChangePasswordPostRequest {
    changePasswordDto?: ChangePasswordDto;
}

export interface ApiServicesAppUserCreatePostRequest {
    createUserDto?: CreateUserDto;
}

export interface ApiServicesAppUserDeActivatePostRequest {
    int64EntityDto?: Int64EntityDto;
}

export interface ApiServicesAppUserDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppUserGetAllGetRequest {
    keyword?: string;
    isActive?: boolean;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppUserGetGetRequest {
    id?: number;
}

export interface ApiServicesAppUserResetPasswordPostRequest {
    resetPasswordDto?: ResetPasswordDto;
}

export interface ApiServicesAppUserUpdatePutRequest {
    userDto?: UserDto;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppUserActivatePostRaw(requestParameters: ApiServicesAppUserActivatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/Activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int64EntityDtoToJSON(requestParameters.int64EntityDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserActivatePost(requestParameters: ApiServicesAppUserActivatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserActivatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserChangeLanguagePostRaw(requestParameters: ApiServicesAppUserChangeLanguagePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/ChangeLanguage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUserLanguageDtoToJSON(requestParameters.changeUserLanguageDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserChangeLanguagePost(requestParameters: ApiServicesAppUserChangeLanguagePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserChangeLanguagePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserChangePasswordPostRaw(requestParameters: ApiServicesAppUserChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/ChangePassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordDtoToJSON(requestParameters.changePasswordDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppUserChangePasswordPost(requestParameters: ApiServicesAppUserChangePasswordPostRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppUserChangePasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserCreatePostRaw(requestParameters: ApiServicesAppUserCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters.createUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserCreatePost(requestParameters: ApiServicesAppUserCreatePostRequest, initOverrides?: RequestInit): Promise<UserDto> {
        const response = await this.apiServicesAppUserCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserDeActivatePostRaw(requestParameters: ApiServicesAppUserDeActivatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/DeActivate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int64EntityDtoToJSON(requestParameters.int64EntityDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserDeActivatePost(requestParameters: ApiServicesAppUserDeActivatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserDeActivatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserDeleteDeleteRaw(requestParameters: ApiServicesAppUserDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/User/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserDeleteDelete(requestParameters: ApiServicesAppUserDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserGetAllGetRaw(requestParameters: ApiServicesAppUserGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/User/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserGetAllGet(requestParameters: ApiServicesAppUserGetAllGetRequest, initOverrides?: RequestInit): Promise<UserDtoPagedResultDto> {
        const response = await this.apiServicesAppUserGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserGetGetRaw(requestParameters: ApiServicesAppUserGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/User/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserGetGet(requestParameters: ApiServicesAppUserGetGetRequest, initOverrides?: RequestInit): Promise<UserDto> {
        const response = await this.apiServicesAppUserGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserGetRolesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDtoListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/User/GetRoles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserGetRolesGet(initOverrides?: RequestInit): Promise<RoleDtoListResultDto> {
        const response = await this.apiServicesAppUserGetRolesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserResetPasswordPostRaw(requestParameters: ApiServicesAppUserResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/ResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordDtoToJSON(requestParameters.resetPasswordDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiServicesAppUserResetPasswordPost(requestParameters: ApiServicesAppUserResetPasswordPostRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiServicesAppUserResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserUpdatePutRaw(requestParameters: ApiServicesAppUserUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/User/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserUpdatePut(requestParameters: ApiServicesAppUserUpdatePutRequest, initOverrides?: RequestInit): Promise<UserDto> {
        const response = await this.apiServicesAppUserUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
