/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductTranslationDto
 */
export interface ProductTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof ProductTranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTranslationDto
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTranslationDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTranslationDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTranslationDto
     */
    cover?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTranslationDto
     */
    detail?: string | null;
}

export function ProductTranslationDtoFromJSON(json: any): ProductTranslationDto {
    return ProductTranslationDtoFromJSONTyped(json, false);
}

export function ProductTranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTranslationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cover': !exists(json, 'cover') ? undefined : json['cover'],
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
    };
}

export function ProductTranslationDtoToJSON(value?: ProductTranslationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'language': value.language,
        'title': value.title,
        'description': value.description,
        'cover': value.cover,
        'detail': value.detail,
    };
}

