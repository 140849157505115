/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Ticket,
    TicketFromJSON,
    TicketFromJSONTyped,
    TicketToJSON,
} from './Ticket';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TicketCommentDto
 */
export interface TicketCommentDto {
    /**
     * 
     * @type {number}
     * @memberof TicketCommentDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketCommentDto
     */
    ticketId?: number;
    /**
     * 
     * @type {Ticket}
     * @memberof TicketCommentDto
     */
    ticket?: Ticket;
    /**
     * 
     * @type {string}
     * @memberof TicketCommentDto
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof TicketCommentDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TicketCommentDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TicketCommentDto
     */
    creatorUser?: User;
    /**
     * 
     * @type {string}
     * @memberof TicketCommentDto
     */
    readonly creatorUserName?: string | null;
}

export function TicketCommentDtoFromJSON(json: any): TicketCommentDto {
    return TicketCommentDtoFromJSONTyped(json, false);
}

export function TicketCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketCommentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'ticket': !exists(json, 'ticket') ? undefined : TicketFromJSON(json['ticket']),
        'message': json['message'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
        'creatorUserName': !exists(json, 'creatorUserName') ? undefined : json['creatorUserName'],
    };
}

export function TicketCommentDtoToJSON(value?: TicketCommentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ticketId': value.ticketId,
        'ticket': TicketToJSON(value.ticket),
        'message': value.message,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'creatorUser': UserToJSON(value.creatorUser),
    };
}

