/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchoolNameWithParentDto,
    SchoolNameWithParentDtoFromJSON,
    SchoolNameWithParentDtoFromJSONTyped,
    SchoolNameWithParentDtoToJSON,
} from './SchoolNameWithParentDto';
import {
    StudentGroupTagDetailDto,
    StudentGroupTagDetailDtoFromJSON,
    StudentGroupTagDetailDtoFromJSONTyped,
    StudentGroupTagDetailDtoToJSON,
} from './StudentGroupTagDetailDto';
import {
    StudentGroupTranslationDto,
    StudentGroupTranslationDtoFromJSON,
    StudentGroupTranslationDtoFromJSONTyped,
    StudentGroupTranslationDtoToJSON,
} from './StudentGroupTranslationDto';

/**
 * 
 * @export
 * @interface StudentGroupDetailDto
 */
export interface StudentGroupDetailDto {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupDetailDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupDetailDto
     */
    schoolId?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupDetailDto
     */
    studentGroupTagId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof StudentGroupDetailDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupDetailDto
     */
    gradeId?: number | null;
    /**
     * 
     * @type {SchoolNameWithParentDto}
     * @memberof StudentGroupDetailDto
     */
    school?: SchoolNameWithParentDto;
    /**
     * 
     * @type {StudentGroupTagDetailDto}
     * @memberof StudentGroupDetailDto
     */
    studentGroupTag?: StudentGroupTagDetailDto;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupDetailDto
     */
    stageId?: number | null;
    /**
     * 
     * @type {Array<StudentGroupTranslationDto>}
     * @memberof StudentGroupDetailDto
     */
    translations?: Array<StudentGroupTranslationDto> | null;
}

export function StudentGroupDetailDtoFromJSON(json: any): StudentGroupDetailDto {
    return StudentGroupDetailDtoFromJSONTyped(json, false);
}

export function StudentGroupDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'studentGroupTagId': !exists(json, 'studentGroupTagId') ? undefined : json['studentGroupTagId'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'school': !exists(json, 'school') ? undefined : SchoolNameWithParentDtoFromJSON(json['school']),
        'studentGroupTag': !exists(json, 'studentGroupTag') ? undefined : StudentGroupTagDetailDtoFromJSON(json['studentGroupTag']),
        'stageId': !exists(json, 'stageId') ? undefined : json['stageId'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StudentGroupTranslationDtoFromJSON)),
    };
}

export function StudentGroupDetailDtoToJSON(value?: StudentGroupDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'studentGroupTagId': value.studentGroupTagId,
        'isActive': value.isActive,
        'gradeId': value.gradeId,
        'school': SchoolNameWithParentDtoToJSON(value.school),
        'studentGroupTag': StudentGroupTagDetailDtoToJSON(value.studentGroupTag),
        'stageId': value.stageId,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StudentGroupTranslationDtoToJSON)),
    };
}

