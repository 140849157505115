/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportLessonListDto,
    ExaminationExportLessonListDtoFromJSON,
    ExaminationExportLessonListDtoFromJSONTyped,
    ExaminationExportLessonListDtoToJSON,
} from './ExaminationExportLessonListDto';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface ExaminationExportQuestionOptionsDto
 */
export interface ExaminationExportQuestionOptionsDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    id?: number;
    /**
     * 
     * @type {QuestionType}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    questionType?: QuestionType;
    /**
     * 
     * @type {ExaminationExportLessonListDto}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    lesson?: ExaminationExportLessonListDto;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    questionTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    questionBody?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    isSubjective?: boolean;
    /**
     * 
     * @type {Array<ExaminationExportQuestionOptionsDto>}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    subQuestions?: Array<ExaminationExportQuestionOptionsDto> | null;
    /**
     * 
     * @type {any}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    options?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    answer?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    displayAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    explain?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    extra?: any | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    stars?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    subjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionOptionsDto
     */
    gradeId?: number | null;
}

export function ExaminationExportQuestionOptionsDtoFromJSON(json: any): ExaminationExportQuestionOptionsDto {
    return ExaminationExportQuestionOptionsDtoFromJSONTyped(json, false);
}

export function ExaminationExportQuestionOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportQuestionOptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeFromJSON(json['questionType']),
        'lesson': !exists(json, 'lesson') ? undefined : ExaminationExportLessonListDtoFromJSON(json['lesson']),
        'questionTypeName': !exists(json, 'questionTypeName') ? undefined : json['questionTypeName'],
        'questionBody': !exists(json, 'questionBody') ? undefined : json['questionBody'],
        'isSubjective': !exists(json, 'isSubjective') ? undefined : json['isSubjective'],
        'subQuestions': !exists(json, 'subQuestions') ? undefined : (json['subQuestions'] === null ? null : (json['subQuestions'] as Array<any>).map(ExaminationExportQuestionOptionsDtoFromJSON)),
        'options': !exists(json, 'options') ? undefined : json['options'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'displayAnswer': !exists(json, 'displayAnswer') ? undefined : json['displayAnswer'],
        'explain': !exists(json, 'explain') ? undefined : json['explain'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
    };
}

export function ExaminationExportQuestionOptionsDtoToJSON(value?: ExaminationExportQuestionOptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'questionType': QuestionTypeToJSON(value.questionType),
        'lesson': ExaminationExportLessonListDtoToJSON(value.lesson),
        'questionTypeName': value.questionTypeName,
        'questionBody': value.questionBody,
        'isSubjective': value.isSubjective,
        'subQuestions': value.subQuestions === undefined ? undefined : (value.subQuestions === null ? null : (value.subQuestions as Array<any>).map(ExaminationExportQuestionOptionsDtoToJSON)),
        'options': value.options,
        'answer': value.answer,
        'displayAnswer': value.displayAnswer,
        'explain': value.explain,
        'extra': value.extra,
        'stars': value.stars,
        'subjectId': value.subjectId,
        'gradeId': value.gradeId,
    };
}

