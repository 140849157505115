/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateTenantDto,
    CreateTenantDtoFromJSON,
    CreateTenantDtoToJSON,
    TenantDto,
    TenantDtoFromJSON,
    TenantDtoToJSON,
    TenantDtoPagedResultDto,
    TenantDtoPagedResultDtoFromJSON,
    TenantDtoPagedResultDtoToJSON,
} from '../models';

export interface ApiServicesAppTenantCreatePostRequest {
    createTenantDto?: CreateTenantDto;
}

export interface ApiServicesAppTenantDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppTenantGetAllGetRequest {
    keyword?: string;
    isActive?: boolean;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppTenantGetGetRequest {
    id?: number;
}

export interface ApiServicesAppTenantUpdatePutRequest {
    tenantDto?: TenantDto;
}

/**
 * 
 */
export class TenantApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppTenantCreatePostRaw(requestParameters: ApiServicesAppTenantCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Tenant/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTenantDtoToJSON(requestParameters.createTenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTenantCreatePost(requestParameters: ApiServicesAppTenantCreatePostRequest, initOverrides?: RequestInit): Promise<TenantDto> {
        const response = await this.apiServicesAppTenantCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTenantDeleteDeleteRaw(requestParameters: ApiServicesAppTenantDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Tenant/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppTenantDeleteDelete(requestParameters: ApiServicesAppTenantDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppTenantDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppTenantGetAllGetRaw(requestParameters: ApiServicesAppTenantGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TenantDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Tenant/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTenantGetAllGet(requestParameters: ApiServicesAppTenantGetAllGetRequest, initOverrides?: RequestInit): Promise<TenantDtoPagedResultDto> {
        const response = await this.apiServicesAppTenantGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTenantGetGetRaw(requestParameters: ApiServicesAppTenantGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TenantDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Tenant/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTenantGetGet(requestParameters: ApiServicesAppTenantGetGetRequest, initOverrides?: RequestInit): Promise<TenantDto> {
        const response = await this.apiServicesAppTenantGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppTenantUpdatePutRaw(requestParameters: ApiServicesAppTenantUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Tenant/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TenantDtoToJSON(requestParameters.tenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppTenantUpdatePut(requestParameters: ApiServicesAppTenantUpdatePutRequest, initOverrides?: RequestInit): Promise<TenantDto> {
        const response = await this.apiServicesAppTenantUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
