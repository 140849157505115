import { useTranslation } from "next-i18next";
import { FC, useEffect } from "react";
import BigGreenDot from '@/assets/images/auth/big_green_dot.svg';
import GreenGooey from '@/assets/images/auth/green_gooey.svg';
import GreenSlash from '@/assets/images/auth/green_slash.svg';
import clsx from "clsx";
import LoadingLottie from "@/components/common/Lottie/LoadingLottie";
import { useDebounce } from "usehooks-ts";

export type LoadingDialogResult<T> = {status:"success", data: T } |{ status:"error", error: any };
export type LoadingDialogModel<T> = {
    title: string,
    content: JSX.Element | string,
    ableToCancel?: boolean,
    task: () => Promise<T>,
}

export const LoadingDialog: FC<{ isOpen: boolean, onResult: (result: LoadingDialogResult<any>) => void, onCancel: () => void, model?: LoadingDialogModel<any> }> = (props) => {
  const { isOpen, onResult, onCancel, model } = props;
  const debouncedOpen = useDebounce(isOpen, 100);
  const { title, content, task, ableToCancel } = model ?? {};
  const { t } = useTranslation(["common"]);
  useEffect(() => {
    if (task && onResult) {
      task?.()?.then(it => { onResult({status:"success", data:it}); return it; })?.catch(it => { onResult({status:"error", error: it }); throw it; })
    }
  }, [task, onResult])
  return <>
    <div className={clsx("dui-modal dui-modal-bottom sm:dui-modal-middle", debouncedOpen && "dui-modal-open")}>
      {ableToCancel === true &&
                <div onClick={() => onCancel()} className="w-full h-full ">

                </div>
      }
      <div className="dui-modal-box  overflow-clip absolute ">
        <div className=" w-full h-full absolute">
          <BigGreenDot className="scale-[0.8] left-0 bottom-0 absolute opacity-30" />
          <GreenSlash className="scale-50 -translate-x-1/2 -translate-y-1/2 left-0 top-0 absolute opacity-50" />
          <BigGreenDot className="scale-50 right-0 bottom-0 absolute opacity-30" />
        </div>

        <div className="flex flex-col gap-4 z-10 relative text-center items-center prose">
          <h2 className="m-0">{title}</h2>
          <div className="">
            {content}
          </div>
          <LoadingLottie />


        </div>
      </div>
    </div>
  </>
}