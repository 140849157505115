/* tslint:disable */
/* eslint-disable */
export * from './AccountApi';
export * from './AccountConfirmApi';
export * from './AdminOrderApi';
export * from './AdminTicketApi';
export * from './AdvertisementApi';
export * from './AggregatesApi';
export * from './CaptchaApi';
export * from './CategoryApi';
export * from './ChildAccountApi';
export * from './ConfigurationApi';
export * from './ContentArticleApi';
export * from './ContentCategoryApi';
export * from './DashboardApi';
export * from './EmailTemplateApi';
export * from './ExportApi';
export * from './FAQCategoryApi';
export * from './FAQItemApi';
export * from './LessonApi';
export * from './LocationApi';
export * from './MemberApi';
export * from './OrderApi';
export * from './PaymentApi';
export * from './PermissionApi';
export * from './ProductApi';
export * from './ProgressApi';
export * from './QuestionApi';
export * from './RoleApi';
export * from './SchoolApi';
export * from './SchoolAdminApi';
export * from './SchoolStudentApi';
export * from './SchoolTeacherApi';
export * from './SessionApi';
export * from './SettingsApi';
export * from './SiteInfoApi';
export * from './StudentGroupApi';
export * from './StudentGroupTagApi';
export * from './SubjectiveAnswerApi';
export * from './TenantApi';
export * from './TestApi';
export * from './TokenAuthApi';
export * from './UploadApi';
export * from './UserApi';
export * from './UserEventApi';
export * from './UserTicketApi';
export * from './UserWalletApi';
