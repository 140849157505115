/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentListDto
 */
export interface PaymentListDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentListDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentListDto
     */
    logo?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PaymentListDto
     */
    configs?: { [key: string]: any; } | null;
}

export function PaymentListDtoFromJSON(json: any): PaymentListDto {
    return PaymentListDtoFromJSONTyped(json, false);
}

export function PaymentListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'configs': !exists(json, 'configs') ? undefined : json['configs'],
    };
}

export function PaymentListDtoToJSON(value?: PaymentListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'displayName': value.displayName,
        'logo': value.logo,
        'configs': value.configs,
    };
}

