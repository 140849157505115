/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectStatementDaily,
    SubjectStatementDailyFromJSON,
    SubjectStatementDailyFromJSONTyped,
    SubjectStatementDailyToJSON,
} from './SubjectStatementDaily';
import {
    SubjectStatementMonthly,
    SubjectStatementMonthlyFromJSON,
    SubjectStatementMonthlyFromJSONTyped,
    SubjectStatementMonthlyToJSON,
} from './SubjectStatementMonthly';
import {
    TimeSpan,
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';
import {
    TimeSpentDaily,
    TimeSpentDailyFromJSON,
    TimeSpentDailyFromJSONTyped,
    TimeSpentDailyToJSON,
} from './TimeSpentDaily';

/**
 * 
 * @export
 * @interface StudentStatments
 */
export interface StudentStatments {
    /**
     * 
     * @type {Date}
     * @memberof StudentStatments
     */
    lastLogonTime?: Date | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof StudentStatments
     */
    timeSpentToday?: TimeSpan;
    /**
     * 
     * @type {Array<SubjectStatementDaily>}
     * @memberof StudentStatments
     */
    subjectStatementsToday?: Array<SubjectStatementDaily> | null;
    /**
     * 
     * @type {Array<SubjectStatementDaily>}
     * @memberof StudentStatments
     */
    subjectStatementsYesterday?: Array<SubjectStatementDaily> | null;
    /**
     * 
     * @type {Array<SubjectStatementMonthly>}
     * @memberof StudentStatments
     */
    subjectStatementsThisMonth?: Array<SubjectStatementMonthly> | null;
    /**
     * 
     * @type {Array<TimeSpentDaily>}
     * @memberof StudentStatments
     */
    dailyTimeSpentOfYear?: Array<TimeSpentDaily> | null;
    /**
     * 
     * @type {number}
     * @memberof StudentStatments
     */
    unfinishedAssignmentCount?: number;
}

export function StudentStatmentsFromJSON(json: any): StudentStatments {
    return StudentStatmentsFromJSONTyped(json, false);
}

export function StudentStatmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentStatments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastLogonTime': !exists(json, 'lastLogonTime') ? undefined : (json['lastLogonTime'] === null ? null : new Date(json['lastLogonTime'])),
        'timeSpentToday': !exists(json, 'timeSpentToday') ? undefined : TimeSpanFromJSON(json['timeSpentToday']),
        'subjectStatementsToday': !exists(json, 'subjectStatementsToday') ? undefined : (json['subjectStatementsToday'] === null ? null : (json['subjectStatementsToday'] as Array<any>).map(SubjectStatementDailyFromJSON)),
        'subjectStatementsYesterday': !exists(json, 'subjectStatementsYesterday') ? undefined : (json['subjectStatementsYesterday'] === null ? null : (json['subjectStatementsYesterday'] as Array<any>).map(SubjectStatementDailyFromJSON)),
        'subjectStatementsThisMonth': !exists(json, 'subjectStatementsThisMonth') ? undefined : (json['subjectStatementsThisMonth'] === null ? null : (json['subjectStatementsThisMonth'] as Array<any>).map(SubjectStatementMonthlyFromJSON)),
        'dailyTimeSpentOfYear': !exists(json, 'dailyTimeSpentOfYear') ? undefined : (json['dailyTimeSpentOfYear'] === null ? null : (json['dailyTimeSpentOfYear'] as Array<any>).map(TimeSpentDailyFromJSON)),
        'unfinishedAssignmentCount': !exists(json, 'unfinishedAssignmentCount') ? undefined : json['unfinishedAssignmentCount'],
    };
}

export function StudentStatmentsToJSON(value?: StudentStatments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastLogonTime': value.lastLogonTime === undefined ? undefined : (value.lastLogonTime === null ? null : value.lastLogonTime.toISOString()),
        'timeSpentToday': TimeSpanToJSON(value.timeSpentToday),
        'subjectStatementsToday': value.subjectStatementsToday === undefined ? undefined : (value.subjectStatementsToday === null ? null : (value.subjectStatementsToday as Array<any>).map(SubjectStatementDailyToJSON)),
        'subjectStatementsYesterday': value.subjectStatementsYesterday === undefined ? undefined : (value.subjectStatementsYesterday === null ? null : (value.subjectStatementsYesterday as Array<any>).map(SubjectStatementDailyToJSON)),
        'subjectStatementsThisMonth': value.subjectStatementsThisMonth === undefined ? undefined : (value.subjectStatementsThisMonth === null ? null : (value.subjectStatementsThisMonth as Array<any>).map(SubjectStatementMonthlyToJSON)),
        'dailyTimeSpentOfYear': value.dailyTimeSpentOfYear === undefined ? undefined : (value.dailyTimeSpentOfYear === null ? null : (value.dailyTimeSpentOfYear as Array<any>).map(TimeSpentDailyToJSON)),
        'unfinishedAssignmentCount': value.unfinishedAssignmentCount,
    };
}

