/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssigneeScoreDto
 */
export interface AssigneeScoreDto {
    /**
     * 
     * @type {number}
     * @memberof AssigneeScoreDto
     */
    testId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssigneeScoreDto
     */
    score?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof AssigneeScoreDto
     */
    beginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AssigneeScoreDto
     */
    finishTime?: Date | null;
}

export function AssigneeScoreDtoFromJSON(json: any): AssigneeScoreDto {
    return AssigneeScoreDtoFromJSONTyped(json, false);
}

export function AssigneeScoreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssigneeScoreDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'beginTime': !exists(json, 'beginTime') ? undefined : (json['beginTime'] === null ? null : new Date(json['beginTime'])),
        'finishTime': !exists(json, 'finishTime') ? undefined : (json['finishTime'] === null ? null : new Date(json['finishTime'])),
    };
}

export function AssigneeScoreDtoToJSON(value?: AssigneeScoreDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'testId': value.testId,
        'score': value.score,
        'beginTime': value.beginTime === undefined ? undefined : (value.beginTime === null ? null : value.beginTime.toISOString()),
        'finishTime': value.finishTime === undefined ? undefined : (value.finishTime === null ? null : value.finishTime.toISOString()),
    };
}

