import React from 'react';

const LanguageIcon = (prop: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.568" height="23.568" viewBox="0 0 23.568 23.568" fill="currentColor" {...prop}>
      <path id="language" d="M22.917,494.867a.66.66,0,0,0-.045-.132,11.783,11.783,0,0,0-22.177,0,.658.658,0,0,0-.039.115,11.784,11.784,0,1,0,22.261.015m-15.132-5.8a12.666,12.666,0,0,0-2.417,5.275H2.3a10.51,10.51,0,0,1,5.486-5.275m-6.451,9.653a10.414,10.414,0,0,1,.454-3.044H5.116a20.8,20.8,0,0,0,0,6.072H1.783a10.412,10.412,0,0,1-.449-3.028m.957,4.362H5.364a12.68,12.68,0,0,0,2.42,5.291,10.51,10.51,0,0,1-5.493-5.291m8.825,6.006c-1.912-.453-3.558-2.815-4.364-6.006h4.364Zm0-7.34H6.474a18.569,18.569,0,0,1,0-6.072h4.64Zm0-7.406H6.757c.807-3.183,2.451-5.538,4.36-5.99Zm10.152,0H18.2a12.667,12.667,0,0,0-2.417-5.275,10.51,10.51,0,0,1,5.486,5.275m-8.818-5.99c1.909.452,3.553,2.807,4.36,5.99h-4.36Zm0,7.324h4.64a18.4,18.4,0,0,1,.251,3.044,18.4,18.4,0,0,1-.249,3.028H12.451Zm0,13.412v-6.006h4.364c-.805,3.192-2.452,5.554-4.364,6.006m3.332-.715a12.679,12.679,0,0,0,2.42-5.291h3.073a10.51,10.51,0,0,1-5.493,5.291m6-6.625h-3.33a20.465,20.465,0,0,0,.221-3.028,20.458,20.458,0,0,0-.224-3.044H21.78a10.44,10.44,0,0,1,0,6.072" transform="translate(0 -486.938)" fill="currentColor" />
    </svg>
  );
}

export default LanguageIcon;