/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectNameWithGradeDto,
    SubjectNameWithGradeDtoFromJSON,
    SubjectNameWithGradeDtoFromJSONTyped,
    SubjectNameWithGradeDtoToJSON,
} from './SubjectNameWithGradeDto';

/**
 * 
 * @export
 * @interface LessonNameWithSubjectDto
 */
export interface LessonNameWithSubjectDto {
    /**
     * 
     * @type {number}
     * @memberof LessonNameWithSubjectDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LessonNameWithSubjectDto
     */
    name?: string | null;
    /**
     * 
     * @type {SubjectNameWithGradeDto}
     * @memberof LessonNameWithSubjectDto
     */
    subject?: SubjectNameWithGradeDto;
}

export function LessonNameWithSubjectDtoFromJSON(json: any): LessonNameWithSubjectDto {
    return LessonNameWithSubjectDtoFromJSONTyped(json, false);
}

export function LessonNameWithSubjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonNameWithSubjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'subject': !exists(json, 'subject') ? undefined : SubjectNameWithGradeDtoFromJSON(json['subject']),
    };
}

export function LessonNameWithSubjectDtoToJSON(value?: LessonNameWithSubjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'subject': SubjectNameWithGradeDtoToJSON(value.subject),
    };
}

