/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessonNameDto,
    LessonNameDtoFromJSON,
    LessonNameDtoFromJSONTyped,
    LessonNameDtoToJSON,
} from './LessonNameDto';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface QuestionDetailDto
 */
export interface QuestionDetailDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionDetailDto
     */
    id?: number;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionDetailDto
     */
    questionType: QuestionType;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailDto
     */
    questionTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailDto
     */
    questionBody: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailDto
     */
    isSubjective?: boolean;
    /**
     * 
     * @type {any}
     * @memberof QuestionDetailDto
     */
    options?: any | null;
    /**
     * 
     * @type {any}
     * @memberof QuestionDetailDto
     */
    answer?: any | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailDto
     */
    displayAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailDto
     */
    explain?: string | null;
    /**
     * 
     * @type {any}
     * @memberof QuestionDetailDto
     */
    extra?: any | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionDetailDto
     */
    stars?: number;
    /**
     * 
     * @type {LessonNameDto}
     * @memberof QuestionDetailDto
     */
    lesson?: LessonNameDto;
}

export function QuestionDetailDtoFromJSON(json: any): QuestionDetailDto {
    return QuestionDetailDtoFromJSONTyped(json, false);
}

export function QuestionDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'questionType': QuestionTypeFromJSON(json['questionType']),
        'questionTypeName': !exists(json, 'questionTypeName') ? undefined : json['questionTypeName'],
        'questionBody': json['questionBody'],
        'isSubjective': !exists(json, 'isSubjective') ? undefined : json['isSubjective'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'displayAnswer': !exists(json, 'displayAnswer') ? undefined : json['displayAnswer'],
        'explain': !exists(json, 'explain') ? undefined : json['explain'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
        'lesson': !exists(json, 'lesson') ? undefined : LessonNameDtoFromJSON(json['lesson']),
    };
}

export function QuestionDetailDtoToJSON(value?: QuestionDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'questionType': QuestionTypeToJSON(value.questionType),
        'questionTypeName': value.questionTypeName,
        'questionBody': value.questionBody,
        'isSubjective': value.isSubjective,
        'options': value.options,
        'answer': value.answer,
        'displayAnswer': value.displayAnswer,
        'explain': value.explain,
        'extra': value.extra,
        'stars': value.stars,
        'lesson': LessonNameDtoToJSON(value.lesson),
    };
}

