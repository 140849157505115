/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantLoginInfoDto
 */
export interface TenantLoginInfoDto {
    /**
     * 
     * @type {number}
     * @memberof TenantLoginInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantLoginInfoDto
     */
    tenancyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantLoginInfoDto
     */
    name?: string | null;
}

export function TenantLoginInfoDtoFromJSON(json: any): TenantLoginInfoDto {
    return TenantLoginInfoDtoFromJSONTyped(json, false);
}

export function TenantLoginInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantLoginInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenancyName': !exists(json, 'tenancyName') ? undefined : json['tenancyName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function TenantLoginInfoDtoToJSON(value?: TenantLoginInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenancyName': value.tenancyName,
        'name': value.name,
    };
}

