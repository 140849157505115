import {Dispatch, FC, useRef} from "react"
import moment from "moment";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { createContext, PropsWithChildren, useCallback, useContext, useState, useEffect, useMemo, Provider, Context } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CategoryApi, ChildUserListDto, ExaminationExportDto, PracticeDto, SessionApi, SubjectListDto, SubjectQuestionTypeSettingDto, TestApi, TestQuestionDto, TestResultDetailDto, TestType } from "src/fetch";
import { useLocalForage, useLocalForageReducer } from "src/hooks/LocalForageHook";
import { useUserData } from "src/hooks/UserDataHook";
import {useWritingHook} from "src/hooks/question/WritingHook"
import { useDialog } from "src/hooks/dialog/DialogHook";
import Button from "@/components/common/Button/Button";
import { SubjectType } from "src/types/ApiTypes";
import { useDailyPractice } from "src/hooks/daily/DailyPracticeHook";
import { Socket, io } from "socket.io-client";
import { useSocket } from "../SocketHook";
import {XMarkIcon as XIcon, XMarkIcon} from "@heroicons/react/24/outline"
import useLayout from "src/views/Layout/LayoutHook";
import ConfirmIcon from "public/icons/practice/confirmation-graphic.svg" 
export const SubjectTypeList = [SubjectType.Chinese, SubjectType.English, SubjectType.Maths]
export const prettySubjectType: ["chinese", "maths", "english"] = ["chinese", "maths", "english"];
export type PrettySubjectType = typeof prettySubjectType[number];
export type PracticeProgress = number | "not_started" | "reviewing" | "submitting" | "submitted" | "parent_review";
export type PracticeContextStatus = "loading" | "loading_daily" | "error" | "ready";
export const subjectNumberToSubjectType = (subjectNumber: number | null): PrettySubjectType|null => subjectNumber!=null?prettySubjectType[Math.max(0, Math.min(prettySubjectType.length, subjectNumber))]:null;

export type PracticePatch= WritePracticePatch | ReadPracticePatch
export type WritePracticePatch= {
  target:  "begin" | "discard" | "review"
} | {
  target: "answer",
  questionIdx: number,
  subQuestionIdx?:number,
  answer: string,
  jumpToNextQuestion?:boolean,
}
export type ReadPracticePatch={
  target: "changeQuestion",
  questionIdx: "next" | "prev" | number,
}

export type WritePracticeContext =  {
  function:{"write":true, "read"?: any,"edit"?:any }
  /**
   * Create Daily Practice with subject, create a new practice if not exist.
   * load the practice from local forage if still valid.
   * @deprecated no more daily practice
   */
  createDailyPractice: (subject: PrettySubjectType) => Promise<void>,
  /**
   * Create practice with the lesson id
   */
  createPractice: (lessonId: number) => Promise<void>,


  patchPractice: (patch: WritePracticePatch)=>Promise<any>,

  /**
   * submit the current practice result
   */
  completePractice: () => Promise<void>,
  status:PracticeContextStatus
}
export type ReadPracticeContext = {
  function:{"read":true, "write"?: any, "edit"?:any }

  /**
   * Load practice from server with the test id
   */
  loadPractice: (testId: number) => Promise<void>,

  /**
   * The current question index from local forage.
   */
  currentPracticeProcess: PracticeProgress | null,
  /**
   * The current ongoing practice
   */
  currentPracticeSubjectType: SubjectType | null,
  /**
   * The current ongoing practice
   */
  currentPractice: TestResultDetailDto | null,

  /**
   * The current displaying question 
   */
  currentQuestion: TestQuestionDto | null,


  patchPractice: (patch: ReadPracticePatch)=>Promise<any>,

  status:PracticeContextStatus
}



export type ExportPracticeContext={
  function:{"read": true,"edit"?:true, "write"?:any},

  status?:PracticeContextStatus

  currentPractice?: ExaminationExportDto | null,
  setIsShowAnswerPreview ?: React.Dispatch<React.SetStateAction<boolean>>,
  isShowAnswerPreview?:boolean,
  studentList?:ChildUserListDto[],
  symbolList?:SubjectQuestionTypeSettingDto[]
}

export const PracticeContext = createContext<(WritePracticeContext  |  ReadPracticeContext | ExportPracticeContext ) | null>(null);

type PracticeReducerAction ={
  type:"replaceAnswer",
  payload: {
    questions: {
      testerAnswer?:string | null,
      subTestQuestions?:{
        testerAnswer?:string | null,
      }[]
    }[]
  }
} | {
  type:"replace",
  payload: TestResultDetailDto | null
}  | {
  type:  "begin",
  beginTime: Date
} | {
  type:  "discard"
} | {
  type: "answer",
  questionIdx: number,
  subQuestionIdx?:number,
  answer: string,
}
export function practiceReducer(state: TestResultDetailDto | null, action: PracticeReducerAction): TestResultDetailDto | null | undefined{
  if(action.type==="replace")
    return action.payload
  if(!state)
    return;
  switch(action.type){
  case "begin":
    state.beginTime = action.beginTime
    return 
  case "discard":
    return null
  case "answer":
    if(!state) return null
    if(!action.subQuestionIdx && typeof action.subQuestionIdx !== "number"){
      //no sub question idx
      const answered = state.questions?.at(action.questionIdx ?? -1);
      if(answered && answered.testerAnswer != action.answer)
        answered.testerAnswer = action.answer;
    }else{
      const answered = state.questions?.at(action.questionIdx ?? -1);
      const subQuestion = answered?.subTestQuestions?.find(it => it.id == action.subQuestionIdx);
      if (answered && subQuestion && answered.testerAnswer != action.answer) {
        subQuestion.testerAnswer = action.answer;
      }
    }
    return ;
  case "replaceAnswer":
    if(!state) return null
    action.payload.questions?.forEach((question, questionIdx) => {
      if(!question)
        return;
      const answered = state.questions?.at(questionIdx ?? -1);
      if(!answered)
        return;
      answered.testerAnswer = question.testerAnswer;
      question.subTestQuestions?.forEach((subQuestion, subQuestionIdx) => {
        const subAnswered = answered.subTestQuestions?.at(subQuestionIdx ?? -1);
        if(!subAnswered)
          return;
        subAnswered.testerAnswer = subQuestion.testerAnswer;
      });
    });
    return;
  default:
    throw `Unknown action type ${(action as any).type}`
  
  }
}

export const PracticeSubmitDialog: FC<{
  onResult: (result:boolean) => void;
  onCancel?: () => void ;
}> = (props) => {
  const {onResult,onCancel ,}=props
  const { t } = useTranslation(["common","question"]);

  return (
    <div className=" self-center w-full  p-8 ">
      <div>
        <ConfirmIcon className="w-32 h-32 lg:w-52 lg:h-52 mx-auto" />
        <h2 className="m-0 my-6 text-2xl text-gray-500 "> {t("question:confirmSubmit")}</h2>
        <div className="  text-primary-500  w-full  flex flex-row items-center justify-center gap-8">
          <div className="text-2xl  text-gray-400 cursor-pointer hover:text-gray-400/80 "
            onClick={onCancel}>{t("common:cancel")}</div>
          <Button className="rounded-3xl  px-6 " onClick={()=>onResult(true)}>
            <span className="text-2xl">{t("common:enter")}</span></Button>
        </div>
      </div>
    </div>
  );
};



export  const SubjectiveSubmitDialog: FC<{
  onResult: (result:boolean) => void;
  onCancel?: () => void ;
}> = (props) => {
  const {onResult,onCancel}=props
  const { t } = useTranslation(["common","question"]);

  return (
    <div className=" self-center w-full  p-8 ">
      <div >close</div>
      <div>
        <h2 className="m-0 mb-8 text-lg "> {t("question:waitingForTeacherReview")}</h2>
        <div className="  text-primary-500  w-full ">
          <Button className="text-lg" onClick={()=>onResult(true)}>{t("common:enter")}</Button>
        </div>
      </div>
    </div>
  );
};

export const PracticeProvider = (props: PropsWithChildren<{ dailyPracticeMode?: boolean, loadTestId?: number }>) => {
  const { dailyPracticeMode, loadTestId } = props;
  const { accessToken, userId, userInfo, getApiConfig } = useUserData();
  const {dailyPracticeMeta, updateProgress} = useDailyPractice();
  const {askYesNo ,showCustomDialog} = useDialog();
  const router = useRouter();
  const {socket} = useSocket();
  const { t } = useTranslation(["common"]);

  const { getFiles,uploadImageFilesApi } = useWritingHook()
  const {updateBridge ,setUpdateBridge}=useLayout()
  const [status, setStatus] = useState<PracticeContextStatus>(dailyPracticeMode ? "ready" : "loading");
  // const [dailyProcess, setDailyProcess] = useLocalForage<{ [key in PrettySubjectType]?: PracticeProgress } | null>("dailyProcess", null);

  // const [dailyPractices, setDailyPractices] = useLocalForage<{ [key in PrettySubjectType]?: TestResultDetailDto } | null>("dailyPractice", null);
  const [currentPractice, dispatchCurrentPractice, deleteCurrentPractice, currentPracticeLoaded] = useLocalForageReducer(dailyPracticeMode?"currentDailyPractice":"currentPractice", practiceReducer, null as TestResultDetailDto | null);
  const [currentPracticeProcess, setCurrentPracticeProcess] = useLocalForage<PracticeProgress | null>(dailyPracticeMode?"currentDailtPracticeProcess":"currentPracticeProcess", 0);

  const currentQuestion = useMemo(() => currentPractice?.questions?.at(+(currentPracticeProcess ?? -1)) ?? null, [currentPractice, currentPracticeProcess]);
  const currentPracticeSubjectType = useMemo(() => (currentPractice?.subject?.subjectType as any as SubjectType) ?? null, [currentPractice]);

  const dispatchCurrentPracticeWithRemote:Dispatch<PracticeReducerAction> = (value: PracticeReducerAction)=>{
    dispatchCurrentPractice(value);
    if(socket && value.type!=="replace"){
      (async()=>socket.emit("reducePractice", {...value, pid: currentPractice?.id}))()
    }
  }
  const setCurrentPracticeProcessWithRemote = (meta: PracticeProgress | null)=>{
    setCurrentPracticeProcess(meta);
    const practiceMeta = dailyPracticeMeta?.[currentPracticeSubjectType];
    const matchedDailyPractice = (practiceMeta && practiceMeta?.id === currentPractice?.id)
    if(matchedDailyPractice && meta !="parent_review" && meta)
      updateProgress(currentPracticeSubjectType, meta);
    if(socket && currentPractice?.id){
      (async()=>socket.emit("metaUpdate", {
        meta, 
        pid: currentPractice?.id, 
        ...(dailyPracticeMode?{"dailyDate":moment().format("YYYY-MM-DD"), "subject":subjectNumberToSubjectType(currentPracticeSubjectType)}:{})
      }))()
    }
  }

  const discardPractice = async () => {
    dispatchCurrentPracticeWithRemote({type:"discard"});
    setCurrentPracticeProcessWithRemote(null);
  }
  const createDailyPractice = async (subject: PrettySubjectType) => {
    // if (userId) {
    //   if (currentPractice && currentPracticeSubjectType) {
    //     setDailyPractices({ ...dailyPractices, [currentPracticeSubjectType]: currentPractice });
    //   }
    //   let result: PracticeDto | null = null;
    //   if (dailyPractices?.[subject]) {
    //     result = dailyPractices?.[subject] ?? null;
    //   } else if (subjectQuery.isSuccess) {
    //     const targetSubject = getTargetSubject(subject);
    //     if (!targetSubject)
    //       throw new Error("no subject found");
    //     result = await new TestApi(getApiConfig()).apiServicesAppTestCreateDailyPracticePostRaw({
    //       createDailyPracticeInput: {
    //         subjectId: targetSubject.id ?? 0,
    //         date: new Date(),
    //         userId: userId ?? 0,
    //         testType: TestType.NUMBER_2
    //       }
    //     }).Convert();
    //   }
    //   if (result) {
    //     if (dailyPractices?.[subject] !== result)
    //       setDailyPractices({ ...dailyPractices, [subject]: result });
    //     dispatchCurrentPractice({type:"replace", payload: result});
    //     //setCurrentPracticeProcess(-1);
    //     // const latestQuestionIndex = result?.questions?.findIndex(it => it.testerAnswer != null && it.testerAnswer.length > 0);
    //     setCurrentPracticeProcess(dailyProcess?.[subject] ?? "not_started");
    //   } else {
    //     toast.error("create daily practice failed");
    //   }
    // } else {
    //   toast.error(t("common:error.not_login"));
    // }
  }

  const createPractice = async (lessonId: number) => {
    const result = await new TestApi(getApiConfig()).apiServicesAppTestCreatePracticePostRaw({
      createPracticeInput: {
        lessonId: lessonId
      }
    }).Convert();
    if (result) {
      dispatchCurrentPracticeWithRemote({type:"replace", payload: result});
      setCurrentPracticeProcessWithRemote("not_started");
    } else {
      toast.error(t("common:error.unknown"));
    }
  }

  const beginPractice = async () => {
    const practiceMeta = (dailyPracticeMeta as any)?.[currentPracticeSubjectType];
    const matchedDailyPractice = (practiceMeta && practiceMeta?.id === currentPractice?.id)
    if (currentPractice) {
      const result = await new TestApi(getApiConfig()).apiServicesAppTestBeginTestPostRaw({
        testIdInput: {
          testId: currentPractice.id,
        }
      });
      if (result.raw.ok) {
        dispatchCurrentPracticeWithRemote({type:"begin", beginTime: new Date()});
        setCurrentPracticeProcessWithRemote(0);
        if(matchedDailyPractice)
          updateProgress(currentPracticeSubjectType as any, 0);
      } else {
        toast.error((await result.raw.json())?.message ?? t("common:error.unknown"));
      }
    } else {
      toast.error(t("common:error.unknown"));
    }
  }
  const loadPractice = async (testId: number) => {
    const result = await new TestApi(getApiConfig()).apiServicesAppTestGetTestResultDetailGetRaw({
      testId
    }).Convert();

    const latestQuestionIndex = Math.max(0, result?.questions?.findIndex(it => it.testerAnswer != null && it.testerAnswer.length > 0) ?? 0);
    const remoteProcess = result == null ? "not_started" :
      result?.finishTime != null ? "submitted" :
        result?.beginTime !== null ? latestQuestionIndex : "not_started";
    if (result && (currentPractice?.id !== result.id || (currentPractice?.id === result.id && remoteProcess === "submitted"))) {
      dispatchCurrentPracticeWithRemote({type:"replace", payload: result});
      setCurrentPracticeProcess(remoteProcess ?? "not_started");
    } else if (!result){
      throw `failed to load practice with id ${testId}`
    }
  }

  const nextQuestion = () => {
    setCurrentPracticeProcessWithRemote(Math.min(currentPractice?.questions?.length ?? 0, +(currentPracticeProcess ?? 0) + 1));
  }

  const previousQuestion = () => {
    setCurrentPracticeProcessWithRemote(Math.max(0, +(currentPracticeProcess ?? 0) - 1));
  }

  const changeQuestion = (questionIndex: number) => {
    setCurrentPracticeProcessWithRemote(Math.min(currentPractice?.questions?.length ?? 0, Math.max(0, questionIndex)));
  }

  const completePractice = async () => {
    
    const practiceMeta = (dailyPracticeMeta as any)?.[currentPracticeSubjectType];
    const matchedDailyPractice = (practiceMeta && practiceMeta?.id === currentPractice?.id)

    // if (currentPractice?.questions?.every(it => it.testerAnswer 
    //             || (it.subTestQuestions && it.subTestQuestions.length >0&& it.subTestQuestions.every(st=>st.testerAnswer))
    // )) {     
    if(currentPractice?.questions?.every(it=>it?.question?.isSubjective)){
      submitSubjectiveHandle()
    }else {
      setCurrentPracticeProcessWithRemote("submitting");
      if(matchedDailyPractice)
        updateProgress(currentPracticeSubjectType as any, "submitting");
      const result= await completeApiHandle()
      if(result.raw.ok){
        setCurrentPracticeProcessWithRemote("submitted");
        if(matchedDailyPractice)
          updateProgress(currentPracticeSubjectType as any, "submitted");
      }
      await  callTestResult()
    }
    setUpdateBridge&&setUpdateBridge(true)        
    // } else {
    //   //TODO: not answered all
    //   toast.warn("not all questions answered");
    // }
  }

  const reviewPractice = async () => {

    if (currentPractice){
      const notNullAnswer=   currentPractice.questions?.every((it)=>{
        if(it.subTestQuestions&&it.subTestQuestions.length){
          return it.subTestQuestions.every(subItem=> subItem.testerAnswer!==null)
        }else return  it.testerAnswer!==null
      })
      const result = await showCustomDialog({
        title: "",
        Content: PracticeSubmitDialog,
        contentProps: {},
        ableToCancel: true,
        titleClass:""
      });
      if(result.status=="success"){
        await completePractice();
      }
    }
  }

  const submitSubjectiveHandle= async()=>{
    if(currentPractice?.questions?.every(it=>it?.question?.questionType==7 )){
      await writingCompleteHandle()
    }
  }

  const completeApiHandle= async(payload: PracticeDto |null =null)=>{
    const api = new TestApi(getApiConfig());
    try {
      if( !(payload || (currentPractice as PracticeDto )))
        return;

      const result = await api.apiServicesAppTestSubmitPracticePostRaw({
        practiceDto: (payload || (currentPractice as PracticeDto ))
      });
      return result
       
    } catch (err: any) {
      toast.error((await err?.json())?.error?.message ?? t("common:error.unknown"));
      setCurrentPracticeProcessWithRemote("submitted");
      return err
    }
  }

  const callTestResult= async()=>{
    const api = new TestApi(getApiConfig());
    const testResult = await api.apiServicesAppTestGetTestResultDetailGetRaw({ testId: currentPractice?.id }).Convert();
    if (testResult)
      dispatchCurrentPracticeWithRemote({type:"replace", payload: testResult});
  }

  const writingCompleteHandle = async()=>{
    const payload={}
    let warning=true
    const testerAnswer=  JSON.parse(currentPractice?.questions?.[0]?.testerAnswer??"null")
    if(testerAnswer.inputContent){
      const inputContent=testerAnswer.inputContent
      const textCurrentLength=inputContent.trim().replaceAll("\n", "").replace(/\s+/g, "").length;
      if(textCurrentLength>300*2){
        toast.error(t("common:error.wordCountExceeded"))
      }else if(textCurrentLength< 300*.8){
        toast.error(t("common:error.wordCountNotEnough"))
      }else{
        warning=false
        Object.assign(payload,{
          ...currentPractice,
          questions: [{...currentPractice?.questions?.[0], testerAnswer: JSON.stringify({inputContent: inputContent}) }]
        })
      }
    }else  if(testerAnswer.files&& testerAnswer.files.length>0){
      const files=await getFiles();
      if(files.length>0){
        const payloadFiles= files.map((it) => {
          return testerAnswer.files.includes(it.key) && it.value;
        })
        const newFile= await uploadImageFilesApi(payloadFiles)
        Object.assign(payload,{
          ...currentPractice,
          questions: [{...currentPractice?.questions?.[0], testerAnswer: JSON.stringify({files: newFile}) }]
        })
        warning=false
      }
    }
    if(!warning){
      const result=  await completeApiHandle(payload)
      if(result.raw.ok){
        const result = await showCustomDialog({
          title: t("common:subjectiveSubmitTitle"),
          Content: SubjectiveSubmitDialog,
          contentProps: {},
          ableToCancel: false,
          titleClass:"text-5xl text-primary-500  font-bold "
        });
        if (result) {
          router.replace(`/${router.locale}/student`);
          
        }
      }
    } 
  }
  
  // useEffect(() => {
  //     if (dailyPractices && currentPractice && +(currentPracticeProcess ?? -1) >= 0) {

  //         let matchedDailyPractice = Object.entries(dailyPractices).find(it => it[1].id === currentPractice?.id);
  //         if (matchedDailyPractice) {
  //             setDailyProcess({ ...dailyProcess ?? {}, [matchedDailyPractice[0]]: currentPracticeProcess });
  //         }
  //     }

  // }, [currentPractice])
  // useEffect(() => {
  //   const practiceMeta = (dailyPracticeMeta as any)?.[currentPracticeSubjectType];
  //   if (practiceMeta && practiceMeta?.id === currentPractice?.id && currentPracticeProcess && practiceMeta.progress != currentPracticeProcess)
  //     updateProgress(currentPracticeSubjectType as any, currentPracticeProcess);
  // }, [currentPractice?.id, currentPracticeProcess, currentPracticeSubjectType, dailyPracticeMeta, updateProgress])


  // useEffect(() => {
  //   if (userInfo && dailyPracticeMode && preloadedDailyPractices) {
  //     const filteredDailyPractices = Object.entries(preloadedDailyPractices).filter(it => moment((it[1] as TestResultDetailDto)?.creationTime).local().isSame(moment(), 'day'))
  //       .reduce((pre, it) => { pre[it[0] as PrettySubjectType] = it[1]; return pre; }, {} as { [key in PrettySubjectType]?: TestResultDetailDto });
  //     const updatedSubjects = prettySubjectType
  //       .filter(it => 
  //         !dailyPractices?.[it] || (
  //           (
  //             dailyPractices?.[it]?.id != filteredDailyPractices?.[it]?.id ||
  //                           (dailyPractices?.[it] as TestResultDetailDto)?.finishTime != filteredDailyPractices?.[it]?.finishTime ||
  //                           (dailyPractices?.[it] as TestResultDetailDto)?.beginTime != filteredDailyPractices?.[it]?.beginTime 
  //           )
  //                   &&
  //                   !(
  //                     (dailyPractices?.[it] as TestResultDetailDto)?.creationTime &&
  //                   moment((dailyPractices?.[it] as TestResultDetailDto)?.creationTime).local().isSame(moment(), 'day') 
  //                   )
  //         )
  //       )
  //       .map(it => it)

  //     console.debug("updating subject: ", updatedSubjects);
  //     const remoteTodayPractices = filteredDailyPractices ?? {};
  //     console.debug("dailyPractices: ", dailyPractices);
  //     console.debug("remoteTodayPractices: ", remoteTodayPractices);
  //     const newDailyPractices = ({
  //       chinese: updatedSubjects.includes("chinese") ? remoteTodayPractices?.chinese : dailyPractices?.chinese,
  //       english: updatedSubjects.includes("english") ? remoteTodayPractices?.english : dailyPractices?.english,
  //       maths: updatedSubjects.includes("maths") ? remoteTodayPractices?.maths : dailyPractices?.maths,
  //     });
  //     setDailyPractices(newDailyPractices);
  //     console.debug("remoteTodayPractices: ", remoteTodayPractices);
  //     console.debug("dailyPractices: ", dailyPractices);
  //     console.debug("newDailyPractices: ", newDailyPractices);
  //     const updatedProcess = { ...dailyProcess }
  //     prettySubjectType.forEach(it => {
  //       const latestQuestionIndex = Math.max(0, newDailyPractices[it]?.questions?.findIndex(it => it.testerAnswer != null && it.testerAnswer.length > 0) ?? 0);
  //       updatedProcess[it] = newDailyPractices?.[it] == null ? "not_started" :
  //         newDailyPractices?.[it]?.finishTime != null ? "submitted" :
  //           newDailyPractices?.[it]?.beginTime != null ? latestQuestionIndex :
  //             "not_started";
  //     });
  //     console.debug("updating subject: ", updatedProcess);
  //     setDailyProcess(updatedProcess);
  //     if (currentPractice && !Object.entries(newDailyPractices).find(it => it[1]?.id === currentPractice?.id) && currentPracticeSubjectType) {
  //       dispatchCurrentPractice({type:"replace", payload: remoteTodayPractices[currentPracticeSubjectType] ?? null})
  //       setCurrentPracticeProcess(updatedProcess[currentPracticeSubjectType] ?? null);
  //     }
  //     setStatus("ready");
  //   }
  // }, [userId, dailyPracticeMode, preloadedDailyPractices]);

  useEffect(() => {
    const func = async () => {
      if (currentPracticeLoaded&& (loadTestId != null || loadTestId != undefined)) {
        setStatus("loading");
        await loadPractice(loadTestId);
        setStatus("ready");
      }
    }
    if (userId && currentPracticeLoaded && (loadTestId != null || loadTestId != undefined)) {
      func().catch(err => {
        console.error(err);
        // router.push("/error")
      });
    }
  }, [userId, loadTestId, currentPracticeLoaded]);
  useEffect(() => {
    if(currentPracticeLoaded && dailyPracticeMode && currentPractice && !moment(currentPractice.creationTime).utcOffset(8).isSame(moment(), 'day')){
      deleteCurrentPractice();
      setCurrentPracticeProcess(null);
    }
  }, [dailyPracticeMode,currentPractice, currentPracticeLoaded]);
  
  const patchPractice = async (patch: PracticePatch)=>{
    switch( patch.target){
    case "changeQuestion":
      if(patch.questionIdx=="next")
        return nextQuestion();
      if(patch.questionIdx =="prev")
        return previousQuestion();
      return changeQuestion(patch.questionIdx);
    case "begin":
      return await beginPractice();
    case "discard":
      return await discardPractice();
    case "review":
      return await reviewPractice();
    case "answer":{
      const {target, ...rest} = patch;
      return dispatchCurrentPracticeWithRemote({type:"answer", ...rest});
    }
    }
  }
  // useEffect(()=>{
  //   if(!ioclientRef.current)
  //     return ()=>{};
  //   ioclientRef.current.on("refreshPractice", (ev)=>{
  //     ioclientRef.current!.emit("reducePractice", {type:"replace", payload: currentPractice})
  //   })
  //   return ()=>ioclientRef.current?.removeListener("refreshPractice");
  // }, [currentPractice])
    
  useEffect(():any => {
    if(!socket || !currentPractice?.id)
      return;

    const events = {
      connect:()=>{
        socket.emit("joinPractice",currentPractice?.id);
      },
      roomResult:(message : any) => {
        if(message.success && message.pid==currentPractice?.id){
          socket.emit("refreshPractice",currentPractice?.id);
          socket.emit("touchPractice",{
            pid: currentPractice?.id, 
            ...(dailyPracticeMode?{"dailyDate":moment().format("YYYY-MM-DD"), "subject":subjectNumberToSubjectType(currentPracticeSubjectType)}:{})
          });
        }
      }, 
      reducePractice: (message : any) => {
        if(message.pid==currentPractice?.id)
          dispatchCurrentPractice(message);
      },
      metaUpdate: (message: any)=>{
        if(message.pid==currentPractice?.id)
          setCurrentPracticeProcess(message.meta);
      }
    }
    Object.entries(events).forEach(([key, value]) => {
      socket.on(key, value);
    });
    if(!currentPractice?.finishTime)
      socket.emit("joinPractice",currentPractice?.id);
    
    // socket disconnet onUnmount if exists
    if (socket) return () => {
      console.debug("dropping socket listeners", currentPractice?.id);
      Object.entries(events).forEach(([key, value]) => {
        socket.removeListener(key, value);
      });
      socket.emit("leavePractice",currentPractice?.id);
    }
  }, [currentPractice?.id, socket]);

  return <PracticeContext.Provider value={{
    function:{
      "read":true,
      "write":true
    },
    createDailyPractice,
    createPractice,
    loadPractice,
    currentPracticeSubjectType,
    currentPractice,
    currentPracticeProcess,
    currentQuestion,
    completePractice,
    patchPractice,
    status

  }}>
    {props.children}
  </PracticeContext.Provider>
}

export const usePractice = () => {
  const ctx = useContext(PracticeContext);
  if(!ctx)
    throw new Error("usePractice must be used within a PracticeProvider");
  if(!ctx.function?.write)
    throw new Error("usePractice must be used within a PracticeProvider with write function");
  if(!ctx.function?.read)
    throw new Error("usePractice must be used within a PracticeProvider with read function");
  return ctx as ReadPracticeContext & WritePracticeContext & ExportPracticeContext;
}