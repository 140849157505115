import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Image from "next/legacy/image"
import clsx from 'clsx';
import Button from '@/components/common/Button/Button';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import Header, { i18nHeaderNamespace } from '@/components/common/Header/Header';
import Footer from '@/components/common/Footer/Footer';
import Head from 'next/head';
import { DataCategory, SiteInfo } from 'src/utils/SiteInfo';
import { Resources } from 'next-i18next';
import { PracticePopup } from '@/components/practicePopup/PracticePopup';

export interface CommonTemplateProps {
    pageTitle?: string;
    siteInfo?: SiteInfo;
    dataCategory?: DataCategory;
    showFreeTryButton?: boolean;
    snap?: boolean
    variant?: 'default' | 'stalker'
}

export const i18nCommonTemplateNamespace: Array<keyof Resources> = [...i18nHeaderNamespace];
export const CommonTemplate = (props: PropsWithChildren<CommonTemplateProps>) => {
  const { pageTitle, children, siteInfo, dataCategory, showFreeTryButton, variant } = props;

  return (    <div className='flex flex-col w-full h-full min-h-screen bg-gray-300 transition-all bg-opacity-0 data-[variant=stalker]:bg-opacity-100' data-variant={variant}>

    {/* TODO: replace with next-seo instead */}
    <Head>
      <title>{pageTitle ? pageTitle : "StudyLu E-Learning Platform"}</title>
      <link rel="shortcut icon" href="/favicon.ico" />
    </Head>
    <div id="top" />
    <div className='relative  w-full  prose max-w-none z-10 grow '>
      <Header  />
      <div className="container mx-auto mt-[100px] max-w-6xl px-1 sm:px-4 ">
        {children ?? <></>}
      </div>
    </div>
    <Footer siteInfo={siteInfo} dataCategory={dataCategory} snap={props.snap} />
    {/* {background()} */}
    {/* <div className={clsx("fixed  right-[25px] z-20",  "block" , showFreeTryButton ? "bottom-40" : "bottom-10")}>
      <Button aria-label='Scroll-to-Top Button' variant="roundedWhite" className={clsx("m-1 shadow-lg")} onClick={() => { window.scrollTo({top:0,behavior:"smooth"}); }} >
        <ChevronUpIcon />
      </Button>
    </div> */}
  </div>);
}