/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CurrentUserWalletDto,
    CurrentUserWalletDtoFromJSON,
    CurrentUserWalletDtoToJSON,
    ModifyBalanceDto,
    ModifyBalanceDtoFromJSON,
    ModifyBalanceDtoToJSON,
    UserCoinHistoryDto,
    UserCoinHistoryDtoFromJSON,
    UserCoinHistoryDtoToJSON,
} from '../models';

export interface ApiServicesAppUserWalletChangeGradePostRequest {
    oldId?: number;
    newId?: number;
}

export interface ApiServicesAppUserWalletGetUserWalletsGetRequest {
    userId?: number;
}

export interface ApiServicesAppUserWalletGetWalletGetRequest {
    walletId?: number;
}

export interface ApiServicesAppUserWalletUpdateWalletPutRequest {
    modifyBalanceDto?: ModifyBalanceDto;
}

/**
 * 
 */
export class UserWalletApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppUserWalletChangeGradePostRaw(requestParameters: ApiServicesAppUserWalletChangeGradePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CurrentUserWalletDto>>> {
        const queryParameters: any = {};

        if (requestParameters.oldId !== undefined) {
            queryParameters['oldId'] = requestParameters.oldId;
        }

        if (requestParameters.newId !== undefined) {
            queryParameters['newId'] = requestParameters.newId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserWallet/ChangeGrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrentUserWalletDtoFromJSON));
    }

    /**
     */
    async apiServicesAppUserWalletChangeGradePost(requestParameters: ApiServicesAppUserWalletChangeGradePostRequest, initOverrides?: RequestInit): Promise<Array<CurrentUserWalletDto>> {
        const response = await this.apiServicesAppUserWalletChangeGradePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserWalletGetCurrentUserWalletsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CurrentUserWalletDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserWallet/GetCurrentUserWallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrentUserWalletDtoFromJSON));
    }

    /**
     */
    async apiServicesAppUserWalletGetCurrentUserWalletsGet(initOverrides?: RequestInit): Promise<Array<CurrentUserWalletDto>> {
        const response = await this.apiServicesAppUserWalletGetCurrentUserWalletsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserWalletGetUserCoinHistoryGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserCoinHistoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserWallet/GetUserCoinHistory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserCoinHistoryDtoFromJSON));
    }

    /**
     */
    async apiServicesAppUserWalletGetUserCoinHistoryGet(initOverrides?: RequestInit): Promise<Array<UserCoinHistoryDto>> {
        const response = await this.apiServicesAppUserWalletGetUserCoinHistoryGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserWalletGetUserWalletsGetRaw(requestParameters: ApiServicesAppUserWalletGetUserWalletsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CurrentUserWalletDto>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserWallet/GetUserWallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrentUserWalletDtoFromJSON));
    }

    /**
     */
    async apiServicesAppUserWalletGetUserWalletsGet(requestParameters: ApiServicesAppUserWalletGetUserWalletsGetRequest, initOverrides?: RequestInit): Promise<Array<CurrentUserWalletDto>> {
        const response = await this.apiServicesAppUserWalletGetUserWalletsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserWalletGetWalletGetRaw(requestParameters: ApiServicesAppUserWalletGetWalletGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CurrentUserWalletDto>> {
        const queryParameters: any = {};

        if (requestParameters.walletId !== undefined) {
            queryParameters['walletId'] = requestParameters.walletId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserWallet/GetWallet`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentUserWalletDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserWalletGetWalletGet(requestParameters: ApiServicesAppUserWalletGetWalletGetRequest, initOverrides?: RequestInit): Promise<CurrentUserWalletDto> {
        const response = await this.apiServicesAppUserWalletGetWalletGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserWalletUpdateWalletPutRaw(requestParameters: ApiServicesAppUserWalletUpdateWalletPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/UserWallet/UpdateWallet`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyBalanceDtoToJSON(requestParameters.modifyBalanceDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserWalletUpdateWalletPut(requestParameters: ApiServicesAppUserWalletUpdateWalletPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserWalletUpdateWalletPutRaw(requestParameters, initOverrides);
    }

}
