var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Sbtn9B91rk6EkNC7igTjs"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment:process.env.RUNTIME_ENV || process.env.NODE_ENV,
  disabled: process.env.NODE_ENV === 'development',
  dsn: SENTRY_DSN || 'https://70fab161a3fd410d8fef6de33c5767ed@sentry.cooltechsol.com/2',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate:  (process.env.NODE_ENV !== 'production' ||process.env.RUNTIME_ENV !== 'production'  )? 1.0:0.1,
  integrations: [
    new CaptureConsole({
      levels: ['error']
    })
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
