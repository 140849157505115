/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserCoinReasonType,
    UserCoinReasonTypeFromJSON,
    UserCoinReasonTypeFromJSONTyped,
    UserCoinReasonTypeToJSON,
} from './UserCoinReasonType';

/**
 * 
 * @export
 * @interface UserCoinHistory
 */
export interface UserCoinHistory {
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistory
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistory
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistory
     */
    amount?: number;
    /**
     * 
     * @type {UserCoinReasonType}
     * @memberof UserCoinHistory
     */
    userCoinReasonType?: UserCoinReasonType;
    /**
     * 
     * @type {string}
     * @memberof UserCoinHistory
     */
    reasonId: string;
    /**
     * 
     * @type {string}
     * @memberof UserCoinHistory
     */
    remark: string;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistory
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UserCoinHistory
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistory
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UserCoinHistory
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserCoinHistory
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UserCoinHistory
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCoinHistory
     */
    isDeleted?: boolean;
}

export function UserCoinHistoryFromJSON(json: any): UserCoinHistory {
    return UserCoinHistoryFromJSONTyped(json, false);
}

export function UserCoinHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCoinHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'userCoinReasonType': !exists(json, 'userCoinReasonType') ? undefined : UserCoinReasonTypeFromJSON(json['userCoinReasonType']),
        'reasonId': json['reasonId'],
        'remark': json['remark'],
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function UserCoinHistoryToJSON(value?: UserCoinHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'amount': value.amount,
        'userCoinReasonType': UserCoinReasonTypeToJSON(value.userCoinReasonType),
        'reasonId': value.reasonId,
        'remark': value.remark,
        'creatorUserId': value.creatorUserId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
    };
}

