/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    normalizedName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    grantedPermissions?: Array<string> | null;
}

export function CreateRoleDtoFromJSON(json: any): CreateRoleDto {
    return CreateRoleDtoFromJSONTyped(json, false);
}

export function CreateRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayName': json['displayName'],
        'normalizedName': !exists(json, 'normalizedName') ? undefined : json['normalizedName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'grantedPermissions': !exists(json, 'grantedPermissions') ? undefined : json['grantedPermissions'],
    };
}

export function CreateRoleDtoToJSON(value?: CreateRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'normalizedName': value.normalizedName,
        'description': value.description,
        'grantedPermissions': value.grantedPermissions,
    };
}

