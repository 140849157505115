import { AtomEffect } from "recoil";
import localforage from 'localforage';
import { isClient } from "src/utils/next-helper";

export function localforageEffect<T>(key:string):AtomEffect<T>{
  return ({setSelf, onSet, trigger}) => {
    if(!isClient())
      return;
    localforage.ready(err=>{
      if(err){
        console.warn("localforage not ready", err);
      }else{
        const loadPersisted = async () => {
          const savedValue = await localforage.getItem<T>(key);
    
          if (savedValue != null) {
            setSelf(savedValue);
          }
        };
    
        // Asynchronously set the persisted data
        if (trigger === 'get') {
          loadPersisted();
        }
    
        // Subscribe to state changes and persist them to localForage
        onSet((newValue, _, isReset) => {
          isReset
            ? localforage.removeItem(key)
            : localforage.setItem(key,newValue);
        });
      }
    })
  };
}