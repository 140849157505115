import React from "react";
import { useLocalForage } from "../LocalForageHook";
import localforage from "localforage";

//hook
import { useUserData } from "src/hooks/UserDataHook";

//api
import { UploadApi } from "src/fetch";
import { isArray } from "lodash";


export  type FileObjType = {
    key:string;
    value:File
};

interface ReturnType {
  saveFiles: (files: File[]|string[]) => void;
  getFiles: () =>Promise <FileObjType[]>;
  cleanFiles:()=>void;

  uploadImageFilesApi:(files:File[])=>Promise<any[]>
}

export const useWritingHook = (): ReturnType => {

  const { userId, userInfo, getApiConfig } = useUserData();


  const saveFiles = async (files: File[]|string[]) => {
  
    const saveArr =
      (await files?.map((file, index) => {
        return file instanceof Blob&& {
          key:file.name,
          value: file,
        };
      })) ?? [];
    localforage.setItem("writingFile", saveArr);
  };



  const getFiles  = async() => {
    return localforage
      .getItem("writingFile")
      .then((value) => {
        if( isArray(value)) return value
        else return [];
      }).catch((err)=>{
        console.log(err);
        return []
      })
  };


  const cleanFiles=()=>{
    localforage.removeItem("writingFile")

  }



  const uploadImageFilesApi=async(files:File[])=>{
    const promise =await Promise.all(files.map(async(fileItem)=>{
      try {
        const result = await new UploadApi(getApiConfig()).apiUploadImageUploadPostRaw({
          file:fileItem
        }).Convert();
        return result
      } catch (error) {
        console.log(error);
      }
    }) )
    return promise
    //apiUploadImageUploadPost
    //updata answer 
  }







  return { saveFiles, getFiles,uploadImageFilesApi ,cleanFiles};
};
