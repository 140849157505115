/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddTicketCommentInput
 */
export interface AddTicketCommentInput {
    /**
     * 
     * @type {number}
     * @memberof AddTicketCommentInput
     */
    ticketId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddTicketCommentInput
     */
    message: string;
}

export function AddTicketCommentInputFromJSON(json: any): AddTicketCommentInput {
    return AddTicketCommentInputFromJSONTyped(json, false);
}

export function AddTicketCommentInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddTicketCommentInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'message': json['message'],
    };
}

export function AddTicketCommentInputToJSON(value?: AddTicketCommentInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticketId': value.ticketId,
        'message': value.message,
    };
}

