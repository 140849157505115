/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FAQItemTranslationDto,
    FAQItemTranslationDtoFromJSON,
    FAQItemTranslationDtoFromJSONTyped,
    FAQItemTranslationDtoToJSON,
} from './FAQItemTranslationDto';

/**
 * 
 * @export
 * @interface FAQItemDto
 */
export interface FAQItemDto {
    /**
     * 
     * @type {number}
     * @memberof FAQItemDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FAQItemDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FAQItemDto
     */
    sort?: number;
    /**
     * 
     * @type {Array<FAQItemTranslationDto>}
     * @memberof FAQItemDto
     */
    translations?: Array<FAQItemTranslationDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FAQItemDto
     */
    faqCategoryId?: number;
    /**
     * 
     * @type {Date}
     * @memberof FAQItemDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof FAQItemDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FAQItemDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FAQItemDto
     */
    lastModificationTime?: Date | null;
}

export function FAQItemDtoFromJSON(json: any): FAQItemDto {
    return FAQItemDtoFromJSONTyped(json, false);
}

export function FAQItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(FAQItemTranslationDtoFromJSON)),
        'faqCategoryId': !exists(json, 'faqCategoryId') ? undefined : json['faqCategoryId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function FAQItemDtoToJSON(value?: FAQItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'sort': value.sort,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(FAQItemTranslationDtoToJSON)),
        'faqCategoryId': value.faqCategoryId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

