/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PropertyAttributes {
    NUMBER_0 = 0,
    NUMBER_512 = 512,
    NUMBER_1024 = 1024,
    NUMBER_4096 = 4096,
    NUMBER_8192 = 8192,
    NUMBER_16384 = 16384,
    NUMBER_32768 = 32768,
    NUMBER_62464 = 62464
}

export function PropertyAttributesFromJSON(json: any): PropertyAttributes {
    return PropertyAttributesFromJSONTyped(json, false);
}

export function PropertyAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyAttributes {
    return json as PropertyAttributes;
}

export function PropertyAttributesToJSON(value?: PropertyAttributes | null): any {
    return value as any;
}

