/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchoolTransationDto,
    SchoolTransationDtoFromJSON,
    SchoolTransationDtoFromJSONTyped,
    SchoolTransationDtoToJSON,
} from './SchoolTransationDto';

/**
 * 
 * @export
 * @interface SchoolDetailDto
 */
export interface SchoolDetailDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolDetailDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolDetailDto
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDetailDto
     */
    schoolPhone: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDetailDto
     */
    schoolCode: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDetailDto
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolDetailDto
     */
    contactEmail: string;
    /**
     * 
     * @type {Array<SchoolTransationDto>}
     * @memberof SchoolDetailDto
     */
    translations: Array<SchoolTransationDto>;
}

export function SchoolDetailDtoFromJSON(json: any): SchoolDetailDto {
    return SchoolDetailDtoFromJSONTyped(json, false);
}

export function SchoolDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'logo': json['logo'],
        'schoolPhone': json['schoolPhone'],
        'schoolCode': json['schoolCode'],
        'contactPhone': json['contactPhone'],
        'contactEmail': json['contactEmail'],
        'translations': ((json['translations'] as Array<any>).map(SchoolTransationDtoFromJSON)),
    };
}

export function SchoolDetailDtoToJSON(value?: SchoolDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentId': value.parentId,
        'logo': value.logo,
        'schoolPhone': value.schoolPhone,
        'schoolCode': value.schoolCode,
        'contactPhone': value.contactPhone,
        'contactEmail': value.contactEmail,
        'translations': ((value.translations as Array<any>).map(SchoolTransationDtoToJSON)),
    };
}

