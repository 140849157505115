/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminLessonListDto,
    AdminLessonListDtoFromJSON,
    AdminLessonListDtoFromJSONTyped,
    AdminLessonListDtoToJSON,
} from './AdminLessonListDto';

/**
 * 
 * @export
 * @interface AdminLessonListDtoListResultDto
 */
export interface AdminLessonListDtoListResultDto {
    /**
     * 
     * @type {Array<AdminLessonListDto>}
     * @memberof AdminLessonListDtoListResultDto
     */
    items?: Array<AdminLessonListDto> | null;
}

export function AdminLessonListDtoListResultDtoFromJSON(json: any): AdminLessonListDtoListResultDto {
    return AdminLessonListDtoListResultDtoFromJSONTyped(json, false);
}

export function AdminLessonListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminLessonListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(AdminLessonListDtoFromJSON)),
    };
}

export function AdminLessonListDtoListResultDtoToJSON(value?: AdminLessonListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(AdminLessonListDtoToJSON)),
    };
}

