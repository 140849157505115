/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroup,
    StudentGroupFromJSON,
    StudentGroupFromJSONTyped,
    StudentGroupToJSON,
} from './StudentGroup';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SchoolStudent
 */
export interface SchoolStudent {
    /**
     * 
     * @type {number}
     * @memberof SchoolStudent
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SchoolStudent
     */
    studentGroupId?: number;
    /**
     * 
     * @type {StudentGroup}
     * @memberof SchoolStudent
     */
    studentGroup?: StudentGroup;
    /**
     * 
     * @type {number}
     * @memberof SchoolStudent
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof SchoolStudent
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof SchoolStudent
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SchoolStudent
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof SchoolStudent
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SchoolStudent
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SchoolStudent
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SchoolStudent
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof SchoolStudent
     */
    isDeleted?: boolean;
}

export function SchoolStudentFromJSON(json: any): SchoolStudent {
    return SchoolStudentFromJSONTyped(json, false);
}

export function SchoolStudentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolStudent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'studentGroupId': !exists(json, 'studentGroupId') ? undefined : json['studentGroupId'],
        'studentGroup': !exists(json, 'studentGroup') ? undefined : StudentGroupFromJSON(json['studentGroup']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function SchoolStudentToJSON(value?: SchoolStudent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'studentGroupId': value.studentGroupId,
        'studentGroup': StudentGroupToJSON(value.studentGroup),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'creatorUserId': value.creatorUserId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
    };
}

