/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectIncludeGradeDto,
    SubjectIncludeGradeDtoFromJSON,
    SubjectIncludeGradeDtoFromJSONTyped,
    SubjectIncludeGradeDtoToJSON,
} from './SubjectIncludeGradeDto';
import {
    TestResultTestQuestionDto,
    TestResultTestQuestionDtoFromJSON,
    TestResultTestQuestionDtoFromJSONTyped,
    TestResultTestQuestionDtoToJSON,
} from './TestResultTestQuestionDto';
import {
    TestType,
    TestTypeFromJSON,
    TestTypeFromJSONTyped,
    TestTypeToJSON,
} from './TestType';

/**
 * 
 * @export
 * @interface DailyPracticeDetailDto
 */
export interface DailyPracticeDetailDto {
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyPracticeDetailDto
     */
    testName?: string | null;
    /**
     * 
     * @type {TestType}
     * @memberof DailyPracticeDetailDto
     */
    testType?: TestType;
    /**
     * 
     * @type {string}
     * @memberof DailyPracticeDetailDto
     */
    testTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DailyPracticeDetailDto
     */
    testerName?: string | null;
    /**
     * 
     * @type {SubjectIncludeGradeDto}
     * @memberof DailyPracticeDetailDto
     */
    subject?: SubjectIncludeGradeDto;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    questionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    score?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    totalPoints?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DailyPracticeDetailDto
     */
    readonly date?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DailyPracticeDetailDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    testTimeLimit?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DailyPracticeDetailDto
     */
    beginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DailyPracticeDetailDto
     */
    finishTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    subjectiveCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    subjectiveScoredCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyPracticeDetailDto
     */
    readonly timeUsed?: number;
    /**
     * 
     * @type {Array<TestResultTestQuestionDto>}
     * @memberof DailyPracticeDetailDto
     */
    questions?: Array<TestResultTestQuestionDto> | null;
}

export function DailyPracticeDetailDtoFromJSON(json: any): DailyPracticeDetailDto {
    return DailyPracticeDetailDtoFromJSONTyped(json, false);
}

export function DailyPracticeDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyPracticeDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'testName': !exists(json, 'testName') ? undefined : json['testName'],
        'testType': !exists(json, 'testType') ? undefined : TestTypeFromJSON(json['testType']),
        'testTypeName': !exists(json, 'testTypeName') ? undefined : json['testTypeName'],
        'testerName': !exists(json, 'testerName') ? undefined : json['testerName'],
        'subject': !exists(json, 'subject') ? undefined : SubjectIncludeGradeDtoFromJSON(json['subject']),
        'questionCount': !exists(json, 'questionCount') ? undefined : json['questionCount'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'testTimeLimit': !exists(json, 'testTimeLimit') ? undefined : json['testTimeLimit'],
        'beginTime': !exists(json, 'beginTime') ? undefined : (json['beginTime'] === null ? null : new Date(json['beginTime'])),
        'finishTime': !exists(json, 'finishTime') ? undefined : (json['finishTime'] === null ? null : new Date(json['finishTime'])),
        'subjectiveCount': !exists(json, 'subjectiveCount') ? undefined : json['subjectiveCount'],
        'subjectiveScoredCount': !exists(json, 'subjectiveScoredCount') ? undefined : json['subjectiveScoredCount'],
        'timeUsed': !exists(json, 'timeUsed') ? undefined : json['timeUsed'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(TestResultTestQuestionDtoFromJSON)),
    };
}

export function DailyPracticeDetailDtoToJSON(value?: DailyPracticeDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'testName': value.testName,
        'testType': TestTypeToJSON(value.testType),
        'testTypeName': value.testTypeName,
        'testerName': value.testerName,
        'subject': SubjectIncludeGradeDtoToJSON(value.subject),
        'questionCount': value.questionCount,
        'score': value.score,
        'totalPoints': value.totalPoints,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'testTimeLimit': value.testTimeLimit,
        'beginTime': value.beginTime === undefined ? undefined : (value.beginTime === null ? null : value.beginTime.toISOString()),
        'finishTime': value.finishTime === undefined ? undefined : (value.finishTime === null ? null : value.finishTime.toISOString()),
        'subjectiveCount': value.subjectiveCount,
        'subjectiveScoredCount': value.subjectiveScoredCount,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(TestResultTestQuestionDtoToJSON)),
    };
}

