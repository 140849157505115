/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchoolTeacherSelectListDto
 */
export interface SchoolTeacherSelectListDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolTeacherSelectListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolTeacherSelectListDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolTeacherSelectListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolTeacherSelectListDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolTeacherSelectListDto
     */
    emailAddress?: string | null;
}

export function SchoolTeacherSelectListDtoFromJSON(json: any): SchoolTeacherSelectListDto {
    return SchoolTeacherSelectListDtoFromJSONTyped(json, false);
}

export function SchoolTeacherSelectListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolTeacherSelectListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
    };
}

export function SchoolTeacherSelectListDtoToJSON(value?: SchoolTeacherSelectListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
    };
}

