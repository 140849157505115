/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTicketCommentInput,
    AddTicketCommentInputFromJSON,
    AddTicketCommentInputToJSON,
    UserAddTicketInput,
    UserAddTicketInputFromJSON,
    UserAddTicketInputToJSON,
    UserTicketDto,
    UserTicketDtoFromJSON,
    UserTicketDtoToJSON,
    UserTicketListDtoPagedResultDto,
    UserTicketListDtoPagedResultDtoFromJSON,
    UserTicketListDtoPagedResultDtoToJSON,
} from '../models';

export interface ApiServicesAppUserTicketAddPostRequest {
    userAddTicketInput?: UserAddTicketInput;
}

export interface ApiServicesAppUserTicketCommentPostRequest {
    addTicketCommentInput?: AddTicketCommentInput;
}

export interface ApiServicesAppUserTicketDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppUserTicketGetAllGetRequest {
    keyword?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppUserTicketGetGetRequest {
    id?: number;
}

export interface ApiServicesAppUserTicketUpdatePutRequest {
    userAddTicketInput?: UserAddTicketInput;
}

/**
 * 
 */
export class UserTicketApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppUserTicketAddPostRaw(requestParameters: ApiServicesAppUserTicketAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/UserTicket/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAddTicketInputToJSON(requestParameters.userAddTicketInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserTicketAddPost(requestParameters: ApiServicesAppUserTicketAddPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserTicketAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserTicketCommentPostRaw(requestParameters: ApiServicesAppUserTicketCommentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/UserTicket/Comment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTicketCommentInputToJSON(requestParameters.addTicketCommentInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserTicketCommentPost(requestParameters: ApiServicesAppUserTicketCommentPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserTicketCommentPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserTicketDeleteDeleteRaw(requestParameters: ApiServicesAppUserTicketDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserTicket/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserTicketDeleteDelete(requestParameters: ApiServicesAppUserTicketDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserTicketDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppUserTicketGetAllGetRaw(requestParameters: ApiServicesAppUserTicketGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserTicketListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserTicket/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTicketListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserTicketGetAllGet(requestParameters: ApiServicesAppUserTicketGetAllGetRequest, initOverrides?: RequestInit): Promise<UserTicketListDtoPagedResultDto> {
        const response = await this.apiServicesAppUserTicketGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserTicketGetGetRaw(requestParameters: ApiServicesAppUserTicketGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserTicketDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/UserTicket/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTicketDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppUserTicketGetGet(requestParameters: ApiServicesAppUserTicketGetGetRequest, initOverrides?: RequestInit): Promise<UserTicketDto> {
        const response = await this.apiServicesAppUserTicketGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppUserTicketUpdatePutRaw(requestParameters: ApiServicesAppUserTicketUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/UserTicket/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserAddTicketInputToJSON(requestParameters.userAddTicketInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppUserTicketUpdatePut(requestParameters: ApiServicesAppUserTicketUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppUserTicketUpdatePutRaw(requestParameters, initOverrides);
    }

}
