import React, { useState } from 'react';
import clsx from 'clsx';
import css from './Header.module.css';
import Image from "next/legacy/image";
import Button from '../Button/Button';
import CombinedLink from '../CombinedLink/CombinedLink';

const DropDownMenuMobile = (props: { url: string, Icon: (prop: React.SVGProps<SVGSVGElement>) => JSX.Element, title: string, Items?: JSX.Element[], handleOnClick: () => void }) => {
  const { url, Icon, title, Items, handleOnClick } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (<>
    <div className={clsx("relative")}>
      <CombinedLink href={url}>
        <Button aria-label='Button' variant='white' className={clsx("block border-l border-r border-b w-full h-[120px] hover:bg-primary-500 text-primary-500 hover:text-white", isOpen && `${css.arrowBottom} bg-primary-500 text-white border-primary-500`)}
          onClick={() => { handleOnClick(); setIsOpen(!isOpen); }}>
          <Icon className={clsx("w-10 h-10 mb-2 m-auto inline-block")} /> {title}
        </Button>
      </CombinedLink>
    </div>
    <div className={clsx("text-center border-l border-r pt-7 pb-7", isOpen ? "block" : "hidden")}>
      {
        Items?.map(it => it)
      }
    </div>
  </>)
}

export default DropDownMenuMobile;