/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectQuestionTypeSettingDto,
    SubjectQuestionTypeSettingDtoFromJSON,
    SubjectQuestionTypeSettingDtoFromJSONTyped,
    SubjectQuestionTypeSettingDtoToJSON,
} from './SubjectQuestionTypeSettingDto';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';

/**
 * 
 * @export
 * @interface SubjectListDto
 */
export interface SubjectListDto {
    /**
     * 
     * @type {number}
     * @memberof SubjectListDto
     */
    id?: number;
    /**
     * 
     * @type {SubjectType}
     * @memberof SubjectListDto
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof SubjectListDto
     */
    readonly subjectTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectListDto
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectListDto
     */
    sort?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectListDto
     */
    term?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectListDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<SubjectQuestionTypeSettingDto>}
     * @memberof SubjectListDto
     */
    questionTypeSettings?: Array<SubjectQuestionTypeSettingDto> | null;
}

export function SubjectListDtoFromJSON(json: any): SubjectListDto {
    return SubjectListDtoFromJSONTyped(json, false);
}

export function SubjectListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'subjectTypeName': !exists(json, 'subjectTypeName') ? undefined : json['subjectTypeName'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'questionTypeSettings': !exists(json, 'questionTypeSettings') ? undefined : (json['questionTypeSettings'] === null ? null : (json['questionTypeSettings'] as Array<any>).map(SubjectQuestionTypeSettingDtoFromJSON)),
    };
}

export function SubjectListDtoToJSON(value?: SubjectListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'icon': value.icon,
        'sort': value.sort,
        'term': value.term,
        'name': value.name,
        'description': value.description,
        'questionTypeSettings': value.questionTypeSettings === undefined ? undefined : (value.questionTypeSettings === null ? null : (value.questionTypeSettings as Array<any>).map(SubjectQuestionTypeSettingDtoToJSON)),
    };
}

