/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubQuestionListDto,
    SubQuestionListDtoFromJSON,
    SubQuestionListDtoFromJSONTyped,
    SubQuestionListDtoToJSON,
} from './SubQuestionListDto';

/**
 * 
 * @export
 * @interface SubQuestionListDtoListResultDto
 */
export interface SubQuestionListDtoListResultDto {
    /**
     * 
     * @type {Array<SubQuestionListDto>}
     * @memberof SubQuestionListDtoListResultDto
     */
    items?: Array<SubQuestionListDto> | null;
}

export function SubQuestionListDtoListResultDtoFromJSON(json: any): SubQuestionListDtoListResultDto {
    return SubQuestionListDtoListResultDtoFromJSONTyped(json, false);
}

export function SubQuestionListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubQuestionListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(SubQuestionListDtoFromJSON)),
    };
}

export function SubQuestionListDtoListResultDtoToJSON(value?: SubQuestionListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(SubQuestionListDtoToJSON)),
    };
}

