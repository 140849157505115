/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailTemplateListDto
 */
export interface EmailTemplateListDto {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateListDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateListDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateListDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EmailTemplateListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailTemplateListDto
     */
    lastModificationTime?: Date | null;
}

export function EmailTemplateListDtoFromJSON(json: any): EmailTemplateListDto {
    return EmailTemplateListDtoFromJSONTyped(json, false);
}

export function EmailTemplateListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplateListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function EmailTemplateListDtoToJSON(value?: EmailTemplateListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'subject': value.subject,
        'description': value.description,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

