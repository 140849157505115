/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderIntent,
    OrderIntentFromJSON,
    OrderIntentFromJSONTyped,
    OrderIntentToJSON,
} from './OrderIntent';
import {
    OrderItemListDto,
    OrderItemListDtoFromJSON,
    OrderItemListDtoFromJSONTyped,
    OrderItemListDtoToJSON,
} from './OrderItemListDto';
import {
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import {
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
} from './SubscriptionStatus';

/**
 * 
 * @export
 * @interface OrderListDto
 */
export interface OrderListDto {
    /**
     * 
     * @type {number}
     * @memberof OrderListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderListDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderListDto
     */
    transactionId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderListDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderListDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListDto
     */
    remark?: string | null;
    /**
     * 
     * @type {OrderIntent}
     * @memberof OrderListDto
     */
    orderIntent?: OrderIntent;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderListDto
     */
    status?: OrderStatus;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof OrderListDto
     */
    subscriptionStatus?: SubscriptionStatus;
    /**
     * 
     * @type {Array<OrderItemListDto>}
     * @memberof OrderListDto
     */
    items?: Array<OrderItemListDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListDto
     */
    gateway?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderListDto
     */
    externalTransactionId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderListDto
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderListDto
     */
    readonly guaranteeUtil?: Date | null;
}

export function OrderListDtoFromJSON(json: any): OrderListDto {
    return OrderListDtoFromJSONTyped(json, false);
}

export function OrderListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'remark': !exists(json, 'remark') ? undefined : json['remark'],
        'orderIntent': !exists(json, 'orderIntent') ? undefined : OrderIntentFromJSON(json['orderIntent']),
        'status': !exists(json, 'status') ? undefined : OrderStatusFromJSON(json['status']),
        'subscriptionStatus': !exists(json, 'subscriptionStatus') ? undefined : SubscriptionStatusFromJSON(json['subscriptionStatus']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(OrderItemListDtoFromJSON)),
        'gateway': !exists(json, 'gateway') ? undefined : json['gateway'],
        'externalTransactionId': !exists(json, 'externalTransactionId') ? undefined : json['externalTransactionId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'guaranteeUtil': !exists(json, 'guaranteeUtil') ? undefined : (json['guaranteeUtil'] === null ? null : new Date(json['guaranteeUtil'])),
    };
}

export function OrderListDtoToJSON(value?: OrderListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'transactionId': value.transactionId,
        'amount': value.amount,
        'currency': value.currency,
        'remark': value.remark,
        'orderIntent': OrderIntentToJSON(value.orderIntent),
        'status': OrderStatusToJSON(value.status),
        'subscriptionStatus': SubscriptionStatusToJSON(value.subscriptionStatus),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(OrderItemListDtoToJSON)),
        'gateway': value.gateway,
        'externalTransactionId': value.externalTransactionId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

