/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordInput
 */
export interface ResetPasswordInput {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    captchaResponse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    password?: string | null;
}

export function ResetPasswordInputFromJSON(json: any): ResetPasswordInput {
    return ResetPasswordInputFromJSONTyped(json, false);
}

export function ResetPasswordInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'captchaResponse': !exists(json, 'captchaResponse') ? undefined : json['captchaResponse'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function ResetPasswordInputToJSON(value?: ResetPasswordInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'captchaResponse': value.captchaResponse,
        'password': value.password,
    };
}

