/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationType,
    ExaminationTypeFromJSON,
    ExaminationTypeFromJSONTyped,
    ExaminationTypeToJSON,
} from './ExaminationType';

/**
 * 
 * @export
 * @interface LessonTestRangeDto
 */
export interface LessonTestRangeDto {
    /**
     * 
     * @type {number}
     * @memberof LessonTestRangeDto
     */
    id?: number;
    /**
     * 
     * @type {ExaminationType}
     * @memberof LessonTestRangeDto
     */
    examinationType?: ExaminationType;
}

export function LessonTestRangeDtoFromJSON(json: any): LessonTestRangeDto {
    return LessonTestRangeDtoFromJSONTyped(json, false);
}

export function LessonTestRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonTestRangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationType': !exists(json, 'examinationType') ? undefined : ExaminationTypeFromJSON(json['examinationType']),
    };
}

export function LessonTestRangeDtoToJSON(value?: LessonTestRangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationType': ExaminationTypeToJSON(value.examinationType),
    };
}

