/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    QuestionAnswerDto,
    QuestionAnswerDtoFromJSON,
    QuestionAnswerDtoToJSON,
    QuestionAnswerListDtoPagedResultDto,
    QuestionAnswerListDtoPagedResultDtoFromJSON,
    QuestionAnswerListDtoPagedResultDtoToJSON,
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeToJSON,
    SubjectiveAnswerDtoPagedResultDto,
    SubjectiveAnswerDtoPagedResultDtoFromJSON,
    SubjectiveAnswerDtoPagedResultDtoToJSON,
} from '../models';

export interface ApiServicesAppSubjectiveAnswerCancelUpdatePostRequest {
    subjectiveAnswerId?: number;
}

export interface ApiServicesAppSubjectiveAnswerGetAllClosedGetRequest {
    gradeCode?: string;
    subjectType?: SubjectType;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSubjectiveAnswerGetAllGetRequest {
    gradeCode?: string;
    subjectType?: SubjectType;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSubjectiveAnswerGetGetRequest {
    subjectiveAnswerId?: number;
}

export interface ApiServicesAppSubjectiveAnswerGetMineGetRequest {
    subjectType?: Array<SubjectType>;
    userId?: number;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSubjectiveAnswerUpdatePutRequest {
    questionAnswerDto?: QuestionAnswerDto;
}

/**
 * 
 */
export class SubjectiveAnswerApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSubjectiveAnswerCancelUpdatePostRaw(requestParameters: ApiServicesAppSubjectiveAnswerCancelUpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.subjectiveAnswerId !== undefined) {
            queryParameters['subjectiveAnswerId'] = requestParameters.subjectiveAnswerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SubjectiveAnswer/CancelUpdate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerCancelUpdatePost(requestParameters: ApiServicesAppSubjectiveAnswerCancelUpdatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSubjectiveAnswerCancelUpdatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetAllClosedGetRaw(requestParameters: ApiServicesAppSubjectiveAnswerGetAllClosedGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionAnswerListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeCode !== undefined) {
            queryParameters['GradeCode'] = requestParameters.gradeCode;
        }

        if (requestParameters.subjectType !== undefined) {
            queryParameters['SubjectType'] = requestParameters.subjectType;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SubjectiveAnswer/GetAllClosed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionAnswerListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetAllClosedGet(requestParameters: ApiServicesAppSubjectiveAnswerGetAllClosedGetRequest, initOverrides?: RequestInit): Promise<QuestionAnswerListDtoPagedResultDto> {
        const response = await this.apiServicesAppSubjectiveAnswerGetAllClosedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetAllGetRaw(requestParameters: ApiServicesAppSubjectiveAnswerGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectiveAnswerDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.gradeCode !== undefined) {
            queryParameters['GradeCode'] = requestParameters.gradeCode;
        }

        if (requestParameters.subjectType !== undefined) {
            queryParameters['SubjectType'] = requestParameters.subjectType;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SubjectiveAnswer/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectiveAnswerDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetAllGet(requestParameters: ApiServicesAppSubjectiveAnswerGetAllGetRequest, initOverrides?: RequestInit): Promise<SubjectiveAnswerDtoPagedResultDto> {
        const response = await this.apiServicesAppSubjectiveAnswerGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetGetRaw(requestParameters: ApiServicesAppSubjectiveAnswerGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionAnswerDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectiveAnswerId !== undefined) {
            queryParameters['subjectiveAnswerId'] = requestParameters.subjectiveAnswerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SubjectiveAnswer/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionAnswerDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetGet(requestParameters: ApiServicesAppSubjectiveAnswerGetGetRequest, initOverrides?: RequestInit): Promise<QuestionAnswerDto> {
        const response = await this.apiServicesAppSubjectiveAnswerGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetMineGetRaw(requestParameters: ApiServicesAppSubjectiveAnswerGetMineGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuestionAnswerListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.subjectType) {
            queryParameters['SubjectType'] = requestParameters.subjectType;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SubjectiveAnswer/GetMine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionAnswerListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerGetMineGet(requestParameters: ApiServicesAppSubjectiveAnswerGetMineGetRequest, initOverrides?: RequestInit): Promise<QuestionAnswerListDtoPagedResultDto> {
        const response = await this.apiServicesAppSubjectiveAnswerGetMineGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerUpdatePutRaw(requestParameters: ApiServicesAppSubjectiveAnswerUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SubjectiveAnswer/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionAnswerDtoToJSON(requestParameters.questionAnswerDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSubjectiveAnswerUpdatePut(requestParameters: ApiServicesAppSubjectiveAnswerUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSubjectiveAnswerUpdatePutRaw(requestParameters, initOverrides);
    }

}
