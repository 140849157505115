/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationType,
    ExaminationTypeFromJSON,
    ExaminationTypeFromJSONTyped,
    ExaminationTypeToJSON,
} from './ExaminationType';
import {
    TestQuestionDto,
    TestQuestionDtoFromJSON,
    TestQuestionDtoFromJSONTyped,
    TestQuestionDtoToJSON,
} from './TestQuestionDto';

/**
 * 
 * @export
 * @interface ExaminationDto
 */
export interface ExaminationDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationDto
     */
    testName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationDto
     */
    testTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationDto
     */
    questionTypes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationDto
     */
    questionCount?: number;
    /**
     * 
     * @type {ExaminationType}
     * @memberof ExaminationDto
     */
    examinationType?: ExaminationType;
    /**
     * 
     * @type {number}
     * @memberof ExaminationDto
     */
    testTimeLimit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationDto
     */
    totalPoints?: number | null;
    /**
     * 
     * @type {Array<TestQuestionDto>}
     * @memberof ExaminationDto
     */
    questions?: Array<TestQuestionDto> | null;
}

export function ExaminationDtoFromJSON(json: any): ExaminationDto {
    return ExaminationDtoFromJSONTyped(json, false);
}

export function ExaminationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'testName': !exists(json, 'testName') ? undefined : json['testName'],
        'testTypeName': !exists(json, 'testTypeName') ? undefined : json['testTypeName'],
        'questionTypes': !exists(json, 'questionTypes') ? undefined : json['questionTypes'],
        'questionCount': !exists(json, 'questionCount') ? undefined : json['questionCount'],
        'examinationType': !exists(json, 'examinationType') ? undefined : ExaminationTypeFromJSON(json['examinationType']),
        'testTimeLimit': !exists(json, 'testTimeLimit') ? undefined : json['testTimeLimit'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(TestQuestionDtoFromJSON)),
    };
}

export function ExaminationDtoToJSON(value?: ExaminationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'testName': value.testName,
        'testTypeName': value.testTypeName,
        'questionTypes': value.questionTypes,
        'questionCount': value.questionCount,
        'examinationType': ExaminationTypeToJSON(value.examinationType),
        'testTimeLimit': value.testTimeLimit,
        'totalPoints': value.totalPoints,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(TestQuestionDtoToJSON)),
    };
}

