/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExport,
    ExaminationExportFromJSON,
    ExaminationExportFromJSONTyped,
    ExaminationExportToJSON,
} from './ExaminationExport';
import {
    ExaminationExportQuestion,
    ExaminationExportQuestionFromJSON,
    ExaminationExportQuestionFromJSONTyped,
    ExaminationExportQuestionToJSON,
} from './ExaminationExportQuestion';

/**
 * 
 * @export
 * @interface ExaminationExportSection
 */
export interface ExaminationExportSection {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSection
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSection
     */
    examinationExportId?: number;
    /**
     * 
     * @type {ExaminationExport}
     * @memberof ExaminationExportSection
     */
    examinationExport?: ExaminationExport;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportSection
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSection
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSection
     */
    sort?: number;
    /**
     * 
     * @type {Array<ExaminationExportQuestion>}
     * @memberof ExaminationExportSection
     */
    questions?: Array<ExaminationExportQuestion> | null;
}

export function ExaminationExportSectionFromJSON(json: any): ExaminationExportSection {
    return ExaminationExportSectionFromJSONTyped(json, false);
}

export function ExaminationExportSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'examinationExport': !exists(json, 'examinationExport') ? undefined : ExaminationExportFromJSON(json['examinationExport']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(ExaminationExportQuestionFromJSON)),
    };
}

export function ExaminationExportSectionToJSON(value?: ExaminationExportSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'examinationExport': ExaminationExportToJSON(value.examinationExport),
        'title': value.title,
        'points': value.points,
        'sort': value.sort,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(ExaminationExportQuestionToJSON)),
    };
}

