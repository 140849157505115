/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    emailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserDto
     */
    roleNames?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    password: string;
}

export function CreateUserDtoFromJSON(json: any): CreateUserDto {
    return CreateUserDtoFromJSONTyped(json, false);
}

export function CreateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': json['userName'],
        'name': json['name'],
        'surname': json['surname'],
        'emailAddress': json['emailAddress'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'roleNames': !exists(json, 'roleNames') ? undefined : json['roleNames'],
        'password': json['password'],
    };
}

export function CreateUserDtoToJSON(value?: CreateUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'isActive': value.isActive,
        'roleNames': value.roleNames,
        'password': value.password,
    };
}

