/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductItemType,
    ProductItemTypeFromJSON,
    ProductItemTypeFromJSONTyped,
    ProductItemTypeToJSON,
} from './ProductItemType';

/**
 * 
 * @export
 * @interface UserWallet
 */
export interface UserWallet {
    /**
     * 
     * @type {number}
     * @memberof UserWallet
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserWallet
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserWallet
     */
    walletName: string;
    /**
     * 
     * @type {ProductItemType}
     * @memberof UserWallet
     */
    productItemType?: ProductItemType;
    /**
     * 
     * @type {number}
     * @memberof UserWallet
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof UserWallet
     */
    gradeId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UserWallet
     */
    expireDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserWallet
     */
    changeGradeDuration?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserWallet
     */
    canChangeGradeDate?: Date;
}

export function UserWalletFromJSON(json: any): UserWallet {
    return UserWalletFromJSONTyped(json, false);
}

export function UserWalletFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWallet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'walletName': json['walletName'],
        'productItemType': !exists(json, 'productItemType') ? undefined : ProductItemTypeFromJSON(json['productItemType']),
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'expireDate': !exists(json, 'expireDate') ? undefined : (json['expireDate'] === null ? null : new Date(json['expireDate'])),
        'changeGradeDuration': !exists(json, 'changeGradeDuration') ? undefined : json['changeGradeDuration'],
        'canChangeGradeDate': !exists(json, 'canChangeGradeDate') ? undefined : (new Date(json['canChangeGradeDate'])),
    };
}

export function UserWalletToJSON(value?: UserWallet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'walletName': value.walletName,
        'productItemType': ProductItemTypeToJSON(value.productItemType),
        'balance': value.balance,
        'gradeId': value.gradeId,
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate === null ? null : value.expireDate.toISOString()),
        'changeGradeDuration': value.changeGradeDuration,
        'canChangeGradeDate': value.canChangeGradeDate === undefined ? undefined : (value.canChangeGradeDate.toISOString()),
    };
}

