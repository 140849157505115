/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentCategoryDto,
    ContentCategoryDtoFromJSON,
    ContentCategoryDtoToJSON,
    ContentCategoryListDtoListResultDto,
    ContentCategoryListDtoListResultDtoFromJSON,
    ContentCategoryListDtoListResultDtoToJSON,
} from '../models';

export interface ApiServicesAppContentCategoryCreatePostRequest {
    contentCategoryDto?: ContentCategoryDto;
}

export interface ApiServicesAppContentCategoryDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppContentCategoryGetAllCategoriesGetRequest {
    search?: string;
}

export interface ApiServicesAppContentCategoryGetCategoriesGetRequest {
    isActive?: boolean;
    slug?: string;
    search?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppContentCategoryGetGetRequest {
    id?: number;
}

export interface ApiServicesAppContentCategoryUpdatePutRequest {
    contentCategoryDto?: ContentCategoryDto;
}

/**
 * 
 */
export class ContentCategoryApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppContentCategoryCreatePostRaw(requestParameters: ApiServicesAppContentCategoryCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCategoryDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentCategory/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContentCategoryDtoToJSON(requestParameters.contentCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentCategoryCreatePost(requestParameters: ApiServicesAppContentCategoryCreatePostRequest, initOverrides?: RequestInit): Promise<ContentCategoryDto> {
        const response = await this.apiServicesAppContentCategoryCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentCategoryDeleteDeleteRaw(requestParameters: ApiServicesAppContentCategoryDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentCategory/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppContentCategoryDeleteDelete(requestParameters: ApiServicesAppContentCategoryDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppContentCategoryDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppContentCategoryGetAllCategoriesGetRaw(requestParameters: ApiServicesAppContentCategoryGetAllCategoriesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCategoryListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentCategory/GetAllCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCategoryListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentCategoryGetAllCategoriesGet(requestParameters: ApiServicesAppContentCategoryGetAllCategoriesGetRequest, initOverrides?: RequestInit): Promise<ContentCategoryListDtoListResultDto> {
        const response = await this.apiServicesAppContentCategoryGetAllCategoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentCategoryGetCategoriesGetRaw(requestParameters: ApiServicesAppContentCategoryGetCategoriesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCategoryListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.slug !== undefined) {
            queryParameters['Slug'] = requestParameters.slug;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['Search'] = requestParameters.search;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentCategory/GetCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCategoryListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentCategoryGetCategoriesGet(requestParameters: ApiServicesAppContentCategoryGetCategoriesGetRequest, initOverrides?: RequestInit): Promise<ContentCategoryListDtoListResultDto> {
        const response = await this.apiServicesAppContentCategoryGetCategoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentCategoryGetGetRaw(requestParameters: ApiServicesAppContentCategoryGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCategoryDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentCategory/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentCategoryGetGet(requestParameters: ApiServicesAppContentCategoryGetGetRequest, initOverrides?: RequestInit): Promise<ContentCategoryDto> {
        const response = await this.apiServicesAppContentCategoryGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentCategoryUpdatePutRaw(requestParameters: ApiServicesAppContentCategoryUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCategoryDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentCategory/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentCategoryDtoToJSON(requestParameters.contentCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentCategoryUpdatePut(requestParameters: ApiServicesAppContentCategoryUpdatePutRequest, initOverrides?: RequestInit): Promise<ContentCategoryDto> {
        const response = await this.apiServicesAppContentCategoryUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
