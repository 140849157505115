/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportAssignee,
    ExaminationExportAssigneeFromJSON,
    ExaminationExportAssigneeFromJSONTyped,
    ExaminationExportAssigneeToJSON,
} from './ExaminationExportAssignee';
import {
    ExaminationExportGrade,
    ExaminationExportGradeFromJSON,
    ExaminationExportGradeFromJSONTyped,
    ExaminationExportGradeToJSON,
} from './ExaminationExportGrade';
import {
    ExaminationExportSection,
    ExaminationExportSectionFromJSON,
    ExaminationExportSectionFromJSONTyped,
    ExaminationExportSectionToJSON,
} from './ExaminationExportSection';
import {
    ExaminationExportSubject,
    ExaminationExportSubjectFromJSON,
    ExaminationExportSubjectFromJSONTyped,
    ExaminationExportSubjectToJSON,
} from './ExaminationExportSubject';
import {
    ExaminationExportType,
    ExaminationExportTypeFromJSON,
    ExaminationExportTypeFromJSONTyped,
    ExaminationExportTypeToJSON,
} from './ExaminationExportType';
import {
    Test,
    TestFromJSON,
    TestFromJSONTyped,
    TestToJSON,
} from './Test';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ExaminationExport
 */
export interface ExaminationExport {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExport
     */
    id?: number;
    /**
     * 
     * @type {ExaminationExportType}
     * @memberof ExaminationExport
     */
    type?: ExaminationExportType;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExport
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ExaminationExport
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExport
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExport
     */
    schoolName?: string | null;
    /**
     * 
     * @type {Array<ExaminationExportGrade>}
     * @memberof ExaminationExport
     */
    grades?: Array<ExaminationExportGrade> | null;
    /**
     * 
     * @type {Array<ExaminationExportSubject>}
     * @memberof ExaminationExport
     */
    subjects?: Array<ExaminationExportSubject> | null;
    /**
     * 
     * @type {Array<ExaminationExportSection>}
     * @memberof ExaminationExport
     */
    sections?: Array<ExaminationExportSection> | null;
    /**
     * 
     * @type {Array<ExaminationExportAssignee>}
     * @memberof ExaminationExport
     */
    assignees?: Array<ExaminationExportAssignee> | null;
    /**
     * 
     * @type {Array<Test>}
     * @memberof ExaminationExport
     */
    referenceTests?: Array<Test> | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExport
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExport
     */
    timeLimit?: number;
    /**
     * 
     * @type {Date}
     * @memberof ExaminationExport
     */
    creationTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExport
     */
    openId?: string | null;
}

export function ExaminationExportFromJSON(json: any): ExaminationExport {
    return ExaminationExportFromJSONTyped(json, false);
}

export function ExaminationExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ExaminationExportTypeFromJSON(json['type']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] === null ? null : (json['grades'] as Array<any>).map(ExaminationExportGradeFromJSON)),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(ExaminationExportSubjectFromJSON)),
        'sections': !exists(json, 'sections') ? undefined : (json['sections'] === null ? null : (json['sections'] as Array<any>).map(ExaminationExportSectionFromJSON)),
        'assignees': !exists(json, 'assignees') ? undefined : (json['assignees'] === null ? null : (json['assignees'] as Array<any>).map(ExaminationExportAssigneeFromJSON)),
        'referenceTests': !exists(json, 'referenceTests') ? undefined : (json['referenceTests'] === null ? null : (json['referenceTests'] as Array<any>).map(TestFromJSON)),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'timeLimit': !exists(json, 'timeLimit') ? undefined : json['timeLimit'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'openId': !exists(json, 'openId') ? undefined : json['openId'],
    };
}

export function ExaminationExportToJSON(value?: ExaminationExport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ExaminationExportTypeToJSON(value.type),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'logo': value.logo,
        'schoolName': value.schoolName,
        'grades': value.grades === undefined ? undefined : (value.grades === null ? null : (value.grades as Array<any>).map(ExaminationExportGradeToJSON)),
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(ExaminationExportSubjectToJSON)),
        'sections': value.sections === undefined ? undefined : (value.sections === null ? null : (value.sections as Array<any>).map(ExaminationExportSectionToJSON)),
        'assignees': value.assignees === undefined ? undefined : (value.assignees === null ? null : (value.assignees as Array<any>).map(ExaminationExportAssigneeToJSON)),
        'referenceTests': value.referenceTests === undefined ? undefined : (value.referenceTests === null ? null : (value.referenceTests as Array<any>).map(TestToJSON)),
        'points': value.points,
        'timeLimit': value.timeLimit,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'openId': value.openId,
    };
}

