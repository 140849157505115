/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeSpan,
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';

/**
 * 
 * @export
 * @interface TimeSpentDaily
 */
export interface TimeSpentDaily {
    /**
     * 
     * @type {string}
     * @memberof TimeSpentDaily
     */
    day?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TimeSpentDaily
     */
    timeSpent?: TimeSpan;
}

export function TimeSpentDailyFromJSON(json: any): TimeSpentDaily {
    return TimeSpentDailyFromJSONTyped(json, false);
}

export function TimeSpentDailyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSpentDaily {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : json['day'],
        'timeSpent': !exists(json, 'timeSpent') ? undefined : TimeSpanFromJSON(json['timeSpent']),
    };
}

export function TimeSpentDailyToJSON(value?: TimeSpentDaily | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'timeSpent': TimeSpanToJSON(value.timeSpent),
    };
}

