/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MemberTypes {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_4 = 4,
    NUMBER_8 = 8,
    NUMBER_16 = 16,
    NUMBER_32 = 32,
    NUMBER_64 = 64,
    NUMBER_128 = 128,
    NUMBER_191 = 191
}

export function MemberTypesFromJSON(json: any): MemberTypes {
    return MemberTypesFromJSONTyped(json, false);
}

export function MemberTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberTypes {
    return json as MemberTypes;
}

export function MemberTypesToJSON(value?: MemberTypes | null): any {
    return value as any;
}

