/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportQuestionOptionsDto,
    ExaminationExportQuestionOptionsDtoFromJSON,
    ExaminationExportQuestionOptionsDtoFromJSONTyped,
    ExaminationExportQuestionOptionsDtoToJSON,
} from './ExaminationExportQuestionOptionsDto';

/**
 * 
 * @export
 * @interface ExaminationExportSubQuestionListDto
 */
export interface ExaminationExportSubQuestionListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubQuestionListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubQuestionListDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubQuestionListDto
     */
    examinationExportSectionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubQuestionListDto
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubQuestionListDto
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubQuestionListDto
     */
    sort?: number;
    /**
     * 
     * @type {ExaminationExportQuestionOptionsDto}
     * @memberof ExaminationExportSubQuestionListDto
     */
    question?: ExaminationExportQuestionOptionsDto;
}

export function ExaminationExportSubQuestionListDtoFromJSON(json: any): ExaminationExportSubQuestionListDto {
    return ExaminationExportSubQuestionListDtoFromJSONTyped(json, false);
}

export function ExaminationExportSubQuestionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSubQuestionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'examinationExportSectionId': !exists(json, 'examinationExportSectionId') ? undefined : json['examinationExportSectionId'],
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'question': !exists(json, 'question') ? undefined : ExaminationExportQuestionOptionsDtoFromJSON(json['question']),
    };
}

export function ExaminationExportSubQuestionListDtoToJSON(value?: ExaminationExportSubQuestionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'examinationExportSectionId': value.examinationExportSectionId,
        'questionId': value.questionId,
        'points': value.points,
        'sort': value.sort,
        'question': ExaminationExportQuestionOptionsDtoToJSON(value.question),
    };
}

