/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTenantDto
 */
export interface CreateTenantDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    tenancyName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    adminEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    connectionString?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTenantDto
     */
    isActive?: boolean;
}

export function CreateTenantDtoFromJSON(json: any): CreateTenantDto {
    return CreateTenantDtoFromJSONTyped(json, false);
}

export function CreateTenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenancyName': json['tenancyName'],
        'name': json['name'],
        'adminEmailAddress': json['adminEmailAddress'],
        'connectionString': !exists(json, 'connectionString') ? undefined : json['connectionString'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function CreateTenantDtoToJSON(value?: CreateTenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenancyName': value.tenancyName,
        'name': value.name,
        'adminEmailAddress': value.adminEmailAddress,
        'connectionString': value.connectionString,
        'isActive': value.isActive,
    };
}

