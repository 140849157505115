/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExport,
    ExaminationExportFromJSON,
    ExaminationExportFromJSONTyped,
    ExaminationExportToJSON,
} from './ExaminationExport';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';

/**
 * 
 * @export
 * @interface ExaminationExportGrade
 */
export interface ExaminationExportGrade {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportGrade
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportGrade
     */
    examinationExportId?: number;
    /**
     * 
     * @type {ExaminationExport}
     * @memberof ExaminationExportGrade
     */
    examinationExport?: ExaminationExport;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportGrade
     */
    gradeId?: number;
    /**
     * 
     * @type {Grade}
     * @memberof ExaminationExportGrade
     */
    grade?: Grade;
}

export function ExaminationExportGradeFromJSON(json: any): ExaminationExportGrade {
    return ExaminationExportGradeFromJSONTyped(json, false);
}

export function ExaminationExportGradeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportGrade {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'examinationExport': !exists(json, 'examinationExport') ? undefined : ExaminationExportFromJSON(json['examinationExport']),
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
    };
}

export function ExaminationExportGradeToJSON(value?: ExaminationExportGrade | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'examinationExport': ExaminationExportToJSON(value.examinationExport),
        'gradeId': value.gradeId,
        'grade': GradeToJSON(value.grade),
    };
}

