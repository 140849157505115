/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';
import {
    TimeSpan,
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';

/**
 * 
 * @export
 * @interface SubjectStatementMonthly
 */
export interface SubjectStatementMonthly {
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    subjectId?: number;
    /**
     * 
     * @type {SubjectType}
     * @memberof SubjectStatementMonthly
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementMonthly
     */
    subjectName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    term?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementMonthly
     */
    gradeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementMonthly
     */
    gradeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementMonthly
     */
    stageCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementMonthly
     */
    stageName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectStatementMonthly
     */
    month?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    practiceCount?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof SubjectStatementMonthly
     */
    totalTimeSpent?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof SubjectStatementMonthly
     */
    averageTimeSpent?: TimeSpan;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    totalScore?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    averageScore?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    maxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectStatementMonthly
     */
    minScore?: number;
}

export function SubjectStatementMonthlyFromJSON(json: any): SubjectStatementMonthly {
    return SubjectStatementMonthlyFromJSONTyped(json, false);
}

export function SubjectStatementMonthlyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectStatementMonthly {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'subjectName': !exists(json, 'subjectName') ? undefined : json['subjectName'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'gradeName': !exists(json, 'gradeName') ? undefined : json['gradeName'],
        'stageCode': !exists(json, 'stageCode') ? undefined : json['stageCode'],
        'stageName': !exists(json, 'stageName') ? undefined : json['stageName'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'practiceCount': !exists(json, 'practiceCount') ? undefined : json['practiceCount'],
        'totalTimeSpent': !exists(json, 'totalTimeSpent') ? undefined : TimeSpanFromJSON(json['totalTimeSpent']),
        'averageTimeSpent': !exists(json, 'averageTimeSpent') ? undefined : TimeSpanFromJSON(json['averageTimeSpent']),
        'totalScore': !exists(json, 'totalScore') ? undefined : json['totalScore'],
        'averageScore': !exists(json, 'averageScore') ? undefined : json['averageScore'],
        'maxScore': !exists(json, 'maxScore') ? undefined : json['maxScore'],
        'minScore': !exists(json, 'minScore') ? undefined : json['minScore'],
    };
}

export function SubjectStatementMonthlyToJSON(value?: SubjectStatementMonthly | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectId': value.subjectId,
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'subjectName': value.subjectName,
        'term': value.term,
        'gradeCode': value.gradeCode,
        'gradeName': value.gradeName,
        'stageCode': value.stageCode,
        'stageName': value.stageName,
        'month': value.month,
        'practiceCount': value.practiceCount,
        'totalTimeSpent': TimeSpanToJSON(value.totalTimeSpent),
        'averageTimeSpent': TimeSpanToJSON(value.averageTimeSpent),
        'totalScore': value.totalScore,
        'averageScore': value.averageScore,
        'maxScore': value.maxScore,
        'minScore': value.minScore,
    };
}

