/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TicketCommentListDto,
    TicketCommentListDtoFromJSON,
    TicketCommentListDtoFromJSONTyped,
    TicketCommentListDtoToJSON,
} from './TicketCommentListDto';
import {
    TicketStatus,
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface UserTicketDto
 */
export interface UserTicketDto {
    /**
     * 
     * @type {number}
     * @memberof UserTicketDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserTicketDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof UserTicketDto
     */
    message: string;
    /**
     * 
     * @type {TicketStatus}
     * @memberof UserTicketDto
     */
    status?: TicketStatus;
    /**
     * 
     * @type {Date}
     * @memberof UserTicketDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Array<TicketCommentListDto>}
     * @memberof UserTicketDto
     */
    comments?: Array<TicketCommentListDto> | null;
}

export function UserTicketDtoFromJSON(json: any): UserTicketDto {
    return UserTicketDtoFromJSONTyped(json, false);
}

export function UserTicketDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTicketDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subject': json['subject'],
        'message': json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'comments': !exists(json, 'comments') ? undefined : (json['comments'] === null ? null : (json['comments'] as Array<any>).map(TicketCommentListDtoFromJSON)),
    };
}

export function UserTicketDtoToJSON(value?: UserTicketDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'comments': value.comments === undefined ? undefined : (value.comments === null ? null : (value.comments as Array<any>).map(TicketCommentListDtoToJSON)),
    };
}

