/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeAttributes {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_16 = 16,
    NUMBER_24 = 24,
    NUMBER_32 = 32,
    NUMBER_128 = 128,
    NUMBER_256 = 256,
    NUMBER_1024 = 1024,
    NUMBER_2048 = 2048,
    NUMBER_4096 = 4096,
    NUMBER_8192 = 8192,
    NUMBER_16384 = 16384,
    NUMBER_65536 = 65536,
    NUMBER_131072 = 131072,
    NUMBER_196608 = 196608,
    NUMBER_262144 = 262144,
    NUMBER_264192 = 264192,
    NUMBER_1048576 = 1048576,
    NUMBER_12582912 = 12582912
}

export function TypeAttributesFromJSON(json: any): TypeAttributes {
    return TypeAttributesFromJSONTyped(json, false);
}

export function TypeAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeAttributes {
    return json as TypeAttributes;
}

export function TypeAttributesToJSON(value?: TypeAttributes | null): any {
    return value as any;
}

