/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExaminationType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function ExaminationTypeFromJSON(json: any): ExaminationType {
    return ExaminationTypeFromJSONTyped(json, false);
}

export function ExaminationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationType {
    return json as ExaminationType;
}

export function ExaminationTypeToJSON(value?: ExaminationType | null): any {
    return value as any;
}

