/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetCurrentLoginInformationsOutput,
    GetCurrentLoginInformationsOutputFromJSON,
    GetCurrentLoginInformationsOutputToJSON,
} from '../models';

/**
 * 
 */
export class SessionApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSessionGetCurrentLoginInformationsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCurrentLoginInformationsOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Session/GetCurrentLoginInformations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentLoginInformationsOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSessionGetCurrentLoginInformationsGet(initOverrides?: RequestInit): Promise<GetCurrentLoginInformationsOutput> {
        const response = await this.apiServicesAppSessionGetCurrentLoginInformationsGetRaw(initOverrides);
        return await response.value();
    }

}
