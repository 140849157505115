/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GradeTranslationDto
 */
export interface GradeTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof GradeTranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GradeTranslationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GradeTranslationDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GradeTranslationDto
     */
    coreId?: number;
    /**
     * 
     * @type {string}
     * @memberof GradeTranslationDto
     */
    language?: string | null;
}

export function GradeTranslationDtoFromJSON(json: any): GradeTranslationDto {
    return GradeTranslationDtoFromJSONTyped(json, false);
}

export function GradeTranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeTranslationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'coreId': !exists(json, 'coreId') ? undefined : json['coreId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function GradeTranslationDtoToJSON(value?: GradeTranslationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'coreId': value.coreId,
        'language': value.language,
    };
}

