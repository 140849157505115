/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductListDto,
    ProductListDtoFromJSON,
    ProductListDtoFromJSONTyped,
    ProductListDtoToJSON,
} from './ProductListDto';

/**
 * 
 * @export
 * @interface ProductListDtoListResultDto
 */
export interface ProductListDtoListResultDto {
    /**
     * 
     * @type {Array<ProductListDto>}
     * @memberof ProductListDtoListResultDto
     */
    items?: Array<ProductListDto> | null;
}

export function ProductListDtoListResultDtoFromJSON(json: any): ProductListDtoListResultDto {
    return ProductListDtoListResultDtoFromJSONTyped(json, false);
}

export function ProductListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ProductListDtoFromJSON)),
    };
}

export function ProductListDtoListResultDtoToJSON(value?: ProductListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ProductListDtoToJSON)),
    };
}

