/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LayoutKind {
    NUMBER_0 = 0,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

export function LayoutKindFromJSON(json: any): LayoutKind {
    return LayoutKindFromJSONTyped(json, false);
}

export function LayoutKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): LayoutKind {
    return json as LayoutKind;
}

export function LayoutKindToJSON(value?: LayoutKind | null): any {
    return value as any;
}

