/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    ProductItemTranslationDto,
    ProductItemTranslationDtoFromJSON,
    ProductItemTranslationDtoFromJSONTyped,
    ProductItemTranslationDtoToJSON,
} from './ProductItemTranslationDto';
import {
    ProductItemType,
    ProductItemTypeFromJSON,
    ProductItemTypeFromJSONTyped,
    ProductItemTypeToJSON,
} from './ProductItemType';

/**
 * 
 * @export
 * @interface ProductItemDto
 */
export interface ProductItemDto {
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    id?: number;
    /**
     * 
     * @type {ProductItemType}
     * @memberof ProductItemDto
     */
    productItemType?: ProductItemType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductItemDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    sort?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    gradeId?: number | null;
    /**
     * 
     * @type {Grade}
     * @memberof ProductItemDto
     */
    grade?: Grade;
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    productId?: number;
    /**
     * 
     * @type {Array<ProductItemTranslationDto>}
     * @memberof ProductItemDto
     */
    translations?: Array<ProductItemTranslationDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductItemDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductItemDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductItemDto
     */
    lastModificationTime?: Date | null;
}

export function ProductItemDtoFromJSON(json: any): ProductItemDto {
    return ProductItemDtoFromJSONTyped(json, false);
}

export function ProductItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productItemType': !exists(json, 'productItemType') ? undefined : ProductItemTypeFromJSON(json['productItemType']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(ProductItemTranslationDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function ProductItemDtoToJSON(value?: ProductItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productItemType': ProductItemTypeToJSON(value.productItemType),
        'isActive': value.isActive,
        'sort': value.sort,
        'gradeId': value.gradeId,
        'grade': GradeToJSON(value.grade),
        'value': value.value,
        'productId': value.productId,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(ProductItemTranslationDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

