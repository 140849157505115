/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationType,
    ExaminationTypeFromJSON,
    ExaminationTypeFromJSONTyped,
    ExaminationTypeToJSON,
} from './ExaminationType';
import {
    Lesson,
    LessonFromJSON,
    LessonFromJSONTyped,
    LessonToJSON,
} from './Lesson';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface TestRange
 */
export interface TestRange {
    /**
     * 
     * @type {number}
     * @memberof TestRange
     */
    id?: number;
    /**
     * 
     * @type {ExaminationType}
     * @memberof TestRange
     */
    examinationType?: ExaminationType;
    /**
     * 
     * @type {number}
     * @memberof TestRange
     */
    subjectId?: number;
    /**
     * 
     * @type {Subject}
     * @memberof TestRange
     */
    subject?: Subject;
    /**
     * 
     * @type {number}
     * @memberof TestRange
     */
    lessonId?: number;
    /**
     * 
     * @type {Lesson}
     * @memberof TestRange
     */
    lesson?: Lesson;
}

export function TestRangeFromJSON(json: any): TestRange {
    return TestRangeFromJSONTyped(json, false);
}

export function TestRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationType': !exists(json, 'examinationType') ? undefined : ExaminationTypeFromJSON(json['examinationType']),
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subject': !exists(json, 'subject') ? undefined : SubjectFromJSON(json['subject']),
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
        'lesson': !exists(json, 'lesson') ? undefined : LessonFromJSON(json['lesson']),
    };
}

export function TestRangeToJSON(value?: TestRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationType': ExaminationTypeToJSON(value.examinationType),
        'subjectId': value.subjectId,
        'subject': SubjectToJSON(value.subject),
        'lessonId': value.lessonId,
        'lesson': LessonToJSON(value.lesson),
    };
}

