/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportAssigneeMappingDto,
    ExaminationExportAssigneeMappingDtoFromJSON,
    ExaminationExportAssigneeMappingDtoFromJSONTyped,
    ExaminationExportAssigneeMappingDtoToJSON,
} from './ExaminationExportAssigneeMappingDto';
import {
    ExaminationExportGradeMappingDto,
    ExaminationExportGradeMappingDtoFromJSON,
    ExaminationExportGradeMappingDtoFromJSONTyped,
    ExaminationExportGradeMappingDtoToJSON,
} from './ExaminationExportGradeMappingDto';
import {
    ExaminationExportSubjectMappingDto,
    ExaminationExportSubjectMappingDtoFromJSON,
    ExaminationExportSubjectMappingDtoFromJSONTyped,
    ExaminationExportSubjectMappingDtoToJSON,
} from './ExaminationExportSubjectMappingDto';
import {
    ReferenceTestListDto,
    ReferenceTestListDtoFromJSON,
    ReferenceTestListDtoFromJSONTyped,
    ReferenceTestListDtoToJSON,
} from './ReferenceTestListDto';

/**
 * 
 * @export
 * @interface ExaminationExportListDto
 */
export interface ExaminationExportListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportListDto
     */
    openId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportListDto
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportListDto
     */
    schoolName?: string | null;
    /**
     * 
     * @type {Array<ExaminationExportAssigneeMappingDto>}
     * @memberof ExaminationExportListDto
     */
    assignees?: Array<ExaminationExportAssigneeMappingDto> | null;
    /**
     * 
     * @type {Array<ExaminationExportGradeMappingDto>}
     * @memberof ExaminationExportListDto
     */
    grades?: Array<ExaminationExportGradeMappingDto> | null;
    /**
     * 
     * @type {Array<ExaminationExportSubjectMappingDto>}
     * @memberof ExaminationExportListDto
     */
    subjects?: Array<ExaminationExportSubjectMappingDto> | null;
    /**
     * 
     * @type {Array<ReferenceTestListDto>}
     * @memberof ExaminationExportListDto
     */
    referenceTests?: Array<ReferenceTestListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportListDto
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportListDto
     */
    timeLimit?: number;
    /**
     * 
     * @type {Date}
     * @memberof ExaminationExportListDto
     */
    creationTime?: Date;
}

export function ExaminationExportListDtoFromJSON(json: any): ExaminationExportListDto {
    return ExaminationExportListDtoFromJSONTyped(json, false);
}

export function ExaminationExportListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'openId': !exists(json, 'openId') ? undefined : json['openId'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'assignees': !exists(json, 'assignees') ? undefined : (json['assignees'] === null ? null : (json['assignees'] as Array<any>).map(ExaminationExportAssigneeMappingDtoFromJSON)),
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] === null ? null : (json['grades'] as Array<any>).map(ExaminationExportGradeMappingDtoFromJSON)),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(ExaminationExportSubjectMappingDtoFromJSON)),
        'referenceTests': !exists(json, 'referenceTests') ? undefined : (json['referenceTests'] === null ? null : (json['referenceTests'] as Array<any>).map(ReferenceTestListDtoFromJSON)),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'timeLimit': !exists(json, 'timeLimit') ? undefined : json['timeLimit'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
    };
}

export function ExaminationExportListDtoToJSON(value?: ExaminationExportListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'openId': value.openId,
        'logo': value.logo,
        'schoolName': value.schoolName,
        'assignees': value.assignees === undefined ? undefined : (value.assignees === null ? null : (value.assignees as Array<any>).map(ExaminationExportAssigneeMappingDtoToJSON)),
        'grades': value.grades === undefined ? undefined : (value.grades === null ? null : (value.grades as Array<any>).map(ExaminationExportGradeMappingDtoToJSON)),
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(ExaminationExportSubjectMappingDtoToJSON)),
        'referenceTests': value.referenceTests === undefined ? undefined : (value.referenceTests === null ? null : (value.referenceTests as Array<any>).map(ReferenceTestListDtoToJSON)),
        'points': value.points,
        'timeLimit': value.timeLimit,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
    };
}

