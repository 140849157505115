/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailDto,
    QuestionDetailDtoFromJSON,
    QuestionDetailDtoFromJSONTyped,
    QuestionDetailDtoToJSON,
} from './QuestionDetailDto';
import {
    TestResultTestSubQuestionDto,
    TestResultTestSubQuestionDtoFromJSON,
    TestResultTestSubQuestionDtoFromJSONTyped,
    TestResultTestSubQuestionDtoToJSON,
} from './TestResultTestSubQuestionDto';

/**
 * 
 * @export
 * @interface TestResultTestQuestionDto
 */
export interface TestResultTestQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof TestResultTestQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestQuestionDto
     */
    points?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestResultTestQuestionDto
     */
    testerAnswer?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestResultTestQuestionDto
     */
    isTesterAnswerCorrect?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestQuestionDto
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultTestQuestionDto
     */
    teacherUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof TestResultTestQuestionDto
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {QuestionDetailDto}
     * @memberof TestResultTestQuestionDto
     */
    question?: QuestionDetailDto;
    /**
     * 
     * @type {Array<TestResultTestSubQuestionDto>}
     * @memberof TestResultTestQuestionDto
     */
    subTestQuestions?: Array<TestResultTestSubQuestionDto> | null;
}

export function TestResultTestQuestionDtoFromJSON(json: any): TestResultTestQuestionDto {
    return TestResultTestQuestionDtoFromJSONTyped(json, false);
}

export function TestResultTestQuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestResultTestQuestionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'testerAnswer': !exists(json, 'testerAnswer') ? undefined : json['testerAnswer'],
        'isTesterAnswerCorrect': !exists(json, 'isTesterAnswerCorrect') ? undefined : json['isTesterAnswerCorrect'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'teacherUserId': !exists(json, 'teacherUserId') ? undefined : json['teacherUserId'],
        'teacherComment': !exists(json, 'teacherComment') ? undefined : json['teacherComment'],
        'question': !exists(json, 'question') ? undefined : QuestionDetailDtoFromJSON(json['question']),
        'subTestQuestions': !exists(json, 'subTestQuestions') ? undefined : (json['subTestQuestions'] === null ? null : (json['subTestQuestions'] as Array<any>).map(TestResultTestSubQuestionDtoFromJSON)),
    };
}

export function TestResultTestQuestionDtoToJSON(value?: TestResultTestQuestionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'points': value.points,
        'testerAnswer': value.testerAnswer,
        'isTesterAnswerCorrect': value.isTesterAnswerCorrect,
        'score': value.score,
        'teacherUserId': value.teacherUserId,
        'teacherComment': value.teacherComment,
        'question': QuestionDetailDtoToJSON(value.question),
        'subTestQuestions': value.subTestQuestions === undefined ? undefined : (value.subTestQuestions === null ? null : (value.subTestQuestions as Array<any>).map(TestResultTestSubQuestionDtoToJSON)),
    };
}

