/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnswerStatus,
    AnswerStatusFromJSON,
    AnswerStatusFromJSONTyped,
    AnswerStatusToJSON,
} from './AnswerStatus';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SubjectiveAnswerDto
 */
export interface SubjectiveAnswerDto {
    /**
     * 
     * @type {number}
     * @memberof SubjectiveAnswerDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectiveAnswerDto
     */
    testQuestionId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectiveAnswerDto
     */
    questionBody?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectiveAnswerDto
     */
    studentGrade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectiveAnswerDto
     */
    studentName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SubjectiveAnswerDto
     */
    submitTime?: Date;
    /**
     * 
     * @type {User}
     * @memberof SubjectiveAnswerDto
     */
    reviewingUser?: User;
    /**
     * 
     * @type {string}
     * @memberof SubjectiveAnswerDto
     */
    readonly reviewingUserName?: string | null;
    /**
     * 
     * @type {AnswerStatus}
     * @memberof SubjectiveAnswerDto
     */
    status?: AnswerStatus;
}

export function SubjectiveAnswerDtoFromJSON(json: any): SubjectiveAnswerDto {
    return SubjectiveAnswerDtoFromJSONTyped(json, false);
}

export function SubjectiveAnswerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectiveAnswerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'testQuestionId': !exists(json, 'testQuestionId') ? undefined : json['testQuestionId'],
        'questionBody': !exists(json, 'questionBody') ? undefined : json['questionBody'],
        'studentGrade': !exists(json, 'studentGrade') ? undefined : json['studentGrade'],
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'submitTime': !exists(json, 'submitTime') ? undefined : (new Date(json['submitTime'])),
        'reviewingUser': !exists(json, 'reviewingUser') ? undefined : UserFromJSON(json['reviewingUser']),
        'reviewingUserName': !exists(json, 'reviewingUserName') ? undefined : json['reviewingUserName'],
        'status': !exists(json, 'status') ? undefined : AnswerStatusFromJSON(json['status']),
    };
}

export function SubjectiveAnswerDtoToJSON(value?: SubjectiveAnswerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'testQuestionId': value.testQuestionId,
        'questionBody': value.questionBody,
        'studentGrade': value.studentGrade,
        'studentName': value.studentName,
        'submitTime': value.submitTime === undefined ? undefined : (value.submitTime.toISOString()),
        'reviewingUser': UserToJSON(value.reviewingUser),
        'status': AnswerStatusToJSON(value.status),
    };
}

