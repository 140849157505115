import React from 'react';
import clsx from 'clsx';
import Image from "next/legacy/image";
import Logo from '@/assets/images/logo.png';
import QrcodeiOS from '@/assets/images/ios.png';
import QrCodeAndroid from '@/assets/images/android.png';
import Button from '@/components/common/Button/Button';
import FacebookIcon from '@/assets/images/Facebook';
import WhatsAppIcon from '@/assets/images/WhatsAppIcon';
import { DataCategory, SiteInfo } from 'src/utils/SiteInfo';
import TwitterIcon from '@/assets/images/TwitterIcon';
import CombinedLink from '../CombinedLink/CombinedLink';
import Link from 'next/link';

export interface FooterProps {
    siteInfo?: SiteInfo;
    dataCategory?: DataCategory;
    snap?: boolean;
}

const Footer = (props: FooterProps) => {
  const { siteInfo, dataCategory, snap } = props;
  return <div className='container pb-4 pt-24 max-w-6xl'>
    <span>{siteInfo?.copyright ?? "© 2023 TP GROUP EDUCATION LIMITED All Rights Reserved. Designed by CoolTech."}</span>
  </div>  
}

export default Footer;