/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GradeListDto
 */
export interface GradeListDto {
    /**
     * 
     * @type {number}
     * @memberof GradeListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GradeListDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GradeListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GradeListDto
     */
    description?: string | null;
}

export function GradeListDtoFromJSON(json: any): GradeListDto {
    return GradeListDtoFromJSONTyped(json, false);
}

export function GradeListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': json['code'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function GradeListDtoToJSON(value?: GradeListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'description': value.description,
    };
}

