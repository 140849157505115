/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSchoolStudentInput,
    CreateSchoolStudentInputFromJSON,
    CreateSchoolStudentInputToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    SchoolStudentListDto,
    SchoolStudentListDtoFromJSON,
    SchoolStudentListDtoToJSON,
    SchoolStudentListDtoPagedResultDto,
    SchoolStudentListDtoPagedResultDtoFromJSON,
    SchoolStudentListDtoPagedResultDtoToJSON,
    ToggleActivateResult,
    ToggleActivateResultFromJSON,
    ToggleActivateResultToJSON,
    UpdateSchoolStudentInput,
    UpdateSchoolStudentInputFromJSON,
    UpdateSchoolStudentInputToJSON,
} from '../models';

export interface ApiServicesAppSchoolStudentAddPostRequest {
    createSchoolStudentInput?: CreateSchoolStudentInput;
}

export interface ApiServicesAppSchoolStudentDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppSchoolStudentGetGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolStudentGetListGetRequest {
    studentGroupId?: number;
    keyWords?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSchoolStudentToggleActivatePutRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppSchoolStudentUpdatePutRequest {
    updateSchoolStudentInput?: UpdateSchoolStudentInput;
}

/**
 * 
 */
export class SchoolStudentApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSchoolStudentAddPostRaw(requestParameters: ApiServicesAppSchoolStudentAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolStudent/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSchoolStudentInputToJSON(requestParameters.createSchoolStudentInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolStudentAddPost(requestParameters: ApiServicesAppSchoolStudentAddPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolStudentAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolStudentDeleteDeleteRaw(requestParameters: ApiServicesAppSchoolStudentDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolStudent/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolStudentDeleteDelete(requestParameters: ApiServicesAppSchoolStudentDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolStudentDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolStudentGetGetRaw(requestParameters: ApiServicesAppSchoolStudentGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolStudentListDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolStudent/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolStudentListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolStudentGetGet(requestParameters: ApiServicesAppSchoolStudentGetGetRequest, initOverrides?: RequestInit): Promise<SchoolStudentListDto> {
        const response = await this.apiServicesAppSchoolStudentGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolStudentGetListGetRaw(requestParameters: ApiServicesAppSchoolStudentGetListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolStudentListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.studentGroupId !== undefined) {
            queryParameters['StudentGroupId'] = requestParameters.studentGroupId;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolStudent/GetList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolStudentListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolStudentGetListGet(requestParameters: ApiServicesAppSchoolStudentGetListGetRequest, initOverrides?: RequestInit): Promise<SchoolStudentListDtoPagedResultDto> {
        const response = await this.apiServicesAppSchoolStudentGetListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolStudentToggleActivatePutRaw(requestParameters: ApiServicesAppSchoolStudentToggleActivatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToggleActivateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolStudent/ToggleActivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleActivateResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolStudentToggleActivatePut(requestParameters: ApiServicesAppSchoolStudentToggleActivatePutRequest, initOverrides?: RequestInit): Promise<ToggleActivateResult> {
        const response = await this.apiServicesAppSchoolStudentToggleActivatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolStudentUpdatePutRaw(requestParameters: ApiServicesAppSchoolStudentUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolStudent/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchoolStudentInputToJSON(requestParameters.updateSchoolStudentInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolStudentUpdatePut(requestParameters: ApiServicesAppSchoolStudentUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolStudentUpdatePutRaw(requestParameters, initOverrides);
    }

}
