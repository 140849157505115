/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberSettingDto
 */
export interface MemberSettingDto {
    /**
     * 
     * @type {number}
     * @memberof MemberSettingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberSettingDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSettingDto
     */
    value?: string | null;
}

export function MemberSettingDtoFromJSON(json: any): MemberSettingDto {
    return MemberSettingDtoFromJSONTyped(json, false);
}

export function MemberSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberSettingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function MemberSettingDtoToJSON(value?: MemberSettingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
    };
}

