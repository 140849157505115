/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRoleDto,
    CreateRoleDtoFromJSON,
    CreateRoleDtoToJSON,
    GetRoleForEditOutput,
    GetRoleForEditOutputFromJSON,
    GetRoleForEditOutputToJSON,
    PermissionDtoListResultDto,
    PermissionDtoListResultDtoFromJSON,
    PermissionDtoListResultDtoToJSON,
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoToJSON,
    RoleDtoPagedResultDto,
    RoleDtoPagedResultDtoFromJSON,
    RoleDtoPagedResultDtoToJSON,
    RoleListDtoListResultDto,
    RoleListDtoListResultDtoFromJSON,
    RoleListDtoListResultDtoToJSON,
} from '../models';

export interface ApiServicesAppRoleCreatePostRequest {
    createRoleDto?: CreateRoleDto;
}

export interface ApiServicesAppRoleDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppRoleGetAllGetRequest {
    keyword?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppRoleGetGetRequest {
    id?: number;
}

export interface ApiServicesAppRoleGetRoleForEditGetRequest {
    id?: number;
}

export interface ApiServicesAppRoleGetRolesGetRequest {
    permission?: string;
}

export interface ApiServicesAppRoleUpdatePutRequest {
    roleDto?: RoleDto;
}

/**
 * 
 */
export class RoleApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppRoleCreatePostRaw(requestParameters: ApiServicesAppRoleCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Role/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleDtoToJSON(requestParameters.createRoleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleCreatePost(requestParameters: ApiServicesAppRoleCreatePostRequest, initOverrides?: RequestInit): Promise<RoleDto> {
        const response = await this.apiServicesAppRoleCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppRoleDeleteDeleteRaw(requestParameters: ApiServicesAppRoleDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Role/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppRoleDeleteDelete(requestParameters: ApiServicesAppRoleDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppRoleDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppRoleGetAllGetRaw(requestParameters: ApiServicesAppRoleGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Role/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleGetAllGet(requestParameters: ApiServicesAppRoleGetAllGetRequest, initOverrides?: RequestInit): Promise<RoleDtoPagedResultDto> {
        const response = await this.apiServicesAppRoleGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppRoleGetAllPermissionsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PermissionDtoListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Role/GetAllPermissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleGetAllPermissionsGet(initOverrides?: RequestInit): Promise<PermissionDtoListResultDto> {
        const response = await this.apiServicesAppRoleGetAllPermissionsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppRoleGetGetRaw(requestParameters: ApiServicesAppRoleGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Role/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleGetGet(requestParameters: ApiServicesAppRoleGetGetRequest, initOverrides?: RequestInit): Promise<RoleDto> {
        const response = await this.apiServicesAppRoleGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppRoleGetRoleForEditGetRaw(requestParameters: ApiServicesAppRoleGetRoleForEditGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetRoleForEditOutput>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Role/GetRoleForEdit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleForEditOutputFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleGetRoleForEditGet(requestParameters: ApiServicesAppRoleGetRoleForEditGetRequest, initOverrides?: RequestInit): Promise<GetRoleForEditOutput> {
        const response = await this.apiServicesAppRoleGetRoleForEditGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppRoleGetRolesGetRaw(requestParameters: ApiServicesAppRoleGetRolesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.permission !== undefined) {
            queryParameters['Permission'] = requestParameters.permission;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Role/GetRoles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleGetRolesGet(requestParameters: ApiServicesAppRoleGetRolesGetRequest, initOverrides?: RequestInit): Promise<RoleListDtoListResultDto> {
        const response = await this.apiServicesAppRoleGetRolesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppRoleUpdatePutRaw(requestParameters: ApiServicesAppRoleUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Role/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleDtoToJSON(requestParameters.roleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppRoleUpdatePut(requestParameters: ApiServicesAppRoleUpdatePutRequest, initOverrides?: RequestInit): Promise<RoleDto> {
        const response = await this.apiServicesAppRoleUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
