/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterOutput
 */
export interface RegisterOutput {
    /**
     * 
     * @type {boolean}
     * @memberof RegisterOutput
     */
    canLogin?: boolean;
}

export function RegisterOutputFromJSON(json: any): RegisterOutput {
    return RegisterOutputFromJSONTyped(json, false);
}

export function RegisterOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canLogin': !exists(json, 'canLogin') ? undefined : json['canLogin'],
    };
}

export function RegisterOutputToJSON(value?: RegisterOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canLogin': value.canLogin,
    };
}

