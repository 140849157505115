/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';

/**
 * 
 * @export
 * @interface GetOrderResponse
 */
export interface GetOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponse
     */
    externalTransactionID?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GetOrderResponse
     */
    status?: OrderStatus;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponse
     */
    currency?: string | null;
    /**
     * 
     * @type {any}
     * @memberof GetOrderResponse
     */
    originResponse?: any | null;
}

export function GetOrderResponseFromJSON(json: any): GetOrderResponse {
    return GetOrderResponseFromJSONTyped(json, false);
}

export function GetOrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalTransactionID': !exists(json, 'externalTransactionID') ? undefined : json['externalTransactionID'],
        'status': !exists(json, 'status') ? undefined : OrderStatusFromJSON(json['status']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'originResponse': !exists(json, 'originResponse') ? undefined : json['originResponse'],
    };
}

export function GetOrderResponseToJSON(value?: GetOrderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalTransactionID': value.externalTransactionID,
        'status': OrderStatusToJSON(value.status),
        'amount': value.amount,
        'currency': value.currency,
        'originResponse': value.originResponse,
    };
}

