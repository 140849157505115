/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExport,
    ExaminationExportFromJSON,
    ExaminationExportFromJSONTyped,
    ExaminationExportToJSON,
} from './ExaminationExport';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface ExaminationExportSubject
 */
export interface ExaminationExportSubject {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubject
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubject
     */
    examinationExportId?: number;
    /**
     * 
     * @type {ExaminationExport}
     * @memberof ExaminationExportSubject
     */
    examinationExport?: ExaminationExport;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubject
     */
    subjectId?: number;
    /**
     * 
     * @type {Subject}
     * @memberof ExaminationExportSubject
     */
    subject?: Subject;
}

export function ExaminationExportSubjectFromJSON(json: any): ExaminationExportSubject {
    return ExaminationExportSubjectFromJSONTyped(json, false);
}

export function ExaminationExportSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'examinationExport': !exists(json, 'examinationExport') ? undefined : ExaminationExportFromJSON(json['examinationExport']),
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subject': !exists(json, 'subject') ? undefined : SubjectFromJSON(json['subject']),
    };
}

export function ExaminationExportSubjectToJSON(value?: ExaminationExportSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'examinationExport': ExaminationExportToJSON(value.examinationExport),
        'subjectId': value.subjectId,
        'subject': SubjectToJSON(value.subject),
    };
}

