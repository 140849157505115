/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationType,
    ExaminationTypeFromJSON,
    ExaminationTypeFromJSONTyped,
    ExaminationTypeToJSON,
} from './ExaminationType';

/**
 * 
 * @export
 * @interface CreateExaminationInput
 */
export interface CreateExaminationInput {
    /**
     * 
     * @type {number}
     * @memberof CreateExaminationInput
     */
    subjectId?: number;
    /**
     * 
     * @type {ExaminationType}
     * @memberof CreateExaminationInput
     */
    examinationType?: ExaminationType;
}

export function CreateExaminationInputFromJSON(json: any): CreateExaminationInput {
    return CreateExaminationInputFromJSONTyped(json, false);
}

export function CreateExaminationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExaminationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'examinationType': !exists(json, 'examinationType') ? undefined : ExaminationTypeFromJSON(json['examinationType']),
    };
}

export function CreateExaminationInputToJSON(value?: CreateExaminationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectId': value.subjectId,
        'examinationType': ExaminationTypeToJSON(value.examinationType),
    };
}

