/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum QuestionType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7
}

export function QuestionTypeFromJSON(json: any): QuestionType {
    return QuestionTypeFromJSONTyped(json, false);
}

export function QuestionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionType {
    return json as QuestionType;
}

export function QuestionTypeToJSON(value?: QuestionType | null): any {
    return value as any;
}

