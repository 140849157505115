import React from 'react';
import Link, { LinkProps } from 'next/link';
import { StringNullableChain } from 'lodash';
export interface CombinedLinkProps extends LinkProps {
    children?: React.ReactNode,
    target?: string,
    className?:string,
}
/**
 * 
 * @deprecated use nextjs link instead
 */
const CombinedLink = (props: CombinedLinkProps) => {
  const {className, ...rest} = props;
  const { ...nextjsLinkProps } = { ...rest, target: props.target ? props.target : '_self' } as LinkProps;

  return (
    <Link {...nextjsLinkProps} className={className?className:"no-underline text-primary-500 hover:text-primary-700 transition bg-gradient-to-r from-primary-500 to-primary-500  bg-growing-underline-from-c"} target={props.target}>

      {props.children}
    </Link>
  )
}
CombinedLink.displayName = 'CombinedLink';
export default CombinedLink;