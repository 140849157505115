/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LessonTestRangeDto,
    LessonTestRangeDtoFromJSON,
    LessonTestRangeDtoFromJSONTyped,
    LessonTestRangeDtoToJSON,
} from './LessonTestRangeDto';

/**
 * 
 * @export
 * @interface LessonDto
 */
export interface LessonDto {
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    visible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    freeTrial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    subjectiveOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    sort?: number;
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    subjectId?: number;
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    maxQuestionCountPerPractice?: number;
    /**
     * 
     * @type {any}
     * @memberof LessonDto
     */
    extra?: any | null;
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    extraSerialize?: string | null;
    /**
     * 
     * @type {Array<LessonTestRangeDto>}
     * @memberof LessonDto
     */
    testRanges?: Array<LessonTestRangeDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    hasQuestions?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof LessonDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonDto
     */
    lastModificationTime?: Date | null;
}

export function LessonDtoFromJSON(json: any): LessonDto {
    return LessonDtoFromJSONTyped(json, false);
}

export function LessonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
        'freeTrial': !exists(json, 'freeTrial') ? undefined : json['freeTrial'],
        'subjectiveOnly': !exists(json, 'subjectiveOnly') ? undefined : json['subjectiveOnly'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'maxQuestionCountPerPractice': !exists(json, 'maxQuestionCountPerPractice') ? undefined : json['maxQuestionCountPerPractice'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'extraSerialize': !exists(json, 'extraSerialize') ? undefined : json['extraSerialize'],
        'testRanges': !exists(json, 'testRanges') ? undefined : (json['testRanges'] === null ? null : (json['testRanges'] as Array<any>).map(LessonTestRangeDtoFromJSON)),
        'hasQuestions': !exists(json, 'hasQuestions') ? undefined : json['hasQuestions'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function LessonDtoToJSON(value?: LessonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'visible': value.visible,
        'freeTrial': value.freeTrial,
        'subjectiveOnly': value.subjectiveOnly,
        'name': value.name,
        'description': value.description,
        'sort': value.sort,
        'subjectId': value.subjectId,
        'maxQuestionCountPerPractice': value.maxQuestionCountPerPractice,
        'extra': value.extra,
        'extraSerialize': value.extraSerialize,
        'testRanges': value.testRanges === undefined ? undefined : (value.testRanges === null ? null : (value.testRanges as Array<any>).map(LessonTestRangeDtoToJSON)),
        'hasQuestions': value.hasQuestions,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

