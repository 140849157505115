/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLoginInfoDto
 */
export interface UserLoginInfoDto {
    /**
     * 
     * @type {number}
     * @memberof UserLoginInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserLoginInfoDto
     */
    schoolId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserLoginInfoDto
     */
    parentId?: number | null;
}

export function UserLoginInfoDtoFromJSON(json: any): UserLoginInfoDto {
    return UserLoginInfoDtoFromJSONTyped(json, false);
}

export function UserLoginInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
    };
}

export function UserLoginInfoDtoToJSON(value?: UserLoginInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'surname': value.surname,
        'userName': value.userName,
        'emailAddress': value.emailAddress,
        'schoolId': value.schoolId,
        'parentId': value.parentId,
    };
}

