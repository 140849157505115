/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportSubjectListDto,
    ExaminationExportSubjectListDtoFromJSON,
    ExaminationExportSubjectListDtoFromJSONTyped,
    ExaminationExportSubjectListDtoToJSON,
} from './ExaminationExportSubjectListDto';

/**
 * 
 * @export
 * @interface ExaminationExportLessonListDto
 */
export interface ExaminationExportLessonListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportLessonListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportLessonListDto
     */
    name?: string | null;
    /**
     * 
     * @type {ExaminationExportSubjectListDto}
     * @memberof ExaminationExportLessonListDto
     */
    subject?: ExaminationExportSubjectListDto;
}

export function ExaminationExportLessonListDtoFromJSON(json: any): ExaminationExportLessonListDto {
    return ExaminationExportLessonListDtoFromJSONTyped(json, false);
}

export function ExaminationExportLessonListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportLessonListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'subject': !exists(json, 'subject') ? undefined : ExaminationExportSubjectListDtoFromJSON(json['subject']),
    };
}

export function ExaminationExportLessonListDtoToJSON(value?: ExaminationExportLessonListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'subject': ExaminationExportSubjectListDtoToJSON(value.subject),
    };
}

