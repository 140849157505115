/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationType,
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
} from './LocationType';

/**
 * 
 * @export
 * @interface LocationListDto
 */
export interface LocationListDto {
    /**
     * 
     * @type {string}
     * @memberof LocationListDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationListDto
     */
    parentId?: string | null;
    /**
     * 
     * @type {LocationType}
     * @memberof LocationListDto
     */
    type?: LocationType;
    /**
     * 
     * @type {string}
     * @memberof LocationListDto
     */
    name: string;
    /**
     * 
     * @type {Array<LocationListDto>}
     * @memberof LocationListDto
     */
    children?: Array<LocationListDto> | null;
}

export function LocationListDtoFromJSON(json: any): LocationListDto {
    return LocationListDtoFromJSONTyped(json, false);
}

export function LocationListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'type': !exists(json, 'type') ? undefined : LocationTypeFromJSON(json['type']),
        'name': json['name'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(LocationListDtoFromJSON)),
    };
}

export function LocationListDtoToJSON(value?: LocationListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'type': LocationTypeToJSON(value.type),
        'name': value.name,
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(LocationListDtoToJSON)),
    };
}

