/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSchoolTeacherInput,
    CreateSchoolTeacherInputFromJSON,
    CreateSchoolTeacherInputToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    LinkSchoolTeachersInput,
    LinkSchoolTeachersInputFromJSON,
    LinkSchoolTeachersInputToJSON,
    RemoveSchoolTeacherInput,
    RemoveSchoolTeacherInputFromJSON,
    RemoveSchoolTeacherInputToJSON,
    SchoolTeacherDetailDto,
    SchoolTeacherDetailDtoFromJSON,
    SchoolTeacherDetailDtoToJSON,
    SchoolTeacherListDto,
    SchoolTeacherListDtoFromJSON,
    SchoolTeacherListDtoToJSON,
    SchoolTeacherListDtoPagedResultDto,
    SchoolTeacherListDtoPagedResultDtoFromJSON,
    SchoolTeacherListDtoPagedResultDtoToJSON,
    SchoolTeacherSelectListDtoPagedResultDto,
    SchoolTeacherSelectListDtoPagedResultDtoFromJSON,
    SchoolTeacherSelectListDtoPagedResultDtoToJSON,
    ToggleActivateResult,
    ToggleActivateResultFromJSON,
    ToggleActivateResultToJSON,
    UpdateSchoolTeacherInput,
    UpdateSchoolTeacherInputFromJSON,
    UpdateSchoolTeacherInputToJSON,
} from '../models';

export interface ApiServicesAppSchoolTeacherAddPostRequest {
    createSchoolTeacherInput?: CreateSchoolTeacherInput;
}

export interface ApiServicesAppSchoolTeacherDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppSchoolTeacherGetGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolTeacherGetListGetRequest {
    schoolId?: number;
    studentGroupId?: number;
    keyWords?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSchoolTeacherGetSelectListForStudentGroupGetRequest {
    schoolId?: number;
    studentGroupId?: number;
    keyWords?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppSchoolTeacherGetWithSchoolGetRequest {
    id?: number;
}

export interface ApiServicesAppSchoolTeacherLinkPostRequest {
    linkSchoolTeachersInput?: LinkSchoolTeachersInput;
}

export interface ApiServicesAppSchoolTeacherToggleActivatePutRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppSchoolTeacherUnlinkPostRequest {
    removeSchoolTeacherInput?: RemoveSchoolTeacherInput;
}

export interface ApiServicesAppSchoolTeacherUpdatePutRequest {
    updateSchoolTeacherInput?: UpdateSchoolTeacherInput;
}

/**
 * 
 */
export class SchoolTeacherApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppSchoolTeacherAddPostRaw(requestParameters: ApiServicesAppSchoolTeacherAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSchoolTeacherInputToJSON(requestParameters.createSchoolTeacherInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolTeacherAddPost(requestParameters: ApiServicesAppSchoolTeacherAddPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolTeacherAddPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolTeacherDeleteDeleteRaw(requestParameters: ApiServicesAppSchoolTeacherDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolTeacherDeleteDelete(requestParameters: ApiServicesAppSchoolTeacherDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolTeacherDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetGetRaw(requestParameters: ApiServicesAppSchoolTeacherGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolTeacherListDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolTeacherListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetGet(requestParameters: ApiServicesAppSchoolTeacherGetGetRequest, initOverrides?: RequestInit): Promise<SchoolTeacherListDto> {
        const response = await this.apiServicesAppSchoolTeacherGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetListGetRaw(requestParameters: ApiServicesAppSchoolTeacherGetListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolTeacherListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['SchoolId'] = requestParameters.schoolId;
        }

        if (requestParameters.studentGroupId !== undefined) {
            queryParameters['StudentGroupId'] = requestParameters.studentGroupId;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/GetList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolTeacherListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetListGet(requestParameters: ApiServicesAppSchoolTeacherGetListGetRequest, initOverrides?: RequestInit): Promise<SchoolTeacherListDtoPagedResultDto> {
        const response = await this.apiServicesAppSchoolTeacherGetListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetSelectListForStudentGroupGetRaw(requestParameters: ApiServicesAppSchoolTeacherGetSelectListForStudentGroupGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolTeacherSelectListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['SchoolId'] = requestParameters.schoolId;
        }

        if (requestParameters.studentGroupId !== undefined) {
            queryParameters['StudentGroupId'] = requestParameters.studentGroupId;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/GetSelectListForStudentGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolTeacherSelectListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetSelectListForStudentGroupGet(requestParameters: ApiServicesAppSchoolTeacherGetSelectListForStudentGroupGetRequest, initOverrides?: RequestInit): Promise<SchoolTeacherSelectListDtoPagedResultDto> {
        const response = await this.apiServicesAppSchoolTeacherGetSelectListForStudentGroupGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetWithSchoolGetRaw(requestParameters: ApiServicesAppSchoolTeacherGetWithSchoolGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchoolTeacherDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/GetWithSchool`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchoolTeacherDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolTeacherGetWithSchoolGet(requestParameters: ApiServicesAppSchoolTeacherGetWithSchoolGetRequest, initOverrides?: RequestInit): Promise<SchoolTeacherDetailDto> {
        const response = await this.apiServicesAppSchoolTeacherGetWithSchoolGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolTeacherLinkPostRaw(requestParameters: ApiServicesAppSchoolTeacherLinkPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/Link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkSchoolTeachersInputToJSON(requestParameters.linkSchoolTeachersInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolTeacherLinkPost(requestParameters: ApiServicesAppSchoolTeacherLinkPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolTeacherLinkPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolTeacherToggleActivatePutRaw(requestParameters: ApiServicesAppSchoolTeacherToggleActivatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToggleActivateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/ToggleActivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleActivateResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppSchoolTeacherToggleActivatePut(requestParameters: ApiServicesAppSchoolTeacherToggleActivatePutRequest, initOverrides?: RequestInit): Promise<ToggleActivateResult> {
        const response = await this.apiServicesAppSchoolTeacherToggleActivatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppSchoolTeacherUnlinkPostRaw(requestParameters: ApiServicesAppSchoolTeacherUnlinkPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/Unlink`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveSchoolTeacherInputToJSON(requestParameters.removeSchoolTeacherInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolTeacherUnlinkPost(requestParameters: ApiServicesAppSchoolTeacherUnlinkPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolTeacherUnlinkPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppSchoolTeacherUpdatePutRaw(requestParameters: ApiServicesAppSchoolTeacherUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/SchoolTeacher/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchoolTeacherInputToJSON(requestParameters.updateSchoolTeacherInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppSchoolTeacherUpdatePut(requestParameters: ApiServicesAppSchoolTeacherUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppSchoolTeacherUpdatePutRaw(requestParameters, initOverrides);
    }

}
