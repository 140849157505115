/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChildAccountDto,
    ChildAccountDtoFromJSON,
    ChildAccountDtoFromJSONTyped,
    ChildAccountDtoToJSON,
} from './ChildAccountDto';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';

/**
 * 
 * @export
 * @interface Register3Input
 */
export interface Register3Input {
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    provider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    providerKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    avator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    engFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    engLastName?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof Register3Input
     */
    gender?: Gender;
    /**
     * 
     * @type {number}
     * @memberof Register3Input
     */
    age?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    tel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register3Input
     */
    zipcode?: string | null;
    /**
     * 
     * @type {Array<ChildAccountDto>}
     * @memberof Register3Input
     */
    childrenAccounts?: Array<ChildAccountDto> | null;
}

export function Register3InputFromJSON(json: any): Register3Input {
    return Register3InputFromJSONTyped(json, false);
}

export function Register3InputFromJSONTyped(json: any, ignoreDiscriminator: boolean): Register3Input {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'surname': json['surname'],
        'userName': json['userName'],
        'emailAddress': json['emailAddress'],
        'password': json['password'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'providerKey': !exists(json, 'providerKey') ? undefined : json['providerKey'],
        'avator': !exists(json, 'avator') ? undefined : json['avator'],
        'engFirstName': !exists(json, 'engFirstName') ? undefined : json['engFirstName'],
        'engLastName': !exists(json, 'engLastName') ? undefined : json['engLastName'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'childrenAccounts': !exists(json, 'childrenAccounts') ? undefined : (json['childrenAccounts'] === null ? null : (json['childrenAccounts'] as Array<any>).map(ChildAccountDtoFromJSON)),
    };
}

export function Register3InputToJSON(value?: Register3Input | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
        'userName': value.userName,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'provider': value.provider,
        'providerKey': value.providerKey,
        'avator': value.avator,
        'engFirstName': value.engFirstName,
        'engLastName': value.engLastName,
        'gender': GenderToJSON(value.gender),
        'age': value.age,
        'mobile': value.mobile,
        'tel': value.tel,
        'address': value.address,
        'region': value.region,
        'country': value.country,
        'zipcode': value.zipcode,
        'childrenAccounts': value.childrenAccounts === undefined ? undefined : (value.childrenAccounts === null ? null : (value.childrenAccounts as Array<any>).map(ChildAccountDtoToJSON)),
    };
}

