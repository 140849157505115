/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderProductListDto,
    OrderProductListDtoFromJSON,
    OrderProductListDtoFromJSONTyped,
    OrderProductListDtoToJSON,
} from './OrderProductListDto';
import {
    PaymentListDto,
    PaymentListDtoFromJSON,
    PaymentListDtoFromJSONTyped,
    PaymentListDtoToJSON,
} from './PaymentListDto';

/**
 * 
 * @export
 * @interface ProductPaymentListDto
 */
export interface ProductPaymentListDto {
    /**
     * 
     * @type {Array<OrderProductListDto>}
     * @memberof ProductPaymentListDto
     */
    products?: Array<OrderProductListDto> | null;
    /**
     * 
     * @type {Array<PaymentListDto>}
     * @memberof ProductPaymentListDto
     */
    payments?: Array<PaymentListDto> | null;
}

export function ProductPaymentListDtoFromJSON(json: any): ProductPaymentListDto {
    return ProductPaymentListDtoFromJSONTyped(json, false);
}

export function ProductPaymentListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPaymentListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': !exists(json, 'products') ? undefined : (json['products'] === null ? null : (json['products'] as Array<any>).map(OrderProductListDtoFromJSON)),
        'payments': !exists(json, 'payments') ? undefined : (json['payments'] === null ? null : (json['payments'] as Array<any>).map(PaymentListDtoFromJSON)),
    };
}

export function ProductPaymentListDtoToJSON(value?: ProductPaymentListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': value.products === undefined ? undefined : (value.products === null ? null : (value.products as Array<any>).map(OrderProductListDtoToJSON)),
        'payments': value.payments === undefined ? undefined : (value.payments === null ? null : (value.payments as Array<any>).map(PaymentListDtoToJSON)),
    };
}

