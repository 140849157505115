/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GradeNameWithStageDto,
    GradeNameWithStageDtoFromJSON,
    GradeNameWithStageDtoFromJSONTyped,
    GradeNameWithStageDtoToJSON,
} from './GradeNameWithStageDto';

/**
 * 
 * @export
 * @interface SubjectNameWithGradeDto
 */
export interface SubjectNameWithGradeDto {
    /**
     * 
     * @type {number}
     * @memberof SubjectNameWithGradeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectNameWithGradeDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectNameWithGradeDto
     */
    term?: number;
    /**
     * 
     * @type {GradeNameWithStageDto}
     * @memberof SubjectNameWithGradeDto
     */
    grade?: GradeNameWithStageDto;
}

export function SubjectNameWithGradeDtoFromJSON(json: any): SubjectNameWithGradeDto {
    return SubjectNameWithGradeDtoFromJSONTyped(json, false);
}

export function SubjectNameWithGradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectNameWithGradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'grade': !exists(json, 'grade') ? undefined : GradeNameWithStageDtoFromJSON(json['grade']),
    };
}

export function SubjectNameWithGradeDtoToJSON(value?: SubjectNameWithGradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'term': value.term,
        'grade': GradeNameWithStageDtoToJSON(value.grade),
    };
}

