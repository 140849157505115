/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TicketCommentListDto
 */
export interface TicketCommentListDto {
    /**
     * 
     * @type {number}
     * @memberof TicketCommentListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketCommentListDto
     */
    readonly userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketCommentListDto
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TicketCommentListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {User}
     * @memberof TicketCommentListDto
     */
    creatorUser?: User;
}

export function TicketCommentListDtoFromJSON(json: any): TicketCommentListDto {
    return TicketCommentListDtoFromJSONTyped(json, false);
}

export function TicketCommentListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketCommentListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
    };
}

export function TicketCommentListDtoToJSON(value?: TicketCommentListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUser': UserToJSON(value.creatorUser),
    };
}

