/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessonListDto
 */
export interface LessonListDto {
    /**
     * 
     * @type {number}
     * @memberof LessonListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LessonListDto
     */
    sort?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LessonListDto
     */
    freeTrial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LessonListDto
     */
    subjectiveOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LessonListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LessonListDto
     */
    description?: string | null;
    /**
     * 
     * @type {any}
     * @memberof LessonListDto
     */
    extra?: any | null;
    /**
     * 
     * @type {number}
     * @memberof LessonListDto
     */
    schoolId?: number | null;
}

export function LessonListDtoFromJSON(json: any): LessonListDto {
    return LessonListDtoFromJSONTyped(json, false);
}

export function LessonListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'freeTrial': !exists(json, 'freeTrial') ? undefined : json['freeTrial'],
        'subjectiveOnly': !exists(json, 'subjectiveOnly') ? undefined : json['subjectiveOnly'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
    };
}

export function LessonListDtoToJSON(value?: LessonListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'freeTrial': value.freeTrial,
        'subjectiveOnly': value.subjectiveOnly,
        'name': value.name,
        'description': value.description,
        'extra': value.extra,
        'schoolId': value.schoolId,
    };
}

