/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportSubjectListDto,
    ExaminationExportSubjectListDtoFromJSON,
    ExaminationExportSubjectListDtoFromJSONTyped,
    ExaminationExportSubjectListDtoToJSON,
} from './ExaminationExportSubjectListDto';

/**
 * 
 * @export
 * @interface ExaminationExportSubjectListDtoListResultDto
 */
export interface ExaminationExportSubjectListDtoListResultDto {
    /**
     * 
     * @type {Array<ExaminationExportSubjectListDto>}
     * @memberof ExaminationExportSubjectListDtoListResultDto
     */
    items?: Array<ExaminationExportSubjectListDto> | null;
}

export function ExaminationExportSubjectListDtoListResultDtoFromJSON(json: any): ExaminationExportSubjectListDtoListResultDto {
    return ExaminationExportSubjectListDtoListResultDtoFromJSONTyped(json, false);
}

export function ExaminationExportSubjectListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSubjectListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ExaminationExportSubjectListDtoFromJSON)),
    };
}

export function ExaminationExportSubjectListDtoListResultDtoToJSON(value?: ExaminationExportSubjectListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ExaminationExportSubjectListDtoToJSON)),
    };
}

