import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { createContext, useState, useMemo, FC, PropsWithChildren, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CategoryApi, SubjectListDto, TestApi, PracticeDto, TestResultDetailDto, TestType } from "src/fetch";
import { useLocalForage, useLocalForageReducer } from "../LocalForageHook";
import { useUserData } from "../UserDataHook";
import { useDialog } from "../dialog/DialogHook";
import { useWritingHook } from "../question/WritingHook";
import { SubjectType } from "src/types/ApiTypes";
import { StudentRoles } from "src/utils/constants";
import { DailyPracticeMeta, DailyPracticeMetaAtom, PracticeProgress } from "src/atoms/student/practiceAtom";
import { useRecoilState } from "recoil";
import moment from "moment";
import { SubjectTypeList } from "../practice/PracticeProvider";

export type PracticeContextStatus = "loading" | "loading_daily" | "error" | "ready";
export const DailyPracticeContext = createContext<{
  dailyPracticeMeta: DailyPracticeMeta;
  enabledDailyPractice: boolean;
  getDailyPracticeId: (subject: SubjectType) => Promise<number | undefined>;
  updateProgress: (subject: SubjectType, progress: PracticeProgress) => void;
    } | null>(null);
export const DailyPracticeProvider:FC<PropsWithChildren> = (props)=>{
  const { userId, userInfo, getApiConfig } = useUserData();
  const [dailyPracticeMeta, setDailyPracticeMeta] = useRecoilState(DailyPracticeMetaAtom);
  const enabledDailyPractice = useMemo(() => !!userId && !!userInfo?.profiles?.grade?.code && !!StudentRoles.find(it => (userInfo?.roles?.indexOf(it)??-1) >= 0), [userId, userInfo?.profiles?.grade?.code]);
  const subjectQuery = useQuery(["subjectInGrade", userInfo?.profiles?.grade?.code],
    async () => await new CategoryApi(getApiConfig()).apiServicesAppCategoryGetSubjectsInGradeGetRaw({ gradeCode: userInfo?.profiles?.grade?.code }).Convert(), {
      enabled: enabledDailyPractice,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    })
  const getTargetSubject = (subject: SubjectType) => {
    let result: SubjectListDto | null = null;
    result = subjectQuery?.data?.items?.find(it => it.subjectType === subject as number && it.term === userInfo?.profiles?.term) ?? null
    return result;
  }
  const preloadedDailyPracticesQuery = useQuery(["dailyPractice2", userId], async () => {
    const api = new TestApi(getApiConfig());
    const remotePractices = (await api.apiServicesAppTestGetDailyPracticesWithDetailGetRaw({}).Convert())?.filter(it=>moment(it.creationTime).utcOffset(8).isSame(moment(), 'day') );
    // const remotePractices = [...remotePendingPractices?.items ?? [], ...remoteFinishedPractices?.items ?? []];
    let preloadedPractices: { [key in SubjectType]?: PracticeDto | TestResultDetailDto } = {};
    if (remotePractices) {
      preloadedPractices = {
        [SubjectType.Chinese]: remotePractices?.find(it => it.subject?.subjectType == 0) ?? undefined,
        [SubjectType.English]: remotePractices?.find(it => it.subject?.subjectType == 2) ?? undefined,
        [SubjectType.Maths]: remotePractices?.find(it => it.subject?.subjectType == 1) ?? undefined,
      }
      preloadedPractices = Object.fromEntries(Object.entries(preloadedPractices).filter(([_, value]) => !!value));
    }
    return preloadedPractices;
  }, {
    enabled: enabledDailyPractice,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    onError: (err) => {
      console.error(err);
    }
  });
  
  const getDailyPracticeId = async (subject: SubjectType): Promise<number|undefined> => {
    if (!userId){      
      return undefined;
    }
    if (dailyPracticeMeta && dailyPracticeMeta[subject]) {
      return dailyPracticeMeta[subject]!.id;
    }
    let result: PracticeDto | null = null;
    if (subjectQuery.isSuccess) {
      const targetSubject = getTargetSubject(subject);
      if (!targetSubject)
        throw new Error("no subject found");
      result = await new TestApi(getApiConfig()).apiServicesAppTestCreateDailyPracticePostRaw({
        createDailyPracticeInput: {
          subjectId: targetSubject.id ?? 0,
          date: new Date(),
          userId: userId ?? 0,
          testType: TestType.NUMBER_2
        }
      }).Convert();
    }
    if (result) {        
      setDailyPracticeMeta(v=>{
        return {
          ...v,
          [subject]: {
            id: result?.id,
            progress: "not_started"
          }
        }
      });
      return result.id;
    } else {
      toast.error("create daily practice failed");
    }
  }
  useEffect(()=>{
    if(!preloadedDailyPracticesQuery.data)
      return;
    setDailyPracticeMeta(v=>{
      const out = {...v};
      SubjectTypeList.forEach(subject=>{
        const remotePractice = preloadedDailyPracticesQuery.data?.[subject] as TestResultDetailDto;
        if(remotePractice){
          out[subject] = {
            id:  remotePractice.id!,
            progress:  remotePractice.finishTime != null ? "submitted" :
              remotePractice?.beginTime != null ? ((v[subject]?.id == remotePractice?.id?v[subject]?.progress??0 : 0))://TODO: use latest progress
                "not_started"
          }
        }else{
          delete out[subject]
        }
      });
      return  out;
    });
  }, [preloadedDailyPracticesQuery.data, setDailyPracticeMeta])
  const updateProgress = (subject: SubjectType, progress: PracticeProgress) => {
    console.log("update progress", subject, progress);
    setDailyPracticeMeta(v=>{
      return {
        ...v,
        [subject]:{
          ...v[subject],
          progress: progress
        }
      }
    });
  }
  
  return <DailyPracticeContext.Provider value={{ dailyPracticeMeta, enabledDailyPractice, getDailyPracticeId, updateProgress }}>
    {props.children}
  </DailyPracticeContext.Provider>
}

export const useDailyPractice = ()=>{
  const ctx=  useContext(DailyPracticeContext);
  if(!ctx)
    throw "use this with provider";
  
  return ctx;
}