/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeUiThemeInput
 */
export interface ChangeUiThemeInput {
    /**
     * 
     * @type {string}
     * @memberof ChangeUiThemeInput
     */
    theme: string;
}

export function ChangeUiThemeInputFromJSON(json: any): ChangeUiThemeInput {
    return ChangeUiThemeInputFromJSONTyped(json, false);
}

export function ChangeUiThemeInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeUiThemeInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'theme': json['theme'],
    };
}

export function ChangeUiThemeInputToJSON(value?: ChangeUiThemeInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'theme': value.theme,
    };
}

