/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateStudentGroupInput,
    CreateStudentGroupInputFromJSON,
    CreateStudentGroupInputToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    StudentGroupDetailDto,
    StudentGroupDetailDtoFromJSON,
    StudentGroupDetailDtoToJSON,
    StudentGroupListDtoPagedResultDto,
    StudentGroupListDtoPagedResultDtoFromJSON,
    StudentGroupListDtoPagedResultDtoToJSON,
    StudentGroupListLightDtoListResultDto,
    StudentGroupListLightDtoListResultDtoFromJSON,
    StudentGroupListLightDtoListResultDtoToJSON,
    StudentGroupUpdateInput,
    StudentGroupUpdateInputFromJSON,
    StudentGroupUpdateInputToJSON,
    ToggleActivateResult,
    ToggleActivateResultFromJSON,
    ToggleActivateResultToJSON,
} from '../models';

export interface ApiServicesAppStudentGroupCreatePostRequest {
    createStudentGroupInput?: CreateStudentGroupInput;
}

export interface ApiServicesAppStudentGroupDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppStudentGroupGetAllGetRequest {
    schoolId?: number;
    keyWords?: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppStudentGroupGetGetRequest {
    id?: number;
}

export interface ApiServicesAppStudentGroupGetListGetRequest {
    schoolId?: number;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppStudentGroupGetNameListGetRequest {
    schoolId?: number;
}

export interface ApiServicesAppStudentGroupToggleActivatePutRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppStudentGroupUpdatePutRequest {
    studentGroupUpdateInput?: StudentGroupUpdateInput;
}

/**
 * 
 */
export class StudentGroupApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppStudentGroupCreatePostRaw(requestParameters: ApiServicesAppStudentGroupCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/StudentGroup/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStudentGroupInputToJSON(requestParameters.createStudentGroupInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupCreatePost(requestParameters: ApiServicesAppStudentGroupCreatePostRequest, initOverrides?: RequestInit): Promise<StudentGroupDetailDto> {
        const response = await this.apiServicesAppStudentGroupCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupDeleteDeleteRaw(requestParameters: ApiServicesAppStudentGroupDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroup/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppStudentGroupDeleteDelete(requestParameters: ApiServicesAppStudentGroupDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppStudentGroupDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppStudentGroupGetAllGetRaw(requestParameters: ApiServicesAppStudentGroupGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['SchoolId'] = requestParameters.schoolId;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroup/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupGetAllGet(requestParameters: ApiServicesAppStudentGroupGetAllGetRequest, initOverrides?: RequestInit): Promise<StudentGroupListDtoPagedResultDto> {
        const response = await this.apiServicesAppStudentGroupGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupGetGetRaw(requestParameters: ApiServicesAppStudentGroupGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroup/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupGetGet(requestParameters: ApiServicesAppStudentGroupGetGetRequest, initOverrides?: RequestInit): Promise<StudentGroupDetailDto> {
        const response = await this.apiServicesAppStudentGroupGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupGetListGetRaw(requestParameters: ApiServicesAppStudentGroupGetListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['SchoolId'] = requestParameters.schoolId;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroup/GetList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupGetListGet(requestParameters: ApiServicesAppStudentGroupGetListGetRequest, initOverrides?: RequestInit): Promise<StudentGroupListDtoPagedResultDto> {
        const response = await this.apiServicesAppStudentGroupGetListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupGetNameListGetRaw(requestParameters: ApiServicesAppStudentGroupGetNameListGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentGroupListLightDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.schoolId !== undefined) {
            queryParameters['schoolId'] = requestParameters.schoolId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/StudentGroup/GetNameList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentGroupListLightDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupGetNameListGet(requestParameters: ApiServicesAppStudentGroupGetNameListGetRequest, initOverrides?: RequestInit): Promise<StudentGroupListLightDtoListResultDto> {
        const response = await this.apiServicesAppStudentGroupGetNameListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupToggleActivatePutRaw(requestParameters: ApiServicesAppStudentGroupToggleActivatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToggleActivateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/StudentGroup/ToggleActivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleActivateResultFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppStudentGroupToggleActivatePut(requestParameters: ApiServicesAppStudentGroupToggleActivatePutRequest, initOverrides?: RequestInit): Promise<ToggleActivateResult> {
        const response = await this.apiServicesAppStudentGroupToggleActivatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppStudentGroupUpdatePutRaw(requestParameters: ApiServicesAppStudentGroupUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/StudentGroup/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StudentGroupUpdateInputToJSON(requestParameters.studentGroupUpdateInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppStudentGroupUpdatePut(requestParameters: ApiServicesAppStudentGroupUpdatePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppStudentGroupUpdatePutRaw(requestParameters, initOverrides);
    }

}
