/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TestIdInput
 */
export interface TestIdInput {
    /**
     * 
     * @type {number}
     * @memberof TestIdInput
     */
    testId?: number;
}

export function TestIdInputFromJSON(json: any): TestIdInput {
    return TestIdInputFromJSONTyped(json, false);
}

export function TestIdInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestIdInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
    };
}

export function TestIdInputToJSON(value?: TestIdInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'testId': value.testId,
    };
}

