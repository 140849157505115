/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleListDto
 */
export interface RoleListDto {
    /**
     * 
     * @type {number}
     * @memberof RoleListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleListDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleListDto
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleListDto
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RoleListDto
     */
    creationTime?: Date;
}

export function RoleListDtoFromJSON(json: any): RoleListDto {
    return RoleListDtoFromJSONTyped(json, false);
}

export function RoleListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'isStatic': !exists(json, 'isStatic') ? undefined : json['isStatic'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
    };
}

export function RoleListDtoToJSON(value?: RoleListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'displayName': value.displayName,
        'isStatic': value.isStatic,
        'isDefault': value.isDefault,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
    };
}

