/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportListDto,
    ExaminationExportListDtoFromJSON,
    ExaminationExportListDtoFromJSONTyped,
    ExaminationExportListDtoToJSON,
} from './ExaminationExportListDto';

/**
 * 
 * @export
 * @interface ExaminationExportListDtoPagedResultDto
 */
export interface ExaminationExportListDtoPagedResultDto {
    /**
     * 
     * @type {Array<ExaminationExportListDto>}
     * @memberof ExaminationExportListDtoPagedResultDto
     */
    items?: Array<ExaminationExportListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportListDtoPagedResultDto
     */
    totalCount?: number;
}

export function ExaminationExportListDtoPagedResultDtoFromJSON(json: any): ExaminationExportListDtoPagedResultDto {
    return ExaminationExportListDtoPagedResultDtoFromJSONTyped(json, false);
}

export function ExaminationExportListDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportListDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ExaminationExportListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function ExaminationExportListDtoPagedResultDtoToJSON(value?: ExaminationExportListDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ExaminationExportListDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

