/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';

/**
 * 
 * @export
 * @interface AdminUpdateChildUserInput
 */
export interface AdminUpdateChildUserInput {
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateChildUserInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    grade: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateChildUserInput
     */
    readonly term?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    school?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    district?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof AdminUpdateChildUserInput
     */
    gender?: Gender;
    /**
     * 
     * @type {Date}
     * @memberof AdminUpdateChildUserInput
     */
    birthday?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateChildUserInput
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateChildUserInput
     */
    isActive?: boolean;
}

export function AdminUpdateChildUserInputFromJSON(json: any): AdminUpdateChildUserInput {
    return AdminUpdateChildUserInputFromJSONTyped(json, false);
}

export function AdminUpdateChildUserInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdateChildUserInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'grade': json['grade'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'school': !exists(json, 'school') ? undefined : json['school'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'birthday': !exists(json, 'birthday') ? undefined : (json['birthday'] === null ? null : new Date(json['birthday'])),
        'userName': json['userName'],
        'name': json['name'],
        'surname': json['surname'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function AdminUpdateChildUserInputToJSON(value?: AdminUpdateChildUserInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'grade': value.grade,
        'school': value.school,
        'district': value.district,
        'gender': GenderToJSON(value.gender),
        'birthday': value.birthday === undefined ? undefined : (value.birthday === null ? null : value.birthday.toISOString()),
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'fullName': value.fullName,
        'password': value.password,
        'isActive': value.isActive,
    };
}

