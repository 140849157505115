import { Session } from 'next-auth';
import { ApiResponse, Configuration, HTTPQuery, querystring } from '.';

export const baseUrl = process.env["NEXT_PUBLIC_API_URL"];
//export const baseUrl='';
// export const baseUrl='https://beyondfuture.6uwu.com';
export const genApiConfig = (lang = "zh-HK", noCache: boolean | null = null, auth?: string | Session|null): Configuration => {
  const conf = new Configuration({
    basePath: baseUrl,
    headers: {
      "Authorization": typeof auth === "string" ?
        auth :
        auth?.accessToken ?
          `Bearer ${auth?.accessToken}` :
          ``,
      "Accept-Language": lang,
      // "Authorization": ``,
      // "Accept-Language": "en",
      credentials: 'omit'
    },
    credentials: 'omit',
    fetchApi: (input: any, init: any) => {
      if (init.headers["Authorization"] == null)
        delete init.headers["Authorization"]
      if (noCache == true) {
        init.headers["cache"] = 'no-cache'
        init.headers["pragma"] = 'no-cache'
        init.headers["Pragma"] = 'no-cache'
        init.headers["cache-control"] = 'no-cache, no-store'
        init.headers["Cache-Control"] = 'no-cache, no-store'
      }

      return fetch(input, init).then((res) => {
        // console.log(res);
        if (!res.ok) {
          return Promise.reject(res)
        } else {
          return res;
        }
      })
        .catch(async (res) => {
          if (res.status && res.status === 401) {
            console.debug("Token Timeout, auto refreshing...");
            // try {
            //     var token =
            //         await new UsersApi(new Configuration({
            //             basePath: baseUrl,
            //             headers: {
            //                 "Accept-Language": Storage.language
            //             },
            //         })).apiUsersRefreshTokenPost();

            //     console.debug("Received new token... ");
            //     // Storage.accessToken = token.accessToken;
            //     // Storage.refreshToken = token.refreshToken;

            //     Storage.userInfo = token.result;
            //     Storage.accessToken = token.result.jwtToken;
            //     init.headers["Authorization"] = `Bearer ${Storage.accessToken}`;
            //     return fetch(input, init)
            // } catch (e) {
            //     console.error(e);
            //     Storage.accessToken = Storage.refreshToken = Storage.userInfo = null;
            //     window.location.replace('/');
            // }
          } else if (res.status && res.status >= 500) {
            console.error(res);
            //window.location.replace('/');
          }
          return Promise.reject(res);
        });
    },
    queryParamsStringify: (params: HTTPQuery) => {
      lang = lang == "zh" ? "zh-HK" : lang;
      return querystring(Object.assign(params, { culture: lang }));
    },
    // middleware:[
    //     {
    //         pre:((context: RequestContext)=>{
    //             // context.url = "ggglasdawwas";
    //             return new Promise((res, rej)=>{
    //                 debugger
    //                 console.log("context", context)
    //                 return {url:"", init:""}
    //             });
    //         }),
    //     }
    // ]

  });
  return conf;
}


export const sharedBaseUrl = baseUrl;

type ExtractApiResponseParameter<T extends ApiResponse<any> | any> = T extends ApiResponse<infer U> ? U: never;
declare global {
    interface Promise<T> {
        Convert<TModel extends ExtractApiResponseParameter<T>>(): Promise<TModel>;
    }
}

Promise.prototype.Convert = async function <T extends ApiResponse<any>, TModel extends ExtractApiResponseParameter<T>>(): Promise<TModel> {
  const s1 = (await this);
  const s2 = await s1.raw.json();
  const s3 = (s2 as any).result as TModel;
  return s3;
}