/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryTranslationDto,
    CategoryTranslationDtoFromJSON,
    CategoryTranslationDtoFromJSONTyped,
    CategoryTranslationDtoToJSON,
} from './CategoryTranslationDto';

/**
 * 
 * @export
 * @interface ContentCategoryDto
 */
export interface ContentCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof ContentCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ContentCategoryDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ContentCategoryDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentCategoryDto
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContentCategoryDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContentCategoryDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContentCategoryDto
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentCategoryDto
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContentCategoryDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContentCategoryDto
     */
    slug: string;
    /**
     * 
     * @type {Array<CategoryTranslationDto>}
     * @memberof ContentCategoryDto
     */
    translations?: Array<CategoryTranslationDto> | null;
}

export function ContentCategoryDtoFromJSON(json: any): ContentCategoryDto {
    return ContentCategoryDtoFromJSONTyped(json, false);
}

export function ContentCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'slug': json['slug'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(CategoryTranslationDtoFromJSON)),
    };
}

export function ContentCategoryDtoToJSON(value?: ContentCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'isDeleted': value.isDeleted,
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isActive': value.isActive,
        'slug': value.slug,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(CategoryTranslationDtoToJSON)),
    };
}

