/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FAQCategoryAdminListDto,
    FAQCategoryAdminListDtoFromJSON,
    FAQCategoryAdminListDtoFromJSONTyped,
    FAQCategoryAdminListDtoToJSON,
} from './FAQCategoryAdminListDto';

/**
 * 
 * @export
 * @interface FAQCategoryAdminListDtoListResultDto
 */
export interface FAQCategoryAdminListDtoListResultDto {
    /**
     * 
     * @type {Array<FAQCategoryAdminListDto>}
     * @memberof FAQCategoryAdminListDtoListResultDto
     */
    items?: Array<FAQCategoryAdminListDto> | null;
}

export function FAQCategoryAdminListDtoListResultDtoFromJSON(json: any): FAQCategoryAdminListDtoListResultDto {
    return FAQCategoryAdminListDtoListResultDtoFromJSONTyped(json, false);
}

export function FAQCategoryAdminListDtoListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQCategoryAdminListDtoListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(FAQCategoryAdminListDtoFromJSON)),
    };
}

export function FAQCategoryAdminListDtoListResultDtoToJSON(value?: FAQCategoryAdminListDtoListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(FAQCategoryAdminListDtoToJSON)),
    };
}

