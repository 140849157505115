/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignTicketInput
 */
export interface AssignTicketInput {
    /**
     * 
     * @type {number}
     * @memberof AssignTicketInput
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignTicketInput
     */
    ticketId?: number;
}

export function AssignTicketInputFromJSON(json: any): AssignTicketInput {
    return AssignTicketInputFromJSONTyped(json, false);
}

export function AssignTicketInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignTicketInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
    };
}

export function AssignTicketInputToJSON(value?: AssignTicketInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'ticketId': value.ticketId,
    };
}

