import { useTranslation } from "next-i18next";
import { FC } from "react";
import BigGreenDot from '@/assets/images/auth/big_green_dot.svg';
import GreenGooey from '@/assets/images/auth/green_gooey.svg';
import GreenSlash from '@/assets/images/auth/green_slash.svg';
import clsx from "clsx";
import { XMarkIcon as  XIcon } from "@heroicons/react/24/outline";

export type InfoDialogResult = "confirm" | "cancel";
export type InfoDialogModel = {
    title: string,
    content: JSX.Element | string,
    confirmText?: string,
}

export const InfoDialog: FC<{ isOpen: boolean, onResult: (result: InfoDialogResult) => void, onCancel: () => void, model?: InfoDialogModel }> = (props) => {
  const { isOpen, onResult, onCancel, model } = props;
  const { title, content, confirmText } = model ?? {};
  const { t } = useTranslation(["common"]);
  return <>
    <div className={clsx("dui-modal dui-modal-bottom sm:dui-modal-middle", isOpen && "dui-modal-open")}>
      <div onClick={() => onCancel()} className="w-full h-full ">

      </div>
      <div className="dui-modal-box  overflow-clip absolute ">
        <div className=" w-full h-full absolute">
          <BigGreenDot className="scale-[0.8] left-0 bottom-0 absolute opacity-30" />
          <GreenSlash className="scale-50 -translate-x-1/2 -translate-y-1/2 left-0 top-0 absolute opacity-50" />
          <BigGreenDot className="scale-50 right-0 bottom-0 absolute opacity-30" />
        </div>

        <div className="flex flex-col gap-4 z-10 relative text-center prose">
          <div className="flex flex-row-reverse">
            <button className="dui-btn  rounded-full p-2 h-auto min-h-min" title="cancel" onClick={()=>onResult?.("cancel")}>
              <XIcon className="w-5 h-5" />
            </button>
          </div>
          <h2 className="m-0">{title}</h2>
          <div className="">
            {content}
          </div>
          <div className="w-full justify-center gap-4 flex flex-row">
            <label className="dui-btn dui-btn-primary" onClick={() => onResult?.("confirm")}>{confirmText ?? t("common:dialog.yes")}</label>
          </div>

        </div>
      </div>
    </div>
  </>
}