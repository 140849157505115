/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';

/**
 * 
 * @export
 * @interface PaymentCallback
 */
export interface PaymentCallback {
    /**
     * 
     * @type {string}
     * @memberof PaymentCallback
     */
    externalId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentCallback
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCallback
     */
    currency?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof PaymentCallback
     */
    status?: OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof PaymentCallback
     */
    message?: string | null;
}

export function PaymentCallbackFromJSON(json: any): PaymentCallback {
    return PaymentCallbackFromJSONTyped(json, false);
}

export function PaymentCallbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentCallback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'status': !exists(json, 'status') ? undefined : OrderStatusFromJSON(json['status']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function PaymentCallbackToJSON(value?: PaymentCallback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'amount': value.amount,
        'currency': value.currency,
        'status': OrderStatusToJSON(value.status),
        'message': value.message,
    };
}

