/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentCustomFieldListDto,
    ContentCustomFieldListDtoFromJSON,
    ContentCustomFieldListDtoFromJSONTyped,
    ContentCustomFieldListDtoToJSON,
} from './ContentCustomFieldListDto';
import {
    JToken,
    JTokenFromJSON,
    JTokenFromJSONTyped,
    JTokenToJSON,
} from './JToken';

/**
 * 
 * @export
 * @interface ContentArticleFullContentDto
 */
export interface ContentArticleFullContentDto {
    /**
     * 
     * @type {number}
     * @memberof ContentArticleFullContentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleFullContentDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleFullContentDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleFullContentDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {Array<ContentArticleFullContentDto>}
     * @memberof ContentArticleFullContentDto
     */
    subArticles?: Array<ContentArticleFullContentDto> | null;
    /**
     * 
     * @type {Array<ContentCustomFieldListDto>}
     * @memberof ContentArticleFullContentDto
     */
    customFields?: Array<ContentCustomFieldListDto> | null;
    /**
     * 
     * @type {{ [key: string]: JToken; }}
     * @memberof ContentArticleFullContentDto
     */
    readonly extFields?: { [key: string]: JToken; } | null;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleFullContentDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleFullContentDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContentArticleFullContentDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleFullContentDto
     */
    sort?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleFullContentDto
     */
    cover?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentArticleFullContentDto
     */
    content?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentArticleFullContentDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleFullContentDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContentArticleFullContentDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentArticleFullContentDto
     */
    lastModificationTime?: Date | null;
}

export function ContentArticleFullContentDtoFromJSON(json: any): ContentArticleFullContentDto {
    return ContentArticleFullContentDtoFromJSONTyped(json, false);
}

export function ContentArticleFullContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentArticleFullContentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': json['slug'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'subArticles': !exists(json, 'subArticles') ? undefined : (json['subArticles'] === null ? null : (json['subArticles'] as Array<any>).map(ContentArticleFullContentDtoFromJSON)),
        'customFields': !exists(json, 'customFields') ? undefined : (json['customFields'] === null ? null : (json['customFields'] as Array<any>).map(ContentCustomFieldListDtoFromJSON)),
        'extFields': !exists(json, 'extFields') ? undefined : (json['extFields'] === null ? null : mapValues(json['extFields'], JTokenFromJSON)),
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'cover': !exists(json, 'cover') ? undefined : json['cover'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function ContentArticleFullContentDtoToJSON(value?: ContentArticleFullContentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'type': value.type,
        'parentId': value.parentId,
        'subArticles': value.subArticles === undefined ? undefined : (value.subArticles === null ? null : (value.subArticles as Array<any>).map(ContentArticleFullContentDtoToJSON)),
        'customFields': value.customFields === undefined ? undefined : (value.customFields === null ? null : (value.customFields as Array<any>).map(ContentCustomFieldListDtoToJSON)),
        'title': value.title,
        'description': value.description,
        'isActive': value.isActive,
        'sort': value.sort,
        'cover': value.cover,
        'content': value.content,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

