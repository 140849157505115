/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectNameDto,
    SubjectNameDtoFromJSON,
    SubjectNameDtoFromJSONTyped,
    SubjectNameDtoToJSON,
} from './SubjectNameDto';

/**
 * 
 * @export
 * @interface LessonWithSubjectListDto
 */
export interface LessonWithSubjectListDto {
    /**
     * 
     * @type {number}
     * @memberof LessonWithSubjectListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LessonWithSubjectListDto
     */
    sort?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LessonWithSubjectListDto
     */
    freeTrial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LessonWithSubjectListDto
     */
    subjectiveOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LessonWithSubjectListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LessonWithSubjectListDto
     */
    description?: string | null;
    /**
     * 
     * @type {any}
     * @memberof LessonWithSubjectListDto
     */
    extra?: any | null;
    /**
     * 
     * @type {number}
     * @memberof LessonWithSubjectListDto
     */
    schoolId?: number | null;
    /**
     * 
     * @type {SubjectNameDto}
     * @memberof LessonWithSubjectListDto
     */
    subject?: SubjectNameDto;
}

export function LessonWithSubjectListDtoFromJSON(json: any): LessonWithSubjectListDto {
    return LessonWithSubjectListDtoFromJSONTyped(json, false);
}

export function LessonWithSubjectListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonWithSubjectListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'freeTrial': !exists(json, 'freeTrial') ? undefined : json['freeTrial'],
        'subjectiveOnly': !exists(json, 'subjectiveOnly') ? undefined : json['subjectiveOnly'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'subject': !exists(json, 'subject') ? undefined : SubjectNameDtoFromJSON(json['subject']),
    };
}

export function LessonWithSubjectListDtoToJSON(value?: LessonWithSubjectListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'freeTrial': value.freeTrial,
        'subjectiveOnly': value.subjectiveOnly,
        'name': value.name,
        'description': value.description,
        'extra': value.extra,
        'schoolId': value.schoolId,
        'subject': SubjectNameDtoToJSON(value.subject),
    };
}

