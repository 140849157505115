/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TicketComment,
    TicketCommentFromJSON,
    TicketCommentFromJSONTyped,
    TicketCommentToJSON,
} from './TicketComment';
import {
    TicketStatus,
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    message: string;
    /**
     * 
     * @type {TicketStatus}
     * @memberof Ticket
     */
    status?: TicketStatus;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    assignedToUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Ticket
     */
    assignedToUser?: User;
    /**
     * 
     * @type {Array<TicketComment>}
     * @memberof Ticket
     */
    comments?: Array<TicketComment> | null;
    /**
     * 
     * @type {Date}
     * @memberof Ticket
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Ticket
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Ticket
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Ticket
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {User}
     * @memberof Ticket
     */
    creatorUser?: User;
}

export function TicketFromJSON(json: any): Ticket {
    return TicketFromJSONTyped(json, false);
}

export function TicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ticket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subject': json['subject'],
        'message': json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'assignedToUserId': !exists(json, 'assignedToUserId') ? undefined : json['assignedToUserId'],
        'assignedToUser': !exists(json, 'assignedToUser') ? undefined : UserFromJSON(json['assignedToUser']),
        'comments': !exists(json, 'comments') ? undefined : (json['comments'] === null ? null : (json['comments'] as Array<any>).map(TicketCommentFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
    };
}

export function TicketToJSON(value?: Ticket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'assignedToUserId': value.assignedToUserId,
        'assignedToUser': UserToJSON(value.assignedToUser),
        'comments': value.comments === undefined ? undefined : (value.comments === null ? null : (value.comments as Array<any>).map(TicketCommentToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
        'creatorUser': UserToJSON(value.creatorUser),
    };
}

