import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSpring, animated, SpringValues} from '@react-spring/web'
import { MouseParallaxContext } from './MouseParallax';

export interface MouseParallaxLayerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    /**translate = x / xRatio +xShift */
    translateShift?: [xShift: number, yShift: number],
    /**translateRatio, the smaller it faster */
    translateRatio?: [xRatio:number, yRatio:number]
}
const translateScript = (props:MouseParallaxLayerProps) => (x: number,y:number)=>{
  return  `translate3d(${(x / (props.translateRatio?.[0]??1)+(props.translateShift?.[0]??0))}px,${(y / (props.translateRatio?.[1]??1)+(props.translateShift?.[1]??0))}px,0)`;
}
const MouseParallaxLayer= (props:MouseParallaxLayerProps)=>{
  const { xy } = useContext(MouseParallaxContext);
  const {children, ref,translateRatio,  ...rest}  =props;
  return <animated.div {...rest} className={clsx(rest.className, "absolute will-change-transform")} style={{...rest?.style, transform: xy?.to(translateScript(props)) }}>
    {children}
  </animated.div>
}
MouseParallaxLayer.displayName = 'MouseParallaxLayer';
export default MouseParallaxLayer;