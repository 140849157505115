/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportAssigneeMappingDto,
    ExaminationExportAssigneeMappingDtoFromJSON,
    ExaminationExportAssigneeMappingDtoFromJSONTyped,
    ExaminationExportAssigneeMappingDtoToJSON,
} from './ExaminationExportAssigneeMappingDto';
import {
    ExaminationExportGradeMappingDto,
    ExaminationExportGradeMappingDtoFromJSON,
    ExaminationExportGradeMappingDtoFromJSONTyped,
    ExaminationExportGradeMappingDtoToJSON,
} from './ExaminationExportGradeMappingDto';
import {
    ExaminationExportSectionListDto,
    ExaminationExportSectionListDtoFromJSON,
    ExaminationExportSectionListDtoFromJSONTyped,
    ExaminationExportSectionListDtoToJSON,
} from './ExaminationExportSectionListDto';
import {
    ExaminationExportSubjectMappingDto,
    ExaminationExportSubjectMappingDtoFromJSON,
    ExaminationExportSubjectMappingDtoFromJSONTyped,
    ExaminationExportSubjectMappingDtoToJSON,
} from './ExaminationExportSubjectMappingDto';

/**
 * 
 * @export
 * @interface CreateExportExaminationInput
 */
export interface CreateExportExaminationInput {
    /**
     * 
     * @type {number}
     * @memberof CreateExportExaminationInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExportExaminationInput
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExportExaminationInput
     */
    schoolName?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateExportExaminationInput
     */
    gradeIds: Array<number>;
    /**
     * 
     * @type {Array<ExaminationExportGradeMappingDto>}
     * @memberof CreateExportExaminationInput
     */
    grades?: Array<ExaminationExportGradeMappingDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateExportExaminationInput
     */
    subjectIds: Array<number>;
    /**
     * 
     * @type {Array<ExaminationExportSubjectMappingDto>}
     * @memberof CreateExportExaminationInput
     */
    subjects?: Array<ExaminationExportSubjectMappingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateExportExaminationInput
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExportExaminationInput
     */
    timeLimit: number;
    /**
     * 
     * @type {Array<ExaminationExportSectionListDto>}
     * @memberof CreateExportExaminationInput
     */
    sections: Array<ExaminationExportSectionListDto>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateExportExaminationInput
     */
    assigneesUserId?: Array<number> | null;
    /**
     * 
     * @type {Array<ExaminationExportAssigneeMappingDto>}
     * @memberof CreateExportExaminationInput
     */
    assignees?: Array<ExaminationExportAssigneeMappingDto> | null;
}

export function CreateExportExaminationInputFromJSON(json: any): CreateExportExaminationInput {
    return CreateExportExaminationInputFromJSONTyped(json, false);
}

export function CreateExportExaminationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportExaminationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'gradeIds': json['gradeIds'],
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] === null ? null : (json['grades'] as Array<any>).map(ExaminationExportGradeMappingDtoFromJSON)),
        'subjectIds': json['subjectIds'],
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(ExaminationExportSubjectMappingDtoFromJSON)),
        'points': json['points'],
        'timeLimit': json['timeLimit'],
        'sections': ((json['sections'] as Array<any>).map(ExaminationExportSectionListDtoFromJSON)),
        'assigneesUserId': !exists(json, 'assigneesUserId') ? undefined : json['assigneesUserId'],
        'assignees': !exists(json, 'assignees') ? undefined : (json['assignees'] === null ? null : (json['assignees'] as Array<any>).map(ExaminationExportAssigneeMappingDtoFromJSON)),
    };
}

export function CreateExportExaminationInputToJSON(value?: CreateExportExaminationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'logo': value.logo,
        'schoolName': value.schoolName,
        'gradeIds': value.gradeIds,
        'grades': value.grades === undefined ? undefined : (value.grades === null ? null : (value.grades as Array<any>).map(ExaminationExportGradeMappingDtoToJSON)),
        'subjectIds': value.subjectIds,
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(ExaminationExportSubjectMappingDtoToJSON)),
        'points': value.points,
        'timeLimit': value.timeLimit,
        'sections': ((value.sections as Array<any>).map(ExaminationExportSectionListDtoToJSON)),
        'assigneesUserId': value.assigneesUserId,
        'assignees': value.assignees === undefined ? undefined : (value.assignees === null ? null : (value.assignees as Array<any>).map(ExaminationExportAssigneeMappingDtoToJSON)),
    };
}

