/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SecurityRuleSet {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function SecurityRuleSetFromJSON(json: any): SecurityRuleSet {
    return SecurityRuleSetFromJSONTyped(json, false);
}

export function SecurityRuleSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityRuleSet {
    return json as SecurityRuleSet;
}

export function SecurityRuleSetToJSON(value?: SecurityRuleSet | null): any {
    return value as any;
}

