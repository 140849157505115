import { useTranslation } from "next-i18next";
import { FC } from "react";
import BigGreenDot from "@/assets/images/auth/big_green_dot.svg";
import GreenGooey from "@/assets/images/auth/green_gooey.svg";
import GreenSlash from "@/assets/images/auth/green_slash.svg";
import clsx from "clsx";
import { XMarkIcon as  XIcon } from "@heroicons/react/24/outline";

export type CustomDialogResult<T> =
  | { status: "success"; data: T }
  | { status: "error"; error: any }
  | { status: "cancel" };
export type CustomDialogModel<T, TProps> = {
  title: string;
  Content: FC<{ onResult: (result: T) => void; } & TProps>;
  ableToCancel?: boolean;
  contentProps?: TProps;
  customModalClass?: string;
  customModalBodyClass?: string;
  titleClass?: string;
  closeBtnClass?: string;
};
export const CustomDialog: FC<{
  isOpen: boolean;
  onResult: (result: CustomDialogResult<any>) => void;
  onCancel: () => void;
  model?: CustomDialogModel<any, any>;
}> = (props) => {
  const { isOpen, onResult, onCancel, model } = props;
  const {
    title,
    Content,
    ableToCancel,
    customModalClass,
    customModalBodyClass,
    contentProps,
    titleClass,
    closeBtnClass,
  } = model ?? {};
  const { t } = useTranslation(["common"]);
  return (
    <>
      <div
        className={clsx(
          customModalClass ?? "dui-modal dui-modal-bottom sm:dui-modal-middle",
          isOpen && "dui-modal-open"
        )}
      >
        {ableToCancel && (
          <div onClick={() => onCancel()} className="w-full h-full "></div>
        )}
        <div
          className={
            customModalBodyClass ??
            "dui-modal-box  overflow-x-clip overflow-y-auto absolute"
          }
        >
          <div className=" left-0 top-0 bottom-0 right-0 absolute">
            <BigGreenDot className="scale-[0.8] left-0 bottom-0 absolute opacity-30" />
            <GreenSlash className="scale-50 -translate-x-1/2 -translate-y-1/2 left-0 top-0 absolute opacity-50" />
            <BigGreenDot className="scale-50 right-0 bottom-0 absolute opacity-30" />
          </div>

          <div className=" z-10 relative text-center   mx-auto">
            {ableToCancel && (
              <div className="flex flex-row-reverse">
                <button
                  className={clsx(
                    "dui-btn  rounded-full p-2 h-auto min-h-min ",
                    closeBtnClass
                  )}
                  title="cancel"
                  onClick={() => onCancel()}
                >
                  <XIcon className="w-5 h-5" />
                </button>
              </div>
            )}
            <h2 className={clsx("m-0", titleClass)}>{title}</h2>
            {Content && (
              <Content
                onResult={onResult}
                {...contentProps}
                onCancel={onCancel}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
