/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminSchoolListDto
 */
export interface AdminSchoolListDto {
    /**
     * 
     * @type {number}
     * @memberof AdminSchoolListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminSchoolListDto
     */
    schoolCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSchoolListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSchoolListDto
     */
    logo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminSchoolListDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {Array<AdminSchoolListDto>}
     * @memberof AdminSchoolListDto
     */
    children?: Array<AdminSchoolListDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSchoolListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AdminSchoolListDto
     */
    creationTime?: Date;
}

export function AdminSchoolListDtoFromJSON(json: any): AdminSchoolListDto {
    return AdminSchoolListDtoFromJSONTyped(json, false);
}

export function AdminSchoolListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSchoolListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolCode': !exists(json, 'schoolCode') ? undefined : json['schoolCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(AdminSchoolListDtoFromJSON)),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
    };
}

export function AdminSchoolListDtoToJSON(value?: AdminSchoolListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolCode': value.schoolCode,
        'name': value.name,
        'logo': value.logo,
        'parentId': value.parentId,
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(AdminSchoolListDtoToJSON)),
        'isActive': value.isActive,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
    };
}

