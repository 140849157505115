/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderIntent,
    OrderIntentFromJSON,
    OrderIntentFromJSONTyped,
    OrderIntentToJSON,
} from './OrderIntent';
import {
    PreOrderItemInput,
    PreOrderItemInputFromJSON,
    PreOrderItemInputFromJSONTyped,
    PreOrderItemInputToJSON,
} from './PreOrderItemInput';

/**
 * 
 * @export
 * @interface PreOrderInput
 */
export interface PreOrderInput {
    /**
     * 
     * @type {Array<PreOrderItemInput>}
     * @memberof PreOrderInput
     */
    items?: Array<PreOrderItemInput> | null;
    /**
     * 
     * @type {number}
     * @memberof PreOrderInput
     */
    beneficiaryUserId?: number | null;
    /**
     * 
     * @type {OrderIntent}
     * @memberof PreOrderInput
     */
    orderIntent?: OrderIntent;
}

export function PreOrderInputFromJSON(json: any): PreOrderInput {
    return PreOrderInputFromJSONTyped(json, false);
}

export function PreOrderInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOrderInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(PreOrderItemInputFromJSON)),
        'beneficiaryUserId': !exists(json, 'beneficiaryUserId') ? undefined : json['beneficiaryUserId'],
        'orderIntent': !exists(json, 'orderIntent') ? undefined : OrderIntentFromJSON(json['orderIntent']),
    };
}

export function PreOrderInputToJSON(value?: PreOrderInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(PreOrderItemInputToJSON)),
        'beneficiaryUserId': value.beneficiaryUserId,
        'orderIntent': OrderIntentToJSON(value.orderIntent),
    };
}

