/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Ticket,
    TicketFromJSON,
    TicketFromJSONTyped,
    TicketToJSON,
} from './Ticket';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TicketComment
 */
export interface TicketComment {
    /**
     * 
     * @type {number}
     * @memberof TicketComment
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketComment
     */
    ticketId?: number;
    /**
     * 
     * @type {Ticket}
     * @memberof TicketComment
     */
    ticket?: Ticket;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof TicketComment
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TicketComment
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TicketComment
     */
    creatorUser?: User;
}

export function TicketCommentFromJSON(json: any): TicketComment {
    return TicketCommentFromJSONTyped(json, false);
}

export function TicketCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'ticket': !exists(json, 'ticket') ? undefined : TicketFromJSON(json['ticket']),
        'message': json['message'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creatorUser': !exists(json, 'creatorUser') ? undefined : UserFromJSON(json['creatorUser']),
    };
}

export function TicketCommentToJSON(value?: TicketComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ticketId': value.ticketId,
        'ticket': TicketToJSON(value.ticket),
        'message': value.message,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'creatorUser': UserToJSON(value.creatorUser),
    };
}

