/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroupTagDetailDto,
    StudentGroupTagDetailDtoFromJSON,
    StudentGroupTagDetailDtoFromJSONTyped,
    StudentGroupTagDetailDtoToJSON,
} from './StudentGroupTagDetailDto';
import {
    StudentGroupTranslationDto,
    StudentGroupTranslationDtoFromJSON,
    StudentGroupTranslationDtoFromJSONTyped,
    StudentGroupTranslationDtoToJSON,
} from './StudentGroupTranslationDto';

/**
 * 
 * @export
 * @interface CreateStudentGroupInput
 */
export interface CreateStudentGroupInput {
    /**
     * 
     * @type {number}
     * @memberof CreateStudentGroupInput
     */
    schoolId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateStudentGroupInput
     */
    studentGroupTagId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateStudentGroupInput
     */
    gradeId?: number | null;
    /**
     * 
     * @type {StudentGroupTagDetailDto}
     * @memberof CreateStudentGroupInput
     */
    studentGroupTag?: StudentGroupTagDetailDto;
    /**
     * 
     * @type {Array<StudentGroupTranslationDto>}
     * @memberof CreateStudentGroupInput
     */
    translations?: Array<StudentGroupTranslationDto> | null;
}

export function CreateStudentGroupInputFromJSON(json: any): CreateStudentGroupInput {
    return CreateStudentGroupInputFromJSONTyped(json, false);
}

export function CreateStudentGroupInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStudentGroupInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'studentGroupTagId': !exists(json, 'studentGroupTagId') ? undefined : json['studentGroupTagId'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'studentGroupTag': !exists(json, 'studentGroupTag') ? undefined : StudentGroupTagDetailDtoFromJSON(json['studentGroupTag']),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StudentGroupTranslationDtoFromJSON)),
    };
}

export function CreateStudentGroupInputToJSON(value?: CreateStudentGroupInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schoolId': value.schoolId,
        'studentGroupTagId': value.studentGroupTagId,
        'gradeId': value.gradeId,
        'studentGroupTag': StudentGroupTagDetailDtoToJSON(value.studentGroupTag),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StudentGroupTranslationDtoToJSON)),
    };
}

