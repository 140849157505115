/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserClaim
 */
export interface UserClaim {
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserClaim
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    tenantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    claimType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    claimValue?: string | null;
}

export function UserClaimFromJSON(json: any): UserClaim {
    return UserClaimFromJSONTyped(json, false);
}

export function UserClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserClaim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'claimType': !exists(json, 'claimType') ? undefined : json['claimType'],
        'claimValue': !exists(json, 'claimValue') ? undefined : json['claimValue'],
    };
}

export function UserClaimToJSON(value?: UserClaim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'claimType': value.claimType,
        'claimValue': value.claimValue,
    };
}

