/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SendEmailCaptchaInput,
    SendEmailCaptchaInputFromJSON,
    SendEmailCaptchaInputToJSON,
} from '../models';

export interface ApiCaptchaSendEmailCaptchaPostRequest {
    sendEmailCaptchaInput?: SendEmailCaptchaInput;
}

/**
 * 
 */
export class CaptchaApi extends runtime.BaseAPI {

    /**
     */
    async apiCaptchaSendEmailCaptchaPostRaw(requestParameters: ApiCaptchaSendEmailCaptchaPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Captcha/SendEmailCaptcha`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailCaptchaInputToJSON(requestParameters.sendEmailCaptchaInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCaptchaSendEmailCaptchaPost(requestParameters: ApiCaptchaSendEmailCaptchaPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCaptchaSendEmailCaptchaPostRaw(requestParameters, initOverrides);
    }

}
