/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentCustomFieldListDto
 */
export interface ContentCustomFieldListDto {
    /**
     * 
     * @type {number}
     * @memberof ContentCustomFieldListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentCustomFieldListDto
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldListDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldListDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldListDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldListDto
     */
    value?: string | null;
}

export function ContentCustomFieldListDtoFromJSON(json: any): ContentCustomFieldListDto {
    return ContentCustomFieldListDtoFromJSONTyped(json, false);
}

export function ContentCustomFieldListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentCustomFieldListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'label': json['label'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function ContentCustomFieldListDtoToJSON(value?: ContentCustomFieldListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'articleId': value.articleId,
        'name': value.name,
        'type': value.type,
        'label': value.label,
        'comment': value.comment,
        'value': value.value,
    };
}

