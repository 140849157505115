import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon as SelectorIcon } from '@heroicons/react/24/solid'
import { FieldProps } from 'formik';
import InputBox from '../InputBox/InputBox';
import { CommonInputProps } from '../common-input-props';
import clsx from 'clsx';
export interface SelectBoxProps<T extends any> extends CommonInputProps {
    defaultSelectedIdx?: number,
    options: T[],
    labelSelector: (obj: T) => string,
    placeholder?: string,
    onChange?: (value: T) => void,
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}
const SelectBox = <T extends any>(props: SelectBoxProps<T>) => {
  // console.log("props.options", props.options);
  // console.log("props.defaultSelectedIdx", props.defaultSelectedIdx);
  const { defaultSelectedIdx, options, labelSelector, label, errorMessage, placeholder, onChange, disableErrorMessage, inputProps, ...rest } = props;
  // console.log("props", props)
  // console.log("reGen", defaultSelectedIdx);
  const [selected, setSelected] = useState(defaultSelectedIdx != null && options[defaultSelectedIdx]);
  // console.log("reGen selected", defaultSelectedIdx!=null && options[defaultSelectedIdx]);
  const ref = useRef<typeof Listbox>();
  // console.log(defaultSelectedIdx)
  // function sliceValue(string:any){
  //     // const dataSource= string.data;
  //     // dataSource.slice(-1);
  //     console.log(string)
  // }
  useEffect(() => {
    // console.log(options)
    // console.log("defaultSelectedIdx", defaultSelectedIdx)
    // console.log("useEffect selected", selected);
    // debugger
    // console.log("useEffect defaultSelectedIdx", defaultSelectedIdx);
    // console.log("selected", selected);
    setSelected(defaultSelectedIdx != null && options[defaultSelectedIdx])

  }, [defaultSelectedIdx])

  // useEffect(()=>{
  //     // console.log(options)
  //     // console.log("defaultSelectedIdx", defaultSelectedIdx)
  //     // console.log("useEffect selected", selected);
  //     // debugger
  //     console.log("useEffect defaultSelectedIdx", defaultSelectedIdx);
  //     console.log("selected", selected);
  //     console.log(options.indexOf(selected as any));

  // },[selected])

  // return <Listbox {...rest} value={selected} onChange={v=>{ onChange?.(v as T); console.log("ListBox onChange", v); console.log("selected", selected); setSelected(v)}}>
  return <Listbox {...rest} value={selected} onChange={v => { onChange?.(v as T); setSelected(v); }}>


    <div className="relative group">
      <Listbox.Button as={(props: any) => {
        return <InputBox type="text" label={label} errorMessage={errorMessage} disableErrorMessage={disableErrorMessage}
          inputProps={{
            ...inputProps,
            placeholder: placeholder,
            ...props, readOnly: true, value: selected && labelSelector(selected), className: clsx("hover:cursor-pointer group-hover:ring-primary-500 group-hover:border-primary-500 group-active:ring-primary-500", inputProps?.className)
          }}
          endAdornments={<SelectorIcon
            className="w-5 h-5"
            aria-hidden="true"
          />}
        />
      }}>

      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white/80 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm backdrop-blur-md z-50">
          {options.map((option, optionIdx) => (
            <Listbox.Option
              key={optionIdx}
              className={({ active }) =>
                `${active ? 'text-primary-900 bg-primary-50' : ''}
                     cursor-default select-none relative py-2 pl-10 pr-4  z-50`
              }
              value={option}
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={`${selected ? 'font-medium' : 'font-normal'
                    } block truncate`}
                  >
                    {labelSelector(option)}
                  </span>
                  {selected ? (
                    <span
                      className={`${active ? 'text-primary-500' : 'text-primary-500'
                      }
                           absolute inset-y-0 left-0 flex items-center pl-3`}
                    >
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  </Listbox>
}


export const FormikSelectBox = <T extends any>(options: T[], labelSelector: (obj: T) => string, valueSelector?: (obj: T) => any) => (props: SelectBoxProps<T> & FieldProps<any, any>) =>
  SelectBox({
    // ...props.field,
    // labelSelector: labelSelector,
    // defaultSelectedIdx:  options.indexOf(props.field.value),
    // options: options,
    // label: props.label ?? props.field.name,
    // errorMessage: props.errorMessage ?? props.form?.errors?.[props.field?.name],
    // onChange: (v)=>props.form?.setFieldValue(props.field?.name, v)

    ...props.field,
    labelSelector: labelSelector,
    // defaultSelectedIdx: options.indexOf(props.field.value),
    //  defaultSelectedIdx:  options.findIndex((it)=>valueSelector(it)==props.field.value),
    defaultSelectedIdx: valueSelector == null ? options.findIndex((it) => it == props.field.value) : options.findIndex((it) => valueSelector(it) == props.field.value),
    options: options,
    label: props.label ?? props.field.name,
    errorMessage: props.errorMessage ?? props.form?.errors?.[props.field?.name],
    onChange: (v) => { props.form?.setFieldValue(props.field?.name, valueSelector == null ? v : valueSelector(v)); props.onChange?.(v) },
  });

SelectBox.displayName = 'SelectBox';
export default SelectBox;