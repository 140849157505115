/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreOrderInput,
    PreOrderInputFromJSON,
    PreOrderInputFromJSONTyped,
    PreOrderInputToJSON,
} from './PreOrderInput';

/**
 * 
 * @export
 * @interface CreateOrderInput
 */
export interface CreateOrderInput {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInput
     */
    gateway?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderInput
     */
    orderId?: number | null;
    /**
     * 
     * @type {PreOrderInput}
     * @memberof CreateOrderInput
     */
    newOrder?: PreOrderInput;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInput
     */
    successUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInput
     */
    cancelUrl?: string | null;
}

export function CreateOrderInputFromJSON(json: any): CreateOrderInput {
    return CreateOrderInputFromJSONTyped(json, false);
}

export function CreateOrderInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gateway': !exists(json, 'gateway') ? undefined : json['gateway'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'newOrder': !exists(json, 'newOrder') ? undefined : PreOrderInputFromJSON(json['newOrder']),
        'successUrl': !exists(json, 'successUrl') ? undefined : json['successUrl'],
        'cancelUrl': !exists(json, 'cancelUrl') ? undefined : json['cancelUrl'],
    };
}

export function CreateOrderInputToJSON(value?: CreateOrderInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gateway': value.gateway,
        'orderId': value.orderId,
        'newOrder': PreOrderInputToJSON(value.newOrder),
        'successUrl': value.successUrl,
        'cancelUrl': value.cancelUrl,
    };
}

