/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface SubjectiveQuestionListDto
 */
export interface SubjectiveQuestionListDto {
    /**
     * 
     * @type {number}
     * @memberof SubjectiveQuestionListDto
     */
    id?: number;
    /**
     * 
     * @type {QuestionType}
     * @memberof SubjectiveQuestionListDto
     */
    questionType?: QuestionType;
    /**
     * 
     * @type {string}
     * @memberof SubjectiveQuestionListDto
     */
    questionBody?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SubjectiveQuestionListDto
     */
    options?: any | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectiveQuestionListDto
     */
    stars?: number;
    /**
     * 
     * @type {any}
     * @memberof SubjectiveQuestionListDto
     */
    extra?: any | null;
    /**
     * 
     * @type {Array<SubjectiveQuestionListDto>}
     * @memberof SubjectiveQuestionListDto
     */
    subQuestions?: Array<SubjectiveQuestionListDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof SubjectiveQuestionListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubjectiveQuestionListDto
     */
    finishTime?: Date | null;
}

export function SubjectiveQuestionListDtoFromJSON(json: any): SubjectiveQuestionListDto {
    return SubjectiveQuestionListDtoFromJSONTyped(json, false);
}

export function SubjectiveQuestionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectiveQuestionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeFromJSON(json['questionType']),
        'questionBody': !exists(json, 'questionBody') ? undefined : json['questionBody'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'subQuestions': !exists(json, 'subQuestions') ? undefined : (json['subQuestions'] === null ? null : (json['subQuestions'] as Array<any>).map(SubjectiveQuestionListDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'finishTime': !exists(json, 'finishTime') ? undefined : (json['finishTime'] === null ? null : new Date(json['finishTime'])),
    };
}

export function SubjectiveQuestionListDtoToJSON(value?: SubjectiveQuestionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'questionType': QuestionTypeToJSON(value.questionType),
        'questionBody': value.questionBody,
        'options': value.options,
        'stars': value.stars,
        'extra': value.extra,
        'subQuestions': value.subQuestions === undefined ? undefined : (value.subQuestions === null ? null : (value.subQuestions as Array<any>).map(SubjectiveQuestionListDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'finishTime': value.finishTime === undefined ? undefined : (value.finishTime === null ? null : value.finishTime.toISOString()),
    };
}

