/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastLoginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    roleNames?: Array<string> | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': json['userName'],
        'name': json['name'],
        'surname': json['surname'],
        'emailAddress': json['emailAddress'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'lastLoginTime': !exists(json, 'lastLoginTime') ? undefined : (json['lastLoginTime'] === null ? null : new Date(json['lastLoginTime'])),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'roleNames': !exists(json, 'roleNames') ? undefined : json['roleNames'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'isActive': value.isActive,
        'fullName': value.fullName,
        'lastLoginTime': value.lastLoginTime === undefined ? undefined : (value.lastLoginTime === null ? null : value.lastLoginTime.toISOString()),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'roleNames': value.roleNames,
    };
}

