/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * 
     * @type {number}
     * @memberof TenantDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    tenancyName: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDto
     */
    isActive?: boolean;
}

export function TenantDtoFromJSON(json: any): TenantDto {
    return TenantDtoFromJSONTyped(json, false);
}

export function TenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenancyName': json['tenancyName'],
        'name': json['name'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function TenantDtoToJSON(value?: TenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenancyName': value.tenancyName,
        'name': value.name,
        'isActive': value.isActive,
    };
}

