/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentCustomFieldTranslationDto,
    ContentCustomFieldTranslationDtoFromJSON,
    ContentCustomFieldTranslationDtoFromJSONTyped,
    ContentCustomFieldTranslationDtoToJSON,
} from './ContentCustomFieldTranslationDto';

/**
 * 
 * @export
 * @interface ContentCustomFieldDto
 */
export interface ContentCustomFieldDto {
    /**
     * 
     * @type {number}
     * @memberof ContentCustomFieldDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContentCustomFieldDto
     */
    articleId?: number;
    /**
     * 
     * @type {Array<ContentCustomFieldTranslationDto>}
     * @memberof ContentCustomFieldDto
     */
    translations?: Array<ContentCustomFieldTranslationDto> | null;
}

export function ContentCustomFieldDtoFromJSON(json: any): ContentCustomFieldDto {
    return ContentCustomFieldDtoFromJSONTyped(json, false);
}

export function ContentCustomFieldDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentCustomFieldDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(ContentCustomFieldTranslationDtoFromJSON)),
    };
}

export function ContentCustomFieldDtoToJSON(value?: ContentCustomFieldDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'articleId': value.articleId,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(ContentCustomFieldTranslationDtoToJSON)),
    };
}

