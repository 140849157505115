/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchoolTranslation,
    SchoolTranslationFromJSON,
    SchoolTranslationFromJSONTyped,
    SchoolTranslationToJSON,
} from './SchoolTranslation';
import {
    StudentGroup,
    StudentGroupFromJSON,
    StudentGroupFromJSONTyped,
    StudentGroupToJSON,
} from './StudentGroup';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface School
 */
export interface School {
    /**
     * 
     * @type {number}
     * @memberof School
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    schoolCode: string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    schoolPhone: string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    contactEmail: string;
    /**
     * 
     * @type {number}
     * @memberof School
     */
    parentId?: number | null;
    /**
     * 
     * @type {School}
     * @memberof School
     */
    parent?: School;
    /**
     * 
     * @type {Array<User>}
     * @memberof School
     */
    adminUsers?: Array<User> | null;
    /**
     * 
     * @type {Array<School>}
     * @memberof School
     */
    children?: Array<School> | null;
    /**
     * 
     * @type {Array<StudentGroup>}
     * @memberof School
     */
    studentGroups?: Array<StudentGroup> | null;
    /**
     * 
     * @type {Array<SchoolTranslation>}
     * @memberof School
     */
    translations?: Array<SchoolTranslation> | null;
    /**
     * 
     * @type {number}
     * @memberof School
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof School
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof School
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof School
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof School
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof School
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof School
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof School
     */
    isActive?: boolean;
}

export function SchoolFromJSON(json: any): School {
    return SchoolFromJSONTyped(json, false);
}

export function SchoolFromJSONTyped(json: any, ignoreDiscriminator: boolean): School {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolCode': json['schoolCode'],
        'schoolPhone': json['schoolPhone'],
        'logo': json['logo'],
        'contactPhone': json['contactPhone'],
        'contactEmail': json['contactEmail'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'parent': !exists(json, 'parent') ? undefined : SchoolFromJSON(json['parent']),
        'adminUsers': !exists(json, 'adminUsers') ? undefined : (json['adminUsers'] === null ? null : (json['adminUsers'] as Array<any>).map(UserFromJSON)),
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(SchoolFromJSON)),
        'studentGroups': !exists(json, 'studentGroups') ? undefined : (json['studentGroups'] === null ? null : (json['studentGroups'] as Array<any>).map(StudentGroupFromJSON)),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(SchoolTranslationFromJSON)),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function SchoolToJSON(value?: School | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolCode': value.schoolCode,
        'schoolPhone': value.schoolPhone,
        'logo': value.logo,
        'contactPhone': value.contactPhone,
        'contactEmail': value.contactEmail,
        'parentId': value.parentId,
        'parent': SchoolToJSON(value.parent),
        'adminUsers': value.adminUsers === undefined ? undefined : (value.adminUsers === null ? null : (value.adminUsers as Array<any>).map(UserToJSON)),
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(SchoolToJSON)),
        'studentGroups': value.studentGroups === undefined ? undefined : (value.studentGroups === null ? null : (value.studentGroups as Array<any>).map(StudentGroupToJSON)),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(SchoolTranslationToJSON)),
        'creatorUserId': value.creatorUserId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
        'isActive': value.isActive,
    };
}

