/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentCustomFieldTranslationDto
 */
export interface ContentCustomFieldTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof ContentCustomFieldTranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldTranslationDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldTranslationDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldTranslationDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentCustomFieldTranslationDto
     */
    language?: string | null;
}

export function ContentCustomFieldTranslationDtoFromJSON(json: any): ContentCustomFieldTranslationDto {
    return ContentCustomFieldTranslationDtoFromJSONTyped(json, false);
}

export function ContentCustomFieldTranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentCustomFieldTranslationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': json['label'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function ContentCustomFieldTranslationDtoToJSON(value?: ContentCustomFieldTranslationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'comment': value.comment,
        'value': value.value,
        'language': value.language,
    };
}

