/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentCallback,
    PaymentCallbackFromJSON,
    PaymentCallbackToJSON,
    PaymentListDtoListResultDto,
    PaymentListDtoListResultDtoFromJSON,
    PaymentListDtoListResultDtoToJSON,
} from '../models';

export interface ApiServicesAppPaymentGetAvailablePaymentsGetRequest {
    skipCache?: boolean;
}

export interface PaymentNotifyGatewayPostRequest {
    gateway: string;
    body?: any | null;
}

/**
 * 
 */
export class PaymentApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppPaymentGetAvailablePaymentsGetRaw(requestParameters: ApiServicesAppPaymentGetAvailablePaymentsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.skipCache !== undefined) {
            queryParameters['skipCache'] = requestParameters.skipCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Payment/GetAvailablePayments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppPaymentGetAvailablePaymentsGet(requestParameters: ApiServicesAppPaymentGetAvailablePaymentsGetRequest, initOverrides?: RequestInit): Promise<PaymentListDtoListResultDto> {
        const response = await this.apiServicesAppPaymentGetAvailablePaymentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentNotifyGatewayPostRaw(requestParameters: PaymentNotifyGatewayPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentCallback>> {
        if (requestParameters.gateway === null || requestParameters.gateway === undefined) {
            throw new runtime.RequiredError('gateway','Required parameter requestParameters.gateway was null or undefined when calling paymentNotifyGatewayPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/payment/notify/{gateway}`.replace(`{${"gateway"}}`, encodeURIComponent(String(requestParameters.gateway))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentCallbackFromJSON(jsonValue));
    }

    /**
     */
    async paymentNotifyGatewayPost(requestParameters: PaymentNotifyGatewayPostRequest, initOverrides?: RequestInit): Promise<PaymentCallback> {
        const response = await this.paymentNotifyGatewayPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
