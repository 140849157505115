/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuntimeMethodHandle
 */
export interface RuntimeMethodHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeMethodHandle
     */
    value?: object;
}

export function RuntimeMethodHandleFromJSON(json: any): RuntimeMethodHandle {
    return RuntimeMethodHandleFromJSONTyped(json, false);
}

export function RuntimeMethodHandleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeMethodHandle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function RuntimeMethodHandleToJSON(value?: RuntimeMethodHandle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}

