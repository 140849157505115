/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExaminationExportAssigneeListDto
 */
export interface ExaminationExportAssigneeListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssigneeListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportAssigneeListDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportAssigneeListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportAssigneeListDto
     */
    surname?: string | null;
}

export function ExaminationExportAssigneeListDtoFromJSON(json: any): ExaminationExportAssigneeListDto {
    return ExaminationExportAssigneeListDtoFromJSONTyped(json, false);
}

export function ExaminationExportAssigneeListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportAssigneeListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
    };
}

export function ExaminationExportAssigneeListDtoToJSON(value?: ExaminationExportAssigneeListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
    };
}

