/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalAuthenticateResultModel
 */
export interface ExternalAuthenticateResultModel {
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateResultModel
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateResultModel
     */
    encryptedAccessToken?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExternalAuthenticateResultModel
     */
    expireInSeconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalAuthenticateResultModel
     */
    waitingForActivation?: boolean;
}

export function ExternalAuthenticateResultModelFromJSON(json: any): ExternalAuthenticateResultModel {
    return ExternalAuthenticateResultModelFromJSONTyped(json, false);
}

export function ExternalAuthenticateResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAuthenticateResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'encryptedAccessToken': !exists(json, 'encryptedAccessToken') ? undefined : json['encryptedAccessToken'],
        'expireInSeconds': !exists(json, 'expireInSeconds') ? undefined : json['expireInSeconds'],
        'waitingForActivation': !exists(json, 'waitingForActivation') ? undefined : json['waitingForActivation'],
    };
}

export function ExternalAuthenticateResultModelToJSON(value?: ExternalAuthenticateResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'encryptedAccessToken': value.encryptedAccessToken,
        'expireInSeconds': value.expireInSeconds,
        'waitingForActivation': value.waitingForActivation,
    };
}

