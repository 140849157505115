/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TicketCommentListDto,
    TicketCommentListDtoFromJSON,
    TicketCommentListDtoFromJSONTyped,
    TicketCommentListDtoToJSON,
} from './TicketCommentListDto';
import {
    TicketStatus,
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface UserTicketListDto
 */
export interface UserTicketListDto {
    /**
     * 
     * @type {number}
     * @memberof UserTicketListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserTicketListDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof UserTicketListDto
     */
    message: string;
    /**
     * 
     * @type {TicketStatus}
     * @memberof UserTicketListDto
     */
    status?: TicketStatus;
    /**
     * 
     * @type {Date}
     * @memberof UserTicketListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {TicketCommentListDto}
     * @memberof UserTicketListDto
     */
    lastestComment?: TicketCommentListDto;
    /**
     * 
     * @type {Array<TicketCommentListDto>}
     * @memberof UserTicketListDto
     */
    comments?: Array<TicketCommentListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof UserTicketListDto
     */
    readonly commentCount?: number;
}

export function UserTicketListDtoFromJSON(json: any): UserTicketListDto {
    return UserTicketListDtoFromJSONTyped(json, false);
}

export function UserTicketListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTicketListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subject': json['subject'],
        'message': json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastestComment': !exists(json, 'lastestComment') ? undefined : TicketCommentListDtoFromJSON(json['lastestComment']),
        'comments': !exists(json, 'comments') ? undefined : (json['comments'] === null ? null : (json['comments'] as Array<any>).map(TicketCommentListDtoFromJSON)),
        'commentCount': !exists(json, 'commentCount') ? undefined : json['commentCount'],
    };
}

export function UserTicketListDtoToJSON(value?: UserTicketListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastestComment': TicketCommentListDtoToJSON(value.lastestComment),
        'comments': value.comments === undefined ? undefined : (value.comments === null ? null : (value.comments as Array<any>).map(TicketCommentListDtoToJSON)),
    };
}

