/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticateModel,
    AuthenticateModelFromJSON,
    AuthenticateModelToJSON,
    AuthenticateResultModel,
    AuthenticateResultModelFromJSON,
    AuthenticateResultModelToJSON,
    AuthenticationScheme,
    AuthenticationSchemeFromJSON,
    AuthenticationSchemeToJSON,
    ExternalAuthenticateModel,
    ExternalAuthenticateModelFromJSON,
    ExternalAuthenticateModelToJSON,
    ExternalAuthenticateResultModel,
    ExternalAuthenticateResultModelFromJSON,
    ExternalAuthenticateResultModelToJSON,
    ExternalLoginProviderInfoModel,
    ExternalLoginProviderInfoModelFromJSON,
    ExternalLoginProviderInfoModelToJSON,
    RefreshTokenModel,
    RefreshTokenModelFromJSON,
    RefreshTokenModelToJSON,
} from '../models';

export interface ApiTokenAuthAuthenticatePostRequest {
    authenticateModel?: AuthenticateModel;
}

export interface ApiTokenAuthExternalAuthenticatePostRequest {
    externalAuthenticateModel?: ExternalAuthenticateModel;
}

export interface ApiTokenAuthExternalLoginCallbackGetRequest {
    returnUrl?: string;
}

export interface ApiTokenAuthExternalLoginGetRequest {
    provider?: string;
    returnUrl?: string;
}

export interface ApiTokenAuthExternalLoginPostRequest {
    provider?: string;
    returnUrl?: string;
}

export interface ApiTokenAuthRefreshTokenPostRequest {
    refreshTokenModel?: RefreshTokenModel;
}

/**
 * 
 */
export class TokenAuthApi extends runtime.BaseAPI {

    /**
     */
    async apiTokenAuthAuthenticatePostRaw(requestParameters: ApiTokenAuthAuthenticatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthenticateResultModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/TokenAuth/Authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateModelToJSON(requestParameters.authenticateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResultModelFromJSON(jsonValue));
    }

    /**
     */
    async apiTokenAuthAuthenticatePost(requestParameters: ApiTokenAuthAuthenticatePostRequest, initOverrides?: RequestInit): Promise<AuthenticateResultModel> {
        const response = await this.apiTokenAuthAuthenticatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTokenAuthExternalAuthenticatePostRaw(requestParameters: ApiTokenAuthExternalAuthenticatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalAuthenticateResultModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/TokenAuth/ExternalAuthenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalAuthenticateModelToJSON(requestParameters.externalAuthenticateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalAuthenticateResultModelFromJSON(jsonValue));
    }

    /**
     */
    async apiTokenAuthExternalAuthenticatePost(requestParameters: ApiTokenAuthExternalAuthenticatePostRequest, initOverrides?: RequestInit): Promise<ExternalAuthenticateResultModel> {
        const response = await this.apiTokenAuthExternalAuthenticatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTokenAuthExternalLoginCallbackGetRaw(requestParameters: ApiTokenAuthExternalLoginCallbackGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/TokenAuth/ExternalLoginCallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTokenAuthExternalLoginCallbackGet(requestParameters: ApiTokenAuthExternalLoginCallbackGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTokenAuthExternalLoginCallbackGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTokenAuthExternalLoginGetRaw(requestParameters: ApiTokenAuthExternalLoginGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/TokenAuth/ExternalLogin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTokenAuthExternalLoginGet(requestParameters: ApiTokenAuthExternalLoginGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTokenAuthExternalLoginGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTokenAuthExternalLoginPostRaw(requestParameters: ApiTokenAuthExternalLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/TokenAuth/ExternalLogin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTokenAuthExternalLoginPost(requestParameters: ApiTokenAuthExternalLoginPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTokenAuthExternalLoginPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTokenAuthGetExternalAuthenticationProvidersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ExternalLoginProviderInfoModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/TokenAuth/GetExternalAuthenticationProviders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalLoginProviderInfoModelFromJSON));
    }

    /**
     */
    async apiTokenAuthGetExternalAuthenticationProvidersGet(initOverrides?: RequestInit): Promise<Array<ExternalLoginProviderInfoModel>> {
        const response = await this.apiTokenAuthGetExternalAuthenticationProvidersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTokenAuthGetExternalAuthenticationSchemesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AuthenticationScheme>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/TokenAuth/GetExternalAuthenticationSchemes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthenticationSchemeFromJSON));
    }

    /**
     */
    async apiTokenAuthGetExternalAuthenticationSchemesGet(initOverrides?: RequestInit): Promise<Array<AuthenticationScheme>> {
        const response = await this.apiTokenAuthGetExternalAuthenticationSchemesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTokenAuthRefreshTokenPostRaw(requestParameters: ApiTokenAuthRefreshTokenPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthenticateResultModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/TokenAuth/RefreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenModelToJSON(requestParameters.refreshTokenModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResultModelFromJSON(jsonValue));
    }

    /**
     */
    async apiTokenAuthRefreshTokenPost(requestParameters: ApiTokenAuthRefreshTokenPostRequest, initOverrides?: RequestInit): Promise<AuthenticateResultModel> {
        const response = await this.apiTokenAuthRefreshTokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
