/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailTemplateTranslationDto,
    EmailTemplateTranslationDtoFromJSON,
    EmailTemplateTranslationDtoFromJSONTyped,
    EmailTemplateTranslationDtoToJSON,
} from './EmailTemplateTranslationDto';

/**
 * 
 * @export
 * @interface EmailTemplateDto
 */
export interface EmailTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateDto
     */
    params?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EmailTemplateDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailTemplateDto
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {Array<EmailTemplateTranslationDto>}
     * @memberof EmailTemplateDto
     */
    translations?: Array<EmailTemplateTranslationDto> | null;
}

export function EmailTemplateDtoFromJSON(json: any): EmailTemplateDto {
    return EmailTemplateDtoFromJSONTyped(json, false);
}

export function EmailTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': json['slug'],
        'params': !exists(json, 'params') ? undefined : json['params'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(EmailTemplateTranslationDtoFromJSON)),
    };
}

export function EmailTemplateDtoToJSON(value?: EmailTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'params': value.params,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(EmailTemplateTranslationDtoToJSON)),
    };
}

