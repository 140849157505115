/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface QuestionTypeListDto
 */
export interface QuestionTypeListDto {
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionTypeListDto
     */
    questionType?: QuestionType;
    /**
     * 
     * @type {string}
     * @memberof QuestionTypeListDto
     */
    questionTypeName?: string | null;
}

export function QuestionTypeListDtoFromJSON(json: any): QuestionTypeListDto {
    return QuestionTypeListDtoFromJSONTyped(json, false);
}

export function QuestionTypeListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionTypeListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeFromJSON(json['questionType']),
        'questionTypeName': !exists(json, 'questionTypeName') ? undefined : json['questionTypeName'],
    };
}

export function QuestionTypeListDtoToJSON(value?: QuestionTypeListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionType': QuestionTypeToJSON(value.questionType),
        'questionTypeName': value.questionTypeName,
    };
}

