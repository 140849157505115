/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportGradeListDto,
    ExaminationExportGradeListDtoFromJSON,
    ExaminationExportGradeListDtoFromJSONTyped,
    ExaminationExportGradeListDtoToJSON,
} from './ExaminationExportGradeListDto';
import {
    ExaminationExportSubjectListDto,
    ExaminationExportSubjectListDtoFromJSON,
    ExaminationExportSubjectListDtoFromJSONTyped,
    ExaminationExportSubjectListDtoToJSON,
} from './ExaminationExportSubjectListDto';

/**
 * 
 * @export
 * @interface ExportExaminationSettingDto
 */
export interface ExportExaminationSettingDto {
    /**
     * 
     * @type {string}
     * @memberof ExportExaminationSettingDto
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportExaminationSettingDto
     */
    schoolName?: string | null;
    /**
     * 
     * @type {Array<ExaminationExportGradeListDto>}
     * @memberof ExportExaminationSettingDto
     */
    grades?: Array<ExaminationExportGradeListDto> | null;
    /**
     * 
     * @type {Array<ExaminationExportSubjectListDto>}
     * @memberof ExportExaminationSettingDto
     */
    subjects?: Array<ExaminationExportSubjectListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExportExaminationSettingDto
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportExaminationSettingDto
     */
    timeLimit?: number;
}

export function ExportExaminationSettingDtoFromJSON(json: any): ExportExaminationSettingDto {
    return ExportExaminationSettingDtoFromJSONTyped(json, false);
}

export function ExportExaminationSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportExaminationSettingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] === null ? null : (json['grades'] as Array<any>).map(ExaminationExportGradeListDtoFromJSON)),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(ExaminationExportSubjectListDtoFromJSON)),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'timeLimit': !exists(json, 'timeLimit') ? undefined : json['timeLimit'],
    };
}

export function ExportExaminationSettingDtoToJSON(value?: ExportExaminationSettingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logo': value.logo,
        'schoolName': value.schoolName,
        'grades': value.grades === undefined ? undefined : (value.grades === null ? null : (value.grades as Array<any>).map(ExaminationExportGradeListDtoToJSON)),
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(ExaminationExportSubjectListDtoToJSON)),
        'points': value.points,
        'timeLimit': value.timeLimit,
    };
}

