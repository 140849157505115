/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FAQCategoryAdminListDtoListResultDto,
    FAQCategoryAdminListDtoListResultDtoFromJSON,
    FAQCategoryAdminListDtoListResultDtoToJSON,
    FAQCategoryDto,
    FAQCategoryDtoFromJSON,
    FAQCategoryDtoToJSON,
    FAQCategoryDtoPagedResultDto,
    FAQCategoryDtoPagedResultDtoFromJSON,
    FAQCategoryDtoPagedResultDtoToJSON,
    FAQCategoryListDtoListResultDto,
    FAQCategoryListDtoListResultDtoFromJSON,
    FAQCategoryListDtoListResultDtoToJSON,
    ReorderInput,
    ReorderInputFromJSON,
    ReorderInputToJSON,
} from '../models';

export interface ApiServicesAppFAQCategoryCreatePostRequest {
    fAQCategoryDto?: FAQCategoryDto;
}

export interface ApiServicesAppFAQCategoryDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppFAQCategoryGetAllGetRequest {
    skipCount?: number;
    maxResultCount?: number;
    sorting?: string;
}

export interface ApiServicesAppFAQCategoryGetAllTranslatedGetRequest {
    isActive?: boolean;
    keyword?: string;
}

export interface ApiServicesAppFAQCategoryGetGetRequest {
    id?: number;
}

export interface ApiServicesAppFAQCategoryReorderPostRequest {
    reorderInput?: ReorderInput;
}

export interface ApiServicesAppFAQCategoryUpdatePutRequest {
    fAQCategoryDto?: FAQCategoryDto;
}

/**
 * 
 */
export class FAQCategoryApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppFAQCategoryCreatePostRaw(requestParameters: ApiServicesAppFAQCategoryCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQCategoryDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/FAQCategory/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FAQCategoryDtoToJSON(requestParameters.fAQCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQCategoryCreatePost(requestParameters: ApiServicesAppFAQCategoryCreatePostRequest, initOverrides?: RequestInit): Promise<FAQCategoryDto> {
        const response = await this.apiServicesAppFAQCategoryCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQCategoryDeleteDeleteRaw(requestParameters: ApiServicesAppFAQCategoryDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQCategory/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppFAQCategoryDeleteDelete(requestParameters: ApiServicesAppFAQCategoryDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppFAQCategoryDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppFAQCategoryGetActivatedCategoriesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQCategoryListDtoListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQCategory/GetActivatedCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQCategoryListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQCategoryGetActivatedCategoriesGet(initOverrides?: RequestInit): Promise<FAQCategoryListDtoListResultDto> {
        const response = await this.apiServicesAppFAQCategoryGetActivatedCategoriesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQCategoryGetAllGetRaw(requestParameters: ApiServicesAppFAQCategoryGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQCategoryDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQCategory/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQCategoryDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQCategoryGetAllGet(requestParameters: ApiServicesAppFAQCategoryGetAllGetRequest, initOverrides?: RequestInit): Promise<FAQCategoryDtoPagedResultDto> {
        const response = await this.apiServicesAppFAQCategoryGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQCategoryGetAllTranslatedGetRaw(requestParameters: ApiServicesAppFAQCategoryGetAllTranslatedGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQCategoryAdminListDtoListResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['Keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQCategory/GetAllTranslated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQCategoryAdminListDtoListResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQCategoryGetAllTranslatedGet(requestParameters: ApiServicesAppFAQCategoryGetAllTranslatedGetRequest, initOverrides?: RequestInit): Promise<FAQCategoryAdminListDtoListResultDto> {
        const response = await this.apiServicesAppFAQCategoryGetAllTranslatedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQCategoryGetGetRaw(requestParameters: ApiServicesAppFAQCategoryGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQCategoryDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/FAQCategory/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQCategoryGetGet(requestParameters: ApiServicesAppFAQCategoryGetGetRequest, initOverrides?: RequestInit): Promise<FAQCategoryDto> {
        const response = await this.apiServicesAppFAQCategoryGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppFAQCategoryReorderPostRaw(requestParameters: ApiServicesAppFAQCategoryReorderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/FAQCategory/Reorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderInputToJSON(requestParameters.reorderInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppFAQCategoryReorderPost(requestParameters: ApiServicesAppFAQCategoryReorderPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppFAQCategoryReorderPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppFAQCategoryUpdatePutRaw(requestParameters: ApiServicesAppFAQCategoryUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FAQCategoryDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/FAQCategory/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FAQCategoryDtoToJSON(requestParameters.fAQCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FAQCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppFAQCategoryUpdatePut(requestParameters: ApiServicesAppFAQCategoryUpdatePutRequest, initOverrides?: RequestInit): Promise<FAQCategoryDto> {
        const response = await this.apiServicesAppFAQCategoryUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
