/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GradeListDto,
    GradeListDtoFromJSON,
    GradeListDtoFromJSONTyped,
    GradeListDtoToJSON,
} from './GradeListDto';

/**
 * 
 * @export
 * @interface StageWithGradesListDto
 */
export interface StageWithGradesListDto {
    /**
     * 
     * @type {number}
     * @memberof StageWithGradesListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StageWithGradesListDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof StageWithGradesListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StageWithGradesListDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<GradeListDto>}
     * @memberof StageWithGradesListDto
     */
    grades?: Array<GradeListDto> | null;
}

export function StageWithGradesListDtoFromJSON(json: any): StageWithGradesListDto {
    return StageWithGradesListDtoFromJSONTyped(json, false);
}

export function StageWithGradesListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StageWithGradesListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': json['code'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] === null ? null : (json['grades'] as Array<any>).map(GradeListDtoFromJSON)),
    };
}

export function StageWithGradesListDtoToJSON(value?: StageWithGradesListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'description': value.description,
        'grades': value.grades === undefined ? undefined : (value.grades === null ? null : (value.grades as Array<any>).map(GradeListDtoToJSON)),
    };
}

