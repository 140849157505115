/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminLessonListDtoPagedResultDto,
    AdminLessonListDtoPagedResultDtoFromJSON,
    AdminLessonListDtoPagedResultDtoToJSON,
    Int32EntityDto,
    Int32EntityDtoFromJSON,
    Int32EntityDtoToJSON,
    LessonDto,
    LessonDtoFromJSON,
    LessonDtoToJSON,
    ReorderInput,
    ReorderInputFromJSON,
    ReorderInputToJSON,
} from '../models';

export interface ApiServicesAppLessonCreatePostRequest {
    lessonDto?: LessonDto;
}

export interface ApiServicesAppLessonDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppLessonGetAllGetRequest {
    isActive?: boolean;
    keyWords?: string;
    subjectId?: number;
    schoolOnly?: boolean;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppLessonGetGetRequest {
    id?: number;
}

export interface ApiServicesAppLessonReorderPostRequest {
    reorderInput?: ReorderInput;
}

export interface ApiServicesAppLessonToggleActivationPostRequest {
    int32EntityDto?: Int32EntityDto;
}

export interface ApiServicesAppLessonUpdatePutRequest {
    lessonDto?: LessonDto;
}

/**
 * 
 */
export class LessonApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppLessonCreatePostRaw(requestParameters: ApiServicesAppLessonCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessonDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Lesson/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessonDtoToJSON(requestParameters.lessonDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppLessonCreatePost(requestParameters: ApiServicesAppLessonCreatePostRequest, initOverrides?: RequestInit): Promise<LessonDto> {
        const response = await this.apiServicesAppLessonCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppLessonDeleteDeleteRaw(requestParameters: ApiServicesAppLessonDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Lesson/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppLessonDeleteDelete(requestParameters: ApiServicesAppLessonDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppLessonDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppLessonGetAllGetRaw(requestParameters: ApiServicesAppLessonGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminLessonListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.isActive !== undefined) {
            queryParameters['IsActive'] = requestParameters.isActive;
        }

        if (requestParameters.keyWords !== undefined) {
            queryParameters['KeyWords'] = requestParameters.keyWords;
        }

        if (requestParameters.subjectId !== undefined) {
            queryParameters['SubjectId'] = requestParameters.subjectId;
        }

        if (requestParameters.schoolOnly !== undefined) {
            queryParameters['SchoolOnly'] = requestParameters.schoolOnly;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Lesson/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminLessonListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppLessonGetAllGet(requestParameters: ApiServicesAppLessonGetAllGetRequest, initOverrides?: RequestInit): Promise<AdminLessonListDtoPagedResultDto> {
        const response = await this.apiServicesAppLessonGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppLessonGetGetRaw(requestParameters: ApiServicesAppLessonGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessonDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Lesson/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppLessonGetGet(requestParameters: ApiServicesAppLessonGetGetRequest, initOverrides?: RequestInit): Promise<LessonDto> {
        const response = await this.apiServicesAppLessonGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppLessonReorderPostRaw(requestParameters: ApiServicesAppLessonReorderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Lesson/Reorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderInputToJSON(requestParameters.reorderInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppLessonReorderPost(requestParameters: ApiServicesAppLessonReorderPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppLessonReorderPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppLessonToggleActivationPostRaw(requestParameters: ApiServicesAppLessonToggleActivationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessonDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Lesson/ToggleActivation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Int32EntityDtoToJSON(requestParameters.int32EntityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppLessonToggleActivationPost(requestParameters: ApiServicesAppLessonToggleActivationPostRequest, initOverrides?: RequestInit): Promise<LessonDto> {
        const response = await this.apiServicesAppLessonToggleActivationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppLessonUpdatePutRaw(requestParameters: ApiServicesAppLessonUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessonDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Lesson/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LessonDtoToJSON(requestParameters.lessonDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppLessonUpdatePut(requestParameters: ApiServicesAppLessonUpdatePutRequest, initOverrides?: RequestInit): Promise<LessonDto> {
        const response = await this.apiServicesAppLessonUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
