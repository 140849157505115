/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryOrderInput
 */
export interface QueryOrderInput {
    /**
     * 
     * @type {string}
     * @memberof QueryOrderInput
     */
    externalTransactionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QueryOrderInput
     */
    subscriptionID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QueryOrderInput
     */
    gateway?: string | null;
}

export function QueryOrderInputFromJSON(json: any): QueryOrderInput {
    return QueryOrderInputFromJSONTyped(json, false);
}

export function QueryOrderInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryOrderInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalTransactionId': !exists(json, 'externalTransactionId') ? undefined : json['externalTransactionId'],
        'subscriptionID': !exists(json, 'subscriptionID') ? undefined : json['subscriptionID'],
        'gateway': !exists(json, 'gateway') ? undefined : json['gateway'],
    };
}

export function QueryOrderInputToJSON(value?: QueryOrderInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalTransactionId': value.externalTransactionId,
        'subscriptionID': value.subscriptionID,
        'gateway': value.gateway,
    };
}

