/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportSection,
    ExaminationExportSectionFromJSON,
    ExaminationExportSectionFromJSONTyped,
    ExaminationExportSectionToJSON,
} from './ExaminationExportSection';
import {
    Question,
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface ExaminationExportQuestion
 */
export interface ExaminationExportQuestion {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestion
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestion
     */
    parentId?: number | null;
    /**
     * 
     * @type {Array<ExaminationExportQuestion>}
     * @memberof ExaminationExportQuestion
     */
    subExaminationExportQuestions?: Array<ExaminationExportQuestion> | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestion
     */
    examinationExportSectionId?: number | null;
    /**
     * 
     * @type {ExaminationExportSection}
     * @memberof ExaminationExportQuestion
     */
    examinationExportSection?: ExaminationExportSection;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestion
     */
    questionId?: number;
    /**
     * 
     * @type {Question}
     * @memberof ExaminationExportQuestion
     */
    question?: Question;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestion
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestion
     */
    sort?: number;
}

export function ExaminationExportQuestionFromJSON(json: any): ExaminationExportQuestion {
    return ExaminationExportQuestionFromJSONTyped(json, false);
}

export function ExaminationExportQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'subExaminationExportQuestions': !exists(json, 'subExaminationExportQuestions') ? undefined : (json['subExaminationExportQuestions'] === null ? null : (json['subExaminationExportQuestions'] as Array<any>).map(ExaminationExportQuestionFromJSON)),
        'examinationExportSectionId': !exists(json, 'examinationExportSectionId') ? undefined : json['examinationExportSectionId'],
        'examinationExportSection': !exists(json, 'examinationExportSection') ? undefined : ExaminationExportSectionFromJSON(json['examinationExportSection']),
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'question': !exists(json, 'question') ? undefined : QuestionFromJSON(json['question']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
    };
}

export function ExaminationExportQuestionToJSON(value?: ExaminationExportQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'subExaminationExportQuestions': value.subExaminationExportQuestions === undefined ? undefined : (value.subExaminationExportQuestions === null ? null : (value.subExaminationExportQuestions as Array<any>).map(ExaminationExportQuestionToJSON)),
        'examinationExportSectionId': value.examinationExportSectionId,
        'examinationExportSection': ExaminationExportSectionToJSON(value.examinationExportSection),
        'questionId': value.questionId,
        'question': QuestionToJSON(value.question),
        'points': value.points,
        'sort': value.sort,
    };
}

