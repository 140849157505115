/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSettingDto,
    MemberSettingDtoFromJSON,
    MemberSettingDtoFromJSONTyped,
    MemberSettingDtoToJSON,
} from './MemberSettingDto';
import {
    UserPermissionSetting,
    UserPermissionSettingFromJSON,
    UserPermissionSettingFromJSONTyped,
    UserPermissionSettingToJSON,
} from './UserPermissionSetting';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    emailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<MemberSettingDto>}
     * @memberof MemberDto
     */
    settings?: Array<MemberSettingDto> | null;
    /**
     * 
     * @type {Array<UserPermissionSetting>}
     * @memberof MemberDto
     */
    permissions?: Array<UserPermissionSetting> | null;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof MemberDto
     */
    roles?: Array<UserRole> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MemberDto
     */
    readonly roleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberDto
     */
    readonly permissionNames?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    lastLoginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    isStudent?: boolean;
}

export function MemberDtoFromJSON(json: any): MemberDto {
    return MemberDtoFromJSONTyped(json, false);
}

export function MemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': json['userName'],
        'name': json['name'],
        'surname': json['surname'],
        'emailAddress': json['emailAddress'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'settings': !exists(json, 'settings') ? undefined : (json['settings'] === null ? null : (json['settings'] as Array<any>).map(MemberSettingDtoFromJSON)),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(UserPermissionSettingFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(UserRoleFromJSON)),
        'roleIds': !exists(json, 'roleIds') ? undefined : json['roleIds'],
        'permissionNames': !exists(json, 'permissionNames') ? undefined : json['permissionNames'],
        'lastLoginTime': !exists(json, 'lastLoginTime') ? undefined : (json['lastLoginTime'] === null ? null : new Date(json['lastLoginTime'])),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'isStudent': !exists(json, 'isStudent') ? undefined : json['isStudent'],
    };
}

export function MemberDtoToJSON(value?: MemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'isActive': value.isActive,
        'settings': value.settings === undefined ? undefined : (value.settings === null ? null : (value.settings as Array<any>).map(MemberSettingDtoToJSON)),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(UserPermissionSettingToJSON)),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(UserRoleToJSON)),
        'lastLoginTime': value.lastLoginTime === undefined ? undefined : (value.lastLoginTime === null ? null : value.lastLoginTime.toISOString()),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'isStudent': value.isStudent,
    };
}

