import React from 'react';

const AccountIcon = (prop: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="currentColor" {...prop}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1283" data-name="Rectangle 1283" width="29.117" height="29.108" fill="currentColor" />
        </clipPath>
      </defs>
      <g id="account" transform="translate(-1218.5 -78.5)">
        <g id="Ellipse_55" data-name="Ellipse 55" transform="translate(1220.5 80.5)" fill="none" stroke="currentColor" strokeWidth="2">
          <circle cx="25" cy="25" r="25" stroke="none" />
          <circle cx="25" cy="25" r="26" fill="none" />
        </g>
        <g id="Group_3389" data-name="Group 3389" transform="translate(1230.442 90.446)">
          <g id="Group_3388" data-name="Group 3388" clipPath="url(#clip-path)">
            <path id="Path_1741" data-name="Path 1741" d="M18.931,14.534A45.948,45.948,0,0,1,24.06,17.71a13.759,13.759,0,0,1,5.037,10c.1,1.18-.144,1.4-1.374,1.4q-13.1,0-26.195,0c-1.428,0-1.611-.2-1.5-1.65A14.505,14.505,0,0,1,9.752,14.972c.211-.071.42-.147.758-.267A7.937,7.937,0,0,1,6.633,7.9,7.616,7.616,0,0,1,9.1,2.2a7.87,7.87,0,0,1,11.351.452,7.833,7.833,0,0,1-1.523,11.88m8.5,12.931A12.439,12.439,0,0,0,21.521,17.8a12.571,12.571,0,0,0-13.837-.054,12.335,12.335,0,0,0-5.976,9.717ZM14.612,14.2A6.258,6.258,0,0,0,20.85,7.979,6.292,6.292,0,1,0,8.267,7.947,6.362,6.362,0,0,0,14.612,14.2" transform="translate(0 0)" fill="currentColor" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AccountIcon;