/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Lesson,
    LessonFromJSON,
    LessonFromJSONTyped,
    LessonToJSON,
} from './Lesson';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';
import {
    School,
    SchoolFromJSON,
    SchoolFromJSONTyped,
    SchoolToJSON,
} from './School';

/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    isActive?: boolean;
    /**
     * 
     * @type {QuestionType}
     * @memberof Question
     */
    questionType: QuestionType;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    questionBody: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    isSubjective?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    stars?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    parentId?: number | null;
    /**
     * 
     * @type {Question}
     * @memberof Question
     */
    parentQuestion?: Question;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Question
     */
    subQuestions?: Array<Question> | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    lessonId?: number | null;
    /**
     * 
     * @type {Lesson}
     * @memberof Question
     */
    lesson?: Lesson;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    schoolId?: number | null;
    /**
     * 
     * @type {School}
     * @memberof Question
     */
    school?: School;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    optionsSerialize?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Question
     */
    readonly options?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    answerSerialize?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Question
     */
    readonly answer?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    readonly displayAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    explain?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Question
     */
    readonly extra?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    extraSerialize?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Question
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Question
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Question
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    isDeleted?: boolean;
}

export function QuestionFromJSON(json: any): Question {
    return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Question {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'questionType': QuestionTypeFromJSON(json['questionType']),
        'questionBody': json['questionBody'],
        'isSubjective': !exists(json, 'isSubjective') ? undefined : json['isSubjective'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'parentQuestion': !exists(json, 'parentQuestion') ? undefined : QuestionFromJSON(json['parentQuestion']),
        'subQuestions': !exists(json, 'subQuestions') ? undefined : (json['subQuestions'] === null ? null : (json['subQuestions'] as Array<any>).map(QuestionFromJSON)),
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
        'lesson': !exists(json, 'lesson') ? undefined : LessonFromJSON(json['lesson']),
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'school': !exists(json, 'school') ? undefined : SchoolFromJSON(json['school']),
        'optionsSerialize': !exists(json, 'optionsSerialize') ? undefined : json['optionsSerialize'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'answerSerialize': !exists(json, 'answerSerialize') ? undefined : json['answerSerialize'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'displayAnswer': !exists(json, 'displayAnswer') ? undefined : json['displayAnswer'],
        'explain': !exists(json, 'explain') ? undefined : json['explain'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'extraSerialize': !exists(json, 'extraSerialize') ? undefined : json['extraSerialize'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function QuestionToJSON(value?: Question | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'questionType': QuestionTypeToJSON(value.questionType),
        'questionBody': value.questionBody,
        'isSubjective': value.isSubjective,
        'stars': value.stars,
        'parentId': value.parentId,
        'parentQuestion': QuestionToJSON(value.parentQuestion),
        'subQuestions': value.subQuestions === undefined ? undefined : (value.subQuestions === null ? null : (value.subQuestions as Array<any>).map(QuestionToJSON)),
        'lessonId': value.lessonId,
        'lesson': LessonToJSON(value.lesson),
        'schoolId': value.schoolId,
        'school': SchoolToJSON(value.school),
        'optionsSerialize': value.optionsSerialize,
        'answerSerialize': value.answerSerialize,
        'explain': value.explain,
        'extraSerialize': value.extraSerialize,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
    };
}

