/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkSchoolTeachersInput
 */
export interface LinkSchoolTeachersInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkSchoolTeachersInput
     */
    usersId: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof LinkSchoolTeachersInput
     */
    studentGroupId: number;
}

export function LinkSchoolTeachersInputFromJSON(json: any): LinkSchoolTeachersInput {
    return LinkSchoolTeachersInputFromJSONTyped(json, false);
}

export function LinkSchoolTeachersInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkSchoolTeachersInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usersId': json['usersId'],
        'studentGroupId': json['studentGroupId'],
    };
}

export function LinkSchoolTeachersInputToJSON(value?: LinkSchoolTeachersInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usersId': value.usersId,
        'studentGroupId': value.studentGroupId,
    };
}

