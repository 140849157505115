/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';

/**
 * 
 * @export
 * @interface RoleDtoPagedResultDto
 */
export interface RoleDtoPagedResultDto {
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleDtoPagedResultDto
     */
    items?: Array<RoleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RoleDtoPagedResultDto
     */
    totalCount?: number;
}

export function RoleDtoPagedResultDtoFromJSON(json: any): RoleDtoPagedResultDto {
    return RoleDtoPagedResultDtoFromJSONTyped(json, false);
}

export function RoleDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(RoleDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function RoleDtoPagedResultDtoToJSON(value?: RoleDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(RoleDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

