/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSchoolStudentInput
 */
export interface CreateSchoolStudentInput {
    /**
     * 
     * @type {number}
     * @memberof CreateSchoolStudentInput
     */
    studentGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolStudentInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolStudentInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolStudentInput
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolStudentInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolStudentInput
     */
    surname: string;
}

export function CreateSchoolStudentInputFromJSON(json: any): CreateSchoolStudentInput {
    return CreateSchoolStudentInputFromJSONTyped(json, false);
}

export function CreateSchoolStudentInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSchoolStudentInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentGroupId': json['studentGroupId'],
        'userName': json['userName'],
        'emailAddress': json['emailAddress'],
        'password': json['password'],
        'name': json['name'],
        'surname': json['surname'],
    };
}

export function CreateSchoolStudentInputToJSON(value?: CreateSchoolStudentInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentGroupId': value.studentGroupId,
        'userName': value.userName,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'name': value.name,
        'surname': value.surname,
    };
}

