/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConstructorInfo,
    ConstructorInfoFromJSON,
    ConstructorInfoFromJSONTyped,
    ConstructorInfoToJSON,
} from './ConstructorInfo';
import {
    CustomAttributeNamedArgument,
    CustomAttributeNamedArgumentFromJSON,
    CustomAttributeNamedArgumentFromJSONTyped,
    CustomAttributeNamedArgumentToJSON,
} from './CustomAttributeNamedArgument';
import {
    CustomAttributeTypedArgument,
    CustomAttributeTypedArgumentFromJSON,
    CustomAttributeTypedArgumentFromJSONTyped,
    CustomAttributeTypedArgumentToJSON,
} from './CustomAttributeTypedArgument';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';

/**
 * 
 * @export
 * @interface CustomAttributeData
 */
export interface CustomAttributeData {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeData
     */
    attributeType?: Type;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof CustomAttributeData
     */
    constructor?: ConstructorInfo;
    /**
     * 
     * @type {Array<CustomAttributeTypedArgument>}
     * @memberof CustomAttributeData
     */
    readonly constructorArguments?: Array<CustomAttributeTypedArgument> | null;
    /**
     * 
     * @type {Array<CustomAttributeNamedArgument>}
     * @memberof CustomAttributeData
     */
    readonly namedArguments?: Array<CustomAttributeNamedArgument> | null;
}

export function CustomAttributeDataFromJSON(json: any): CustomAttributeData {
    return CustomAttributeDataFromJSONTyped(json, false);
}

export function CustomAttributeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAttributeData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeType': !exists(json, 'attributeType') ? undefined : TypeFromJSON(json['attributeType']),
        'constructor': !exists(json, 'constructor') ? undefined : ConstructorInfoFromJSON(json['constructor']),
        'constructorArguments': !exists(json, 'constructorArguments') ? undefined : (json['constructorArguments'] === null ? null : (json['constructorArguments'] as Array<any>).map(CustomAttributeTypedArgumentFromJSON)),
        'namedArguments': !exists(json, 'namedArguments') ? undefined : (json['namedArguments'] === null ? null : (json['namedArguments'] as Array<any>).map(CustomAttributeNamedArgumentFromJSON)),
    };
}

export function CustomAttributeDataToJSON(value?: CustomAttributeData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeType': TypeToJSON(value.attributeType),
        'constructor': ConstructorInfoToJSON(value.constructor),
    };
}

