/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoFromJSONTyped,
    StudentDtoToJSON,
} from './StudentDto';

/**
 * 
 * @export
 * @interface ReferenceTestListDto
 */
export interface ReferenceTestListDto {
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceTestListDto
     */
    readonly userName?: string | null;
    /**
     * 
     * @type {StudentDto}
     * @memberof ReferenceTestListDto
     */
    user?: StudentDto;
    /**
     * 
     * @type {string}
     * @memberof ReferenceTestListDto
     */
    testName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    questionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    score?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    totalPoints?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    subjectiveCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceTestListDto
     */
    subjectiveScoredCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReferenceTestListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReferenceTestListDto
     */
    beginTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReferenceTestListDto
     */
    finishTime?: Date | null;
}

export function ReferenceTestListDtoFromJSON(json: any): ReferenceTestListDto {
    return ReferenceTestListDtoFromJSONTyped(json, false);
}

export function ReferenceTestListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferenceTestListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'user': !exists(json, 'user') ? undefined : StudentDtoFromJSON(json['user']),
        'testName': !exists(json, 'testName') ? undefined : json['testName'],
        'questionCount': !exists(json, 'questionCount') ? undefined : json['questionCount'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'subjectiveCount': !exists(json, 'subjectiveCount') ? undefined : json['subjectiveCount'],
        'subjectiveScoredCount': !exists(json, 'subjectiveScoredCount') ? undefined : json['subjectiveScoredCount'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'beginTime': !exists(json, 'beginTime') ? undefined : (json['beginTime'] === null ? null : new Date(json['beginTime'])),
        'finishTime': !exists(json, 'finishTime') ? undefined : (json['finishTime'] === null ? null : new Date(json['finishTime'])),
    };
}

export function ReferenceTestListDtoToJSON(value?: ReferenceTestListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'user': StudentDtoToJSON(value.user),
        'testName': value.testName,
        'questionCount': value.questionCount,
        'score': value.score,
        'totalPoints': value.totalPoints,
        'subjectiveCount': value.subjectiveCount,
        'subjectiveScoredCount': value.subjectiveScoredCount,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'beginTime': value.beginTime === undefined ? undefined : (value.beginTime === null ? null : value.beginTime.toISOString()),
        'finishTime': value.finishTime === undefined ? undefined : (value.finishTime === null ? null : value.finishTime.toISOString()),
    };
}

