/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportQuestionListDto,
    ExaminationExportQuestionListDtoFromJSON,
    ExaminationExportQuestionListDtoFromJSONTyped,
    ExaminationExportQuestionListDtoToJSON,
} from './ExaminationExportQuestionListDto';

/**
 * 
 * @export
 * @interface ExaminationExportSectionListDto
 */
export interface ExaminationExportSectionListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSectionListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSectionListDto
     */
    examinationExportId?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportSectionListDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSectionListDto
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSectionListDto
     */
    sort?: number;
    /**
     * 
     * @type {Array<ExaminationExportQuestionListDto>}
     * @memberof ExaminationExportSectionListDto
     */
    questions?: Array<ExaminationExportQuestionListDto> | null;
}

export function ExaminationExportSectionListDtoFromJSON(json: any): ExaminationExportSectionListDto {
    return ExaminationExportSectionListDtoFromJSONTyped(json, false);
}

export function ExaminationExportSectionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSectionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(ExaminationExportQuestionListDtoFromJSON)),
    };
}

export function ExaminationExportSectionListDtoToJSON(value?: ExaminationExportSectionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'title': value.title,
        'points': value.points,
        'sort': value.sort,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(ExaminationExportQuestionListDtoToJSON)),
    };
}

