/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StudentProgress,
    StudentProgressFromJSON,
    StudentProgressToJSON,
} from '../models';

export interface ApiServicesAppProgressGetGetRequest {
    userId?: number;
}

/**
 * 
 */
export class ProgressApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppProgressGetGetRaw(requestParameters: ApiServicesAppProgressGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StudentProgress>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Progress/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StudentProgressFromJSON));
    }

    /**
     */
    async apiServicesAppProgressGetGet(requestParameters: ApiServicesAppProgressGetGetRequest, initOverrides?: RequestInit): Promise<Array<StudentProgress>> {
        const response = await this.apiServicesAppProgressGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
