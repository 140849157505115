/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAddTicketInput
 */
export interface UserAddTicketInput {
    /**
     * 
     * @type {number}
     * @memberof UserAddTicketInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAddTicketInput
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddTicketInput
     */
    message: string;
}

export function UserAddTicketInputFromJSON(json: any): UserAddTicketInput {
    return UserAddTicketInputFromJSONTyped(json, false);
}

export function UserAddTicketInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAddTicketInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subject': json['subject'],
        'message': json['message'],
    };
}

export function UserAddTicketInputToJSON(value?: UserAddTicketInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'message': value.message,
    };
}

