/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchoolNameWithParentDto,
    SchoolNameWithParentDtoFromJSON,
    SchoolNameWithParentDtoFromJSONTyped,
    SchoolNameWithParentDtoToJSON,
} from './SchoolNameWithParentDto';

/**
 * 
 * @export
 * @interface SchoolAdminDetailDto
 */
export interface SchoolAdminDetailDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolAdminDetailDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminDetailDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminDetailDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminDetailDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolAdminDetailDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SchoolAdminDetailDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SchoolAdminDetailDto
     */
    schoolId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SchoolAdminDetailDto
     */
    lastSeen?: Date | null;
    /**
     * 
     * @type {SchoolNameWithParentDto}
     * @memberof SchoolAdminDetailDto
     */
    school?: SchoolNameWithParentDto;
    /**
     * 
     * @type {Date}
     * @memberof SchoolAdminDetailDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchoolAdminDetailDto
     */
    lastModificationTime?: Date | null;
}

export function SchoolAdminDetailDtoFromJSON(json: any): SchoolAdminDetailDto {
    return SchoolAdminDetailDtoFromJSONTyped(json, false);
}

export function SchoolAdminDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolAdminDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'lastSeen': !exists(json, 'lastSeen') ? undefined : (json['lastSeen'] === null ? null : new Date(json['lastSeen'])),
        'school': !exists(json, 'school') ? undefined : SchoolNameWithParentDtoFromJSON(json['school']),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function SchoolAdminDetailDtoToJSON(value?: SchoolAdminDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'isActive': value.isActive,
        'schoolId': value.schoolId,
        'lastSeen': value.lastSeen === undefined ? undefined : (value.lastSeen === null ? null : value.lastSeen.toISOString()),
        'school': SchoolNameWithParentDtoToJSON(value.school),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

