/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserCoinReasonType {
    NUMBER_1 = 1,
    NUMBER_100 = 100
}

export function UserCoinReasonTypeFromJSON(json: any): UserCoinReasonType {
    return UserCoinReasonTypeFromJSONTyped(json, false);
}

export function UserCoinReasonTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCoinReasonType {
    return json as UserCoinReasonType;
}

export function UserCoinReasonTypeToJSON(value?: UserCoinReasonType | null): any {
    return value as any;
}

