/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Setting
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Setting
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    tenantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    value?: string | null;
}

export function SettingFromJSON(json: any): Setting {
    return SettingFromJSONTyped(json, false);
}

export function SettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Setting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function SettingToJSON(value?: Setting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'lastModifierUserId': value.lastModifierUserId,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'name': value.name,
        'value': value.value,
    };
}

