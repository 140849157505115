/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssigneeScoreDto,
    AssigneeScoreDtoFromJSON,
    AssigneeScoreDtoFromJSONTyped,
    AssigneeScoreDtoToJSON,
} from './AssigneeScoreDto';
import {
    ExaminationExportAssigneeListDto,
    ExaminationExportAssigneeListDtoFromJSON,
    ExaminationExportAssigneeListDtoFromJSONTyped,
    ExaminationExportAssigneeListDtoToJSON,
} from './ExaminationExportAssigneeListDto';

/**
 * 
 * @export
 * @interface ExaminationExportAssigneeMappingDto
 */
export interface ExaminationExportAssigneeMappingDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssigneeMappingDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssigneeMappingDto
     */
    examinationExportId?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssigneeMappingDto
     */
    userId?: number;
    /**
     * 
     * @type {ExaminationExportAssigneeListDto}
     * @memberof ExaminationExportAssigneeMappingDto
     */
    user?: ExaminationExportAssigneeListDto;
    /**
     * 
     * @type {Array<AssigneeScoreDto>}
     * @memberof ExaminationExportAssigneeMappingDto
     */
    scores?: Array<AssigneeScoreDto> | null;
}

export function ExaminationExportAssigneeMappingDtoFromJSON(json: any): ExaminationExportAssigneeMappingDto {
    return ExaminationExportAssigneeMappingDtoFromJSONTyped(json, false);
}

export function ExaminationExportAssigneeMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportAssigneeMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ExaminationExportAssigneeListDtoFromJSON(json['user']),
        'scores': !exists(json, 'scores') ? undefined : (json['scores'] === null ? null : (json['scores'] as Array<any>).map(AssigneeScoreDtoFromJSON)),
    };
}

export function ExaminationExportAssigneeMappingDtoToJSON(value?: ExaminationExportAssigneeMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'userId': value.userId,
        'user': ExaminationExportAssigneeListDtoToJSON(value.user),
        'scores': value.scores === undefined ? undefined : (value.scores === null ? null : (value.scores as Array<any>).map(AssigneeScoreDtoToJSON)),
    };
}

