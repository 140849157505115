/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GradeInludeStageDto,
    GradeInludeStageDtoFromJSON,
    GradeInludeStageDtoFromJSONTyped,
    GradeInludeStageDtoToJSON,
} from './GradeInludeStageDto';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';

/**
 * 
 * @export
 * @interface SubjectIncludeGradeDto
 */
export interface SubjectIncludeGradeDto {
    /**
     * 
     * @type {number}
     * @memberof SubjectIncludeGradeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectIncludeGradeDto
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectIncludeGradeDto
     */
    sort?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectIncludeGradeDto
     */
    term?: number;
    /**
     * 
     * @type {SubjectType}
     * @memberof SubjectIncludeGradeDto
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof SubjectIncludeGradeDto
     */
    name: string;
    /**
     * 
     * @type {GradeInludeStageDto}
     * @memberof SubjectIncludeGradeDto
     */
    grade?: GradeInludeStageDto;
}

export function SubjectIncludeGradeDtoFromJSON(json: any): SubjectIncludeGradeDto {
    return SubjectIncludeGradeDtoFromJSONTyped(json, false);
}

export function SubjectIncludeGradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectIncludeGradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'name': json['name'],
        'grade': !exists(json, 'grade') ? undefined : GradeInludeStageDtoFromJSON(json['grade']),
    };
}

export function SubjectIncludeGradeDtoToJSON(value?: SubjectIncludeGradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'icon': value.icon,
        'sort': value.sort,
        'term': value.term,
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'name': value.name,
        'grade': GradeInludeStageDtoToJSON(value.grade),
    };
}

