/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Type,
    TypeFromJSON,
    TypeFromJSONTyped,
    TypeToJSON,
} from './Type';

/**
 * 
 * @export
 * @interface AuthenticationScheme
 */
export interface AuthenticationScheme {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationScheme
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationScheme
     */
    displayName?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof AuthenticationScheme
     */
    handlerType?: Type;
}

export function AuthenticationSchemeFromJSON(json: any): AuthenticationScheme {
    return AuthenticationSchemeFromJSONTyped(json, false);
}

export function AuthenticationSchemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationScheme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'handlerType': !exists(json, 'handlerType') ? undefined : TypeFromJSON(json['handlerType']),
    };
}

export function AuthenticationSchemeToJSON(value?: AuthenticationScheme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'handlerType': TypeToJSON(value.handlerType),
    };
}

