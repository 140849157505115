import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { TypeOf } from 'yup';
export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    variant?: ButtonVariant,
    image?: React.ReactElement<SVGSVGElement>,
    customFont?: boolean,
    isRounded?: boolean
}
export type ButtonVariant = "success" | "error" | "warn" | "info" | "rounded" | "roundedSmall" | "white" | "disable" | "default" | "orange" | "roundedWhite"

const styles: { [key in ButtonVariant]: string } = {
  success: clsx`text-white bg-success-500 hover:bg-success-600 focus:ring-success-400`,
  error: clsx`text-white bg-danger-500 hover:bg-danger-600 focus:ring-danger-400`,
  warn: clsx`text- bg-warn-500 hover:bg-warn-600 focus:ring-warn-400`,
  info: clsx`text-white bg-info-500 hover:bg-info-600 focus:ring-info-400`,
  rounded: clsx`rounded-full w-20 h-20 text-white bg-primary-500 hover:brightness-90 focus:ring-primary-400`,
  roundedSmall: clsx`rounded-full w-12 h-12 text-white bg-primary-500 hover:brightness-90 focus:ring-primary-400`,
  white: clsx`bg-white focus:ring-primary-500`,
  disable: clsx`text-white bg-gray-400 hover:bg-gray-500 focus:ring-gray-400`,
  default: clsx`text-white bg-primary-500 hover:bg-primary-600 focus:ring-primary-400`,
  orange: clsx`text-white bg-secondary-500 hover:bg-secondary-600 focus:ring-secondary-400`,
  roundedWhite: clsx`rounded-full w-20 h-20 text-primary-500 bg-white active:brightness-90 focus:ring-primary-500`
};
export const commonClasses = clsx`focus:ring-4 transition`;
export const defaultFontClasses = clsx`font-medium text-sm text-center`;

const Button = (props: ButtonProps) => {
  const { children, variant, image, customFont, isRounded, className, ...rest } = props;
  return <button {...rest} className={clsx(commonClasses, styles[variant ?? "default"], !customFont && defaultFontClasses, !isRounded && `px-5 py-2.5`, className)}>{image ? image : children}</button>
}
Button.displayName = 'Button';
export default Button;