/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FAQItemTranslationDto
 */
export interface FAQItemTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof FAQItemTranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FAQItemTranslationDto
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof FAQItemTranslationDto
     */
    answer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQItemTranslationDto
     */
    language: string;
}

export function FAQItemTranslationDtoFromJSON(json: any): FAQItemTranslationDto {
    return FAQItemTranslationDtoFromJSONTyped(json, false);
}

export function FAQItemTranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQItemTranslationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'question': json['question'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'language': json['language'],
    };
}

export function FAQItemTranslationDtoToJSON(value?: FAQItemTranslationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'question': value.question,
        'answer': value.answer,
        'language': value.language,
    };
}

