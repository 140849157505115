/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductItemType,
    ProductItemTypeFromJSON,
    ProductItemTypeFromJSONTyped,
    ProductItemTypeToJSON,
} from './ProductItemType';

/**
 * 
 * @export
 * @interface CurrentUserWalletDto
 */
export interface CurrentUserWalletDto {
    /**
     * 
     * @type {number}
     * @memberof CurrentUserWalletDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserWalletDto
     */
    walletName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserWalletDto
     */
    balance?: number;
    /**
     * 
     * @type {ProductItemType}
     * @memberof CurrentUserWalletDto
     */
    productItemType?: ProductItemType;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserWalletDto
     */
    readonly productItemTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserWalletDto
     */
    gradeId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof CurrentUserWalletDto
     */
    expireDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserWalletDto
     */
    changeGradeDuration?: number;
    /**
     * 
     * @type {Date}
     * @memberof CurrentUserWalletDto
     */
    canChangeGradeDate?: Date;
}

export function CurrentUserWalletDtoFromJSON(json: any): CurrentUserWalletDto {
    return CurrentUserWalletDtoFromJSONTyped(json, false);
}

export function CurrentUserWalletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserWalletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'walletName': !exists(json, 'walletName') ? undefined : json['walletName'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'productItemType': !exists(json, 'productItemType') ? undefined : ProductItemTypeFromJSON(json['productItemType']),
        'productItemTypeName': !exists(json, 'productItemTypeName') ? undefined : json['productItemTypeName'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'expireDate': !exists(json, 'expireDate') ? undefined : (json['expireDate'] === null ? null : new Date(json['expireDate'])),
        'changeGradeDuration': !exists(json, 'changeGradeDuration') ? undefined : json['changeGradeDuration'],
        'canChangeGradeDate': !exists(json, 'canChangeGradeDate') ? undefined : (new Date(json['canChangeGradeDate'])),
    };
}

export function CurrentUserWalletDtoToJSON(value?: CurrentUserWalletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'walletName': value.walletName,
        'balance': value.balance,
        'productItemType': ProductItemTypeToJSON(value.productItemType),
        'gradeId': value.gradeId,
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate === null ? null : value.expireDate.toISOString()),
        'changeGradeDuration': value.changeGradeDuration,
        'canChangeGradeDate': value.canChangeGradeDate === undefined ? undefined : (value.canChangeGradeDate.toISOString()),
    };
}

