/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportQuestionListDto,
    ExportQuestionListDtoFromJSON,
    ExportQuestionListDtoFromJSONTyped,
    ExportQuestionListDtoToJSON,
} from './ExportQuestionListDto';

/**
 * 
 * @export
 * @interface ExportSectionListDto
 */
export interface ExportSectionListDto {
    /**
     * 
     * @type {string}
     * @memberof ExportSectionListDto
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExportSectionListDto
     */
    points?: number;
    /**
     * 
     * @type {Array<ExportQuestionListDto>}
     * @memberof ExportSectionListDto
     */
    questions?: Array<ExportQuestionListDto> | null;
}

export function ExportSectionListDtoFromJSON(json: any): ExportSectionListDto {
    return ExportSectionListDtoFromJSONTyped(json, false);
}

export function ExportSectionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportSectionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(ExportQuestionListDtoFromJSON)),
    };
}

export function ExportSectionListDtoToJSON(value?: ExportSectionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'points': value.points,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(ExportQuestionListDtoToJSON)),
    };
}

