/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailDto,
    QuestionDetailDtoFromJSON,
    QuestionDetailDtoFromJSONTyped,
    QuestionDetailDtoToJSON,
} from './QuestionDetailDto';
import {
    Test,
    TestFromJSON,
    TestFromJSONTyped,
    TestToJSON,
} from './Test';

/**
 * 
 * @export
 * @interface QuestionAnswerListDto
 */
export interface QuestionAnswerListDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerListDto
     */
    id?: number;
    /**
     * 
     * @type {Test}
     * @memberof QuestionAnswerListDto
     */
    test?: Test;
    /**
     * 
     * @type {QuestionDetailDto}
     * @memberof QuestionAnswerListDto
     */
    question?: QuestionDetailDto;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerListDto
     */
    points?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerListDto
     */
    testerAnswer?: string | null;
    /**
     * 
     * @type {any}
     * @memberof QuestionAnswerListDto
     */
    readonly oTesterAnswer?: any | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerListDto
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerListDto
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerListDto
     */
    studentGrade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerListDto
     */
    studentName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionAnswerListDto
     */
    readonly creationTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionAnswerListDto
     */
    teacherAuditedTime?: Date | null;
}

export function QuestionAnswerListDtoFromJSON(json: any): QuestionAnswerListDto {
    return QuestionAnswerListDtoFromJSONTyped(json, false);
}

export function QuestionAnswerListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionAnswerListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'test': !exists(json, 'test') ? undefined : TestFromJSON(json['test']),
        'question': !exists(json, 'question') ? undefined : QuestionDetailDtoFromJSON(json['question']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'testerAnswer': !exists(json, 'testerAnswer') ? undefined : json['testerAnswer'],
        'oTesterAnswer': !exists(json, 'oTesterAnswer') ? undefined : json['oTesterAnswer'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'teacherComment': !exists(json, 'teacherComment') ? undefined : json['teacherComment'],
        'studentGrade': !exists(json, 'studentGrade') ? undefined : json['studentGrade'],
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (json['creationTime'] === null ? null : new Date(json['creationTime'])),
        'teacherAuditedTime': !exists(json, 'teacherAuditedTime') ? undefined : (json['teacherAuditedTime'] === null ? null : new Date(json['teacherAuditedTime'])),
    };
}

export function QuestionAnswerListDtoToJSON(value?: QuestionAnswerListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'test': TestToJSON(value.test),
        'question': QuestionDetailDtoToJSON(value.question),
        'points': value.points,
        'testerAnswer': value.testerAnswer,
        'score': value.score,
        'teacherComment': value.teacherComment,
        'studentGrade': value.studentGrade,
        'studentName': value.studentName,
        'teacherAuditedTime': value.teacherAuditedTime === undefined ? undefined : (value.teacherAuditedTime === null ? null : value.teacherAuditedTime.toISOString()),
    };
}

