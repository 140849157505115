/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshTokenModel
 */
export interface RefreshTokenModel {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenModel
     */
    refreshToken: string;
}

export function RefreshTokenModelFromJSON(json: any): RefreshTokenModel {
    return RefreshTokenModelFromJSONTyped(json, false);
}

export function RefreshTokenModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'refreshToken': json['refreshToken'],
    };
}

export function RefreshTokenModelToJSON(value?: RefreshTokenModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'refreshToken': value.refreshToken,
    };
}

