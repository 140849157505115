/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportGradeListDto,
    ExaminationExportGradeListDtoFromJSON,
    ExaminationExportGradeListDtoFromJSONTyped,
    ExaminationExportGradeListDtoToJSON,
} from './ExaminationExportGradeListDto';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';

/**
 * 
 * @export
 * @interface ExaminationExportSubjectListDto
 */
export interface ExaminationExportSubjectListDto {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubjectListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportSubjectListDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportSubjectListDto
     */
    term?: number;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportSubjectListDto
     */
    readonly gradeCode?: string | null;
    /**
     * 
     * @type {ExaminationExportGradeListDto}
     * @memberof ExaminationExportSubjectListDto
     */
    grade?: ExaminationExportGradeListDto;
    /**
     * 
     * @type {string}
     * @memberof ExaminationExportSubjectListDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {SubjectType}
     * @memberof ExaminationExportSubjectListDto
     */
    subjectType?: SubjectType;
}

export function ExaminationExportSubjectListDtoFromJSON(json: any): ExaminationExportSubjectListDto {
    return ExaminationExportSubjectListDtoFromJSONTyped(json, false);
}

export function ExaminationExportSubjectListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportSubjectListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'grade': !exists(json, 'grade') ? undefined : ExaminationExportGradeListDtoFromJSON(json['grade']),
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
    };
}

export function ExaminationExportSubjectListDtoToJSON(value?: ExaminationExportSubjectListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'term': value.term,
        'grade': ExaminationExportGradeListDtoToJSON(value.grade),
        'fullName': value.fullName,
        'subjectType': SubjectTypeToJSON(value.subjectType),
    };
}

