/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentArticleDto,
    ContentArticleDtoFromJSON,
    ContentArticleDtoToJSON,
    ContentArticleFullContentDto,
    ContentArticleFullContentDtoFromJSON,
    ContentArticleFullContentDtoToJSON,
    ContentArticleListDtoPagedResultDto,
    ContentArticleListDtoPagedResultDtoFromJSON,
    ContentArticleListDtoPagedResultDtoToJSON,
    ContentCustomFieldDto,
    ContentCustomFieldDtoFromJSON,
    ContentCustomFieldDtoToJSON,
    ContentCustomFieldListDto,
    ContentCustomFieldListDtoFromJSON,
    ContentCustomFieldListDtoToJSON,
    ReorderInput,
    ReorderInputFromJSON,
    ReorderInputToJSON,
} from '../models';

export interface ApiServicesAppContentArticleCreateCustomFieldPostRequest {
    contentCustomFieldDto?: ContentCustomFieldDto;
}

export interface ApiServicesAppContentArticleCreatePostRequest {
    contentArticleDto?: ContentArticleDto;
}

export interface ApiServicesAppContentArticleDeleteCustomFieldDeleteRequest {
    id?: number;
}

export interface ApiServicesAppContentArticleDeleteDeleteRequest {
    id?: number;
}

export interface ApiServicesAppContentArticleGetAllArticleInCategoryGetRequest {
    categoryId?: number;
    search?: string;
    isActive?: boolean;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppContentArticleGetContentGetRequest {
    slug?: string;
}

export interface ApiServicesAppContentArticleGetContentInCategoryGetRequest {
    categorySlug: string;
    sorting?: string;
    skipCount?: number;
    maxResultCount?: number;
}

export interface ApiServicesAppContentArticleGetCustomFieldGetRequest {
    id?: number;
}

export interface ApiServicesAppContentArticleGetCustomFieldsGetRequest {
    articleId?: number;
}

export interface ApiServicesAppContentArticleGetGetRequest {
    id?: number;
}

export interface ApiServicesAppContentArticleReorderPostRequest {
    reorderInput?: ReorderInput;
}

export interface ApiServicesAppContentArticleUpdateCustomFieldPutRequest {
    contentCustomFieldDto?: ContentCustomFieldDto;
}

export interface ApiServicesAppContentArticleUpdatePutRequest {
    contentArticleDto?: ContentArticleDto;
}

export interface ApiServicesAppContentArticleUpdateSortPutRequest {
    id?: number;
    sort?: number;
}

/**
 * 
 */
export class ContentArticleApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppContentArticleCreateCustomFieldPostRaw(requestParameters: ApiServicesAppContentArticleCreateCustomFieldPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCustomFieldDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentArticle/CreateCustomField`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContentCustomFieldDtoToJSON(requestParameters.contentCustomFieldDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCustomFieldDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleCreateCustomFieldPost(requestParameters: ApiServicesAppContentArticleCreateCustomFieldPostRequest, initOverrides?: RequestInit): Promise<ContentCustomFieldDto> {
        const response = await this.apiServicesAppContentArticleCreateCustomFieldPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleCreatePostRaw(requestParameters: ApiServicesAppContentArticleCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentArticleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentArticle/Create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContentArticleDtoToJSON(requestParameters.contentArticleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleCreatePost(requestParameters: ApiServicesAppContentArticleCreatePostRequest, initOverrides?: RequestInit): Promise<ContentArticleDto> {
        const response = await this.apiServicesAppContentArticleCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleDeleteCustomFieldDeleteRaw(requestParameters: ApiServicesAppContentArticleDeleteCustomFieldDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/DeleteCustomField`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppContentArticleDeleteCustomFieldDelete(requestParameters: ApiServicesAppContentArticleDeleteCustomFieldDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppContentArticleDeleteCustomFieldDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppContentArticleDeleteDeleteRaw(requestParameters: ApiServicesAppContentArticleDeleteDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/Delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppContentArticleDeleteDelete(requestParameters: ApiServicesAppContentArticleDeleteDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppContentArticleDeleteDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppContentArticleGetAllArticleInCategoryGetRaw(requestParameters: ApiServicesAppContentArticleGetAllArticleInCategoryGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentArticleListDtoPagedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/GetAllArticleInCategory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentArticleListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleGetAllArticleInCategoryGet(requestParameters: ApiServicesAppContentArticleGetAllArticleInCategoryGetRequest, initOverrides?: RequestInit): Promise<ContentArticleListDtoPagedResultDto> {
        const response = await this.apiServicesAppContentArticleGetAllArticleInCategoryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleGetContentGetRaw(requestParameters: ApiServicesAppContentArticleGetContentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentArticleFullContentDto>> {
        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/GetContent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentArticleFullContentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleGetContentGet(requestParameters: ApiServicesAppContentArticleGetContentGetRequest, initOverrides?: RequestInit): Promise<ContentArticleFullContentDto> {
        const response = await this.apiServicesAppContentArticleGetContentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleGetContentInCategoryGetRaw(requestParameters: ApiServicesAppContentArticleGetContentInCategoryGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentArticleListDtoPagedResultDto>> {
        if (requestParameters.categorySlug === null || requestParameters.categorySlug === undefined) {
            throw new runtime.RequiredError('categorySlug','Required parameter requestParameters.categorySlug was null or undefined when calling apiServicesAppContentArticleGetContentInCategoryGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.categorySlug !== undefined) {
            queryParameters['categorySlug'] = requestParameters.categorySlug;
        }

        if (requestParameters.sorting !== undefined) {
            queryParameters['Sorting'] = requestParameters.sorting;
        }

        if (requestParameters.skipCount !== undefined) {
            queryParameters['SkipCount'] = requestParameters.skipCount;
        }

        if (requestParameters.maxResultCount !== undefined) {
            queryParameters['MaxResultCount'] = requestParameters.maxResultCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/GetContentInCategory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentArticleListDtoPagedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleGetContentInCategoryGet(requestParameters: ApiServicesAppContentArticleGetContentInCategoryGetRequest, initOverrides?: RequestInit): Promise<ContentArticleListDtoPagedResultDto> {
        const response = await this.apiServicesAppContentArticleGetContentInCategoryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleGetCustomFieldGetRaw(requestParameters: ApiServicesAppContentArticleGetCustomFieldGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCustomFieldDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/GetCustomField`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCustomFieldDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleGetCustomFieldGet(requestParameters: ApiServicesAppContentArticleGetCustomFieldGetRequest, initOverrides?: RequestInit): Promise<ContentCustomFieldDto> {
        const response = await this.apiServicesAppContentArticleGetCustomFieldGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleGetCustomFieldsGetRaw(requestParameters: ApiServicesAppContentArticleGetCustomFieldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ContentCustomFieldListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.articleId !== undefined) {
            queryParameters['articleId'] = requestParameters.articleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/GetCustomFields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContentCustomFieldListDtoFromJSON));
    }

    /**
     */
    async apiServicesAppContentArticleGetCustomFieldsGet(requestParameters: ApiServicesAppContentArticleGetCustomFieldsGetRequest, initOverrides?: RequestInit): Promise<Array<ContentCustomFieldListDto>> {
        const response = await this.apiServicesAppContentArticleGetCustomFieldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleGetGetRaw(requestParameters: ApiServicesAppContentArticleGetGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentArticleDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleGetGet(requestParameters: ApiServicesAppContentArticleGetGetRequest, initOverrides?: RequestInit): Promise<ContentArticleDto> {
        const response = await this.apiServicesAppContentArticleGetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleReorderPostRaw(requestParameters: ApiServicesAppContentArticleReorderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentArticle/Reorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderInputToJSON(requestParameters.reorderInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppContentArticleReorderPost(requestParameters: ApiServicesAppContentArticleReorderPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppContentArticleReorderPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppContentArticleUpdateCustomFieldPutRaw(requestParameters: ApiServicesAppContentArticleUpdateCustomFieldPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentCustomFieldDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentArticle/UpdateCustomField`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentCustomFieldDtoToJSON(requestParameters.contentCustomFieldDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCustomFieldDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleUpdateCustomFieldPut(requestParameters: ApiServicesAppContentArticleUpdateCustomFieldPutRequest, initOverrides?: RequestInit): Promise<ContentCustomFieldDto> {
        const response = await this.apiServicesAppContentArticleUpdateCustomFieldPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleUpdatePutRaw(requestParameters: ApiServicesAppContentArticleUpdatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContentArticleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/ContentArticle/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentArticleDtoToJSON(requestParameters.contentArticleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiServicesAppContentArticleUpdatePut(requestParameters: ApiServicesAppContentArticleUpdatePutRequest, initOverrides?: RequestInit): Promise<ContentArticleDto> {
        const response = await this.apiServicesAppContentArticleUpdatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiServicesAppContentArticleUpdateSortPutRaw(requestParameters: ApiServicesAppContentArticleUpdateSortPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/ContentArticle/UpdateSort`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppContentArticleUpdateSortPut(requestParameters: ApiServicesAppContentArticleUpdateSortPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppContentArticleUpdateSortPutRaw(requestParameters, initOverrides);
    }

}
