/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function ClientTypeFromJSON(json: any): ClientType {
    return ClientTypeFromJSONTyped(json, false);
}

export function ClientTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientType {
    return json as ClientType;
}

export function ClientTypeToJSON(value?: ClientType | null): any {
    return value as any;
}

