/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductPropertyDto
 */
export interface ProductPropertyDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPropertyDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPropertyDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPropertyDto
     */
    propertyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPropertyDto
     */
    propertyValue?: string | null;
}

export function ProductPropertyDtoFromJSON(json: any): ProductPropertyDto {
    return ProductPropertyDtoFromJSONTyped(json, false);
}

export function ProductPropertyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPropertyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'propertyName': !exists(json, 'propertyName') ? undefined : json['propertyName'],
        'propertyValue': !exists(json, 'propertyValue') ? undefined : json['propertyValue'],
    };
}

export function ProductPropertyDtoToJSON(value?: ProductPropertyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'propertyName': value.propertyName,
        'propertyValue': value.propertyValue,
    };
}

