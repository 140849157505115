/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderIntent,
    OrderIntentFromJSON,
    OrderIntentFromJSONTyped,
    OrderIntentToJSON,
} from './OrderIntent';
import {
    OrderItemListDto,
    OrderItemListDtoFromJSON,
    OrderItemListDtoFromJSONTyped,
    OrderItemListDtoToJSON,
} from './OrderItemListDto';
import {
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import {
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
} from './SubscriptionStatus';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface AdminOrderListDto
 */
export interface AdminOrderListDto {
    /**
     * 
     * @type {number}
     * @memberof AdminOrderListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderListDto
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof AdminOrderListDto
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    readonly userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderListDto
     */
    beneficiaryUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof AdminOrderListDto
     */
    beneficiary?: User;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    readonly beneficiaryUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    transactionId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderListDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    remark?: string | null;
    /**
     * 
     * @type {OrderIntent}
     * @memberof AdminOrderListDto
     */
    orderIntent?: OrderIntent;
    /**
     * 
     * @type {OrderStatus}
     * @memberof AdminOrderListDto
     */
    status?: OrderStatus;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AdminOrderListDto
     */
    subscriptionStatus?: SubscriptionStatus;
    /**
     * 
     * @type {Array<OrderItemListDto>}
     * @memberof AdminOrderListDto
     */
    items?: Array<OrderItemListDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    readonly itemsSummary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    gateway?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderListDto
     */
    externalTransactionId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AdminOrderListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminOrderListDto
     */
    lastModificationTime?: Date | null;
}

export function AdminOrderListDtoFromJSON(json: any): AdminOrderListDto {
    return AdminOrderListDtoFromJSONTyped(json, false);
}

export function AdminOrderListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminOrderListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'beneficiaryUserId': !exists(json, 'beneficiaryUserId') ? undefined : json['beneficiaryUserId'],
        'beneficiary': !exists(json, 'beneficiary') ? undefined : UserFromJSON(json['beneficiary']),
        'beneficiaryUserName': !exists(json, 'beneficiaryUserName') ? undefined : json['beneficiaryUserName'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'remark': !exists(json, 'remark') ? undefined : json['remark'],
        'orderIntent': !exists(json, 'orderIntent') ? undefined : OrderIntentFromJSON(json['orderIntent']),
        'status': !exists(json, 'status') ? undefined : OrderStatusFromJSON(json['status']),
        'subscriptionStatus': !exists(json, 'subscriptionStatus') ? undefined : SubscriptionStatusFromJSON(json['subscriptionStatus']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(OrderItemListDtoFromJSON)),
        'itemsSummary': !exists(json, 'itemsSummary') ? undefined : json['itemsSummary'],
        'gateway': !exists(json, 'gateway') ? undefined : json['gateway'],
        'externalTransactionId': !exists(json, 'externalTransactionId') ? undefined : json['externalTransactionId'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function AdminOrderListDtoToJSON(value?: AdminOrderListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'beneficiaryUserId': value.beneficiaryUserId,
        'beneficiary': UserToJSON(value.beneficiary),
        'transactionId': value.transactionId,
        'amount': value.amount,
        'currency': value.currency,
        'remark': value.remark,
        'orderIntent': OrderIntentToJSON(value.orderIntent),
        'status': OrderStatusToJSON(value.status),
        'subscriptionStatus': SubscriptionStatusToJSON(value.subscriptionStatus),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(OrderItemListDtoToJSON)),
        'gateway': value.gateway,
        'externalTransactionId': value.externalTransactionId,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

