/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationInfoDto,
    ApplicationInfoDtoFromJSON,
    ApplicationInfoDtoFromJSONTyped,
    ApplicationInfoDtoToJSON,
} from './ApplicationInfoDto';
import {
    TenantLoginInfoDto,
    TenantLoginInfoDtoFromJSON,
    TenantLoginInfoDtoFromJSONTyped,
    TenantLoginInfoDtoToJSON,
} from './TenantLoginInfoDto';
import {
    UserLoginInfoDto,
    UserLoginInfoDtoFromJSON,
    UserLoginInfoDtoFromJSONTyped,
    UserLoginInfoDtoToJSON,
} from './UserLoginInfoDto';

/**
 * 
 * @export
 * @interface GetCurrentLoginInformationsOutput
 */
export interface GetCurrentLoginInformationsOutput {
    /**
     * 
     * @type {ApplicationInfoDto}
     * @memberof GetCurrentLoginInformationsOutput
     */
    application?: ApplicationInfoDto;
    /**
     * 
     * @type {UserLoginInfoDto}
     * @memberof GetCurrentLoginInformationsOutput
     */
    user?: UserLoginInfoDto;
    /**
     * 
     * @type {TenantLoginInfoDto}
     * @memberof GetCurrentLoginInformationsOutput
     */
    tenant?: TenantLoginInfoDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCurrentLoginInformationsOutput
     */
    roles?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCurrentLoginInformationsOutput
     */
    permissions?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetCurrentLoginInformationsOutput
     */
    profiles?: { [key: string]: any; } | null;
}

export function GetCurrentLoginInformationsOutputFromJSON(json: any): GetCurrentLoginInformationsOutput {
    return GetCurrentLoginInformationsOutputFromJSONTyped(json, false);
}

export function GetCurrentLoginInformationsOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentLoginInformationsOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'application': !exists(json, 'application') ? undefined : ApplicationInfoDtoFromJSON(json['application']),
        'user': !exists(json, 'user') ? undefined : UserLoginInfoDtoFromJSON(json['user']),
        'tenant': !exists(json, 'tenant') ? undefined : TenantLoginInfoDtoFromJSON(json['tenant']),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'profiles': !exists(json, 'profiles') ? undefined : json['profiles'],
    };
}

export function GetCurrentLoginInformationsOutputToJSON(value?: GetCurrentLoginInformationsOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'application': ApplicationInfoDtoToJSON(value.application),
        'user': UserLoginInfoDtoToJSON(value.user),
        'tenant': TenantLoginInfoDtoToJSON(value.tenant),
        'roles': value.roles,
        'permissions': value.permissions,
        'profiles': value.profiles,
    };
}

