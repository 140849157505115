/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface SubjectQuestionTypeSetting
 */
export interface SubjectQuestionTypeSetting {
    /**
     * 
     * @type {number}
     * @memberof SubjectQuestionTypeSetting
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectQuestionTypeSetting
     */
    subjectId?: number;
    /**
     * 
     * @type {Subject}
     * @memberof SubjectQuestionTypeSetting
     */
    subject?: Subject;
    /**
     * 
     * @type {QuestionType}
     * @memberof SubjectQuestionTypeSetting
     */
    questionType?: QuestionType;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectQuestionTypeSetting
     */
    webEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectQuestionTypeSetting
     */
    appEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectQuestionTypeSetting
     */
    exportEnabled?: boolean;
}

export function SubjectQuestionTypeSettingFromJSON(json: any): SubjectQuestionTypeSetting {
    return SubjectQuestionTypeSettingFromJSONTyped(json, false);
}

export function SubjectQuestionTypeSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectQuestionTypeSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'subject': !exists(json, 'subject') ? undefined : SubjectFromJSON(json['subject']),
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeFromJSON(json['questionType']),
        'webEnabled': !exists(json, 'webEnabled') ? undefined : json['webEnabled'],
        'appEnabled': !exists(json, 'appEnabled') ? undefined : json['appEnabled'],
        'exportEnabled': !exists(json, 'exportEnabled') ? undefined : json['exportEnabled'],
    };
}

export function SubjectQuestionTypeSettingToJSON(value?: SubjectQuestionTypeSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subjectId': value.subjectId,
        'subject': SubjectToJSON(value.subject),
        'questionType': QuestionTypeToJSON(value.questionType),
        'webEnabled': value.webEnabled,
        'appEnabled': value.appEnabled,
        'exportEnabled': value.exportEnabled,
    };
}

