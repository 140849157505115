/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GradeTranslationDto,
    GradeTranslationDtoFromJSON,
    GradeTranslationDtoFromJSONTyped,
    GradeTranslationDtoToJSON,
} from './GradeTranslationDto';

/**
 * 
 * @export
 * @interface GradeDto
 */
export interface GradeDto {
    /**
     * 
     * @type {number}
     * @memberof GradeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GradeDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GradeDto
     */
    stageCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GradeDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<GradeTranslationDto>}
     * @memberof GradeDto
     */
    translations?: Array<GradeTranslationDto> | null;
}

export function GradeDtoFromJSON(json: any): GradeDto {
    return GradeDtoFromJSONTyped(json, false);
}

export function GradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': json['code'],
        'stageCode': !exists(json, 'stageCode') ? undefined : json['stageCode'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(GradeTranslationDtoFromJSON)),
    };
}

export function GradeDtoToJSON(value?: GradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'stageCode': value.stageCode,
        'isActive': value.isActive,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(GradeTranslationDtoToJSON)),
    };
}

