/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroupTranslationDto,
    StudentGroupTranslationDtoFromJSON,
    StudentGroupTranslationDtoFromJSONTyped,
    StudentGroupTranslationDtoToJSON,
} from './StudentGroupTranslationDto';

/**
 * 
 * @export
 * @interface StudentGroupUpdateInput
 */
export interface StudentGroupUpdateInput {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupUpdateInput
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupUpdateInput
     */
    schoolId?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupUpdateInput
     */
    gradeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupUpdateInput
     */
    studentGroupTagId?: number | null;
    /**
     * 
     * @type {Array<StudentGroupTranslationDto>}
     * @memberof StudentGroupUpdateInput
     */
    translations?: Array<StudentGroupTranslationDto> | null;
}

export function StudentGroupUpdateInputFromJSON(json: any): StudentGroupUpdateInput {
    return StudentGroupUpdateInputFromJSONTyped(json, false);
}

export function StudentGroupUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'gradeId': !exists(json, 'gradeId') ? undefined : json['gradeId'],
        'studentGroupTagId': !exists(json, 'studentGroupTagId') ? undefined : json['studentGroupTagId'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StudentGroupTranslationDtoFromJSON)),
    };
}

export function StudentGroupUpdateInputToJSON(value?: StudentGroupUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'gradeId': value.gradeId,
        'studentGroupTagId': value.studentGroupTagId,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StudentGroupTranslationDtoToJSON)),
    };
}

