/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface QuestionNavigator
 */
export interface QuestionNavigator {
    /**
     * 
     * @type {number}
     * @memberof QuestionNavigator
     */
    id?: number;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionNavigator
     */
    questionType?: QuestionType;
}

export function QuestionNavigatorFromJSON(json: any): QuestionNavigator {
    return QuestionNavigatorFromJSONTyped(json, false);
}

export function QuestionNavigatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionNavigator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeFromJSON(json['questionType']),
    };
}

export function QuestionNavigatorToJSON(value?: QuestionNavigator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'questionType': QuestionTypeToJSON(value.questionType),
    };
}

