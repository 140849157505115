/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductItemDto,
    ProductItemDtoFromJSON,
    ProductItemDtoFromJSONTyped,
    ProductItemDtoToJSON,
} from './ProductItemDto';
import {
    ProductPropertyDto,
    ProductPropertyDtoFromJSON,
    ProductPropertyDtoFromJSONTyped,
    ProductPropertyDtoToJSON,
} from './ProductPropertyDto';
import {
    ProductPurchaseMethod,
    ProductPurchaseMethodFromJSON,
    ProductPurchaseMethodFromJSONTyped,
    ProductPurchaseMethodToJSON,
} from './ProductPurchaseMethod';
import {
    ProductTranslationDto,
    ProductTranslationDtoFromJSON,
    ProductTranslationDtoFromJSONTyped,
    ProductTranslationDtoToJSON,
} from './ProductTranslationDto';

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    id?: number;
    /**
     * 
     * @type {ProductPurchaseMethod}
     * @memberof ProductDto
     */
    productPurchaseMethod?: ProductPurchaseMethod;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    autoRenew?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    originPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    sort?: number;
    /**
     * 
     * @type {Array<ProductItemDto>}
     * @memberof ProductDto
     */
    items?: Array<ProductItemDto> | null;
    /**
     * 
     * @type {Array<ProductTranslationDto>}
     * @memberof ProductDto
     */
    translations?: Array<ProductTranslationDto> | null;
    /**
     * 
     * @type {Array<ProductPropertyDto>}
     * @memberof ProductDto
     */
    properties?: Array<ProductPropertyDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    lastModificationTime?: Date | null;
}

export function ProductDtoFromJSON(json: any): ProductDto {
    return ProductDtoFromJSONTyped(json, false);
}

export function ProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productPurchaseMethod': !exists(json, 'productPurchaseMethod') ? undefined : ProductPurchaseMethodFromJSON(json['productPurchaseMethod']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'autoRenew': !exists(json, 'autoRenew') ? undefined : json['autoRenew'],
        'originPrice': !exists(json, 'originPrice') ? undefined : json['originPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ProductItemDtoFromJSON)),
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(ProductTranslationDtoFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : (json['properties'] === null ? null : (json['properties'] as Array<any>).map(ProductPropertyDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function ProductDtoToJSON(value?: ProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productPurchaseMethod': ProductPurchaseMethodToJSON(value.productPurchaseMethod),
        'isActive': value.isActive,
        'autoRenew': value.autoRenew,
        'originPrice': value.originPrice,
        'price': value.price,
        'currency': value.currency,
        'sort': value.sort,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ProductItemDtoToJSON)),
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(ProductTranslationDtoToJSON)),
        'properties': value.properties === undefined ? undefined : (value.properties === null ? null : (value.properties as Array<any>).map(ProductPropertyDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

