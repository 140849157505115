/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FAQCategoryTranslationDto,
    FAQCategoryTranslationDtoFromJSON,
    FAQCategoryTranslationDtoFromJSONTyped,
    FAQCategoryTranslationDtoToJSON,
} from './FAQCategoryTranslationDto';

/**
 * 
 * @export
 * @interface FAQCategoryDto
 */
export interface FAQCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof FAQCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FAQCategoryDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FAQCategoryDto
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FAQCategoryDto
     */
    sort?: number;
    /**
     * 
     * @type {Array<FAQCategoryTranslationDto>}
     * @memberof FAQCategoryDto
     */
    translations?: Array<FAQCategoryTranslationDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof FAQCategoryDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof FAQCategoryDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FAQCategoryDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FAQCategoryDto
     */
    lastModificationTime?: Date | null;
}

export function FAQCategoryDtoFromJSON(json: any): FAQCategoryDto {
    return FAQCategoryDtoFromJSONTyped(json, false);
}

export function FAQCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(FAQCategoryTranslationDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function FAQCategoryDtoToJSON(value?: FAQCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'icon': value.icon,
        'sort': value.sort,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(FAQCategoryTranslationDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

