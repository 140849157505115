/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    Lesson,
    LessonFromJSON,
    LessonFromJSONTyped,
    LessonToJSON,
} from './Lesson';
import {
    SubjectQuestionTypeSetting,
    SubjectQuestionTypeSettingFromJSON,
    SubjectQuestionTypeSettingFromJSONTyped,
    SubjectQuestionTypeSettingToJSON,
} from './SubjectQuestionTypeSetting';
import {
    SubjectTranslation,
    SubjectTranslationFromJSON,
    SubjectTranslationFromJSONTyped,
    SubjectTranslationToJSON,
} from './SubjectTranslation';
import {
    SubjectType,
    SubjectTypeFromJSON,
    SubjectTypeFromJSONTyped,
    SubjectTypeToJSON,
} from './SubjectType';
import {
    TestRange,
    TestRangeFromJSON,
    TestRangeFromJSONTyped,
    TestRangeToJSON,
} from './TestRange';

/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    icon?: string | null;
    /**
     * 
     * @type {SubjectType}
     * @memberof Subject
     */
    subjectType?: SubjectType;
    /**
     * 
     * @type {boolean}
     * @memberof Subject
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    term?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    sort?: number;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    lastModificationTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    deleterUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    deletionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Subject
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<SubjectTranslation>}
     * @memberof Subject
     */
    translations?: Array<SubjectTranslation> | null;
    /**
     * 
     * @type {Array<TestRange>}
     * @memberof Subject
     */
    testRanges?: Array<TestRange> | null;
    /**
     * 
     * @type {Array<Lesson>}
     * @memberof Subject
     */
    lessons?: Array<Lesson> | null;
    /**
     * 
     * @type {Array<SubjectQuestionTypeSetting>}
     * @memberof Subject
     */
    questionTypeSettings?: Array<SubjectQuestionTypeSetting> | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    gradeCode?: string | null;
    /**
     * 
     * @type {Grade}
     * @memberof Subject
     */
    grade?: Grade;
}

export function SubjectFromJSON(json: any): Subject {
    return SubjectFromJSONTyped(json, false);
}

export function SubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'subjectType': !exists(json, 'subjectType') ? undefined : SubjectTypeFromJSON(json['subjectType']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
        'deleterUserId': !exists(json, 'deleterUserId') ? undefined : json['deleterUserId'],
        'deletionTime': !exists(json, 'deletionTime') ? undefined : (json['deletionTime'] === null ? null : new Date(json['deletionTime'])),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(SubjectTranslationFromJSON)),
        'testRanges': !exists(json, 'testRanges') ? undefined : (json['testRanges'] === null ? null : (json['testRanges'] as Array<any>).map(TestRangeFromJSON)),
        'lessons': !exists(json, 'lessons') ? undefined : (json['lessons'] === null ? null : (json['lessons'] as Array<any>).map(LessonFromJSON)),
        'questionTypeSettings': !exists(json, 'questionTypeSettings') ? undefined : (json['questionTypeSettings'] === null ? null : (json['questionTypeSettings'] as Array<any>).map(SubjectQuestionTypeSettingFromJSON)),
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
    };
}

export function SubjectToJSON(value?: Subject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'icon': value.icon,
        'subjectType': SubjectTypeToJSON(value.subjectType),
        'isActive': value.isActive,
        'term': value.term,
        'sort': value.sort,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
        'deleterUserId': value.deleterUserId,
        'deletionTime': value.deletionTime === undefined ? undefined : (value.deletionTime === null ? null : value.deletionTime.toISOString()),
        'isDeleted': value.isDeleted,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(SubjectTranslationToJSON)),
        'testRanges': value.testRanges === undefined ? undefined : (value.testRanges === null ? null : (value.testRanges as Array<any>).map(TestRangeToJSON)),
        'lessons': value.lessons === undefined ? undefined : (value.lessons === null ? null : (value.lessons as Array<any>).map(LessonToJSON)),
        'questionTypeSettings': value.questionTypeSettings === undefined ? undefined : (value.questionTypeSettings === null ? null : (value.questionTypeSettings as Array<any>).map(SubjectQuestionTypeSettingToJSON)),
        'gradeCode': value.gradeCode,
        'grade': GradeToJSON(value.grade),
    };
}

