import React, { PropsWithChildren, useEffect, useRef } from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import Image from "next/legacy/image";
import MouseParallax from '@/components/display/MouseParallax/MouseParallax';
import clsx from 'clsx';
import CardView from '@/components/common/CardView/CardView';
import MouseParallaxContainer from '@/components/display/MouseParallax/MouseParallaxContainer';
import teen_group_1 from '@/assets/images/auth/teen_group_1.png';
import teen_group_2 from '@/assets/images/auth/teen_group_2.png';
import logo from '@/assets/images/logo.png';
// import Logo from '@/assets/images/logo.svg';
import BackgroundImage from '@/components/common/BackgroundImage/BackgroundImage';
import CombinedLink from '@/components/common/CombinedLink/CombinedLink';
import { createUseStyles } from 'react-jss';
import { getCsrfToken, useSession } from 'next-auth/react';
import LoginGraphic from "public/icons/auth/login-graphics.svg"
import LoginCharacterIcon from "public/icons/auth/login-character.svg"
import LoginBackground from "public/icons/auth/login-bubble.svg"

export interface AuthTemplateProps {
  showStepper?: boolean;
  totalStep?: number;
  currentStep?: number;
  variant: AuthTemplateVariant;
}

export type AuthTemplateVariant = "halfScreen" | "fullScreen"

export const AuthTemplate = (props: PropsWithChildren<AuthTemplateProps>) => {
  const { showStepper, totalStep, currentStep, children, variant } = props;  
  const { data: session, status } = useSession()

  const steps = [];

  if (showStepper && totalStep && currentStep) {
    for (let i = 0; i < totalStep; i++) {
      if (currentStep >= i + 1) {
        steps.push(<div className={clsx("bg-primary-500 h-3.5 w-20 mx-5")} />);
      } else {
        steps.push(<div className={clsx("bg-gray-400 h-3.5 w-20 mx-5")} />);
      }
    }
  }
  
  return (<div className="relative   min-h-screen h-full overflow-auto flex flex-row items-center    ">


    <MouseParallax 
      className='py-4 h-full relative container mx-auto   flex flex-col  justify-stretch items-stretch'>
      
      <div className='h-auto flex-1   w-full flex flex-row justify-center  items-center gap-4 xl:gap-[4.5rem]'>


        <div className="relative flex-1   hidden md:block   self-stretch  ">
          <MouseParallax.Container 
            className="h-full w-full  relative    " >
            <MouseParallax.Layer translateRatio={[100, 100]} className='w-full h-full z-10 '>
              <LoginGraphic className="w-full h-full   max-w-3xl " />
            </MouseParallax.Layer>
            <MouseParallax.Layer   translateRatio={[80,80]}  className='  w-full h-full z-10' >
              <LoginCharacterIcon  className=" w-auto h-96  absolute right-[5%]   bottom-[10%] "  />
            </MouseParallax.Layer>
            

            <MouseParallax.Layer translateRatio={[60, 60]} className=' !fixed z-0  left-0 right-0 bottom-0   '>
              <LoginBackground className="w-auto md:w-full h-full md:h-auto  " />
            </MouseParallax.Layer>
          </MouseParallax.Container>
        </div>
       
  

        <div className={clsx("flex-1 z-10 relative  lg:flex lg:justify-center ")}>
          <div className='relative block md:hidden w-[60%]  min-w-[15rem]  mx-auto mb-4 z-auto'>
            <LoginGraphic className="w-full h-auto   max-w-3xl z-10" />
            <LoginCharacterIcon  className=" w-auto h-[50%]  absolute right-[5%]   bottom-[5%]  z-10"  />
          </div>
          {/* {showStepper && totalStep && currentStep &&
          <div className={clsx("w-auto h-auto self-center absolute left-1/2 -translate-x-1/2 top-56 sm:top-40")}>
            <div className={clsx("flex")}>
              {
                steps.map(it => it)
              }
            </div>
          </div>
          } */}

          <div  className={clsx("z-10 bg-white  px-10 lg:px-20 py-6  lg:py-20 rounded-3xl shadow-answerCard  max-w-[40rem] w-full h-auto self-center",
            "")}>
            {children}

          </div>

        </div>
      </div>


  
      <div className={clsx("z-10 h-20   flex flex-row items-center justify-center w-full  text-center text-sm lg:text-base  text-gray-500 ")}
      >
        {/* TODO: make it get content from remote server */}
        &copy; 2023 TP GROUP EDUCATION LIMITED All Rights Reserved. Designed by CoolTech.
      </div>
      
    </MouseParallax>

  </div>)
}