/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodImplAttributes {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_8 = 8,
    NUMBER_16 = 16,
    NUMBER_32 = 32,
    NUMBER_64 = 64,
    NUMBER_128 = 128,
    NUMBER_256 = 256,
    NUMBER_512 = 512,
    NUMBER_4096 = 4096,
    NUMBER_65535 = 65535
}

export function MethodImplAttributesFromJSON(json: any): MethodImplAttributes {
    return MethodImplAttributesFromJSONTyped(json, false);
}

export function MethodImplAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodImplAttributes {
    return json as MethodImplAttributes;
}

export function MethodImplAttributesToJSON(value?: MethodImplAttributes | null): any {
    return value as any;
}

