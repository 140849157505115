/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSettingDto,
    MemberSettingDtoFromJSON,
    MemberSettingDtoFromJSONTyped,
    MemberSettingDtoToJSON,
} from './MemberSettingDto';

/**
 * 
 * @export
 * @interface AdminUpdateMemberInput
 */
export interface AdminUpdateMemberInput {
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateMemberInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateMemberInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateMemberInput
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateMemberInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateMemberInput
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateMemberInput
     */
    emailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateMemberInput
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<MemberSettingDto>}
     * @memberof AdminUpdateMemberInput
     */
    settings?: Array<MemberSettingDto> | null;
}

export function AdminUpdateMemberInputFromJSON(json: any): AdminUpdateMemberInput {
    return AdminUpdateMemberInputFromJSONTyped(json, false);
}

export function AdminUpdateMemberInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdateMemberInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': json['userName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'name': json['name'],
        'surname': json['surname'],
        'emailAddress': json['emailAddress'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'settings': !exists(json, 'settings') ? undefined : (json['settings'] === null ? null : (json['settings'] as Array<any>).map(MemberSettingDtoFromJSON)),
    };
}

export function AdminUpdateMemberInputToJSON(value?: AdminUpdateMemberInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'password': value.password,
        'name': value.name,
        'surname': value.surname,
        'emailAddress': value.emailAddress,
        'isActive': value.isActive,
        'settings': value.settings === undefined ? undefined : (value.settings === null ? null : (value.settings as Array<any>).map(MemberSettingDtoToJSON)),
    };
}

