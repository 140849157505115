/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExport,
    ExaminationExportFromJSON,
    ExaminationExportFromJSONTyped,
    ExaminationExportToJSON,
} from './ExaminationExport';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ExaminationExportAssignee
 */
export interface ExaminationExportAssignee {
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssignee
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssignee
     */
    examinationExportId?: number;
    /**
     * 
     * @type {ExaminationExport}
     * @memberof ExaminationExportAssignee
     */
    examinationExport?: ExaminationExport;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportAssignee
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ExaminationExportAssignee
     */
    user?: User;
}

export function ExaminationExportAssigneeFromJSON(json: any): ExaminationExportAssignee {
    return ExaminationExportAssigneeFromJSONTyped(json, false);
}

export function ExaminationExportAssigneeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportAssignee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'examinationExportId': !exists(json, 'examinationExportId') ? undefined : json['examinationExportId'],
        'examinationExport': !exists(json, 'examinationExport') ? undefined : ExaminationExportFromJSON(json['examinationExport']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function ExaminationExportAssigneeToJSON(value?: ExaminationExportAssignee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examinationExportId': value.examinationExportId,
        'examinationExport': ExaminationExportToJSON(value.examinationExport),
        'userId': value.userId,
        'user': UserToJSON(value.user),
    };
}

