/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeUiThemeInput,
    ChangeUiThemeInputFromJSON,
    ChangeUiThemeInputToJSON,
} from '../models';

export interface ApiServicesAppConfigurationChangeUiThemePostRequest {
    changeUiThemeInput?: ChangeUiThemeInput;
}

/**
 * 
 */
export class ConfigurationApi extends runtime.BaseAPI {

    /**
     */
    async apiServicesAppConfigurationChangeUiThemePostRaw(requestParameters: ApiServicesAppConfigurationChangeUiThemePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/services/app/Configuration/ChangeUiTheme`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUiThemeInputToJSON(requestParameters.changeUiThemeInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppConfigurationChangeUiThemePost(requestParameters: ApiServicesAppConfigurationChangeUiThemePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppConfigurationChangeUiThemePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiServicesAppConfigurationClearCachePostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Configuration/ClearCache`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppConfigurationClearCachePost(initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppConfigurationClearCachePostRaw(initOverrides);
    }

    /**
     */
    async apiServicesAppConfigurationGetDefaultValuesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services/app/Configuration/GetDefaultValues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicesAppConfigurationGetDefaultValuesGet(initOverrides?: RequestInit): Promise<void> {
        await this.apiServicesAppConfigurationGetDefaultValuesGetRaw(initOverrides);
    }

}
