/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudentLessonProgress
 */
export interface StudentLessonProgress {
    /**
     * 
     * @type {number}
     * @memberof StudentLessonProgress
     */
    lessonId?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentLessonProgress
     */
    lessonName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StudentLessonProgress
     */
    totalQuestion?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentLessonProgress
     */
    seenQuestion?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentLessonProgress
     */
    correctedQuestion?: number;
    /**
     * 
     * @type {Date}
     * @memberof StudentLessonProgress
     */
    lastSeenTime?: Date;
}

export function StudentLessonProgressFromJSON(json: any): StudentLessonProgress {
    return StudentLessonProgressFromJSONTyped(json, false);
}

export function StudentLessonProgressFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentLessonProgress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
        'lessonName': !exists(json, 'lessonName') ? undefined : json['lessonName'],
        'totalQuestion': !exists(json, 'totalQuestion') ? undefined : json['totalQuestion'],
        'seenQuestion': !exists(json, 'seenQuestion') ? undefined : json['seenQuestion'],
        'correctedQuestion': !exists(json, 'correctedQuestion') ? undefined : json['correctedQuestion'],
        'lastSeenTime': !exists(json, 'lastSeenTime') ? undefined : (new Date(json['lastSeenTime'])),
    };
}

export function StudentLessonProgressToJSON(value?: StudentLessonProgress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lessonId': value.lessonId,
        'lessonName': value.lessonName,
        'totalQuestion': value.totalQuestion,
        'seenQuestion': value.seenQuestion,
        'correctedQuestion': value.correctedQuestion,
        'lastSeenTime': value.lastSeenTime === undefined ? undefined : (value.lastSeenTime.toISOString()),
    };
}

