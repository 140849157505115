/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLogin
 */
export interface UserLogin {
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    tenantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    loginProvider: string;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    providerKey: string;
}

export function UserLoginFromJSON(json: any): UserLogin {
    return UserLoginFromJSONTyped(json, false);
}

export function UserLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'loginProvider': json['loginProvider'],
        'providerKey': json['providerKey'],
    };
}

export function UserLoginToJSON(value?: UserLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'loginProvider': value.loginProvider,
        'providerKey': value.providerKey,
    };
}

