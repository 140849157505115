/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

export function OrderStatusFromJSON(json: any): OrderStatus {
    return OrderStatusFromJSONTyped(json, false);
}

export function OrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatus {
    return json as OrderStatus;
}

export function OrderStatusToJSON(value?: OrderStatus | null): any {
    return value as any;
}

