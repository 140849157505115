/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentGroupTagTranslationDto,
    StudentGroupTagTranslationDtoFromJSON,
    StudentGroupTagTranslationDtoFromJSONTyped,
    StudentGroupTagTranslationDtoToJSON,
} from './StudentGroupTagTranslationDto';

/**
 * 
 * @export
 * @interface StudentGroupTagDetailDto
 */
export interface StudentGroupTagDetailDto {
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTagDetailDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentGroupTagDetailDto
     */
    schoolId?: number;
    /**
     * 
     * @type {Array<StudentGroupTagTranslationDto>}
     * @memberof StudentGroupTagDetailDto
     */
    translations?: Array<StudentGroupTagTranslationDto> | null;
}

export function StudentGroupTagDetailDtoFromJSON(json: any): StudentGroupTagDetailDto {
    return StudentGroupTagDetailDtoFromJSONTyped(json, false);
}

export function StudentGroupTagDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentGroupTagDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'translations': !exists(json, 'translations') ? undefined : (json['translations'] === null ? null : (json['translations'] as Array<any>).map(StudentGroupTagTranslationDtoFromJSON)),
    };
}

export function StudentGroupTagDetailDtoToJSON(value?: StudentGroupTagDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'translations': value.translations === undefined ? undefined : (value.translations === null ? null : (value.translations as Array<any>).map(StudentGroupTagTranslationDtoToJSON)),
    };
}

