/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';
import {
    TestType,
    TestTypeFromJSON,
    TestTypeFromJSONTyped,
    TestTypeToJSON,
} from './TestType';

/**
 * 
 * @export
 * @interface CreateDailyPracticeInput
 */
export interface CreateDailyPracticeInput {
    /**
     * 
     * @type {number}
     * @memberof CreateDailyPracticeInput
     */
    subjectId: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateDailyPracticeInput
     */
    date: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDailyPracticeInput
     */
    lessonIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyPracticeInput
     */
    questionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyPracticeInput
     */
    testTimeLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyPracticeInput
     */
    userId: number;
    /**
     * 
     * @type {TestType}
     * @memberof CreateDailyPracticeInput
     */
    testType?: TestType;
    /**
     * 
     * @type {Array<QuestionType>}
     * @memberof CreateDailyPracticeInput
     */
    questionTypes?: Array<QuestionType> | null;
}

export function CreateDailyPracticeInputFromJSON(json: any): CreateDailyPracticeInput {
    return CreateDailyPracticeInputFromJSONTyped(json, false);
}

export function CreateDailyPracticeInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDailyPracticeInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectId': json['subjectId'],
        'date': (new Date(json['date'])),
        'lessonIds': !exists(json, 'lessonIds') ? undefined : json['lessonIds'],
        'questionCount': !exists(json, 'questionCount') ? undefined : json['questionCount'],
        'testTimeLimit': !exists(json, 'testTimeLimit') ? undefined : json['testTimeLimit'],
        'userId': json['userId'],
        'testType': !exists(json, 'testType') ? undefined : TestTypeFromJSON(json['testType']),
        'questionTypes': !exists(json, 'questionTypes') ? undefined : (json['questionTypes'] === null ? null : (json['questionTypes'] as Array<any>).map(QuestionTypeFromJSON)),
    };
}

export function CreateDailyPracticeInputToJSON(value?: CreateDailyPracticeInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectId': value.subjectId,
        'date': (value.date.toISOString()),
        'lessonIds': value.lessonIds,
        'questionCount': value.questionCount,
        'testTimeLimit': value.testTimeLimit,
        'userId': value.userId,
        'testType': TestTypeToJSON(value.testType),
        'questionTypes': value.questionTypes === undefined ? undefined : (value.questionTypes === null ? null : (value.questionTypes as Array<any>).map(QuestionTypeToJSON)),
    };
}

