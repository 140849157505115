/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventDto
 */
export interface UserEventDto {
    /**
     * 
     * @type {string}
     * @memberof UserEventDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEventDto
     */
    eventCategory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEventDto
     */
    eventAction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEventDto
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserEventDto
     */
    value?: number;
}

export function UserEventDtoFromJSON(json: any): UserEventDto {
    return UserEventDtoFromJSONTyped(json, false);
}

export function UserEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'eventCategory': !exists(json, 'eventCategory') ? undefined : json['eventCategory'],
        'eventAction': !exists(json, 'eventAction') ? undefined : json['eventAction'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function UserEventDtoToJSON(value?: UserEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'eventCategory': value.eventCategory,
        'eventAction': value.eventAction,
        'label': value.label,
        'value': value.value,
    };
}

