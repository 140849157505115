/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TicketStatus,
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface UpdateTicketStatusInput
 */
export interface UpdateTicketStatusInput {
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketStatusInput
     */
    id?: number;
    /**
     * 
     * @type {TicketStatus}
     * @memberof UpdateTicketStatusInput
     */
    status?: TicketStatus;
}

export function UpdateTicketStatusInputFromJSON(json: any): UpdateTicketStatusInput {
    return UpdateTicketStatusInputFromJSONTyped(json, false);
}

export function UpdateTicketStatusInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTicketStatusInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
    };
}

export function UpdateTicketStatusInputToJSON(value?: UpdateTicketStatusInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': TicketStatusToJSON(value.status),
    };
}

