/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExaminationExportQuestionOptionsDto,
    ExaminationExportQuestionOptionsDtoFromJSON,
    ExaminationExportQuestionOptionsDtoFromJSONTyped,
    ExaminationExportQuestionOptionsDtoToJSON,
} from './ExaminationExportQuestionOptionsDto';

/**
 * 
 * @export
 * @interface ExaminationExportQuestionOptionsDtoPagedResultDto
 */
export interface ExaminationExportQuestionOptionsDtoPagedResultDto {
    /**
     * 
     * @type {Array<ExaminationExportQuestionOptionsDto>}
     * @memberof ExaminationExportQuestionOptionsDtoPagedResultDto
     */
    items?: Array<ExaminationExportQuestionOptionsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExaminationExportQuestionOptionsDtoPagedResultDto
     */
    totalCount?: number;
}

export function ExaminationExportQuestionOptionsDtoPagedResultDtoFromJSON(json: any): ExaminationExportQuestionOptionsDtoPagedResultDto {
    return ExaminationExportQuestionOptionsDtoPagedResultDtoFromJSONTyped(json, false);
}

export function ExaminationExportQuestionOptionsDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExaminationExportQuestionOptionsDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ExaminationExportQuestionOptionsDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function ExaminationExportQuestionOptionsDtoPagedResultDtoToJSON(value?: ExaminationExportQuestionOptionsDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ExaminationExportQuestionOptionsDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

