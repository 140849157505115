/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    School,
    SchoolFromJSON,
    SchoolFromJSONTyped,
    SchoolToJSON,
} from './School';

/**
 * 
 * @export
 * @interface SchoolTranslation
 */
export interface SchoolTranslation {
    /**
     * 
     * @type {number}
     * @memberof SchoolTranslation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolTranslation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolTranslation
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolTranslation
     */
    schoolMaster?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolTranslation
     */
    contactName?: string | null;
    /**
     * 
     * @type {School}
     * @memberof SchoolTranslation
     */
    core?: School;
    /**
     * 
     * @type {number}
     * @memberof SchoolTranslation
     */
    coreId?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolTranslation
     */
    language?: string | null;
}

export function SchoolTranslationFromJSON(json: any): SchoolTranslation {
    return SchoolTranslationFromJSONTyped(json, false);
}

export function SchoolTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolTranslation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'schoolMaster': !exists(json, 'schoolMaster') ? undefined : json['schoolMaster'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'core': !exists(json, 'core') ? undefined : SchoolFromJSON(json['core']),
        'coreId': !exists(json, 'coreId') ? undefined : json['coreId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function SchoolTranslationToJSON(value?: SchoolTranslation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'schoolMaster': value.schoolMaster,
        'contactName': value.contactName,
        'core': SchoolToJSON(value.core),
        'coreId': value.coreId,
        'language': value.language,
    };
}

