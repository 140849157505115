/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminOrderListDto,
    AdminOrderListDtoFromJSON,
    AdminOrderListDtoFromJSONTyped,
    AdminOrderListDtoToJSON,
} from './AdminOrderListDto';

/**
 * 
 * @export
 * @interface AdminOrderListDtoPagedResultDto
 */
export interface AdminOrderListDtoPagedResultDto {
    /**
     * 
     * @type {Array<AdminOrderListDto>}
     * @memberof AdminOrderListDtoPagedResultDto
     */
    items?: Array<AdminOrderListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderListDtoPagedResultDto
     */
    totalCount?: number;
}

export function AdminOrderListDtoPagedResultDtoFromJSON(json: any): AdminOrderListDtoPagedResultDto {
    return AdminOrderListDtoPagedResultDtoFromJSONTyped(json, false);
}

export function AdminOrderListDtoPagedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminOrderListDtoPagedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(AdminOrderListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function AdminOrderListDtoPagedResultDtoToJSON(value?: AdminOrderListDtoPagedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(AdminOrderListDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

