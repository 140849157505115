/* tslint:disable */
/* eslint-disable */
/**
 * ELearn API
 * ELearn
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionType,
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface QuestionListDto
 */
export interface QuestionListDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionListDto
     */
    schoolId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionListDto
     */
    lessonId?: number | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionListDto
     */
    questionType?: QuestionType;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionListDto
     */
    isSubjective?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionListDto
     */
    readonly questionTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionListDto
     */
    questionBody?: string | null;
    /**
     * 
     * @type {any}
     * @memberof QuestionListDto
     */
    options?: any | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionListDto
     */
    displayAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionListDto
     */
    explain?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionListDto
     */
    stars?: number;
    /**
     * 
     * @type {any}
     * @memberof QuestionListDto
     */
    extra?: any | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionListDto
     */
    extraSerialize?: string | null;
    /**
     * 
     * @type {Array<QuestionListDto>}
     * @memberof QuestionListDto
     */
    subQuestions?: Array<QuestionListDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof QuestionListDto
     */
    creatorUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionListDto
     */
    lastModifierUserId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof QuestionListDto
     */
    lastModificationTime?: Date | null;
}

export function QuestionListDtoFromJSON(json: any): QuestionListDto {
    return QuestionListDtoFromJSONTyped(json, false);
}

export function QuestionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'schoolId': !exists(json, 'schoolId') ? undefined : json['schoolId'],
        'lessonId': !exists(json, 'lessonId') ? undefined : json['lessonId'],
        'questionType': !exists(json, 'questionType') ? undefined : QuestionTypeFromJSON(json['questionType']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isSubjective': !exists(json, 'isSubjective') ? undefined : json['isSubjective'],
        'questionTypeName': !exists(json, 'questionTypeName') ? undefined : json['questionTypeName'],
        'questionBody': !exists(json, 'questionBody') ? undefined : json['questionBody'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'displayAnswer': !exists(json, 'displayAnswer') ? undefined : json['displayAnswer'],
        'explain': !exists(json, 'explain') ? undefined : json['explain'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'extraSerialize': !exists(json, 'extraSerialize') ? undefined : json['extraSerialize'],
        'subQuestions': !exists(json, 'subQuestions') ? undefined : (json['subQuestions'] === null ? null : (json['subQuestions'] as Array<any>).map(QuestionListDtoFromJSON)),
        'creationTime': !exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'lastModifierUserId': !exists(json, 'lastModifierUserId') ? undefined : json['lastModifierUserId'],
        'lastModificationTime': !exists(json, 'lastModificationTime') ? undefined : (json['lastModificationTime'] === null ? null : new Date(json['lastModificationTime'])),
    };
}

export function QuestionListDtoToJSON(value?: QuestionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'schoolId': value.schoolId,
        'lessonId': value.lessonId,
        'questionType': QuestionTypeToJSON(value.questionType),
        'isActive': value.isActive,
        'isSubjective': value.isSubjective,
        'questionBody': value.questionBody,
        'options': value.options,
        'displayAnswer': value.displayAnswer,
        'explain': value.explain,
        'stars': value.stars,
        'extra': value.extra,
        'extraSerialize': value.extraSerialize,
        'subQuestions': value.subQuestions === undefined ? undefined : (value.subQuestions === null ? null : (value.subQuestions as Array<any>).map(QuestionListDtoToJSON)),
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'creatorUserId': value.creatorUserId,
        'lastModifierUserId': value.lastModifierUserId,
        'lastModificationTime': value.lastModificationTime === undefined ? undefined : (value.lastModificationTime === null ? null : value.lastModificationTime.toISOString()),
    };
}

