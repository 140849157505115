import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { SSRConfig, appWithTranslation, useTranslation } from 'next-i18next';
import { SessionProvider, signOut, useSession } from "next-auth/react"
import { UserDataProvider } from 'src/hooks/UserDataHook';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch' 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectQuestionPanelProvider } from 'src/hooks/SelectQuestionPanelHook';
import { DialogProvider } from 'src/hooks/dialog/DialogHook';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import "react-datepicker/dist/react-datepicker.css";
import { RecoilRoot } from 'recoil';
import { DailyPracticeProvider } from 'src/hooks/daily/DailyPracticeHook';
import { SocketProvider } from 'src/hooks/SocketHook';
import moment from 'moment';
import { CustPage } from 'src/views/types';
import { LayoutProvider, LayoutTypeToLayoutPropsType } from 'src/views/Layout/LayoutHook';

import "moment/locale/zh-tw";
import { useIsTouchDevice } from 'src/hooks/IsTouchDeviceHook';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useIsClient } from 'usehooks-ts';
import { PracticePopup } from '@/components/practicePopup/PracticePopup';
import { DefaultSeo } from 'next-seo';
moment.locale("zh-tw");
const queryClient = new QueryClient()
const defaultLayoutProps: LayoutTypeToLayoutPropsType = {
  "default": {
  },
  "auth":{
    variant: "halfScreen"
  },
  "empty":{

  }
};
function MyApp({
  Component,
  pageProps: { session, ...pageProps
  } }: AppProps & {pageProps:SSRConfig & {session:Session}}) {

  const {i18n} = useTranslation();
  //update moment when locale change
  useEffect(() => {
    moment.locale(i18n.language == "en" ? "en" : "zh-tw");
  }, [i18n.language, Component]);
  return (
    <SessionProvider session={session} refetchOnWindowFocus={true} refetchInterval={5 * 60/** 5 minutes */}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <UserDataProvider>
            <DndProvider options={HTML5toTouch} >
              <SocketProvider>
                <DialogProvider>
                  <DailyPracticeProvider>
                    <LayoutProvider
                      layoutType={(Component as CustPage).layoutTemplate}
                      layoutProps={defaultLayoutProps}
                      perPageLayoutProps={(Component as CustPage).layoutDefaultProps}>
                      <DefaultSeo
                        dangerouslySetAllPagesToNoFollow={true}
                        dangerouslySetAllPagesToNoIndex={true}
                        titleTemplate={"Studylu - %s"}
                      />
                      <Component {...pageProps} />

                      {/* <PracticePopup /> */}
                    </LayoutProvider>
                  </DailyPracticeProvider>
                  <ToastContainer position='bottom-right' />
                </DialogProvider>    
              </SocketProvider>
            </DndProvider>
          </UserDataProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </SessionProvider>
  )
}

export default appWithTranslation(MyApp);
